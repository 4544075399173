import { message, theme } from 'antd'
import { getAppToken, useAppURL, useConfig, useInstalledApp } from 'old/api'
import { HorizontalContainer, Loading, PageContentWrapper, VerticalContainer } from 'old/components/styled'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Config, Feature }  from 'old/types'
import { getShopId, nanoId, showMessage, useTranslate, useWindowDimensions }  from 'old/utils'
import { AdminHostAppLoader, AppTarget } from '@sesamiapp/app-message'
import { Avatar } from 'old/components/avatar/avatar'

export const AppLoader = () => {
    
    //hooks:
    const { data: config } = useConfig()
    const navigate = useNavigate()
    const params = useParams()

    //if id does not provided:
    useEffect(() => { !params.id && navigate('/app') }, [params.id])

    //if apps are not enabled:
    useEffect(() => { config && !config.features.includes(Feature.APPS) && navigate('/') }, [config])

    return (config && params.id) ? <AppLoaderContent appId={params.id} config={config}/> : <></>
    
}

type Props = {
    appId: string
    config: Config
}

export const AppLoaderContent = ({ appId, config }: Props) => {

    //hooks:
    const { token: { colorBgContainer, colorBgLayout, colorBorder, marginXXS } } = theme.useToken()
    const iframe = useRef<HTMLIFrameElement>(null)
    const { SM, LG } = useWindowDimensions()
    const navigate = useNavigate()
    const { __ } = useTranslate()

    //state:
    const [ timestamp ] = useState((new Date()).getTime())
    const [ messageId ] = useState(nanoId([appId, timestamp, config.locale], 8))
    const [ client, setClient ] = useState<AdminHostAppLoader | null>(null)
    const [ isIframeLoading, setIsIframeLoading ] = useState(true)
    const [ appURL, setAppURL ] = useState<string | null>(null)

    //api:
    const { data: app } = useInstalledApp(appId, () => navigate('/app'))
    const { data: url } = useAppURL(
        appId,
        null,
        AppTarget.ADMIN_APP_LOADER,
        timestamp,
        messageId,
        config.locale,
        null
    )

    // do not update app url:
    useEffect(() => {
        if(url && appURL === null){
            setAppURL(url)
        }
    }, [url])

    //startup:
    useEffect(() => {
        if(app && iframe.current && !client){
            const cl = new AdminHostAppLoader({
                messageId,
                shopId: getShopId() ?? '',
                locale: config.locale,
                onInitEnded: isInitialized => {
                    if(isInitialized){
                        setIsIframeLoading(false)
                    }else{
                        message.error(__`cannot_load_the_app`)
                    }
                },
                getToken,
                onNotification: showMessage,
                onBack: () => navigate('/app')
            })
            setClient(cl)
        }
    }, [app, iframe])

    const getToken = async () => {
        const response: any = await getAppToken(appId, null)
        try{
            const token = response.data.createAccessToken.token
            return token
        }catch(e){e}
        return null
    }

    return (
        <PageContentWrapper flex fullWidth noBottomPadding noFooter>

            <VerticalContainer style={{ width: '100%', gap: 0 }}>

                <HorizontalContainer style={{ height: 37, borderBottom: `solid 1px ${colorBorder}` }}>

                    {app && (
                        <Avatar
                            size='micro'
                            name={app.title}
                            image={app.icon.path}
                            style={{ paddingLeft: 0 }}
                        />
                    )}

                </HorizontalContainer>

                <div style={{
                    width: '100%',
                    height: '100%',
                    marginRight: LG ? -marginXXS : 0, // removing the effect of the layout's padding for app's scrollbar
                    position: 'relative',
                    display: 'flex'
                }}>

                    <iframe
                        ref={iframe}
                        src={(client && appURL) ? appURL : undefined}
                        style={{ border: 'none', flex: 1 }}
                    />

                    {isIframeLoading && (
                        <div style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: SM ? colorBgLayout : colorBgContainer,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0
                        }}>
                            <Loading/>
                        </div>
                    )}

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}
