import { PresetsContainer } from './flow-presets.style'
import { canCreateFlow } from '../flow-list/flow-list'
import { PageHeader } from 'old/components/page-header/page-header'
import { BulbOutlined } from '@ant-design/icons'
import { useTranslate }  from 'old/utils'
import { FlowCard } from 'old/components/flow/flow-card/flow-card'
import { useConfig, useFlowPresets, useFlows } from 'old/api'
import { Loading, PageContentWrapper, PageError, VerticalContainer } from 'old/components/styled'
import { useNavigate } from 'react-router-dom'
import { VerticalPaginatedList } from 'old/components/vertical-paginated-list/vertical-paginated-list'
import { useEffect } from 'react'
import { Feature }  from 'old/types'
import { theme } from 'antd'

export const FlowPresets = () => {
    
    //hooks:
    const { token: { marginXS } } = theme.useToken()
    const { __ } = useTranslate()
    const { data: config } = useConfig()
    const navigate = useNavigate()
    
    //flows:
    const { data: flows, total: totalFlows, isLoading: isFlowsLoading } = useFlows(null, 15)

    //flow presets:
    const {
        data: flowPresets,
        pageNum,
        totalPage,
        isLoading: isFlowPresetsLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useFlowPresets(null, 12)

    // redirect if it doesn't have access to flow:
    useEffect(() => {
        if(config && flows){
            if(!config.features.includes(Feature.FLOWS)){
                navigate('/')
            }else if(!canCreateFlow(config, true, totalFlows)){
                navigate('/flow')
            }
        }
    }, [config, flows])
    
    return (
        (isFlowPresetsLoading || isFlowsLoading) ? <Loading/> : 
        (!flowPresets || !flows) ? <PageError/> : (
        
        <PageContentWrapper fullWidth>
        
            <VerticalContainer style={{ gap: marginXS }}>

                {/* header */}
                <PageHeader
                    title={__`create_flow`}
                    description={__`choose_from_a_flow_below_or_start_one_from_scratch`}
                    primaryButton={{
                        name: __`start_from_scratch`,
                        icon: <BulbOutlined/>,
                        onClick: () => navigate('/flow/new')
                    }}
                    backButton={{
                        name: __`back`,
                        onClick: () => navigate('/flow')
                    }}
                />

                {/* list */}
                <VerticalPaginatedList
                    isFirstLoading={isFlowPresetsLoading}
                    isForbidden={flowPresets === null}
                    isError={false}
                    notFound={(flowPresets && flowPresets.length === 0) ?? false}
                    totalPage={totalPage}
                    pageNum={pageNum}
                    isPreviousData={isPreviousData}
                    isNextDisabled={isNextDisabled}
                    isPreviousDisabled={isPreviousDisabled}
                    fetchNext={fetchNext}
                    fetchPrevious={fetchPrevious}
                >
                    
                    <PresetsContainer>
                        {flowPresets && flowPresets.map(flowPreset => flowPreset.listed &&
                            <FlowCard key={flowPreset.id} flowPreset={flowPreset}/>
                        )}
                    </PresetsContainer>

                </VerticalPaginatedList>

            </VerticalContainer>

        </PageContentWrapper>

    ))
}
