import { defaultKeymap } from '@codemirror/commands'
import {
  Decoration,
  EditorView,
  MatchDecorator,
  ViewPlugin,
  WidgetType,
  keymap
} from '@codemirror/view'
import {basicSetup} from 'codemirror'
import CodeMirror from '@uiw/react-codemirror'
import { FormInputWrapper } from 'components/styled'
import { theme } from 'antd'
import { isNil } from 'utils'

//placeholderMatcher:
const placeholderMatcher = (regexPattern: RegExp) =>
    new MatchDecorator({
        regexp: regexPattern,
        decoration: match =>
            Decoration.replace({
                widget: new PlaceholderWidget(match[0])
            })
    })

//placeholders:
const placeholders = (regexPattern: RegExp) =>
    ViewPlugin.fromClass(
        class {
            placeholders
            constructor(view: any){
                this.placeholders = placeholderMatcher(regexPattern).createDeco(view)
            }
            update(update: any){
                this.placeholders = placeholderMatcher(regexPattern).updateDeco(
                    update,
                    this.placeholders
                )
            }
        }, {
            decorations: v => v.placeholders,
            provide: plugin =>
                EditorView.atomicRanges.of((view) => {
                    return view.plugin(plugin)?.placeholders || Decoration.none
                })
        }
    )

//PlaceholderWidget:
class PlaceholderWidget extends WidgetType{
    label
    constructor(label: any){
        super()
        this.label = label
    }
    eq(other: any){
        return other.label === this.label
    }
    toDOM(){
        const wrap = document.createElement('span')
        wrap.innerHTML = this.label
        wrap.className = 'cm-available-variables'
        return wrap
    }
}

//theme:
const baseTheme = EditorView.baseTheme({
    '.cm-available-variables': {
        paddingLeft: '2px',
        paddingRight: '2px',
        color: '#e23369',
        backgroundColor: '#f6f6f6',
        border: 'solid 1px #e5e5e5',
        borderRadius: '2px'
    }
})

//editor:
export const FormCodeEditor = ({ input, meta, ...rest }: any) => {
    
    //theme:
    const { token: { borderRadius, colorError, colorInfoTextHover } } = theme.useToken()

    return (
        <FormInputWrapper meta={meta} {...rest}>
            <div style={{ // the Ant Design's input shadow for focus and error, witch isn't available in the them!
                border: 'solid 1px ' + (!isNil(meta.error) ? colorError : meta.active ? colorInfoTextHover : 'transparent' ),
                boxShadow: (
                    meta.active ? isNil(meta.error) ?
                    `0 0 0 2px rgba(19, 19, 19, 0.11)` :
                    '0 0 0 2px rgba(255, 38, 5, 0.06)' : 'none'
                ),
                borderRadius: borderRadius,
                overflow: 'hidden',
                transition: 'all 100ms linear'
            }}>
                <CodeMirror
                    {...input}
                    {...rest}
                    extensions={[basicSetup, rest.language, keymap.of(defaultKeymap), [baseTheme, [], placeholders(rest.regexPattern)]]}
                />
            </div>
        </FormInputWrapper>
    )
}
