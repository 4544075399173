import { Typography, theme } from 'antd'
import { objectAssign }  from 'old/utils'
const { Paragraph } = Typography

type Prop = {
    error: string
    style?: object
}

export const InputError = ({ error, style }: Prop) => {

    const { token: { colorErrorText } } = theme.useToken()

    return (
        <Paragraph
            type='secondary'
            style={objectAssign({ marginBottom: 0, color: colorErrorText }, style)}
        >
            {error}
        </Paragraph>
    )

}
