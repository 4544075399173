import { PageHeader } from 'components/page-header/page-header'
import { ServiceItem } from 'components/service/service-item/service-item'
import { useTranslate, useWindowDimensions } from 'utils'
import { useCurrentLocation, useServices as useServices } from 'api'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { useState } from 'react'
import { FormSection } from 'components/form-section/form-section'
import { Select, theme } from 'antd'
import { NotFound, PageContentWrapper, VerticalContainer } from 'components/styled'
import { Location } from 'types'

export const ServiceList = () => {
    const { data: currentLocation } = useCurrentLocation()
    return currentLocation ? <ServiceListContent currentLocation={currentLocation}/> : <></>
}

type Props  = {
    currentLocation: Location
}

export const ServiceListContent = ({ currentLocation }: Props) => {

    //hooks:
    const { token: { padding, paddingMD, marginXXS, margin } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()

    //state:
    const [ selectedLocation, setSelectedLocation ] = useState<string>(currentLocation.id)
    const [ search, setSearch ] = useState<string | null>(null)
    
    //api:
    const {
        data: services,
        pageNum,
        totalPages,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useServices(selectedLocation === 'all' ? null : selectedLocation, search, 15)

    return currentLocation ? (
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`services`}
                    description={__`manage_all_your_services`}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>
                
                    <FormSection
                        title={SM ? __`services` : undefined }
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        topNode={
                            <Select
                                options={[
                                    { value: 'all', label: __`all_locations` },
                                    { value: currentLocation.id, label: currentLocation.name }
                                ]}
                                value={selectedLocation}
                                notFoundContent={<NotFound/>}
                                style={{ minWidth: SM ? 190 : 150, maxWidth: SM ? 190 : 150 }}
                                onChange={setSelectedLocation}
                            />
                        }
                        searchInput={{
                            placeholder: __`search_services`,
                            onSearch: setSearch
                        }}
                    >

                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={services === null}
                            isError={services === false}
                            notFound={(services && services.length === 0) ?? false}
                            pageNum={pageNum}
                            totalPages={totalPages}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            
                            {services && services.map(service => <ServiceItem key={service.id} service={service}/>)}

                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    ) : <></>
    
}
