import { MainWrapper, TimeBlock05, TimeBlock15, TimeBlock30, TimeBlock60, TimeContainer } from './timeline-block.style'

type Props = {
    hour: string
    scale: number
}

export const TimelineBlock = ({ hour, scale }: Props) => {

    //getTimeBlocks:
    const getTimeBlocks = () => {
        const timeBlocks: number[] = []
        timeBlocks.push(0)
        for(let i = 5; i <= 60; i = i + 5)
            timeBlocks.push(i)
        return timeBlocks
    }

    //indicators:
    const doRender05 = (time: number) => scale >= 4 && time % 15 !== 0
    const doRender15 = (time: number) => scale >= 2 && (time === 15 || time === 45)
    const doRender30 = (time: number) => scale >= 1.5 && time === 30
    const doRender60 = (time: number) => time === 60
    
    return (
        <MainWrapper scale={scale}>
            <TimeContainer>{

                getTimeBlocks().map(time =>
                    doRender05(time) ? <TimeBlock05 key={time}/> :
                    doRender15(time) ? <TimeBlock15 key={time}/> :
                    doRender30(time) ? <TimeBlock30 key={time}/> :
                    doRender60(time) ? <TimeBlock60 key={time} hour={hour}/> : null
                )

            }</TimeContainer>
        </MainWrapper>
    )
    
}
