import { Header, MainContainer, MainWrapper, PriceWrapper, TitleWrapper } from './plan-card.style'
import { Button, Typography, theme , ConfigProvider } from 'antd'
import { FeaturesList } from 'components/styled'
import { useTranslate } from 'utils'
const { Title, Text, Paragraph } = Typography

type Props = {
    title: string
    shortDescription?: string
    price: string
    duration: string
    description: string
    type: 'downgrade' | 'current' | 'upgrade'
    onSelect: () => void
}

export const PlanCard = ({ title, shortDescription, price, duration, description, type, onSelect }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSizeHeading1, fontSizeSM, colorBgContainer } } = theme.useToken()
    const { defaultAlgorithm, darkAlgorithm } = theme

    //getButtonText:
    const getButtonText = () => type === 'downgrade' ? __`downgrade` : type === 'current' ? __`current_plan` : __`upgrade`
    
    return (
        <ConfigProvider theme={{ algorithm: type === 'current' ? darkAlgorithm : defaultAlgorithm }} >

            <MainContainer isDarkTheme={type === 'current'}>
                
                <MainWrapper isDarkTheme={type === 'current'}>

                    <Header>

                        <TitleWrapper>
                        
                            {/* title */}
                            <Title
                                level={3}
                                style={{
                                    fontFamily: 'inter-medium',
                                    fontWeight: 'normal',
                                    margin: 0
                                }}
                            >
                                {title}
                            </Title>

                            {/* short description */}
                            <Paragraph
                                type='secondary'
                                style={{
                                    fontSize: fontSizeSM,
                                    marginBottom: 0
                                }}
                            >
                                {shortDescription}
                            </Paragraph>

                        </TitleWrapper>

                        {/* price */}
                        <PriceWrapper>
                            <Text type='secondary' style={{ visibility: 'hidden' }}>/ {duration}</Text>
                            <Text style={{ fontSize: fontSizeHeading1, fontFamily: 'inter-medium' }}>{price}</Text>
                            <Text type='secondary'>/ {duration}</Text>
                        </PriceWrapper>

                    </Header>

                    {/* features */}
                    <FeaturesList
                        isDarkTheme={type === 'current'}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    
                    {/* button */}
                    <Button
                        type={type === 'current' ? 'default' : 'primary'}
                        size='large'
                        style={{
                            minWidth: 125,
                            height: 46,
                            color: type === 'current' ? colorBgContainer : undefined,
                            background: type === 'current' ? 'transparent' : undefined,
                            cursor: type === 'current' ? 'default' : undefined
                        }}
                        disabled={type === 'current'}
                        onClick={onSelect}
                    >
                        {getButtonText()}
                    </Button>
                    
                </MainWrapper>
                
            </MainContainer>

        </ConfigProvider>
    )
    
}
