import { CalendarSync, Resource } from 'types'
import {
    getShopId,
    useMutationHandler,
    usePaginationHandler,
    useQueryHandler
} from 'utils'

const resourceListQuery = `

    id
    type {
        id
        name
    }
    name
    email
    mobile
    description

    image {
        id
        path
    }

    status
    notificationEmailStatus
    eventDescription

    timezone
    
    availabilities {
        
        type
        weekday
        date

        intervals {
            from
            to
        }
    
    }

    availabilitiesRange {
        availableFrom {
            type
            value
        }
        availableTo {
            type
            value
        }
    }
    
`

const resourceQuery = `

    ${resourceListQuery}

    calendarSync {
        calendarSyncStatus
        canUseCalendarSync
    }

`

export const useResources = (
    locationId: string | null,
    typeId: string | null,
    search: string | null,
    limit: number,
    enabled?: boolean
) => {
    const shopId = getShopId()
    return usePaginationHandler<Resource[]>({
        query: `
            query getResources(
                $shop: String!,
                $locationId: String,
                $typeId: String,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String
            ){
                getResources(
                    shop: $shop,
                    locationId: $locationId,
                    typeId: $typeId,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                ){
                    data{
                        ${resourceListQuery}
                    }
                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                    
                }
            }
        `,
        variables: {
            shop: shopId,
            locationId,
            typeId,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['resources', shopId, locationId, typeId, search],
        limit,
        enabled,
        getResponse: json => json.data.getResources,
        onSuccess: response => response.data
    })

}

export const useResource = (id: string, onNotFound?: (json: any) => void) => {
    const shopId = getShopId()
    return useQueryHandler<Resource>({
        query: `
            query getResource (
                $shop: String!,
                $id: String!
            ) {
                getResource (
                    shop: $shop,
                    id: $id
                ) {
                    ${resourceQuery}
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        cacheKey: ['resource', id, shopId],
        onSuccess: json => json.data.getResource,
        onNotFound
    })
}

export const useCreateResource = (resource: Resource) => {
    const shopId = getShopId()
    return useMutationHandler<null | boolean | 'name_is_used'>({
        query: `
            mutation createResource(
                $shop: String!,
                $request: CreateResourceRequest!,
            ){
                createResource(
                    shop: $shop,
                    request: $request
                ){
                    id
                }
            }
        `,
        variables: {

            shop: shopId,

            request: {

                typeId: resource.type.id,
                name: resource.name,
                email: resource.email,
                mobile: resource.mobile,
                description: resource.description,
                image: resource.image.id,

                status: resource.status,
                eventDescription: resource.eventDescription,
                notificationEmailStatus: resource.notificationEmailStatus,
                
                timezone: resource.timezone,
                availabilities: resource.availabilities,
                availabilitiesRange: resource.availabilitiesRange

            }

        },
        onSuccess: json => typeof json.data.createResource.id === 'string',
        onError: json => {
            if(json.errors[0].extensions.errors[0].constraints[0].name === 'is_unique'){
                return 'name_is_used'
            }else{
                return null
            }
        }
    })

}

export const useUpdateResource = (resource: Resource) => {
    const shopId = getShopId()
    return useMutationHandler<null | CalendarSync | 'name_is_used'>({
        query: `
            mutation updateResource(
                $shop: String!,
                $id: String!,
                $request: UpdateResourceRequest!
            ){
                updateResource(
                    shop: $shop,
                    id: $id,
                    request: $request
                ){
                    calendarSync{
                        calendarSyncStatus
                        canUseCalendarSync
                    }
                }
            }
        `,
        variables: {

            shop: shopId,
            id: resource.id,

            request: {

                name: resource.name,
                email: resource.email,
                mobile: resource.mobile,
                description: resource.description,
                image: resource.image.id,

                status: resource.status,
                eventDescription: resource.eventDescription,
                notificationEmailStatus: resource.notificationEmailStatus,
                
                timezone: resource.timezone,
                availabilities: resource.availabilities,
                availabilitiesRange: resource.availabilitiesRange
                
            }

        },
        onSuccess: json => json.data.updateResource.calendarSync,
        onError: json => {
            if(json.errors[0].extensions.errors[0].constraints[0].name === 'is_unique'){
                return 'name_is_used'
            }else{
                return null
            }
        }
    })
}

export const useResourceCalendarInvitation = (id: string) => {
    const shopId = getShopId()
    return useMutationHandler<boolean>({
        query: `
            mutation initCalendarSyncLink (
                $shop: String!,
                $id: String!
            ) {
                initCalendarSyncLink (
                    shop: $shop,
                    id: $id
                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.initCalendarSyncLink.message === 'Done'
    })
}

export const useResourceCalendarRevoke = (id: string) => {
    const shopId = getShopId()
    return useMutationHandler<boolean>({
        query: `
            mutation revokeCalendarSyncAuth(
                $shop: String!,
                $id: String!
            ) {
                revokeCalendarSyncAuth (
                    shop: $shop,
                    id: $id
                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.revokeCalendarSyncAuth.message === 'Done'
    })
}

export const useRemoveResource = (id: string) => {
    const shopId = getShopId()
    return useMutationHandler<boolean | 'assignedToService'>({
        query: `
            mutation deleteResource (
                $shop: String!,
                $id: String!
            ) {
                deleteResource (
                    shop: $shop,
                    id: $id
                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.deleteResource.message === 'Done',
        onError: json => {
            switch(json.errors[0].extensions.errors[0].constraints[0].name){
                case 'resource_has_assigned_to_a_service':
                    return 'assignedToService'
                default:
                    return false
            }
        }
    })
}

type ResourceServicesResponse = {
    id: string,
    title: string,
    locations: {
        id: string,
        name: string
    }[]
}[]

export const useResourceService = (id: string) => {
    const shopId = getShopId()
    return useQueryHandler<ResourceServicesResponse>({
        query: `
            query getRelatedServices(
                $shop: String!,
                $resourceId: String!
            ){
                getRelatedServices(
                    shop: $shop,
                    resourceId: $resourceId
                ){
                    id
                    title
                    locations{
                        id
                        name
                    }
                }
            }
        `,
        variables: {
            shop: shopId,
            resourceId: id
        },
        cacheKey: ['resourceService', id, shopId],
        onSuccess: json => json.data.getRelatedServices
    })
}
