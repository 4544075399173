import { Typography, theme } from 'antd'
import { useConfig, useCurrentShop } from 'api'
import { FormCheckbox, FormInputNumber, FormSelect } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { Message, VerticalContainer } from 'components/styled'
import { Field, useField } from 'react-final-form'
import { NullableField, useTranslate } from 'utils'
import { Feature, FlexStartInterval } from 'types'
import { useEffect } from 'react'
const  { Text } = Typography

export const ServiceOtherOptions = () => {

    //hooks:
    const { token: { margin } } = theme.useToken()
    const { __ } = useTranslate()
    const { data: shop } = useCurrentShop()
    const { data: config } = useConfig()
    const { input: { value: customerCanCancel } } = useField('customerCanCancel')
    const { input: { value: customerCanReschedule } } = useField('customerCanReschedule')
    const { input: { value: customerCanManageBefore, onChange: updateCustomerCanManageBefore } } = useField('customerCanManageBefore')

    //update customer can manage before:
    useEffect(() => {
        if(customerCanManageBefore === 0){
            updateCustomerCanManageBefore(null)
        }
    }, [customerCanManageBefore])

    return !shop ? null : (
        <FormSection
            title={__`other_options`}
            childrenWrapperStyle={{ display: 'flex', flexDirection: 'column', gap: margin }}
        >

            {/* Booking until days */}
            {!shop.isMigrated && (
                <Field
                    name='bookingUntilDays'
                    min={0}
                    max={1000}
                    keyboard
                    placeholder={__`example_30`}
                    style={{ width: '100%' }}
                    component={FormInputNumber}
                    label={
                        <>
                            {__`max_bookable_days`}
                            <Message message={__`limit_the_number_of_days_clients_can_book_an_appointment_in_advance`}/>
                        </>
                    }
                    description={__`leave_it_blank_to_allow_booking_for_any_number_of_days_in_the_future`}
                />
            )}

            {/* same day booking */}
            <Field
                name='disableSameDayBooking'
                type='checkbox'
                description={__`customers_would_not_be_able_to_book_the_service_in_the_same_day`}
                text={__`disable_same_day_booking`}
                component={FormCheckbox}
            />
            
            {/* flex start time */}
            {shop.isMigrated && (
                <NullableField
                    name='flexStartInterval'
                    label={__`slots_flexible_start`}
                    description={__`choose_the_interval_for_the_slots_flexible_start_time`}
                    type='select'
                    component={FormSelect}
                    options={[
                        { value: ''                          , label: __`disabled`   },
                        { value: FlexStartInterval.FIFTEEN   , label: __`15_minutes` },
                        { value: FlexStartInterval.THIRTY    , label: __`30_minutes` },
                        { value: FlexStartInterval.FORTY_FIVE, label: __`45_minutes` },
                        { value: FlexStartInterval.SIXTY     , label: __`60_minutes` },
                    ]}
                />
            )}

            {/* round start interval */}
            <Field
                name='roundStartInterval'
                label={__`round_up_startup_interval_to`}
                type='select'
                component={FormSelect}
                options={[
                    { value: 0 , label: __`disabled`   },
                    { value: 15, label: __`15_minutes` },
                    { value: 30, label: __`30_minutes` },
                ]}
            />
        
            {/* cancellation and rescheduling */}
            {config && config.features.includes(Feature.APPOINTMENT_MANAGEMENT) && (
                    
                <VerticalContainer style={{ gap: margin }}>

                    <VerticalContainer>

                        <Field
                            name='customerCanCancel'
                            label={__`cancellation_and_rescheduling`}
                            type='checkbox'
                            text={__`allow_customers_to_cancel_their_appointments`}
                            component={FormCheckbox}
                        />
                        
                        <Field
                            name='customerCanReschedule'
                            type='checkbox'
                            text={<Text>{__`allow_customers_to_reschedule_their_appointments`}</Text>}
                            component={FormCheckbox}
                        />

                    </VerticalContainer>

                    {/* customer can manage before */}
                    {(customerCanCancel || customerCanReschedule) && (
                        <Field
                            name='customerCanManageBefore'
                            label={__`number_of_hours_customers_can_manage_their_appointment_before_the_start_time`}
                            description={__`leave_it_blank_to_allow_cancellation_rescheduling_until_when_the_appointment_starts`}
                            component={FormInputNumber}
                            placeholder={__`example_24`}
                            style={{ width: '100%' }}
                            min={0}
                            max={365*24}
                            keyboard
                        />
                    )}  

                    {/* refund money on cancel */}
                    {customerCanCancel && (
                        <Field
                            name='refundMoneyOnCancel'
                            type='checkbox'
                            text={__`automatically_refund_money_on_cancellation`}
                            description={__`only_applies_to_payment_methods_that_can_be_refunded_automatically_eg_cash_payment_needs_to_be_handled_manually`}
                            component={FormCheckbox}
                        />
                    )}

                </VerticalContainer>
            )}

        </FormSection>
    )

}
