import { ProfilePasswordStrategy } from '../profile-password-strategy/profile-password-strategy'
import { ProfileGoogleStrategy } from '../profile-google-strategy/profile-google-strategy'
import { Loading, PageError, RWContainer, RWElement, VerticalContainer } from 'components/styled'
import { TranslateFunction, useTranslate } from 'utils'
import { Select, Typography, theme } from 'antd'
import { FormSection } from 'components/form-section/form-section'
import { useState } from 'react'
import { LoginStrategy, ScreenSize } from 'types'
import { useAvailableLoginStrategies } from 'api'
const { Text, Paragraph } = Typography

export const ProfileLoginStrategy = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { paddingLG, marginMD } } = theme.useToken()

    //available strategies:
    const { data: availableStrategies, isLoading: isAllStrategiesLoading } = useAvailableLoginStrategies()

    //state
    const [ strategy, setStrategy ] = useState(LoginStrategy.PASSWORD)

    //strategySelectorOptions:
    const strategySelectorOptions = getStrategySelectorOptions(availableStrategies ? availableStrategies : [], __)

    return (
        isAllStrategiesLoading ? <Loading/> :
        !availableStrategies ? <PageError/> :
        strategySelectorOptions.length === 0 ? null : (
            
            <FormSection title={__`login`} style={{ paddingBottom: paddingLG }}>

                <RWContainer style={{
                    flex: 1,
                    alignItems: 'flex-start',
                    gap: marginMD
                }}>

                    <RWElement breakPoint={ScreenSize.SM/2}>

                        <VerticalContainer style={{ height: '100%', flex: 1 }}>
                        
                            {/* label */}
                            <Text style={{ fontFamily: 'inter-medium' }}>{__`select_your_login_strategy`}</Text>

                            {/* select */}
                            <Select
                                value={strategy}
                                options={strategySelectorOptions}
                                onChange={val => setStrategy(val)}
                            />

                            {/* description */}
                            <Paragraph type='secondary' style={{ margin: 0 }}>
                                {strategy === LoginStrategy.PASSWORD && __`after_saving_a_password_you_can_login_with_email_and_password_from_the_next_session`}
                                {strategy === LoginStrategy.GOOGLE && __`connect_your_google_account_to_sesami_and_you_will_able_to_login_without_password`}
                            </Paragraph>

                        </VerticalContainer>

                    </RWElement>
                    
                    <RWElement breakPoint={ScreenSize.SM/2}>

                        {/* password strategy */}
                        {strategy === LoginStrategy.PASSWORD && (
                            <VerticalContainer style={{ flex: 1 }}>
                                <ProfilePasswordStrategy/>
                            </VerticalContainer>
                        )}

                        {/* google strategy */}
                        {strategy === LoginStrategy.GOOGLE && (
                            <VerticalContainer style={{ flex: 1 }}>
                                <ProfileGoogleStrategy/>
                            </VerticalContainer>
                        )}

                    </RWElement>

                </RWContainer>

            </FormSection>
        )
    )
    
}

//getStrategySelectorOptions:
export const getStrategySelectorOptions = (availableStrategies: LoginStrategy[], __: TranslateFunction) => {
    const strategies = []
    if(availableStrategies.includes(LoginStrategy.PASSWORD))
        strategies.push({ value: LoginStrategy.PASSWORD, label: __`login_with_password` })
    if(availableStrategies.includes(LoginStrategy.GOOGLE))
        strategies.push({ value: LoginStrategy.GOOGLE, label: __`login_with_google` })
    return strategies
}
