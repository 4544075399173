import { Input, InputRef } from 'antd'
import { useTranslate } from 'utils'
import { Ref, useRef } from 'react'
import { DEBOUNCING_TIMEOUT } from 'app.constants'
import { SizeType } from 'antd/es/config-provider/SizeContext'

type Props = {
    placeholder?: string
    size?: SizeType
    inputRef?: Ref<InputRef>
    onType?: (isTyping: boolean) => void
    onSearch: (search: string | null) => void
}

export const Search = ({ placeholder, size, inputRef, onType, onSearch }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //debounce timeout:
    const lastKeyPressTimeoutId = useRef(0 as number | NodeJS.Timeout)

    //handleChange:
    const handleChange = (search: string) => {
        onType?.(true)
        clearTimeout(lastKeyPressTimeoutId.current)
        if(search.length >= 1){
            lastKeyPressTimeoutId.current = setTimeout(() => {
                onType?.(false)
                onSearch(search)
            }, DEBOUNCING_TIMEOUT)
        }else{
            onType?.(false)
            onSearch(null)
        }
    }
    
    return (
        <Input
            ref={inputRef}
            placeholder={placeholder ?? __`search`}
            allowClear
            size={size}
            onChange={e => handleChange(e.target.value)}
        />
    )

}
