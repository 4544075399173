import { theme } from 'antd'
import { useConfig } from 'old/api'
import { RightOutlined } from '@ant-design/icons'
import { FormSection } from 'old/components/form-section/form-section'
import { FeaturesList, Loading, PageError } from 'old/components/styled'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'old/utils'

export const SettingsPlan = () => {

    //theme:
    const { token: {
        marginXS,
        paddingXS,
        paddingXXS,
        fontSizeSM
    }} = theme.useToken()

    //translate:
    const { __ } = useTranslate()

    //navigation:
    const navigate = useNavigate()
    
    //config:
    const { data: config, isLoading } = useConfig()

    //render:
    return (
        <FormSection
            title={!config ? undefined : config.plan?.name ?? __`no_plan`}
            topButton={{
                label: __`view_plans`,
                icon: <RightOutlined style={{ fontSize: fontSizeSM }}/>,
                style: {
                    padding: `${paddingXS}px ${paddingXXS}px `,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    gap: marginXS
                },
                onClick: () => navigate('/settings/plans')
            }}
        >
            {!config ? null : (
                <FeaturesList
                    isDarkTheme={false}
                    dangerouslySetInnerHTML={ { __html: config.plan?.description ?? '' } }
                    style={{ maxWidth: 'unset', paddingLeft: 0, paddingBottom: paddingXS }}
                >
                    {isLoading ? <Loading/> : !config ? <PageError/> : undefined}
                </FeaturesList>
            )}

        </FormSection>
    )

}
