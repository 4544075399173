import {FormSection} from 'components/form-section/form-section'
import { Select, Typography, theme } from 'antd'
import { useTranslate , isNil, useWindowDimensions } from 'utils'
import { RWContainer, VerticalContainer } from 'components/styled'
import { Field, useField } from 'react-final-form'
import { FlowAction, flowBodyExtensions } from 'types'
import { FormCodeEditor, FormInput } from 'components/form-inputs'
import { useEffect, useState } from 'react'
import { flowVariables } from 'data'
const { Text, Paragraph } = Typography

//getDefaultExtensionIndex:
export const getDefaultExtensionIndex = (action: FlowAction) => action === FlowAction.EMAIL ? 1 : 0

type Props = {
    onBodyExtensionChanged: (index: number) => void
}

export const FlowTemplate = ({ onBodyExtensionChanged }: Props) => {

    //theme:
    const { token: { marginXXL, marginSM } } = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //translate:
    const { __ } = useTranslate()

    //field:
    const { input: { value: action } } = useField('action')

    //regex:
    const variablesRegexPattern = new RegExp(flowVariables.join('|'), 'gi')

    //formatEmailSubject:
    const formatEmailSubject = (value: string) => {
        if(isNil(value) || value == ''){
            return ''
        }
        let parsedVal = {
            subject: ''
        }
        try{parsedVal = JSON.parse(value)
        }catch(e){e}
        return parsedVal.subject
    }

    //state:
    const [bodyExtensionIndex, setBodyExtensionIndex] = useState(getDefaultExtensionIndex(action))

    //effect:
    useEffect(() => {
        setBodyExtensionIndex(getDefaultExtensionIndex(action))
    }, [action])
    useEffect(() => {
        onBodyExtensionChanged(bodyExtensionIndex)
    }, [bodyExtensionIndex])

    return (
        <FormSection title={!SM ? '' : action === FlowAction.EMAIL ? __`email_template` : __`webhook_template`}>

            <VerticalContainer style={{ gap: marginSM }}>

                {/* headers */}
                <VerticalContainer>
                    {action === FlowAction.EMAIL ? (
                        <Field
                            name="template.headers"
                            type="text"
                            component={FormInput}
                            placeholder={__`reminder_service_name_appointment_at_date_time_in_customer_timezone`}
                            label={__`subject`}
                            description={__`you_can_use_available_variables_in_your_email_subject`}
                            format={formatEmailSubject}
                            parse={value => JSON.stringify({ subject: value })}
                        />
                    ) : (
                        <Field
                            name="template.headers"
                            component={FormCodeEditor}
                            language={flowBodyExtensions[0][1]} //json
                            regexPattern={variablesRegexPattern}
                            height={'150px'}
                            label={__`headers`}
                            description={__`make_sure_the_headers_data_is_a_valid_json`}
                        />
                    )}
                </VerticalContainer>

                {/* body */}
                <VerticalContainer>

                    <RWContainer>

                        <Text style={{ fontFamily: 'inter-medium' }}>{__`body`}</Text>
                        
                        <Select
                            options={[
                                { value: 0, label: flowBodyExtensions[0][0] },
                                { value: 1, label: flowBodyExtensions[1][0] },
                                { value: 2, label: flowBodyExtensions[2][0] }
                            ]}
                            value={bodyExtensionIndex}
                            onChange={setBodyExtensionIndex}
                            style={{ width: marginXXL*1.8 }}
                        />
                        
                    </RWContainer>
                    
                    <Field
                        name="template.body"
                        component={FormCodeEditor}
                        language={flowBodyExtensions[bodyExtensionIndex][1]}
                        regexPattern={variablesRegexPattern}
                        height={`${action === FlowAction.EMAIL ? 600 : 450}px`}
                    />
                    
                    <Paragraph type='secondary' style={{ margin: 0 }}>{
                        action === FlowAction.EMAIL ? __`edit_your_email_body_here` : __`make_sure_the_body_is_valid_based_on_content_type`
                    }</Paragraph>

                </VerticalContainer>

            </VerticalContainer>
        </FormSection>
    )

}
