import styled from 'styled-components'
import checkIcon  from 'old/assets/icons/check.58d.svg'

type Props = {
    isDarkTheme: boolean
}

export const FeaturesList = styled.div<Props>`
    flex: 1;
    width: 100%;
    max-width: 270px;
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.margin + 'px'};
    & > p{
        margin: 0;
        padding-left: ${props => props.theme.fontSize*2 + 'px'};
        padding-top: ${props => props.theme.paddingXXS/4 + 'px'};
        background: transparent url(${checkIcon}) no-repeat left top / ${props => props.theme.fontSizeXL + 'px'} auto;
        font-family: ${props => props.theme.fontFamily};
        font-size: ${props => props.theme.fontSize + 'px'};
        text-align: start;
        color: ${props => props.isDarkTheme ? 'rgba(255, 255, 255, .75)' : props.theme.colorText};
    }
`
