import logoName from '../../assets/icons/logo-name.svg'
import logo from '../../assets/icons/logo.svg'
import { Typography } from 'antd'
import styled from 'styled-components'
const { Link } = Typography

type LogoProps = {
    withName?: boolean
    width?: number
}

export const Logo = ({ withName, width = 100 }: LogoProps) => {

    return (
        <Link
            href='/'
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <img
                width={`${width}px`}
                src={withName ? logoName : logo}
            />
        </Link>
    )
}

type LogoContainer = {
    size?: number
}

export const LogoContainer = styled.div<LogoContainer>`
    width: ${props => `${props.size ?? 25}px`};
    height: ${props => `${props.size ?? 25}px`};
    background: transparent url(${logo}) no-repeat center / 100% auto;
`
