import { Variant, SimpleTeamMember, Availability }  from 'old/types'
import { boolean, number, object, string } from 'yup'
import { TranslateFunction }  from 'old/utils'

export type Service = {

    id: string
    title: string
    image: {
        id: string | null
        path: string | null
    }

    teamMembers: SimpleTeamMember[]
    variants: Variant[]

    location: { content: string | null }
    eventDescription: string | null
  
    availabilities: Availability[]

    bufferTimeBefore: number
    bufferTimeAfter: number
    bufferFromNow: number

    groupAppointments: boolean
    groupAppointmentSlots: number | null

    storefrontStartDate: string | null
    instantBooking: boolean | null
    displayTeamOnStorefront: boolean | null

    bookingUntilDays: number | null
    disableSameDayBooking: boolean
    flexStartInterval?: FlexStartInterval | null
    roundStartInterval: 0 | 15 | 30

    customerCanCancel: boolean
    customerCanReschedule: boolean
    customerCanManageBefore: number | null
    refundMoneyOnCancel: boolean

}

export enum FlexStartInterval {
    FIFTEEN = 'FIFTEEN',
    THIRTY = 'THIRTY',
    FORTY_FIVE = 'FORTY_FIVE',
    SIXTY = 'SIXTY'
}

export type CalendarSlotService = Pick<Service, 'id' | 'title' | 'groupAppointments' | 'groupAppointmentSlots'>
export type AppointmentService = Pick<Service, 'id' | 'title'>

export const ServiceFormSchema = (__: TranslateFunction) => object({
    
    location:                object({ content: string().nullable() }),
    eventDescription:        string().nullable(),

    bufferTimeBefore:        number().typeError(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(0),
    bufferTimeAfter:         number().typeError(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(0),
    bufferFromNow:           number().typeError(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(0),

    groupAppointments:       boolean(),
    groupAppointmentSlots:   number().when('groupAppointments', {
        is: true,
        then: schema => schema.required(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(1),
        otherwise: schema => schema.nullable()
    }),

    storefrontStartDate:     string().nullable(),
    instantBooking:          boolean(),
    displayTeamOnStorefront: boolean(),

    bookingUntilDays:        number().nullable().integer(__`must_be_an_integer`).min(0),
    disableSameDayBooking:   boolean(),
    roundStartInterval:      number().oneOf([0, 15, 30]),

    customerCanCancel:       boolean(),
    customerCanReschedule:   boolean(),
    customerCanManageBefore: number().nullable().integer(__`must_be_an_integer`).min(0),
    refundMoneyOnCancel:     boolean()

})
