import { Typography, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { SimpleResource } from 'types'
import { useTranslate } from 'utils'
import { ColumnContainer, VerticalContainer } from 'components/styled'
const { Text } = Typography

type Props = {
    resources: SimpleResource[]
}

export const AppointmentDetailsResources = ({ resources }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingXS, padding, marginXS } } = theme.useToken()

    return (
        <ColumnContainer gap={marginXS}>

            <Text
                style={{
                    padding: `0 ${padding}px`,
                    fontFamily: 'inter-medium'
                }}
            >
                {__`resources`}
            </Text>

            <VerticalContainer style={{ gap: 0 }}>
                {resources.map(resource => (
                    <Avatar
                        key={resource.id}
                        name={resource.name ?? __`unknown`}
                        image={resource.image.path ?? null}
                        description={resource.type.name}
                        style={{
                            padding: `${paddingXS}px ${padding}px`,
                            borderRadius: 0
                        }}
                    />
                ))}
            </VerticalContainer>
            
        </ColumnContainer>
    )
}
