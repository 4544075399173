import { AppointmentItem } from 'components/appointment/appointment-item/appointment-item'
import { AppointmentFilter } from 'components/appointment/appointment-navigation/appointment-navigation'
import { useAppointments } from 'api'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { VerticalContainer } from 'components/styled'
import { Appointment } from 'types'
import { useState } from 'react'
import { Drawer } from 'antd'
import { AppointmentDetails } from 'components/appointment-details/appointment-details/appointment-details'
import { getShopId, setCacheManually, useWindowDimensions } from 'utils'

type Props = {
    resourceId: string | null,
    search: string | null,
    filter: AppointmentFilter,
    start: string | null,
    end: string | null
}

export const AppointmentList = ({ resourceId, search, filter, start, end }: Props) => {

    //window dimensions:
    const { width, SM } = useWindowDimensions()
    
    //appointments:
    const {
        data: appointments,
        pageNum,
        totalPages,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useAppointments(resourceId, null, search, filter, start, end, 20, true)

    //state:
    const [ selectedAppointmentId, setSelectedAppointmentId ] = useState<string | null>(null)
    const [ isAppointmentDetailsOpen, setIsAppointmentDetailsOpen ] = useState(false)

    //openAppointmentDetails:
    const openAppointmentDetails = (appointment: Appointment) => {
        setCacheManually(['appointment', appointment.id, getShopId()], appointment)
        setSelectedAppointmentId(appointment.id)
        setIsAppointmentDetailsOpen(true)
    }
    
    return (
        <VerticalContainer style={{ gap: 0 }}>

            <VerticalPaginatedList
                isFirstLoading={isLoading}
                isForbidden={appointments === null}
                isError={appointments === false}
                notFound={(appointments && appointments.length === 0) ?? false}
                totalPages={totalPages}
                pageNum={pageNum}
                isPreviousData={isPreviousData}
                isNextDisabled={isNextDisabled}
                isPreviousDisabled={isPreviousDisabled}
                fetchNext={fetchNext}
                fetchPrevious={fetchPrevious}
            >

                <VerticalContainer style={{ width: '100%', gap: 0 }}>

                    {appointments && appointments.map(appointment =>
                        <AppointmentItem
                            key={appointment.id}
                            appointment={appointment}
                            onClick={() => openAppointmentDetails(appointment)}
                        />
                    )}

                </VerticalContainer>

            </VerticalPaginatedList>


            {/* drawer */}
            <Drawer
                closable={false}
                width={SM ? 450 : width } // full width in mobile
                open={isAppointmentDetailsOpen}
                styles={{ body: { padding: 0 }}}
                onClose={() => {
                    setIsAppointmentDetailsOpen(false)
                    setSelectedAppointmentId(null)
                }}
            >
                
                {selectedAppointmentId && (
                    <AppointmentDetails
                        appointmentId={selectedAppointmentId}
                        isOpen={isAppointmentDetailsOpen}
                        onClose={() => {
                            setIsAppointmentDetailsOpen(false)
                            setSelectedAppointmentId(null)
                        }}
                    />
                )}
                
            </Drawer>

        </VerticalContainer>
    )
    
}
