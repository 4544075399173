import { InfoWrapper, MainWrapper, Step, StepsContainer } from './progress-bar.style'
import { Progress, Typography, theme } from 'antd'
import { RGBtoRGBA }  from 'old/utils'
const  { Text } = Typography

type Props = {
    value: number
    total: number
    maxSteps?: number
    colorRGB: string
}

export const ProgressBar = ({ value, total, maxSteps = 20, colorRGB }: Props) => {

    //theme colors:
    const { token: { colorTextSecondary, fontSizeXL } } = theme.useToken()

    const trailColor = RGBtoRGBA(colorRGB, .25)
    const strokeColor = RGBtoRGBA(colorRGB, .9)

    //getMarkedSteps:
    const getSteps = (number: number, color: string) => {
        const steps = []
        for(let i = 0;  i < number; i++ )
            steps.push(<Step key={i+color} colorRGB={color}/>)
        return steps
    }
    
    //render:
    return (
        <MainWrapper>

            {
                total <= maxSteps ?
                    <StepsContainer total={total}>

                        {/* marked steps */}
                        {getSteps(value, strokeColor)}

                        {/* empty steps */}
                        {getSteps((total - value), trailColor)}

                    </StepsContainer>
                :
                    <Progress
                        percent={(value/total)*100}
                        showInfo={false}
                        strokeColor={strokeColor}
                        trailColor={trailColor}
                        status='normal'
                        style={{ margin: 0 }}
                    />
            }

            {/* info */}
            <InfoWrapper>
                <Text style={{ whiteSpace: 'nowrap', fontFamily: 'inter-medium', fontSize: fontSizeXL, lineHeight: `${fontSizeXL}px` }}>
                    {`${value}`}
                </Text>
                <Text style={{ whiteSpace: 'nowrap', color: colorTextSecondary, lineHeight: `${fontSizeXL}px` }}>
                    {`/`}
                </Text>
                <Text style={{ whiteSpace: 'nowrap', color: colorTextSecondary, lineHeight: `${fontSizeXL}px` }}>
                    {`${total}`}
                </Text>
            </InfoWrapper>

        </MainWrapper>
    )

}
