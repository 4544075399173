import { object, string } from 'yup'
import { TranslateFunction } from 'utils'

export type Location = {
    
    id: string
    name: string
    phone: string | null
    email: string | null
    description: string | null
    image: {
        id: string | null
        path: string | null
    }

    timezone: string

    businessAddress: {
        country: string | null
        address: string | null
        suite: string | null
        city: string | null
        state: string | null
        zipCode: string | null
        latitude: number | null
        longitude: number | null
    }

    isDefault: boolean
    status: boolean
    
}

export type SimpleLocation = Pick<Location, 'id' | 'name' | 'description' | 'image' | 'timezone'>

export const LocationFormSchema = (__: TranslateFunction) => object({
    
    name:            string().required(__`this_field_is_required`),
    phone:           string().nullable(),
    email:           string().nullable(),
    description:     string().nullable(),

    businessAddress: object({
        country:     string().nullable(),
        address:     string().nullable(),
        suite:       string().nullable(),
        city:        string().nullable(),
        state:       string().nullable(),
        zipCode:     string().nullable(),
    })

})
