import { useConfig } from '../api'
import { useEffect, useState } from 'react'
import { replaceVariables } from '@sesamiapp/translation'

export type TranslateKey = null | string | TemplateStringsArray
export type TranslateFunction = (key: TranslateKey, replacements?: any) => string

export const useTranslate = () => {
    
    const { data: config, isLoading } = useConfig()
    const [ translations, setTranslations ] = useState({})
    
    useEffect(() => {
        if(config){
            import(`../../translations/${config.locale}.json`).then(module => {
                setTranslations(module.default)
            }).catch(() => {
                setTranslations({})
            })
        }
    }, [config])

    return isLoading ? {
        __: () => '',
        isLoading: true,
        isError: false
    } : !config ? {
        __: () => '',
        isLoading: false,
        isError: true
    } : {
        __: (key: TranslateKey, replacements?: any) => {
            
            if(key && Object.keys(translations).length){ // if the file is loaded:
                const dictionaryKey = key as keyof typeof translations
                const translation = translations[dictionaryKey]
                if(translation){ // if translation found:
                    return replaceVariables(translation, replacements)
                }else{
                    console.error('Phrase not found:', `"${key}"`)
                }
            }

            // if file is not loaded, or the translation not found:
            if(config.locale.includes('en-')){ // keys are in en
                return generatePhraseFromKey(key)
            }else{
                return ''
            }
            
        },
        isLoading: false,
        isError: false
    }
}

export const generatePhraseFromKey = (key: TranslateKey) => {
    let phrase = ''
    if(key){
        const keyString = key.toString()
        phrase = keyString.toLowerCase().replaceAll('_', ' ')
        phrase = phrase.replace(phrase[0], phrase[0].toUpperCase())
    }
    return phrase
}
