import { Button, Modal, Typography, message, theme } from 'antd'
import { LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { TranslateFunction, useTranslate } from 'old/utils'
import { HorizontalContainer, VerticalContainer } from 'old/components/styled'
import { Field, Form } from 'react-final-form'
import { setIn } from 'final-form'
import { object, string } from 'yup'
import { FormInput } from 'old/components/form-inputs'
import { useLoginWithOTP } from 'old/api'
import { useEffect, useState } from 'react'
const  { Text } = Typography

//LoginOtpFormSchema:
const LoginOtpFormSchema = (__: TranslateFunction) => object({
    code: string().required(__`enter_the_code`).min(6, __`must_be_6_characters`).max(6)
})

//onValidate:
const onValidate = (schema: any) => async (values: { code: string }) => {
    if(typeof schema === 'function')
        schema = schema()
    try{
        await schema.validate(values, { abortEarly: false })
    }catch(err: any){
        const errors = err.inner.reduce((formError: object, innerError: any) => {
            return setIn(formError, innerError.path, innerError.message)
        }, {})
        return errors
    }
}

type Props = {
    email: string
    onLogin: (accessToken: string, refreshToken: string) => void
    onBackToLogin: () => void
}

export const LoginOTP = ({ email, onLogin, onBackToLogin }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginXS, fontSize, colorBgContainer } } = theme.useToken()

    //state:
    const [ code, setCode ] = useState('')

    //login:
    const { mutate: loginWithOTP, isLoading, data } = useLoginWithOTP(email, code)

    //send request:
    useEffect(() => { code.length === 6 && loginWithOTP() }, [code])

    //handle the response:
    useEffect(() => {
        if(data){
            switch(data?.result){
                case 'success':
                    onLogin(data.payload.accessToken, data.payload.refreshToken)
                    break
                case 'invalidOTP':
                    Modal.info({
                        title: __`invalid_code`,
                        content: __`the_code_you_entered_is_wrong_please_try_to_send_another_one`,
                        okText: __`resend`,
                        onOk: onBackToLogin
                    })
                    break
                case 'userNotActivated':
                case 'userNotFound':
                    message.error(__`oops_something_went_wrong`)
                    onBackToLogin()
                    break
            }
        }
    }, [data])

    return (
        <Form
            initialValues={{ code: null }}
            validate={onValidate(LoginOtpFormSchema(__))}
            onSubmit={(values: { code: string }) => 
                LoginOtpFormSchema(__).validate(values).then(() => loginWithOTP())
            }
            render={({ handleSubmit }) => (

                <form onSubmit={handleSubmit}>
                
                    <VerticalContainer style={{ gap: marginXS }}>
                
                        {/* code */}
                        <Field
                            name="code"
                            placeholder={__`the_code_is_case_sensitive`}
                            component={FormInput}
                            maxLength={6}
                            size={'large'}
                            style={{ fontSize }}
                            onKeyUp={(e: any) => setCode(e.target.value)}
                            onPaste={(e: any) => {
                                //waiting for the linux middle click paste to paste!
                                setTimeout(() => setCode(e.target.value), 100)
                            }}
                        />

                        {/* login button */}
                        <Button
                            type='primary'
                            size='large'
                            style={{ fontSize, color: colorBgContainer }}
                            onClick={() => {!isLoading && handleSubmit()}}
                        >

                            <HorizontalContainer style={{ justifyContent: 'space-between' }}>

                                <RightOutlined style={{ visibility: 'hidden' }}/>
                                <Text style={{ color: colorBgContainer, fontFamily: 'inter-medium' }}>{__`login_to_sesami`}</Text>
                                {isLoading ? <LoadingOutlined/> : <RightOutlined/>}

                            </HorizontalContainer>

                        </Button>

                    </VerticalContainer>

                    <input type='submit' hidden/>

                </form>
                
            )}
        />
    )

}
