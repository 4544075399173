import { useConfig } from 'old/api'
import { Alert, Button, Input, Select, Typography, theme } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons'
import { Availability, AvailabilityInterval, Config }  from 'old/types'
import { getIsoStringYearMonthDay, isNil, timeToFloatHoursAndMinutes, useTranslate, useWindowDimensions } from 'old/utils'
import { HorizontalContainer, RWContainer, RWElement, VerticalContainer } from 'old/components/styled'
import { useMemo, useState } from 'react'
const { Text } = Typography

type Props = {
    date: Date
    availability: Availability
    onAdd: (availability: Availability) => void
    onBack: () => void
}

export const EditAvailability = ({ date, availability, onAdd, onBack }: Props) => {

    //hooks:
    const { token: {
        fontSize,
        marginXXS,
        marginXS,
        marginSM,
        margin,
        paddingXXS,
        paddingMD,
        padding,
        fontSizeHeading4,
        fontSizeSM,
        colorPrimary,
        colorBorder
    }} = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config } = useConfig()

    //state:
    type Interval = AvailabilityInterval & { fromError: string | null, toError: string | null }
    const [ intervals, setIntervals ] = useState(
        availability.intervals.map(interval => {
            return {
                ...interval,
                fromError: null,
                toError: null
            }
        }
    ) as Interval[])

    //getWeekDaySum:
    const getWeekDaySum = (date: Date) => {
        const weekDay = date.toLocaleString(['en-US'], { weekday: 'long' }).toLowerCase()
        const weekDay_sum = `${weekDay}_sum`
        return __(weekDay_sum)
    }

    //addInterval:
    const addInterval = () => {
        setIntervals([...intervals, {
            'from': '',
            'to': '',
            fromError: null,
            toError: null
        }])
    }

    //removeInterval:
    const removeInterval = (intervals: Interval[], index: number) => {
        const newIntervals = [...intervals]
        newIntervals.splice(index, 1)
        setIntervals(newIntervals)
    }

    //get12FormatTime:
    const get12FormatTime = (config: Config, date: Date, time: string) => {
        if(time === ''){
            return ''
        }
        const yearMonthDay = getIsoStringYearMonthDay(date)
        const yearMonthDayTime = new Date(`${yearMonthDay} ${time}`)
        const yearMonthDayTime12 = yearMonthDayTime.toLocaleString([config.locale, 'en-US'], { hour: '2-digit', minute: '2-digit', hour12: true })
        return yearMonthDayTime12.replace(' AM', '').replace(' PM', '')
    }

    //get12FormatAmPm:
    const get12FormatAmPm = (date: Date, time: string) => {
        if(time === ''){
            return ''
        }
        const yearMonthDay = getIsoStringYearMonthDay(date)
        const dateWithTime = new Date(`${yearMonthDay} ${time}`)
        const yearMonthDayTime12 = dateWithTime.toLocaleString(['en-US'], { hour: '2-digit', hour12: true })
        return yearMonthDayTime12.includes('AM') ? 'am' : 'pm'
    }

    //updateFromTime:
    const updateFromTime = (date: Date, time: string, index: number) => {
        const amPm = get12FormatAmPm(date, intervals[index].from)
        const newIntervals = [...intervals]
        if(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)){
            if(amPm === 'am'){
                newIntervals[index].from = time.includes('12:') ? time.replace('12:', '00:') : time === '12' ? '00' : time
            }else{
                const splitFrom = time.split(':')
                const hour = Number(splitFrom[0])
                const minute = splitFrom[1]
                const newHour = hour < 12 ? hour + 12 : hour
                newIntervals[index].from = `${newHour}:${minute}`
            }
            newIntervals[index].fromError = null
        }else if(/\b([0-9]|0[0-9]|1[0-9]|2[0-4])\b/.test(time)){
            const hour = Number(time)
            if(amPm === 'pm'){
                const newHour = hour < 12 ? hour + 12 : hour
                newIntervals[index].from = `${newHour}:00`
            }else{
                newIntervals[index].from = `${hour <= 9 ? '0' : ''}${hour}:00`
            }
            newIntervals[index].fromError = null
        }else{
            newIntervals[index].from = ''
            newIntervals[index].fromError = __`please_enter_a_valid_time_for_the_start`
        }
        setIntervals(checkOrderAndInterfereForFrom(newIntervals, index))
    }

    //updateFromAmPm:
    const updateFromAmPm = (amPm: string, index: number) => {
        const time = intervals[index].from
        const splitFrom = time.split(':')
        const hour = Number(splitFrom[0])
        const newHour = amPm === 'am' ? hour - 12 : hour + 12
        const newTime = `${newHour < 10 ? '0' : ''}${newHour}:${splitFrom[1]}`
        const newIntervals = [...intervals]
        newIntervals[index].from = newTime
        setIntervals(checkOrderAndInterfereForFrom(newIntervals, index))
    }

    //checkOrderAndInterfereForFrom:
    const checkOrderAndInterfereForFrom = (intervals: Interval[], index: number) => {

        const from = intervals[index].from
        const to = intervals[index].to

        if(from !== '' && to !== ''){

            //check for order:
            if(timeToFloatHoursAndMinutes(from) >= timeToFloatHoursAndMinutes(to)){
                intervals[index].fromError = __`end_time_must_be_after_start_time`
                intervals[index].toError = null
            }else{
                //check for interference:
                intervals[index].fromError = intervals[index].toError = null
                intervals.map((interval, i) => {
                    if(i != index){
                        if(
                            timeToFloatHoursAndMinutes(from) <= timeToFloatHoursAndMinutes(interval.to) &&
                            timeToFloatHoursAndMinutes(to) >= timeToFloatHoursAndMinutes(interval.from)
                        ){
                            intervals[index].fromError = __`intervals_should_not_interfere_each_other`
                            intervals[index].toError = interval.toError = interval.fromError = ''
                        }else{
                            interval.toError = interval.fromError = null
                        }
                    }
                })
            }

        }

        return intervals

    }

    //updateToTime:
    const updateToTime = (date: Date, time: string, index: number) => {
        const amPm = get12FormatAmPm(date, intervals[index].to)
        const newIntervals = [...intervals]
        if(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)){
            if(amPm === 'am'){
                newIntervals[index].to = time.includes('12:') ? time.replace('12:', '00:') : time === '12' ? '00' : time
            }else{
                const splitFrom = time.split(':')
                const hour = Number(splitFrom[0])
                const minute = splitFrom[1]
                const newHour = hour < 12 ? hour + 12 : hour
                newIntervals[index].to = `${newHour}:${minute}`
            }
            newIntervals[index].toError = null
        }else if(/\b([0-9]|0[0-9]|1[0-9]|2[0-4])\b/.test(time)){
            const hour = Number(time)
            if(amPm === 'pm'){
                const newHour = hour < 12 ? hour + 12 : hour
                newIntervals[index].to = `${newHour}:00`
            }else{
                newIntervals[index].to = `${hour <= 9 ? '0' : ''}${hour}:00`
            }
            newIntervals[index].toError = null
        }else{
            newIntervals[index].to = ''
            newIntervals[index].toError = __`please_enter_a_valid_time_for_the_end`
        }

        //exception!
        if(newIntervals[index].to === '00:00'){
            newIntervals[index].to = '24:00'
        }

        setIntervals(checkOrderAndInterfereForTo(newIntervals, index))
    }

    //updateToAmPm:
    const updateToAmPm = (amPm: string, index: number) => {
        const time = intervals[index].to
        const splitFrom = time.split(':')
        const hour = Number(splitFrom[0])
        const newHour = amPm === 'am' ? hour - 12 : hour + 12
        let newTime = `${newHour < 10 ? '0' : ''}${newHour}:${splitFrom[1]}`

        //exception!
        if(newTime === '00:00'){
            newTime = '24:00'
        }

        const newIntervals = [...intervals]
        newIntervals[index].to = newTime
        setIntervals(checkOrderAndInterfereForTo(newIntervals, index))
    }

    //checkOrderAndInterfereForTo:
    const checkOrderAndInterfereForTo = (intervals: Interval[], index: number) => {

        const from = intervals[index].from
        const to = intervals[index].to

        if(from !== '' && to !== ''){

            //check for order:
            if(timeToFloatHoursAndMinutes(from) >= timeToFloatHoursAndMinutes(to)){
                intervals[index].fromError = null
                intervals[index].toError = __`end_time_must_be_after_start_time`
            }else{
                //check for interference:
                intervals[index].fromError = intervals[index].toError = null
                intervals.map((interval, i) => {
                    if(i != index){
                        if(
                            timeToFloatHoursAndMinutes(from) <= timeToFloatHoursAndMinutes(interval.to) &&
                            timeToFloatHoursAndMinutes(to) >= timeToFloatHoursAndMinutes(interval.from)
                        ){
                            intervals[index].fromError = __`intervals_should_not_interfere_each_other`
                            intervals[index].toError = interval.toError = interval.fromError = ''
                        }else{
                            interval.toError = interval.fromError = null
                        }
                    }
                })
            }

        }

        return intervals

    }

    //isButtonsDisable:
    const isButtonsDisable = useMemo(() => {
        const errors = intervals.find(int =>
            int.from === '' || int.to === '' ||
            !isNil(int.fromError) || !isNil(int.toError)
        )
        return !isNil(errors)
    }, [intervals])
    
    return (!config ? null :
        <VerticalContainer style={{
            height: '100%',
            padding: SM ? paddingMD : padding,
            gap: margin
        }}>

            {/* header / navigation */}
            <VerticalContainer style={{ alignItems: 'flex-start' }}>

                <Button
                    type='text'
                    shape='circle'
                    size='large'
                    style={{ marginLeft: -marginSM, marginTop: -marginXS }}
                    onClick={onBack}
                >
                    <LeftOutlined/>
                </Button>

                {/* title */}
                <Text
                    style={{
                        fontSize: fontSizeHeading4,
                        fontFamily: 'inter-medium'
                    }}
                >
                    {__`edit_availability`}
                </Text>

            </VerticalContainer>

            <VerticalContainer style={{
                flex: 1,
                maxHeight: '100%',
                overflow: 'auto',
                gap: marginXS
            }}>

                {/* alert */}
                {intervals.length === 0 &&
                    <Alert message={__`not_available`}/>
                }

                {/* interval inputs */}
                {intervals.map((interval, index) =>

                    <VerticalContainer key={`${interval.from}.${interval.to}.${index}`} style={{ paddingLeft: 2 }}>

                        <HorizontalContainer style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>

                            <RWContainer style={{ flex: 1, gap: marginXXS }}>

                                <RWElement breakPoint={130}>

                                    <RWContainer style={{ flex: 1, gap: 0 }}>

                                        <RWElement breakPoint={40} style={{ paddingLeft: paddingXXS }}>
                                        
                                            <Text style={{ minWidth: 35, fontSize: fontSizeSM, fontFamily: 'inter-medium' }}>
                                                {__`from_colon`}
                                            </Text>

                                        </RWElement>

                                        <RWElement breakPoint={70}>

                                            <HorizontalContainer style={{ width: 'fit-content', gap: marginXXS }}>
                                                
                                                {/* from */}
                                                <Input
                                                    maxLength={5}
                                                    placeholder="09:00"
                                                    defaultValue={get12FormatTime(config, date, interval.from)}
                                                    status={isNil(interval.fromError) ? undefined : 'error'}
                                                    style={{ width: 60, padding: paddingXXS, textAlign: 'center' }}
                                                    onBlur={e => updateFromTime(date, e.target.value, index)}
                                                />
                                                <Select
                                                    options={[{ value: 'am', label: __`am` }, { value: 'pm', label: __`pm` } ]}
                                                    value={get12FormatAmPm(date, interval.from)}
                                                    status={isNil(interval.fromError) ? undefined : 'error'}
                                                    style={{ minWidth: 65 }}
                                                    onChange={val => updateFromAmPm(val, index)}
                                                />

                                            </HorizontalContainer>

                                        </RWElement>

                                    </RWContainer>

                                </RWElement>

                                <RWElement breakPoint={130}>

                                    <RWContainer style={{ flex: 1, gap: 0 }}>

                                        <RWElement breakPoint={40} style={{ paddingLeft: paddingXXS }}>
                                        
                                            <Text style={{ minWidth: 35, fontSize: fontSizeSM, fontFamily: 'inter-medium' }}>
                                                {__`to_colon`}
                                            </Text>

                                        </RWElement>

                                        <RWElement breakPoint={70}>

                                            <HorizontalContainer style={{ width: 'fit-content', gap: marginXXS }}>
                                                
                                                {/* to */}
                                                <Input
                                                    maxLength={5}
                                                    placeholder="05:00"
                                                    defaultValue={get12FormatTime(config, date, interval.to)}
                                                    status={isNil(interval.toError) ? undefined : 'error'}
                                                    style={{ width: 60, padding: paddingXXS, textAlign: 'center' }}
                                                    onBlur={e => updateToTime(date, e.target.value, index)}
                                                />
                                                <Select
                                                    options={[{ value: 'am', label: __`am` }, { value: 'pm', label: __`pm` } ]}
                                                    status={isNil(interval.toError) ? undefined : 'error'}
                                                    value={get12FormatAmPm(date, interval.to)}
                                                    style={{ minWidth: 65 }}
                                                    onChange={val => updateToAmPm(val, index)}
                                                />

                                            </HorizontalContainer>

                                        </RWElement>

                                    </RWContainer>

                                </RWElement>

                            </RWContainer>

                            {/* remove button */}
                            <Button
                                type='text'
                                shape='circle'
                                size='large'
                                icon={<DeleteOutlined style={{ fontSize }}/>}
                                title={__`remove_interval`}
                                style={{ marginBottom: -marginXXS }}
                                onClick={() => removeInterval(intervals, index)}
                            />

                        </HorizontalContainer>

                        {/* error */}
                        {!isNil(interval.fromError) && interval.fromError !== '' && <Text type='danger' style={{ display: 'flex', gap: marginXXS }}><ExclamationCircleOutlined/>{interval.fromError}</Text>}
                        {!isNil(interval.toError) && interval.toError !== '' && <Text type='danger' style={{ display: 'flex', gap: marginXXS }}><ExclamationCircleOutlined/>{interval.toError}</Text>}

                    </VerticalContainer>
                )}

                {/* add interval */}
                <Button
                    type='text'
                    size='large'
                    icon={<PlusOutlined style={{ fontSize: fontSizeSM }}/>}
                    style={{ fontSize, marginTop: marginXXS }}
                    onClick={addInterval}
                >
                    {intervals.length === 0 ? __`add_interval` : __`add_another_interval`}
                </Button>

            </VerticalContainer>

            <VerticalContainer style={{ gap: marginXS, paddingBottom: SM ? 0 : paddingXXS }}>

                {/* apply to a day */}
                <Button
                    type='text'
                    size='large'
                    style={{
                        fontSize,
                        border: `solid 1px ${isButtonsDisable ? colorBorder : colorPrimary}`
                    }}
                    disabled={isButtonsDisable}
                    onClick={() => onAdd({
                        type: 'date',
                        date: getIsoStringYearMonthDay(date),
                        weekday: null,
                        intervals: intervals.map(i => {
                            return {
                                from: i.from,
                                to: i.to
                            }
                        })
                    })}
                >
                    {`${__`apply_to`} ${date.toLocaleString([config.locale, 'en-US'], { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' })}`}
                </Button>

                {/* apply to all days */}
                <Button
                    type='primary'
                    size='large'
                    style={{ fontSize }}
                    disabled={isButtonsDisable}
                    onClick={() => onAdd({
                        type: 'wday',
                        date: null,
                        weekday: date.toLocaleString(['en-US'], { weekday: 'long' }).toLowerCase(),
                        intervals: intervals.map(i => {
                            return {
                                from: i.from,
                                to: i.to
                            }
                        })
                    })}
                >
                    {`${__`apply_to_all`} ${getWeekDaySum(date)}`}
                </Button>

            </VerticalContainer>

        </VerticalContainer>
    )
    
}
