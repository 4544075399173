import { ButtonWrapper, MainWrapper } from './booking-available-slot.style'
import { useEffect, useState } from 'react'
import { Tooltip, Typography, theme } from 'antd'
import { dateTimeToNumericHour, useTranslate } from 'utils'
import { useConfig } from 'api'
const { Text } = Typography

export type Mode = 'multiLines' | 'singleLine' | 'compact' | 'noText' | 'justLine'

type Props = {
    date: Date
    dayStartHour: number
    startDateTime: string
    duration: number
    length: number
    scale: number
    isSelected: boolean
    onClick: () => void
}

export const BookingAvailableSlot = ({
    date,
    dayStartHour,
    startDateTime,
    duration,
    length,
    scale,
    isSelected,
    onClick
}: Props) => {

    //translate:
    const { __ } = useTranslate()
    const { token: { fontSizeSM, colorPrimaryTextActive, colorPrimary, colorBgContainer } } = theme.useToken()
    const { data: config } = useConfig()
    
    //start date:
    const startDate = new Date(startDateTime)

    //calcTop:
    const calcTop = () => (dateTimeToNumericHour(startDateTime, date) - dayStartHour)*60*scale

    //calcMode:
    const calcMode = (height: number): Mode => {
        if(height >= 34)
            return 'multiLines'
        if(height >= 18)
            return 'singleLine'
        if(height >= 17)
            return 'compact'
        if(height >= 4)
            return 'noText'
        return 'justLine'
    }

    //state:
    const [ top, setTop ] = useState(calcTop())
    const [ height, setHeight ] = useState(length*scale)
    const [ mode, setMode ] = useState(calcMode(length*scale))

    useEffect(() => {
        setTop(calcTop())
        setHeight(length*scale)
        setMode(calcMode(length*scale))
    }, [length, scale])
    
    return !config ? null : (
        <Tooltip
            title={`${isSelected ? __`selected` : __`available`} • ${__('duration_min', { duration })}`}
            color={colorPrimary}
            overlayClassName='bookingTimeSlotTooltip' // styled in index.scss
            trigger={['focus', 'hover']}
            overlayStyle={{ fontSize: fontSizeSM }}
        >

            <MainWrapper height={height} top={top}>

                <ButtonWrapper
                    isSelected={isSelected}
                    onClick={onClick}
                >

                    {mode !== 'noText' && mode !== 'justLine' &&

                        <Text
                            style={{
                                maxWidth: '100%',
                                fontSize: fontSizeSM,
                                fontFamily: 'inter-medium',
                                color: isSelected ? colorBgContainer : colorPrimaryTextActive,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                        >

                            {startDate.toLocaleString([config.locale, 'en-US'], { hour: 'numeric', minute: 'numeric' })}

                        </Text>
                        
                    }

                </ButtonWrapper>

            </MainWrapper>

        </Tooltip>
    )
    
}
