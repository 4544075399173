import { ConfirmationPage } from '../../confirmation-page/confirmation-page'
import { message } from 'antd'
import { useCompleteDraftOrder } from 'old/api'
import { PageError } from 'old/components/styled'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslate }  from 'old/utils'

export const BookingDraft = () => {

    //translate:
    const { __ } = useTranslate()

    //navigation:
    const navigate = useNavigate()

    //params:
    const params = useParams()

    //bookingId:
    const bookingId = params.bookingId
    if(!bookingId){
        navigate('/404')
    }

    //complete order:
    const { mutate: completeOrder, isLoading, data: response } = useCompleteDraftOrder(bookingId ?? '')

    //after complete:
    useEffect(() => {
        if(response){
            message.success(__`your_order_is_completed`)
            if(location.pathname.includes('/service')){
                navigate(-1)
            }else{
                navigate('/calendar')
            }
        }
    }, [isLoading])

    //render:
    return (
        !bookingId ? <PageError/> : (
            <ConfirmationPage
                status='success'
                pageTitle={''}
                title={__`draft_order_created_successfully`}
                message={__`mark_as_paid_or_send_an_invoice_appointment_will_be_booked_as_soon_as_the_order_is_completed`}
                confirmText={__`complete_draft_order`}
                isLoading={isLoading}
                onConfirm={completeOrder}
                onBack={() => navigate(-1)}
            />
        )
    )
}
