import { TeamMemberCopyWrapper } from './team-member-availabilities.style'
import { TeamMemberCopy } from '../team-member-copy/team-member-copy'
import { message, theme } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useTranslate }  from 'old/utils'
import { useState } from 'react'
import { FormSection } from 'old/components/form-section/form-section'
import { useField } from 'react-final-form'
import { VerticalContainer } from 'old/components/styled'
import { AvailabilityCalendar } from 'old/components/availability/availability-calendar/availability-calendar'

export const TeamMemberAvailabilities = () => {

    //hooks:
    const { token: { fontSizeSM, paddingXXS, paddingXS, marginXS, margin } } = theme.useToken()
    const { __ } = useTranslate()
    const { input: { value: availabilities, onChange: updateAvailabilities } } = useField('availabilities')

    //state:
    const [ isMembersOpen, setIsMembersOpen ] = useState(false)
    
    //render:
    return (
        <FormSection
            title={__`availabilities`}
            topButton={{
                label: __`copy_from_another_member`,
                type: 'link',
                icon: isMembersOpen ? <UpOutlined style={{ fontSize: fontSizeSM }}/> : <DownOutlined style={{ fontSize: fontSizeSM }}/>,
                style: {
                    padding: `${paddingXS}px ${paddingXXS}px `,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    gap: marginXS
                },
                onClick: () => setIsMembersOpen(!isMembersOpen)
            }}
        >

            <VerticalContainer style={{ gap: margin }}>

                {/* copy */}
                {isMembersOpen &&
                    <TeamMemberCopyWrapper>
                        <TeamMemberCopy
                            onSelect={availabilities => {
                                setIsMembersOpen(false)
                                updateAvailabilities(availabilities)
                                message.success(__`availabilities_are_copied_from_the_team_member`)
                            }}
                        />
                    </TeamMemberCopyWrapper>
                }
                
                {/* calendar */}
                <AvailabilityCalendar
                    availabilities={availabilities}
                    updateAvailabilities={updateAvailabilities}
                />

            </VerticalContainer>

        </FormSection>
    )
    
}
