import { Typography, theme } from 'antd'
import { FormInput, FormSelect } from 'components/form-inputs'
import { HorizontalContainer, NotFound, RWContainer, RWElement, VerticalContainer } from 'components/styled'
import { Field } from 'react-final-form'
import { NullableField, useTranslate, useWindowDimensions } from 'utils'
import { timezones } from 'data'
import { FormSection } from 'components/form-section/form-section'
import { ImageUpload } from 'components/image-upload/image-upload'
const  { Title, Text } = Typography

export const LocationInfo = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginXS, marginLG } } = theme.useToken()
    const { SM } = useWindowDimensions()

    return (
        <FormSection style={{ minHeight: 0, paddingBottom: SM ? marginLG : 0 }}>

            <RWContainer style={{ alignItems: 'normal', gap: marginLG }}>

                {/* info */}
                <RWElement breakPoint={300}>
                    <LocationInfoForm/>
                </RWElement>

                {/* image */}
                <RWElement breakPoint={300}>

                    <VerticalContainer style={{ height: '100%', gap: marginXS }}>

                        <Text style={{ fontFamily: 'inter-medium' }}>
                            {__`image`}
                        </Text>

                        <ImageUpload
                            type='location'
                            nameField='name'
                            imageField='image'
                        />

                    </VerticalContainer>

                </RWElement>

            </RWContainer>

        </FormSection>
    )

}

const LocationInfoForm = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingXXS, marginXXS, marginSM } } = theme.useToken()
    const { SM } = useWindowDimensions()

    return (
        <VerticalContainer style={{ gap: marginSM }}>

            {/* title */}
            <Title
                level={5}
                style={{
                    paddingTop: SM ? 0 : paddingXXS,
                    margin: `${marginXXS}px 0`
                }}
            >
                {__`info`}
            </Title>
            
            {/* name */}
            <NullableField
                name="name"
                label={__`name`}
                placeholder={__`city_name_example`}
                component={FormInput}
            />

            <HorizontalContainer style={{ alignItems: 'flex-start', gap: marginSM }}>

                {/* phone */}
                <div style={{ flex: 1 }}>
                    <NullableField
                        name="phone"
                        label={__`phone`}
                        placeholder={__`phone_example`}
                        component={FormInput}
                    />
                </div>
                
                {/* email */}
                <div style={{ flex: 1 }}>
                    <NullableField
                        name="email"
                        label={__`email`}
                        placeholder="location@email.com"
                        component={FormInput}
                    />
                </div>

            </HorizontalContainer>

            {/* description */}
            <NullableField
                name="description"
                label={__`description`}
                placeholder={__`our_main_location`}
                component={FormInput}
            />

            {/* timezone */}
            <Field
                name="timezone"
                label={__`timezone`}
                type="select"
                options={timezones}
                filterOption={(input: string, option: { value: string, label: string}) => (
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                )}
                showSearch
                notFoundContent={<NotFound/>}
                component={FormSelect}
            />

        </VerticalContainer>
    )

}
