import { MainWrapper, ProgressBarContainer } from './slot-details.style'
import { Drawer, Typography, theme } from 'antd'
import { Appointment, AppointmentEventType, CalendarSlot, CalendarSlotType, Config } from 'types'
import { RightOutlined } from '@ant-design/icons'
import { TranslateFunction, addMinutes, getColorById, useTranslate, useWindowDimensions } from 'utils'
import { ProgressBar } from 'components/progress-bar/progress-bar'
import { Avatar } from 'components/avatar/avatar'
import { useState } from 'react'
import { useAppointments, useConfig } from 'api'
import { ColumnContainer, Label, Loading, PageError } from 'components/styled'
import { AppointmentFilter } from 'components/appointment/appointment-navigation/appointment-navigation'
import { AppointmentDetails } from 'components/appointment-details/appointment-details/appointment-details'
import { AppointmentDetailsHeader } from 'components/appointment-details/appointment-details-header/appointment-details-header'
import { AppointmentDetailNavigation } from 'components/appointment-details/appointment-details-navigation/appointment-details-navigation'
import { ReservationDetails } from 'components/appointment-details/reservation-details/reservation-details'
const { Text } = Typography

type Props = {
    slot: CalendarSlot
    onClose: () => void
}

export const SlotDetails = ({ slot, onClose }: Props) => {

    //hooks:
    const { token: {
        fontSizeSM,
        paddingXS,
        padding,
        paddingLG,
        marginXXS,
        marginMD,
        marginLG
    }} = theme.useToken()
    const { width, SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //color:
    const colorRGB = getColorById(slot.service?.id)

    //appointmentIds:
    const appointmentIds = (slot.items.filter(i => i.type == CalendarSlotType.APPOINTMENT)).map(i => i.id ?? '')
    const hasAppointments = appointmentIds.length > 0

    //reserved items:
    const reservedItems = slot.items.filter(i => i.type == CalendarSlotType.RESERVATION)
    const hasReservation = reservedItems.length > 0

    //appointments:
    const { data: appointments, isLoading: isAppointmentsLoading } = useAppointments(
        null,
        appointmentIds,
        null,
        AppointmentFilter.ALL,
        null,
        null,
        50,
        hasAppointments,
        false
    )

    //state:
    const [ selectedAppointmentId, setSelectedAppointmentId ] = useState<string | null>(null)
    const [ selectedReservationId, setSelectedReservationId ] = useState<string | null>(null)
    const [ isAppointmentDetailsOpen, setIsAppointmentDetailsOpen ] = useState(false)

    const openInfoDrawer = (appointmentId: string | null, reservationId: string | null) => {
        if(appointmentId){
            setSelectedAppointmentId(appointmentId)
            setSelectedReservationId(null)
        }else{
            setSelectedAppointmentId(null)
            setSelectedReservationId(reservationId)
        }
        setIsAppointmentDetailsOpen(true)
    }

    const calcNumberOfParticipants = (appointments: Appointment[]) => {
        let count = 0
        appointments.map(a => {
            count = count + a.quantity
        })
        return count
    }

    //participantStyle:
    const participantStyle = { padding: `${paddingXS}px ${SM ? paddingLG : padding}px`, borderRadius: 0 }

    return (
        (isConfigLoading || !config) ? null : (
            (hasAppointments && (isAppointmentsLoading)) ? <Loading/> : (hasAppointments && !appointments) ? <PageError/> : (

                <MainWrapper style={{ paddingBottom: `${paddingXS}px` }}>

                    <div style={{ padding: `0 ${SM ? paddingXS : 0}px` }}>

                        {/* navigation */}
                        <AppointmentDetailNavigation onClose={onClose}/>
                        
                        {/* header */}
                        <ColumnContainer marginTop={marginXXS}>
                            <AppointmentDetailsHeader
                                title={slot.service?.title ?? __`unknown`}
                                description={getDateTimeRange(slot.dateTime, slot.duration, config)}
                                tag={__('duration_min', { duration: slot.duration })}
                                colorRGB={colorRGB}
                            />
                        </ColumnContainer>

                    </div>

                    <ColumnContainer marginTop={marginLG}>

                        <div style={{ padding: `0 ${SM ? paddingXS : 0}px` }}>
                            
                            <Text style={{ padding: `0 ${padding}px`, fontFamily: 'inter-medium' }}>
                                {__`participants`}
                            </Text>

                            {/* progress bar */}
                            {slot.service?.groupAppointments &&
                                <ProgressBarContainer>
                                    <ProgressBar
                                        value={appointments ? calcNumberOfParticipants(appointments) : 0}
                                        total={slot.service.groupAppointmentSlots ?? 1}
                                        colorRGB={colorRGB}
                                    />
                                </ProgressBarContainer>
                            }

                        </div>
                        
                        {/* participants */}
                        {hasAppointments && (
                            <ColumnContainer marginTop={marginMD} gap={marginXXS}>
                                {appointments && appointments.map(appointment =>
                                    <Avatar
                                        key={appointment.id}
                                        image={appointment.customer?.image.path ?? null}
                                        name={appointment.customer?.name ?? appointment.customer?.email ?? __`unknown`}
                                        tag={getParticipantLabel(getParticipantStatus(appointment), __)}
                                        description={__`customer`}
                                        extraIcon={<RightOutlined style={{ fontSize: fontSizeSM, marginRight: marginXXS }}/>}
                                        style={participantStyle}
                                        onClick={() => openInfoDrawer(appointment.id, null)}
                                    />
                                )}
                            </ColumnContainer>
                        )}

                        {/* reservations */}
                        {hasReservation && (
                            <ColumnContainer marginTop={marginMD} gap={marginXXS}>
                                {reservedItems.map(reservation =>
                                    <Avatar
                                        key={reservation.id}
                                        image={null}
                                        name={__`reserved`}
                                        description={__`customer`}
                                        style={participantStyle}
                                        onClick={() => openInfoDrawer(null, reservation.id)}
                                    />
                                )}
                            </ColumnContainer>
                        )}

                    </ColumnContainer>

                    {/* info */}
                    <Drawer
                        closable={false}
                        width={SM ? 450 : width} // full width in mobile
                        open={isAppointmentDetailsOpen}
                        styles={{ body: { padding: 0 }}}
                        onClose={() => setIsAppointmentDetailsOpen(false)}
                    >

                        {
                            selectedAppointmentId ? (
                                <AppointmentDetails
                                    appointmentId={selectedAppointmentId}
                                    isOpen={isAppointmentDetailsOpen}
                                    onClose={() => setIsAppointmentDetailsOpen(false)}
                                />
                            ) : selectedReservationId ? (
                                <ReservationDetails
                                    reservationId={selectedReservationId}
                                    onClose={() => setIsAppointmentDetailsOpen(false)}
                                />
                            ) : <></>
                        }
                        
                    </Drawer>

                </MainWrapper>
            )
        )
    )

}

//getDateTimeRange:
export const getDateTimeRange = (start: string, duration: number, config: Config) => {
    const dateTimeFormat = new Intl.DateTimeFormat([config.locale, 'en-US'], {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    })
    const startDate = new Date(start)
    let endDate = new Date(startDate.getTime())
    endDate = addMinutes(endDate, duration)
    return dateTimeFormat.formatRange(startDate, endDate)
}

//getParticipantState:
const getParticipantStatus = (appointment: Appointment): AppointmentEventType | 'CANCELLED' => {
    if(appointment.status === 'cancelled'){
        return 'CANCELLED'
    }else{
        for(let i = appointment.events.length - 1; i >= 0 ; i--){
            if(['NO_SHOW', 'CUSTOMER_CHECKIN', 'RESCHEDULED'].indexOf(appointment.events[i].type) > -1){
                return appointment.events[i].type
            }
        }
        return 'CREATED'
    }
}

//getParticipantLabel:
const getParticipantLabel = (status: AppointmentEventType | 'CANCELLED', __: TranslateFunction) => {
    switch(status){
        case 'NO_SHOW':
            return <Label size='tiny' type='warning' text={__`no_show`}/>
        case 'CUSTOMER_CHECKIN':
            return <Label size='tiny' type='success' text={__`check_in`}/>
        case 'CANCELLED':
            return <Label size='tiny' type='danger' text={__`cancelled`}/>
        default:
            return undefined
    }
}
