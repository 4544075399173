import styled from 'styled-components'
import { useNow } from 'utils'
import { CALENDAR_PAGE_CURRENT_LINE_Z_INDEX } from 'z-indexes'

type Props = {
    dayStartHour: number
    timezoneOffset: number
    scale: number
}

export const CalendarPageCurrentLine = ({ dayStartHour, timezoneOffset, scale }: Props) => {

    const now = useNow(timezoneOffset)
    const nowHour = now.getHours()
    const nowMinute = now.getMinutes()
    const top = ((nowHour - dayStartHour)*60 + nowMinute)*scale
    
    return top >= 0 ? <Line style={{ top: top }}/> : <></>
    
}

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colorErrorHover};
    position: absolute;
    left: 0;
    z-index: ${CALENDAR_PAGE_CURRENT_LINE_Z_INDEX};
`
