import { useEffect } from 'react'
import { getShopId, queryClient, useTranslate } from 'utils'
import { useField } from 'react-final-form'
import { FormRemove } from 'components/form-remove/form-remove'
import { useNavigate } from 'react-router-dom'
import { useRemoveFlow } from 'api'
import { message } from 'antd'

export const FlowRemove = () => {

    //translate:
    const { __ } = useTranslate()

    //navigate
    const navigate = useNavigate()

    //field:
    const { input: { value: id } } = useField('id')

    //mutation:
    const { mutate, isLoading, data: response } = useRemoveFlow(id)

    //effect:
    useEffect(() => {
        if(response){
            queryClient.invalidateQueries(['flows', getShopId()])
            message.success(__`flow_removed`)
            navigate('/flow')
        }
    }, [isLoading])
    
    return (
        <FormRemove
            title={__`remove_flow`}
            message={__`all_settings_will_be_deleted_this_action_cant_be_undone`}
            description={__`this_will_not_remove_the_product_from_your_store`}
            isLoading={isLoading}
            onRemove={mutate}
        />
    )

}
