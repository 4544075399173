import {
    Column,
    Content,
    Cover,
    Header,
    InfoColumn,
    InfoContainer,
    InfoWrapper,
    MainWrapper,
    MemberContainer,
    SlotsColumn,
    TimeBox,
    TimeBoxColumn,
    TimelineColumn
} from './calendar-page-cell.style'
import {
    addHours,
    dateTimeToNumericHour,
    isSameDay,
    min,
    timeToFloatHoursAndMinutes,
    timeToIntegerHours,
    useWindowDimensions
}  from 'old/utils'
import { useCell }  from 'old/context/calendar-provider'
import { CalendarSlot, CalendarSlotTeamMember, CalendarSlotType, Config, TeamMember }  from 'old/types'
import { TimelineBlock } from 'old/components/calendar/timeline-block/timeline-block'
import { Avatar } from 'old/components/avatar/avatar'
import { Drawer, theme } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { CalendarPageCurrentBadge } from 'old/components/calendar-page/calendar-page-current-badge/calendar-page-current-badge'
import { CalendarPageCurrentLine } from 'old/components/calendar-page/calendar-page-current-line/calendar-page-current-line'
import { CalendarPageAvailabilityColumn } from 'old/components/calendar-page/calendar-page-availability-column/calendar-page-availability-column'
import { HorizontalContainer } from 'old/components/styled'
import { CalendarTimeSlot } from 'old/components/calendar/calendar-time-slot/calendar-time-slot'
import { AppointmentDetails } from 'old/components/appointment-details/appointment-details/appointment-details'
import { SlotDetails } from 'old/components/calendar/slot-details/slot-details'
import { ReservationDetails } from 'old/components/appointment-details/reservation-details/reservation-details'

type Props = {
    date: Date
    config: Config
}

export const CalendarPageCell = ({ date, config }: Props) => {

    //theme:
    const { token: {
        paddingXXS,
        paddingXS,
        padding,
        colorBgContainer,
        colorBorderSecondary
    }} = theme.useToken()

    //window dimensions:
    const { width, SM } = useWindowDimensions()

    //slots info:
    const info = useCell(date)
    const selectedTeamMembers = info.selectedTeamMembers as TeamMember[]
    const teamMembers = info.teamMembers as CalendarSlotTeamMember[]
    const scale = info.scale as number
    const now = info.now
    const splittedTime = now.split(' ')
    const nowTime = splittedTime[1]
    const timezoneOffset = info.timezoneOffset

    //state:
    const [ selectedSlot, setSelectedSlot ] = useState(null as CalendarSlot | null)
    const [ selectedSlotTeamMember, setSelectedSlotTeamMember ] = useState(null as CalendarSlotTeamMember | null)
    const [ isSlotDetailsOpen, setIsSlotDetailsOpen ] = useState(false)

    //calc day start hour:
    const dayStartHour = useMemo(() => {

        // between now and 9 am:
        let DSH = min(9, timeToIntegerHours(nowTime))

        // team members first available time:
        selectedTeamMembers.map(member => {
            member.availabilities.map(availability =>
                availability.intervals.map(interval => {
                    DSH = Math.floor(min(DSH, timeToFloatHoursAndMinutes(interval.from)))
                })
            )
        })

        // team members first slot:
        teamMembers.map(member => {
            member.slots.map(slot =>
                DSH = Math.floor(min(DSH, dateTimeToNumericHour(slot.dateTime, date, true)))
            )
        })

        return DSH

    }, [date, teamMembers, now])

    //getAvailableHours:
    const getAvailableHours = useCallback(() => {
        const hours: { value: number, formatted: string }[] = []
        let d = new Date(date.getTime())
        d.setHours(dayStartHour + 1)
        for(let i = d.getHours(); i <= 24; i++){
            hours.push({
                value: i,
                formatted: d.toLocaleString([config.locale, 'en-US'], { hour: 'numeric' })
            })
            d = addHours(d, 1)
        }
        return hours
    }, [dayStartHour])

    //openSlotDetails:
    const openSlotDetails = (teamMember: CalendarSlotTeamMember, slot: CalendarSlot) => {
        setSelectedSlot(slot)
        setSelectedSlotTeamMember(teamMember)
        setIsSlotDetailsOpen(true)
    }
    
    //render:
    return (
        <MainWrapper>

            <Header style={{
                position: SM ? 'static' : 'sticky',
                top: 128
            }}>

                <HorizontalContainer style={{
                    width: '100%',
                    paddingBottom: SM ? paddingXS : paddingXXS,
                    backgroundColor: colorBgContainer,
                    borderBottom: `solid 1px ${colorBorderSecondary}`,
                    gap: 0
                }}>

                    {/* just for alignment */}
                    <TimelineColumn isMobile={!SM} style={{ border: 'none' }}/>
                    
                    {/* members */}
                    {teamMembers.map(teamMember =>
                        <Column key={teamMember.id} totalColumns={teamMembers.length}>
                            <MemberContainer>
                                <Avatar
                                    key={teamMember.id}
                                    name={teamMember.name}
                                    image={teamMember.image.path}
                                    size='tiny'
                                />
                            </MemberContainer>
                        </Column>
                    )}

                </HorizontalContainer>

                {/* just for alignment */}
                <Cover style={{ width: SM ? 0 : padding, border: 'none' }}/>
            
            </Header>

            <Content>

                {/* timeline */}
                <TimelineColumn isMobile={!SM}>

                    {/* current badge */}
                    {isSameDay(date, new Date(now)) &&
                        <CalendarPageCurrentBadge
                            dayStartHour={dayStartHour}
                            timezoneOffset={timezoneOffset}
                            scale={scale}
                            config={config}
                        />
                    }
                    
                    {getAvailableHours().map(hour =>
                        <TimelineBlock
                            key={hour.formatted}
                            hour={hour.formatted}
                            scale={scale}
                        />
                    )}
                
                </TimelineColumn>

                <InfoWrapper>
                    
                    {/* info */}
                    <InfoContainer>
                        
                        {teamMembers.map(teamMember =>
                            <Column key={teamMember.id} totalColumns={teamMembers.length}>

                                <InfoColumn>

                                    {/* availabilities */}
                                    <CalendarPageAvailabilityColumn
                                        currentDay={date}
                                        availabilities={selectedTeamMembers.find(m => m.id === teamMember.id)?.availabilities ?? []}
                                        dayStartHour={dayStartHour}
                                        scale={scale}
                                    />

                                    {/* time boxes */}
                                    <TimeBoxColumn>{
                                        getAvailableHours().map(hour =>
                                            <TimeBox
                                                key={hour.formatted}
                                                scale={scale}
                                            />
                                    )}</TimeBoxColumn>

                                    {/* appointment slots */}
                                    <SlotsColumn>
                                        {teamMember.slots.map(slot =>
                                            <CalendarTimeSlot
                                                key={generateSlotKey(slot)}
                                                slot={slot}
                                                date={date}
                                                dayStartHour={dayStartHour}
                                                scale={scale}
                                                onClick={() => openSlotDetails(teamMember, slot)}
                                            />
                                        )}
                                    </SlotsColumn>

                                </InfoColumn>

                            </Column>
                        )}
                    
                    </InfoContainer>

                    {/* current time line */}
                    {isSameDay(date, new Date(now)) &&
                        <CalendarPageCurrentLine
                            dayStartHour={dayStartHour}
                            timezoneOffset={timezoneOffset}
                            scale={scale}
                        />
                    }

                </InfoWrapper>

                <Cover style={{ width: SM ? 0 : padding }}/>

            </Content>

            {/* drawer */}
            <Drawer
                closable={false}
                width={SM ? 450 : width } // full width in mobile
                open={isSlotDetailsOpen}
                styles={{ body: { padding: 0 }}}
                onClose={() => setIsSlotDetailsOpen(false)}
            >
                
                {(selectedSlotTeamMember && selectedSlot) && (

                    selectedSlot.service?.groupAppointments === false && selectedSlot.items.length === 1 ? (

                        selectedSlot.items[0].type === CalendarSlotType.APPOINTMENT ? (

                            //appointment details:
                            <AppointmentDetails
                                appointmentId={selectedSlot.items[0].id}
                                isOpen={isSlotDetailsOpen}
                                onClose={() => setIsSlotDetailsOpen(false)}
                            />

                        ) : (

                            // reservation details:
                            <ReservationDetails
                                slot={selectedSlot}
                                teamMember={selectedSlotTeamMember}
                                onClose={() => setIsSlotDetailsOpen(false)}
                            />

                        )

                    ) : (

                        //slot details:
                        <SlotDetails
                            teamMember={selectedSlotTeamMember}
                            slot={selectedSlot}
                            onClose={() => setIsSlotDetailsOpen(false)}
                        />

                    )
                )}
                
            </Drawer>
            
        </MainWrapper>
    )
    
}

//generateSlotKey:
const generateSlotKey = (slot: CalendarSlot) => `${slot.dateTime} ${slot.duration} ${slot.service?.id} ${slot.variantTitle}`
