import { Plan } from 'types'

export type Config = {
    locale: string
    canSetPermissions: boolean
    maxApp?: number
    features: Feature[]
    plan: Plan | null
}

export enum Feature {
	
	APPOINTMENT_MANAGEMENT = 'APPOINTMENT_MANAGEMENT',
	APPOINTMENT_REPORT = 'APPOINTMENT_REPORT',
	APPS = 'APPS',
	GROUP_APPOINTMENTS = 'GROUP_APPOINTMENTS',
    CALENDAR_SYNC = 'CALENDAR_SYNC',
    EXPERIENCE_SELECTOR = 'EXPERIENCE_SELECTOR',
    FLOWS = 'FLOWS',
    INSTANT_BOOKING = 'INSTANT_BOOKING',
    PERSONAL_ACCESS_TOKEN = 'PERSONAL_ACCESS_TOKEN',
    REMOVE_BRANDING = 'REMOVE_BRANDING',
    RESERVATION = 'RESERVATION',
    SERVICE_HOURS = 'SERVICE_HOURS',
    MULTI_LOCATION = 'MULTI_LOCATION',
    
    ADMIN_CHAT = 'ADMIN_CHAT',
    PORTAL_ONBOARDING = 'PORTAL_ONBOARDING',
    SENDER_EMAIL_DOMAIN_VERIFICATION = 'SENDER_EMAIL_DOMAIN_VERIFICATION',
    STOREFRONT_CALENDAR_V2 = 'STOREFRONT_CALENDAR_V2',
    STOREFRONT_SDK = 'STOREFRONT_SDK'

}
