import { min } from 'old/utils'

export const nameShortener = (name?: string | null, maxLength?: number) => {
    if(name){
        const splitted = name.split(' ')
        let shortened = ''
        for(let i = 0; i < min(splitted.length, maxLength ?? 3); i++){
            shortened += splitted[i].charAt(0).toUpperCase()
        }
        return shortened
    }else{
        return null
    }
}
