import { Me }  from 'old/types'
import { getRefreshTokenUserId, refreshToken, useMutationHandler, useQueryHandler }  from 'old/utils'

//useMe:
export const useMe = () => useQueryHandler<Me>({
    query: `
        query getMyInfo {
            getMyInfo {

                id
                email
                firstName
                lastName

                image {
                    id
                    path
                }
                
                locale
                loginStrategy
                
            }
        }
    `,
    cacheKey: ['me', getRefreshTokenUserId(refreshToken())],
    onSuccess: json => json.data.getMyInfo
})

//update:
export const useProfileUpdate = (me: Me) => useMutationHandler({
    query: `
        mutation updateMe (
            $request: UpdateMeRequest!
        ) {
            updateMe(
                request: $request
            ){
                email
            }
        }
    `,
    variables: {
        request: {
            firstName: me.firstName,
            lastName: me.lastName,
            locale: me.locale
        }
    },
    onSuccess: json => json.data.updateMe.email === me.email
})

//useSetPassword:
export const useSetPassword = (password: string) => useMutationHandler({
    query: `
        mutation updatePassword (
            $request: UpdatePasswordRequest!
        ) {
            updatePassword(
                request: $request
            )
        }
    `,
    variables: {
        request: {
            currentPassword: null,
            password,
            rePassword: password
        }
    },
    onSuccess: json => json.data.updatePassword === ''
})

//useInitGoogleSync:
export const useInitGoogleSync = () => useMutationHandler<string>({
    query: `
        mutation initGoogleSync {
            initGoogleSync {
                code
            }
        }
    `,
    onSuccess: json => json.data.initGoogleSync.code
})
