import { Button, Typography, theme } from 'antd'
import { env, useTranslate } from 'utils'
import { HorizontalLine, HorizontalContainer, VerticalContainer } from 'components/styled'
import { GoogleOutlined, AppleOutlined, FacebookOutlined, TwitterOutlined } from '@ant-design/icons'
import { LoginStrategy } from 'types'
const  { Text } = Typography

type Props = {
    availableList: string[]
    hideOr: boolean
}

export const LoginSocialButtons = ({ availableList, hideOr }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { fontSize, fontSizeSM, margin, colorTextDescription, marginXS, fontSizeLG, paddingXS, padding } } = theme.useToken()

    const isSingleColumnLayout = availableList.length !== 4 && availableList.length !== 6
    
    return (
        <VerticalContainer style={{ gap: margin }}>

            {/* or */}
            {!hideOr &&
            <HorizontalContainer style={{ gap: marginXS }}>

                <HorizontalLine/>
                <Text style={{ fontSize: fontSizeSM, color: colorTextDescription, fontFamily: 'inter-medium' }}>{__`or_login_with`}</Text>
                <HorizontalLine/>

            </HorizontalContainer>}

            {(availableList.includes(LoginStrategy.GOOGLE) || availableList.includes(LoginStrategy.FACEBOOK)) &&
            <HorizontalContainer style={{ width: '100%', gap: margin, flexDirection: isSingleColumnLayout ? 'column' : 'row' }}>

                {/* google */}
                {availableList.includes(LoginStrategy.GOOGLE) &&
                <Button
                    size='large'
                    style={{
                        flex: 1,
                        width: '100%',
                        fontSize,
                        padding: `${paddingXS}px ${padding}px`
                    }}
                    icon={<GoogleOutlined style={{ fontSize: fontSizeLG }}/>}
                    onClick={() => location.href = env().GOOGLE_LOGIN_ENDPOINT}
                >
                    Google
                </Button>}

                {/* facebook */}
                {availableList.includes(LoginStrategy.FACEBOOK) &&
                <Button
                    size='large'
                    style={{
                        width: '100%',
                        flex: 1,
                        fontSize,
                        padding: `${paddingXS}px ${padding}px`
                    }}
                    icon={<FacebookOutlined style={{ fontSize: fontSizeLG }}/>}
                >
                    Facebook
                </Button>}

            </HorizontalContainer>}

            {(availableList.includes(LoginStrategy.APPLE) || availableList.includes(LoginStrategy.TWEETER)) &&
            <HorizontalContainer style={{ width: '100%', gap: margin, flexDirection: isSingleColumnLayout ? 'column' : 'row' }}>

                {/* apple */}
                {availableList.includes(LoginStrategy.APPLE) &&
                <Button
                    size='large'
                    style={{
                        flex: 1,
                        width: '100%',
                        fontSize,
                        padding: `${paddingXS}px ${padding}px`
                    }}
                    icon={<AppleOutlined style={{ fontSize: fontSizeLG }}/>}
                >
                    Apple
                </Button>}

                {/* X */}
                {availableList.includes(LoginStrategy.TWEETER) &&
                <Button
                    size='large'
                    style={{
                        flex: 1,
                        width: '100%',
                        fontSize,
                        padding: `${paddingXS}px ${padding}px`
                    }}
                    icon={<TwitterOutlined style={{ fontSize: fontSizeLG }}/>}
                >
                    X
                </Button>}

            </HorizontalContainer>}

        </VerticalContainer>
    )

}
