// NOTICE: Ant Design Tooltip has 1070 as it's z-index, and it is overridden to 9 in the index.scss file

//layout:
export const LAYOUT_HEADER_Z_INDEX = 1000
export const BOTTOM_MENU_Z_INDEX = 1000

//resource:
export const TEAM_MEMBER_CALENDAR_HEADER_Z_INDEX = 10

//calendar:
export const SLOT_DETAILS_EVENTS_Z_INDEX = 22
export const APPOINTMENT_DETAILS_EVENTS_SHADOW_Z_INDEX = 21
export const SLOT_DETAILS_NAVIGATION_Z_INDEX = 20

export const CALENDAR_PAGE_SCALE_Z_INDEX = 21
export const CALENDAR_PAGE_CALENDAR_NAVIGATION_Z_INDEX = 20
export const CALENDAR_PAGE_CALENDAR_SHADOW_Z_INDEX = 19
export const CALENDAR_PAGE_CELL_HEADER_Z_INDEX = 18
export const CALENDAR_PAGE_CALENDAR_MESSAGE_WRAPPER_Z_INDEX = 17
// index.scss: calendarTimeSlotTooltip: 16
export const CALENDAR_PAGE_CELL_COVER_Z_INDEX = 15
export const CALENDAR_PAGE_CURRENT_BADGE_Z_INDEX = 14
export const CALENDAR_PAGE_CELL_TIMELINE_COLUMN_Z_INDEX = 13
export const CALENDAR_PAGE_CURRENT_LINE_Z_INDEX = 12
export const TIME_SLOT_WRAPPER_Z_INDEX = 11
export const CALENDAR_PAGE_AVAILABLE_SLOT_WRAPPER_Z_INDEX = 10

// booking:
export const BOOKING_SCALE_WRAPPER_Z_INDEX = 19
export const BOOKING_CALENDAR_NAVIGATION_Z_INDEX = 18
export const BOOKING_CALENDAR_HEADER_Z_INDEX = 17
export const BOOKING_CALENDAR_SHADOW_Z_INDEX = 16
export const BOOKING_CALENDAR_MESSAGE_CONTAINER_Z_INDEX = 15
// index.scss: bookingTimeSlotTooltip: 14
export const BOOKING_CALENDAR_TIMELINE_COLUMN_Z_INDEX = 13
export const BOOKING_SERVICE_SHADOW_Z_INDEX = 1

// location & resource select
export const LOCATION_SELECT_SHADOW_Z_INDEX = 1
export const RESOURCE_SELECT_SHADOW_Z_INDEX = 1
