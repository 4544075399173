import { SwitchWrapper } from './location-status.style'
import { FormSection } from 'components/form-section/form-section'
import { theme, Typography } from 'antd'
import { useTranslate } from 'utils'
import { VerticalContainer } from 'components/styled'
import { FormSwitch } from 'components/form-inputs'
import { Field, useField } from 'react-final-form'
const { Text, Paragraph } = Typography

export const LocationStatus = () => {

    const { __ } = useTranslate()
    const { token: { marginMD }} = theme.useToken()
    const { input: { value: status } } = useField('status')

    return (
        <FormSection title={__`status`}>

            <VerticalContainer style={{ gap: marginMD }}>

                {/* status */}
                <VerticalContainer>

                    <SwitchWrapper>

                        <Text>

                            {__`location_is`}
                            &nbsp;
                            <Text style={{ fontWeight: 'bold' }}>
                                {status ? __`enabled` : __`disabled`}
                            </Text>

                        </Text>

                        <Field
                            name="status"
                            type="switch"
                            component={FormSwitch}
                        />

                    </SwitchWrapper>

                    <Paragraph type='secondary' style={{ marginBottom: 0 }}>
                        {status ? __`disable_if_you_want_to_be_temporarily_unavailable` : __`enable_if_you_want_to_be_available`}
                    </Paragraph>

                </VerticalContainer>

                {/* default location */}
                <VerticalContainer>

                    <SwitchWrapper>

                        <Text>
                            {__`set_as_default_location`}
                        </Text>

                        <Field
                            name="isDefault"
                            type="switch"
                            component={FormSwitch}
                        />

                    </SwitchWrapper>

                    <Paragraph type='secondary' style={{ marginBottom: 0 }}>
                        {__`appointments_without_location_will_be_assigned_to_this_location`}
                    </Paragraph>

                </VerticalContainer>

            </VerticalContainer>

        </FormSection>
    )

}
