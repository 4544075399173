import { EventsButtonWrapper, EventsShadow, FlexSpacer, MainContainer, MainWrapper } from './appointment-details.style'
import { AppointmentDetailsActions } from '../appointment-details-actions/appointment-details-actions'
import { AppointmentDetailsEvents } from '../appointment-details-events/appointment-details-events'
import { AppointmentDetailNavigation } from '../appointment-details-navigation/appointment-details-navigation'
import { AppointmentDetailsHeader } from '../appointment-details-header/appointment-details-header'
import { AppointmentDetailsOrder } from '../appointment-details-order/appointment-details-order'
import { AppointmentDetailLineItemProperties } from '../appointment-details-line-item-properties/appointment-details-line-item-properties'
import { AppointmentDetailsCustomer } from '../appointment-details-customer/appointment-details-customer'
import { useAppointment, useConfig } from 'api'
import { useEffect, useState } from 'react'
import { useTranslate, useWindowDimensions } from 'utils'
import { UpOutlined } from '@ant-design/icons'
import { Button, theme } from 'antd'
import { ColumnContainer, Loading, PageError } from 'components/styled'
import { getDateTimeRange } from 'components/calendar/slot-details/slot-details'
import { Feature } from 'types'
import { AppointmentDetailsResources } from '../appointment-details-resources/appointment-details-resources'
import { AppointmentDetailsInfoExtensionLoader } from '../appointment-details-info-extension-loader'

type Props = {
    appointmentId: string
    isOpen: boolean
    showResource?: boolean
    onClose: () => void
}

export const AppointmentDetails = ({
    appointmentId,
    showResource = true,
    isOpen,
    onClose
}: Props) => {

    //hooks:
    const { token: {
        fontSize,
        fontSizeSM,
        paddingXXS,
        paddingXS,
        paddingSM,
        marginXXS,
        margin,
        marginMD,
        marginLG
    }} = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config } = useConfig()

    //state:
    const [ isEventsOpen, setIsEventsOpen ] = useState(false)

    //appointment:
    const { data: appointment, isLoading: isAppointmentLoading } = useAppointment(appointmentId)

    //after open:
    useEffect(() => !isOpen ? setIsEventsOpen(false) : undefined, [isOpen])

    return config ? (

        isAppointmentLoading ? <Loading/> : (

            !appointment ? <PageError/> : (

                <MainWrapper style={{ paddingBottom: SM ? 0 : `${paddingXXS}px` }}>

                    <MainContainer>

                        <div style={{ padding: `0 ${SM ? paddingXS : 0}px` }}>

                            {/* navigation */}
                            <AppointmentDetailNavigation onClose={onClose}/>

                            {/* header */}
                            <ColumnContainer marginTop={marginXXS}>
                                <AppointmentDetailsHeader
                                    title={appointment.service?.title ?? __`deleted_service`}
                                    description={getDateTimeRange(appointment.datetime, appointment.duration, config)}
                                    tag={__('duration_min', { duration: appointment.duration })}
                                />
                            </ColumnContainer>

                            {/* customer */}
                            <ColumnContainer marginTop={marginMD}>
                                <AppointmentDetailsCustomer customer={appointment.customer} isReserved={false}/>
                            </ColumnContainer>

                            {/* order */}
                            <ColumnContainer marginTop={marginLG}>
                                <AppointmentDetailsOrder
                                    name={appointment.orderName}
                                    id={appointment.orderId}
                                    price={appointment.price}
                                    currency={appointment.currency}
                                    status={appointment.status}
                                />
                            </ColumnContainer>
                            
                            {/* line item properties */}
                            {appointment.lineItemProperties.length > 0 && (
                                <ColumnContainer marginTop={margin}>
                                    <AppointmentDetailLineItemProperties customOptions={appointment.lineItemProperties}/>
                                </ColumnContainer>
                            )}

                        </div>

                        {/* extension */}
                        {config.features.includes(Feature.APPS) && (
                            <AppointmentDetailsInfoExtensionLoader appointment={appointment}/>
                        )}
                        
                        {/* actions */}
                        <ColumnContainer marginTop={marginLG}>
                            <AppointmentDetailsActions appointment={appointment}/>
                        </ColumnContainer>

                        <div style={{ padding: `0 ${SM ? paddingXS : 0}px` }}>

                            {/* resource */}
                            {showResource && (
                                <ColumnContainer marginTop={margin}>
                                    <AppointmentDetailsResources resources={appointment.resources}/>
                                </ColumnContainer>
                            )}
                            
                        </div>
                                
                        {/* spacer to force the events button to move to the bottom if there is space */}
                        <FlexSpacer/>

                        {/* events button */}
                        <EventsButtonWrapper>
                            <Button
                                type='text'
                                size='large'
                                style={{
                                    width: '100%',
                                    height: 'fit-content',
                                    paddingTop: paddingSM,
                                    paddingBottom: paddingSM,
                                    fontSize,
                                    borderRadius: 0
                                }}
                                onClick={() => setIsEventsOpen(!isEventsOpen)}
                            >
                                {__`timeline`}
                                <UpOutlined style={{ fontSize: fontSizeSM }}/>
                            </Button>
                        </EventsButtonWrapper>

                    </MainContainer>

                    {/* events */}
                    <EventsShadow
                        isOpen={isEventsOpen}
                        onClick={() => setIsEventsOpen(!isEventsOpen)}
                    />

                    <AppointmentDetailsEvents
                        events={appointment.events}
                        appointmentCreatedAt={appointment.createdAt}
                        isOpen={isEventsOpen}
                        onClose={() => setIsEventsOpen(!isEventsOpen)}
                    />

                </MainWrapper>

            )
        )
    ) : <></>
}
