import { theme } from 'antd'
import { useWindowDimensions } from 'utils'
import { ResourceSelect } from '../resource-select/resource-select'
import { Availability } from '../../../types'

type Props = {
    onSelect: (availabilities: Availability[]) => void
}

export const ResourceCopy = ({ onSelect }: Props) => {

    //hooks:
    const { token: { margin, marginLG, paddingXXS, paddingSM } } = theme.useToken()
    const { SM } = useWindowDimensions()

    return (
        <ResourceSelect
            selectedId={null}
            onSelect={resource => onSelect(resource?.availabilities ?? [])}
            wrapperStyle={{
                paddingTop: paddingXXS,
                paddingBottom: paddingSM,
                margin: `0 ${SM ? -marginLG : -margin}px`,
                borderTop: 'none',
                borderRight: 'none',
                borderBottom: 'none',
                borderLeft: 'none',
                boxShadow: 'none'
            }}
        />
    )

}
