import { Button, Modal, Typography, message, theme } from 'antd'
import { LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { useTranslate } from 'utils'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
import { useEffect, useState } from 'react'
import { useSendPasswordReset } from 'api'
const  { Text, Paragraph } = Typography

type Props = {
    email: string
    onBackToLogin: () => void
}

export const LoginForgot = ({ email, onBackToLogin }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSize, colorPrimary, colorFillTertiary, fontSizeSM, colorTextDescription } } = theme.useToken()

    //state:
    const [ isButtonHovered, setIsButtonHovered] = useState(false)

    //mutation:
    const { mutate, isLoading, data } = useSendPasswordReset(email)

    //effect:
    useEffect(() => {
        if(data){
            switch(data.result){
                case 'success':
                    Modal.info({
                        title: __`password_reset_link_sent_to_your_email`,
                        content: __`click_on_the_link_in_the_email_to_navigate_to_the_password_reset_page`,
                        okText: __`got_it`,
                        onOk: onBackToLogin
                    })
                    break
                case 'existingOTP':
                    message.info(__`the_link_sent_to_you_less_than_5_min_ago`)
                    onBackToLogin()
                    break
                case 'wrongStrategy':
                case 'userNotActivated':
                case 'userNotFound':
                    message.error(__`oops_something_went_wrong`)
                    onBackToLogin()
                    break
            }
        }
    }, [data])
    
    return (
        <VerticalContainer>

            {/* button */}
            <Button
                size='large'
                style={{ borderColor: colorPrimary, backgroundColor: isButtonHovered ? colorFillTertiary : 'transparent' }}
                onMouseEnter={() => setIsButtonHovered(true)}
                onMouseLeave={() => setIsButtonHovered(false)}
                onClick={() => !isLoading && mutate()}
            >

                <HorizontalContainer style={{ justifyContent: 'space-between' }}>

                    <RightOutlined style={{ visibility: 'hidden' }}/>
                    <Text style={{ color: colorPrimary, fontFamily: 'inter-medium' }}>{__`send_password_reset`}</Text>
                    {isLoading ?
                        <LoadingOutlined style={{ fontSize, color: colorPrimary }}/>
                        :
                        <RightOutlined style={{ fontSize, color: colorPrimary }}/>
                    }

                </HorizontalContainer>

            </Button>

            {/* otp description */}
            <Paragraph style={{ fontSize: fontSizeSM, color: colorTextDescription }}>
                {__`a_link_to_will_be_sent_to_your_email_by_clicking_on_that_you_can_reset_your_password_and_log_in_again`}
            </Paragraph>
            
        </VerticalContainer>
    )

}
