import { EventChildrenWrapper, EventTitleContainer, MainWrapper, TimelineContainer } from './appointment-details-events.style'
import { Button, Timeline, Typography, theme } from 'antd'
import { AppointmentEvent, AppointmentEventType, Config } from 'types'
import { DownOutlined } from '@ant-design/icons'
import { useTranslate } from 'utils'
import { ReactNode } from 'react'
import { useConfig } from 'api'
const { Text } = Typography

type Props = {
    events: AppointmentEvent[]
    appointmentCreatedAt: string
    isOpen: boolean
    onClose: () => void
}

export const AppointmentDetailsEvents = ({ events, appointmentCreatedAt, isOpen, onClose }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: {
        colorSuccess,
        colorWarning,
        colorInfo,
        colorTextTertiary,
        fontSize,
        fontSizeSM,
        paddingSM
    }} = theme.useToken()

    //config:
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //getEventColor:
    const getEventColor = (eventType: AppointmentEventType) => {
        switch(eventType){
            case 'CREATED':
                return 'purple'
            case 'CUSTOMER_CHECKIN':
                return colorSuccess
            case 'NO_SHOW':
                return colorWarning
            case 'RESCHEDULED':
                return colorInfo
            default:
                return colorTextTertiary
        }
    }

    //getFormattedDateTime:
    const getFormattedDateTime = (config: Config, dateTime: string) => {
        const date = new Date(dateTime)
        return date.toLocaleString([config.locale, 'en-US'], {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    //getTimelineItemJSX:
    const getTimelineItemJSX = (config: Config, type: string, initiatedBy: string, dateTime: string) =>
        <EventChildrenWrapper>
            <EventTitleContainer>
                <Text>{type}</Text>
                <Text style={{ fontSize: fontSizeSM, color: colorTextTertiary, whiteSpace: 'nowrap' }}>{initiatedBy}</Text>
            </EventTitleContainer>
            <Text style={{ fontSize: fontSizeSM, color: colorTextTertiary }}>{getFormattedDateTime(config, dateTime)}</Text>
        </EventChildrenWrapper>

    //getTimelineItems:
    const getTimelineItems = (config: Config, events: AppointmentEvent[], appointmentCreatedAt: string) => {
        
        const items:{ color: string, children: ReactNode }[] = []

        //events:
        for(let i = events.length - 1; i >= 0 ; i--){
            items.push({
                color: getEventColor(events[i].type),
                children: getTimelineItemJSX(config, __(events[i].type.toLowerCase()), __(events[i].initiatedBy.toLowerCase()), events[i].dateTime)
            })
        }
        
        //created event:
        items.push({
            color: getEventColor('CREATED'),
            children: getTimelineItemJSX(config, __`created`, '', appointmentCreatedAt)
        })

        return items
    }

    return (isConfigLoading || !config) ? null : (
        <MainWrapper
            style={{ height: isOpen ? '90%' : '0' }}
            onClick={e => e.stopPropagation()}
        >
                
            {/* close button */}
            <Button
                type='text'
                size='large'
                style={{
                    width: '100%',
                    height: 'fit-content',
                    paddingTop: paddingSM,
                    paddingBottom: paddingSM,
                    fontSize,
                    borderRadius: 0
                }}
                onClick={onClose}
            >
                {__`timeline`}
                <DownOutlined style={{ fontSize: fontSizeSM }}/>
            </Button>

            {/* timeline */}
            <TimelineContainer>
                <Timeline items={getTimelineItems(config, events, appointmentCreatedAt)}/>
            </TimelineContainer>

        </MainWrapper>
    )

}
