import { PageHeader } from 'old/components/page-header/page-header'
import { ServiceItem } from 'old/components/service/service-item/service-item'
import { useTranslate, useWindowDimensions }  from 'old/utils'
import { useServices } from 'old/api'
import { VerticalPaginatedList } from 'old/components/vertical-paginated-list/vertical-paginated-list'
import { useState } from 'react'
import { FormSection } from 'old/components/form-section/form-section'
import { theme } from 'antd'
import { PageContentWrapper, VerticalContainer } from 'old/components/styled'

export const ServiceList = () => {

    //hooks:
    const { token: { padding, paddingMD, marginXXS, margin } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()

    //state:
    const [ search, setSearch ] = useState(null as string | null)
    
    //appointments:
    const {
        data: services,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useServices(search, 15)

    return (
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`services`}
                    description={__`manage_all_your_services_or_create_a_new_one`}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>
                
                    <FormSection
                        title={SM ? __`services` : undefined }
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        searchInput={{
                            placeholder: __`search_services`,
                            onSearch: setSearch
                        }}
                    >

                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={services === null}
                            isError={services === false}
                            notFound={(services && services.length === 0) ?? false}
                            pageNum={pageNum}
                            totalPage={totalPage}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            
                            {services && services.map(service => <ServiceItem key={service.id} service={service}/>)}

                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}
