import { Select, Typography, theme } from 'antd'
import { HorizontalContainer, RWContainer, RWElement, VerticalContainer } from 'components/styled'
import { Field, useField } from 'react-final-form'
import { getIsoStringYearMonthDay, useTranslate } from 'utils'
import { FormSection } from '../../form-section/form-section'
import { FormDatePicker, FormInputNumber } from '../../form-inputs'
const  { Text } = Typography

export const ResourceAvailabilitiesRange = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingLG, marginXS, margin } } = theme.useToken()
    const { input: { value: availabilitiesRange, onChange: updateRange } } = useField('availabilitiesRange')

    const updateAvailableFrom = (type: string) => {
        const value = (
            (
                type === 'NOW' &&
                availabilitiesRange.availableFrom.value !== 0
            ) ? 0 :
            (
                type === 'DAYS_INTO_THE_FUTURE' &&
                (
                    typeof availabilitiesRange.availableFrom.value !== 'number' || 
                    availabilitiesRange.availableFrom.value === 0
                )
            ) ? 10 : (
                type === 'EXACT_DATE' &&
                typeof availabilitiesRange.availableFrom.value === 'number'

            ) ? getIsoStringYearMonthDay(new Date()) : null
        )
        if(value !== null){
            updateRange({
                ...availabilitiesRange,
                availableFrom: {
                    type,
                    value
                }
            })
        }
    }

    const updateAvailableTo = (type: string) => {
        const value = (
            (
                type === 'INDEFINITELY' &&
                availabilitiesRange.availableTo.value !== 0
            ) ? 0 :
            (
                type === 'DAYS_INTO_THE_FUTURE' &&
                (
                    typeof availabilitiesRange.availableTo.value !== 'number' || 
                    availabilitiesRange.availableTo.value === 0
                )
            ) ? 10 : (
                type === 'EXACT_DATE' &&
                typeof availabilitiesRange.availableTo.value === 'number'
            ) ? getIsoStringYearMonthDay(new Date()) : null
        )
        if(value !== null){
            updateRange({
                ...availabilitiesRange,
                availableTo: {
                    type,
                    value
                }
            })
        }
    }

    return (
        <FormSection title={__`availabilities_range`} style={{ paddingBottom: paddingLG }}>

            <RWContainer style={{ maxWidth: 750, gap: margin }}>

                {/* from */}
                <RWElement breakPoint={350}>

                    <VerticalContainer style={{ gap: marginXS }}>

                        <Text>{__`from`}:</Text>

                        <HorizontalContainer style={{ gap: marginXS }}>

                            <div style={{ width: '60%' }}>
                                <Select
                                    value={availabilitiesRange.availableFrom.type}
                                    options={[
                                        { value: 'NOW', label: __`now` },
                                        { value: 'EXACT_DATE', label: __`exact_date` },
                                        { value: 'DAYS_INTO_THE_FUTURE', label: __`days_into_the_future` },
                                    ]}
                                    onChange={updateAvailableFrom}
                                    style={{ width: '100%' }}
                                />
                            </div>

                            <div style={{ width: '40%' }}>
                                {availabilitiesRange.availableFrom.type === 'EXACT_DATE' ? (
                                    <Field
                                        name="availabilitiesRange.availableFrom.value"
                                        placeholder={__`select_a_date`}
                                        component={FormDatePicker}
                                        parse={(value: any) => value ? value.format('YYYY-MM-DD') : null}
                                    />
                                ) : (
                                    <Field
                                        name='availabilitiesRange.availableFrom.value'
                                        min={availabilitiesRange.availableFrom.type === 'NOW' ? 0 : 1}
                                        max={9999}
                                        placeholder={__`days`}
                                        disabled={availabilitiesRange.availableFrom.type === 'NOW'}
                                        component={FormInputNumber}
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </div>

                        </HorizontalContainer>

                    </VerticalContainer>

                </RWElement>
                
                {/* to */}
                <RWElement breakPoint={350}>

                    <VerticalContainer style={{ gap: marginXS }}>

                        <Text>{__`to`}:</Text>

                        <HorizontalContainer>

                            <div style={{ width: '60%' }}>
                                <Select
                                    value={availabilitiesRange.availableTo.type}
                                    options={[
                                        { value: 'INDEFINITELY'        , label: __`indefinitely_into_the_future` },
                                        { value: 'EXACT_DATE'          , label: __`exact_date`                   },
                                        { value: 'DAYS_INTO_THE_FUTURE', label: __`days_into_the_future`         },
                                    ]}
                                    onChange={updateAvailableTo}
                                    style={{ width: '100%' }}
                                />
                            </div>

                            <div style={{ width: '40%' }}>
                                {availabilitiesRange.availableTo.type === 'EXACT_DATE' ? (
                                    <Field
                                        name="availabilitiesRange.availableTo.value"
                                        placeholder={__`select_a_date`}
                                        component={FormDatePicker}
                                        parse={(value: any) => value ? value.format('YYYY-MM-DD') : null}
                                    />
                                ) : (
                                    <Field
                                        name='availabilitiesRange.availableTo.value'
                                        min={availabilitiesRange.availableTo.type === 'INDEFINITELY' ? 0 : 1}
                                        max={9999}
                                        placeholder={__`days`}
                                        disabled={availabilitiesRange.availableTo.type === 'INDEFINITELY'}
                                        component={FormInputNumber}
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </div>

                        </HorizontalContainer>

                    </VerticalContainer>

                </RWElement>

            </RWContainer>

        </FormSection>
    )

}
