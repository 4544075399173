import { useCurrentShop } from 'api'
import { APPOINTMENTS_CACHE_STALE_TIME, APPOINTMENT_CACHE_STALE_TIME, CACHE_REFETCH_INTERVAL, RESERVATION_CACHE_STALE_TIME } from 'app.constants'
import { useMutationHandler, useQueryHandler, getShopId, usePaginationHandler, getLocationId } from 'utils'
import { AppointmentFilter } from 'components/appointment/appointment-navigation/appointment-navigation'
import { Appointment, Reservation } from 'types'

const appointmentQuery = `
    id
    orderId
    orderName
    datetime
    createdAt
    
    customer{
        id
        name
        email
        image{
            id
            path
        }
        phone
        billingPhone
    }
    customerManagementLink

    price
    currency
    status

    service{
        id
        title
    }

    externalVariantId
    variantTitle
    duration
    quantity

    resources{
        id
        type{
            id
            name
        }
        name
        description
        image{
            id
            path
        }
    }

    lineItemProperties{
        name
        value
    }

    events{
        id
        type
        dateTime
        initiatedBy
        content
    }
`

//useAppointments:
export const useAppointments = (
    resourceId: string | null,
    ids: string[] | null,
    search: string | null,
    filter: AppointmentFilter,
    start: string | null,
    end: string | null,
    limit: number,
    enabled?: boolean,
    keepPreviousData?: boolean
) => {
    
    const shopId = getShopId()
    const locationId = getLocationId()

    return usePaginationHandler<Appointment[]>({
        query: `
            query getAppointments(
                $shop: String!,
                $locationId: String,
                $resources: [String!],
                $ids: [String!],
                $filterType: AppointmentFilterType,
                $start: Date,
                $end: Date,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String
            ){
                getAppointments(
                    shop: $shop,
                    locationId: $locationId,
                    resources: $resources,
                    ids: $ids
                    filterType: $filterType,
                    start: $start,
                    end: $end,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                ){
                    data{
                        ${appointmentQuery}
                    }
                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                }
            }
        `,
        variables: {
            shop: shopId,
            locationId,
            resources: resourceId ? [resourceId] : null,
            ids,
            filterType: filter,
            start,
            end,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['appointments', shopId, locationId, resourceId, ids, search, filter, start, end],
        limit,
        enabled,
        staleTime: APPOINTMENTS_CACHE_STALE_TIME,
        refetchInterval: CACHE_REFETCH_INTERVAL,
        keepPreviousData: keepPreviousData !== undefined ? keepPreviousData : true,
        getResponse: json => json.data.getAppointments,
        onSuccess: response => response.data
    })

}

//useAppointment:
export const useAppointment = (id: string, enabled?: boolean) => {
    const shopId = getShopId()
    return useQueryHandler<Appointment>({
        query: `
            query getAppointment(
                $id: String!
            ){
                getAppointment(
                    id: $id
                ){
                    ${appointmentQuery}
                }
            }
        `,
        variables: {
            id
        },
        cacheKey: ['appointment', id, shopId],
        enabled,
        staleTime: APPOINTMENT_CACHE_STALE_TIME,
        refetchInterval: CACHE_REFETCH_INTERVAL,
        onSuccess: response => response.data.getAppointment
    })
}

//useReservation:
export const useReservation = (id: string) => {
    const shopId = getShopId()
    return useQueryHandler<Reservation>({
        query: `
            query getReservation(
                $id: String!
            ){
                getReservation(
                    id: $id
                ){
                    id,
                    resources{
                        id
                        type{
                            id
                            name
                        }
                        name
                        description
                        image{
                            id
                            path
                        }
                    }
                }
            }
        `,
        variables: {
            id
        },
        cacheKey: ['reservation', id, shopId],
        staleTime: RESERVATION_CACHE_STALE_TIME,
        refetchInterval: CACHE_REFETCH_INTERVAL,
        onSuccess: response => response.data.getReservation
    })
}

//order url:
export const useOrderURL = (orderId: string | null) => {
    const { data: shop } = useCurrentShop()
    if(shop && shop.url && orderId){
        const shopName = shop.url.replace('.myshopify.com', '')
        return `https://admin.shopify.com/store/${shopName}/orders/${orderId}`
    }else{
        return null
    }
}

//useGenerateReport:
type generateReportResponse = null | {
    result: 'success'
    payload: string
} | {
    result: 'too_many_requests'
    payload: number
}

export const useGenerateReport = (
    resourceId: string | null,
    filter: AppointmentFilter,
    start: string | null,
    end: string | null
) => {
    const shopId = getShopId()
    return useMutationHandler<generateReportResponse>({
        query: `
            mutation generateReport(
                $shop: String!,
                $resource: String,
                $filterType: AppointmentFilterType!,
                $start: Date,
                $end: Date
            ){
                generateReport(
                    shop: $shop,
                    resource: $resource,
                    filterType: $filterType,
                    start: $start,
                    end: $end
                ){
                    email
                }
            }
        `,
        variables: {
            shop: shopId,
            resource: resourceId,
            filterType: filter,
            start,
            end
        },
        onSuccess: json => {
            return {
                result: 'success',
                payload: json.data.generateReport.email
            }
        },
        onError: json => {
            if(json.errors[0].extensions.errors[0].constraints[0].name === 'too_many_requests'){
                return {
                    result: 'too_many_requests',
                    payload: json.errors[0].extensions.errors[0].constraints[0].metaData.retryAfter
                }
            }else{
                return null
            }
        }
    })
}
