import { AddMembersWrapper, MembersWrapper } from './service-team-members.style'
import { useMemo, useState } from 'react'
import { Typography, theme } from 'antd'
import { Avatar } from 'old/components/avatar/avatar'
import { FormSection } from 'old/components/form-section/form-section'
import { DeleteFilled, UpOutlined, MinusOutlined } from '@ant-design/icons'
import { useTranslate }  from 'old/utils'
import { useField } from 'react-final-form'
import { useConfig, useTeamMembers } from 'old/api'
import { Loading, NotFound, PageError } from 'old/components/styled'
import { TeamMember }  from 'old/types'
const { Text } = Typography

export const ServiceTeamMembers = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: {
        paddingXXS,
        paddingXS,
        padding,
        marginXS,
        fontSizeHeading3,
        fontSizeHeading4,
        colorText,
        colorBgContainer,
        colorFill,
        colorError,
        paddingMD,
        fontSizeSM
    }} = theme.useToken()

    //config:
    const { data: config } = useConfig()

    //team members:
    const { data: allTeamMembers, isLoading } = useTeamMembers(null, 50)

    //get the field:
    const { input: { value , onChange: updateTeamMembers } } = useField('teamMembers')
    const serviceTeamMembers = value as TeamMember[]

    //currentMembers:
    const currentMembers = useMemo(() => {
        if(allTeamMembers && serviceTeamMembers){
            return allTeamMembers.filter(member => serviceTeamMembers.some(mem => mem.id === member.id))
        }else{
            return []
        }
    }, [allTeamMembers, serviceTeamMembers])

    //availableMembers:
    const availableMembers = useMemo(() => {
        if(allTeamMembers && serviceTeamMembers){
            if(serviceTeamMembers.length === 0){
                return allTeamMembers
            }else{
                return allTeamMembers.filter(member => {
                    return !serviceTeamMembers.some(sm => sm.id === member.id)
                })
            }
        }else{
            return []
        }
    }, [allTeamMembers, serviceTeamMembers])
    
    //removeMember:
    const removeMember = (memberId: string) => {
        let tmpVal = [...serviceTeamMembers]
        tmpVal = tmpVal.filter(member => member.id !== memberId)
        updateTeamMembers(tmpVal)
    }

    //state:
    const [ isAddMembersOpen   , setIsAddMembersOpen    ] = useState(false)
    const [ isRemoveMembersOpen, setIsRemoveMembersOpen ] = useState(false)

    //isReachedMaxMembers:
    const isReachedMaxMembers = currentMembers.length >= ((config && config.plan?.maxTeamMembers) ? config.plan.maxTeamMembers : 0)

    //areTeamMembersLeft:
    const areTeamMembersLeft = availableMembers.length > 0
    
    //canAddMember:
    const canAddMember = areTeamMembersLeft && !isReachedMaxMembers

    //render:
    return (!config ? null : (
        <FormSection
            title={__`team_members`}
            topButton={ (isAddMembersOpen || isRemoveMembersOpen) ? {
                    label: __`close`,
                    icon: <UpOutlined style={{ fontSize: fontSizeSM }}/>,
                    style: {
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        gap: marginXS
                    },
                    onClick: () => {
                        setIsAddMembersOpen(false)
                        setIsRemoveMembersOpen(false)
                    }
                } : (currentMembers.length > 0 ? {
                        label: __`remove_team_member`,
                        icon: <MinusOutlined style={{ fontSize: fontSizeSM }}/>,
                        onClick: () => {setIsRemoveMembersOpen(true)}
                    } : undefined
                )
            }>
            
            {(isLoading ? <Loading style={{ paddingBottom: paddingMD }}/> : !allTeamMembers ? <PageError/> :
                <>

                    {/* current members list */}
                    <MembersWrapper isEmpty={currentMembers.length === 0}>

                        {currentMembers.length > 0 ? (
                            
                            <>
                                {currentMembers.map(member =>
                                    <Avatar
                                        key={member.id}
                                        name={member.name}
                                        image={member.image.path}
                                        isVertical={true}
                                        extraIcon={isRemoveMembersOpen ? (<DeleteFilled style={{ color: colorError }} />) : undefined}
                                        style={{
                                            width: '80px',
                                            height: '100%',
                                            paddingLeft: paddingXXS,
                                            paddingRight: paddingXXS
                                        }}
                                        onClick={isRemoveMembersOpen ? () => removeMember(member.id) : undefined}
                                    />
                                )}

                                {!isAddMembersOpen && !isRemoveMembersOpen &&
                                    <Avatar
                                        name={__`add`}
                                        title={isReachedMaxMembers ? __`you_reached_your_plans_max_limit` : !areTeamMembersLeft ? __`no_team_members_left` : undefined}
                                        image={null}
                                        placeholder={<a style={{ fontSize: fontSizeHeading4, color: colorText }}>+</a>}
                                        isVertical={true}
                                        isDisabled={!canAddMember}
                                        style={{ padding: `${paddingXXS}px ${paddingXS}px` }}
                                        onClick={() => setIsAddMembersOpen(true)}
                                    />
                                }
                            </>
                            
                            ) : (
                                <Avatar
                                    name={__`no_team_members`}
                                    image={null}
                                    placeholder={<a style={{ fontSize: fontSizeHeading3, color: colorBgContainer }}>!</a>}
                                    isVertical={true}
                                    backgroundColor={colorFill}
                                />
                            )
                        }

                    </MembersWrapper>

                    {/* add members */}
                    <AddMembersWrapper isVisible={
                        (
                            isAddMembersOpen ||
                            (
                                currentMembers.length === 0 &&
                                availableMembers.length > 0
                            )
                        ) && canAddMember
                    }>

                        <Text style={{ fontFamily: 'inter-medium' }}>{__`add_team_member`}</Text>

                        <MembersWrapper isEmpty={availableMembers.length === 0}>
                            
                            {availableMembers.length > 0 ? (
                                availableMembers.map(member =>
                                    <Avatar
                                        key={member.id}
                                        name={member.name}
                                        image={member.image.path}
                                        isVertical={true}
                                        style={{
                                            width: '80px',
                                            height: '100%',
                                            paddingLeft: paddingXXS,
                                            paddingRight: paddingXXS
                                        }}
                                        onClick={() => updateTeamMembers([ ...serviceTeamMembers, { id: member.id } ])}
                                    />
                                )) : <NotFound message={__`no_team_members`} style={{ paddingBottom: padding }}/>
                            }

                        </MembersWrapper>

                    </AddMembersWrapper>
                </>
            )}

        </FormSection>
    ))

}
