import { urlReg } from './app.type'
import { boolean, lazy, number, object, string } from 'yup'
import { xml } from '@codemirror/lang-xml'
import { html } from '@codemirror/lang-html'
import { json } from '@codemirror/lang-json'
import {
    CheckOutlined,
    CloseOutlined,
    ExclamationOutlined,
    PlusOutlined,
    RetweetOutlined,
    RightOutlined
} from '@ant-design/icons'
import { TranslateFunction } from 'utils'

export type FlowPreset = {
    id: string
    name: string
    description: string
    image: string
    listed: boolean
    data: FlowData
}

export type FlowData = Omit<Flow, 'id'>

export type Flow = {
    id: string
    name: string
    action: FlowAction
    target: string
    trigger: FlowTrigger
    when: {
        amount: number
        unit: FlowInterval
    }
    template: FlowTemplate
    status: boolean
}

export type FlowTemplate = {
    headers: string
    body: string
}

export enum FlowAction {
    EMAIL = 'EMAIL',
    WEBHOOK = 'WEBHOOK'
}

export enum FlowTrigger {
    APPOINTMENT_CREATED = 'APPOINTMENT_CREATED',
    APPOINTMENT_RESCHEDULED = 'APPOINTMENT_RESCHEDULED',
    CUSTOMER_CHECKED_IN = 'CUSTOMER_CHECKED_IN',
    APPOINTMENT_STARTED = 'APPOINTMENT_STARTED',
    APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED',
    NO_SHOW = 'NO_SHOW'
}

export enum FlowInterval {
    IMMEDIATELY = 'IMMEDIATELY',
    MINUTES_BEFORE = 'MINUTES_BEFORE',
    HOURS_BEFORE = 'HOURS_BEFORE',
    DAYS_BEFORE = 'DAYS_BEFORE',
    MINUTES_AFTER = 'MINUTES_AFTER',
    HOURS_AFTER = 'HOURS_AFTER',
    DAYS_AFTER = 'DAYS_AFTER'
}

export const FlowMeta = {
    APPOINTMENT_CREATED: {
        value: FlowTrigger.APPOINTMENT_CREATED,
        actions: [FlowAction.EMAIL, FlowAction.WEBHOOK],
        intervals: [
            FlowInterval.IMMEDIATELY,
            FlowInterval.MINUTES_AFTER,
            FlowInterval.HOURS_AFTER,
            FlowInterval.DAYS_AFTER
        ],
        icon: <PlusOutlined/>
    },
    APPOINTMENT_RESCHEDULED: {
        value: FlowTrigger.APPOINTMENT_RESCHEDULED,
        actions: [FlowAction.EMAIL, FlowAction.WEBHOOK],
        intervals: [
            FlowInterval.IMMEDIATELY,
            FlowInterval.MINUTES_AFTER,
            FlowInterval.HOURS_AFTER,
            FlowInterval.DAYS_AFTER
        ],
        icon: <RetweetOutlined/>
    },
    CUSTOMER_CHECKED_IN: {
        value: FlowTrigger.CUSTOMER_CHECKED_IN,
        actions: [FlowAction.EMAIL, FlowAction.WEBHOOK],
        intervals: [
            FlowInterval.IMMEDIATELY,
            FlowInterval.MINUTES_AFTER,
            FlowInterval.HOURS_AFTER,
            FlowInterval.DAYS_AFTER
        ],
        icon: <CheckOutlined/>
    },
    APPOINTMENT_STARTED: {
        value: FlowTrigger.APPOINTMENT_STARTED,
        actions: [FlowAction.EMAIL, FlowAction.WEBHOOK],
        intervals: [
            FlowInterval.IMMEDIATELY,
            FlowInterval.MINUTES_BEFORE,
            FlowInterval.HOURS_BEFORE,
            FlowInterval.DAYS_BEFORE,
            FlowInterval.MINUTES_AFTER,
            FlowInterval.HOURS_AFTER,
            FlowInterval.DAYS_AFTER
        ],
        icon: <RightOutlined/>
    },
    APPOINTMENT_CANCELLED: {
        value: FlowTrigger.APPOINTMENT_CANCELLED,
        actions: [FlowAction.EMAIL, FlowAction.WEBHOOK],
        intervals: [
            FlowInterval.IMMEDIATELY,
            FlowInterval.MINUTES_AFTER,
            FlowInterval.HOURS_AFTER,
            FlowInterval.DAYS_AFTER
        ],
        icon: <CloseOutlined/>
    },
    NO_SHOW: {
        value: FlowTrigger.NO_SHOW,
        actions: [FlowAction.EMAIL, FlowAction.WEBHOOK],
        intervals: [
            FlowInterval.IMMEDIATELY,
            FlowInterval.MINUTES_AFTER,
            FlowInterval.HOURS_AFTER,
            FlowInterval.DAYS_AFTER
        ],
        icon: <ExclamationOutlined/>
    }

}

//getFlowIcon:
export const getFlowIcon = (trigger: string) => {
    const selectedTrigger = trigger as keyof typeof FlowMeta
    return FlowMeta[selectedTrigger].icon
}

export const flowBodyExtensions = [
    [ 'JSON', json() ],
    [ 'HTML', html() ],
    [ 'XML' , xml()  ]
]

export const FlowFormSchema = (action: FlowAction, bodyExt: string, __ : TranslateFunction) => object({
    
    status: boolean(),
    name: string().required(__`this_field_is_required`),
    trigger: string(),
    action: string(),
    target: string().required(__`this_field_is_required`).when('action', {
        is: FlowAction.EMAIL,
        then: schema => schema.when('target', {
            is: (val: string) => val === '{{CustomerEmail}}' || val === '{{ResourceEmail}}',
            then: schema => schema.oneOf(['{{CustomerEmail}}', '{{ResourceEmail}}']),
            otherwise: schema => schema.email(__`must_be_a_valid_email`)
        }),
        otherwise: schema => schema.when('target', {
            is: (val: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val),
            then: schema => schema.oneOf(['error'], __`you_can_t_use_email_as_a_target_in_a_webhook_action`), // 'error' is just for making it invalid
            otherwise: (schema) => schema.matches(urlReg, __`must_be_a_valid_url`),
        })
    }),
    when: object({
        amount: number().when('unit', {
            is: FlowInterval.IMMEDIATELY,
            then: schema => schema.nullable(),
            otherwise: schema => schema.typeError(__`must_specify_a_number`).integer(__`must_be_an_integer`).min(0),
        }),
        unit: string()
    }),
    template: object({
        headers: lazy(() => (
            action === FlowAction.WEBHOOK ?
                object().json().typeError(__`should_be_a_valid_json`) :
                string().nullable()
        )),
        body: lazy(() => (
            bodyExt === flowBodyExtensions[0][0] ? //json
                object().json().typeError(__`should_be_a_valid_json`) :
                string().nullable()
        ))
    })

})
