import { CalendarSync, TeamMember }  from 'old/types'
import {
    getShopId,
    useMutationHandler,
    usePaginationHandler,
    useQueryHandler
}  from 'old/utils'

const teamMemberListQuery = `
    id
    name
    email
    mobile
    description

    image {
        id
        path
    }

    status
    notificationEmailStatus
    eventDescription
    
    availabilities {

        type
        weekday
        date

        intervals {
            from
            to
        }

    }

    services {
        id
    }
`

const teamMemberQuery = `

    ${teamMemberListQuery}

    calendarSync {
        calendarSyncStatus
        canUseCalendarSync
    }

`

//useTeamMembers:
export const useTeamMembers = (search: string | null, limit: number, enabled?: boolean) => {

    //shop id:
    const shopId = getShopId()

    //query handler:
    return usePaginationHandler<TeamMember[]>({
        query: `
            query getTeamMembers (
                
                $shop: String!,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String

            ) {
                getTeamMembers(

                    shop: $shop,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                    
                ) {

                    data {
                        ${teamMemberListQuery}
                    }

                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                    
                }
            }
        `,
        variables: {
            shop: shopId,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['teamMembers', shopId],
        limit,
        enabled,
        getResponse: json => json.data.getTeamMembers,
        onSuccess: response => response.data
    })

}

//useTeamMember:
export const useTeamMember = (id: string, onNotFound?: (json: any) => void) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useQueryHandler<TeamMember>({
        query: `
            query getTeamMember (
                $shop: String!,
                $id: String!
            ) {
                getTeamMember (
                    shop: $shop,
                    id: $id
                ) {
                    ${teamMemberQuery}
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        cacheKey: ['teamMember', id, shopId],
        onSuccess: json => json.data.getTeamMember,
        onNotFound
    })
}

//create:
export const useCreateTeamMember = (teamMember: TeamMember) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useMutationHandler<string>({
        query: `
            mutation createTeamMember (
                
                $shop: String!,
                $request: CreateTeamMemberRequest!,

            ) {
                createTeamMember (

                    shop: $shop,
                    request: $request
                    
                ) {
                    id
                }
            }
        `,
        variables: {

            shop: shopId,

            request: {

                name: teamMember.name,
                email: teamMember.email,
                mobile: teamMember.mobile,
                description: teamMember.description,
                image: teamMember.image.id,

                status: teamMember.status,
                eventDescription: teamMember.eventDescription,
                notificationEmailStatus: teamMember.notificationEmailStatus,
                
                availabilities: teamMember.availabilities,
                
                services: teamMember.services.map(s => s.id)

            }

        },
        onSuccess: json => json.data.createTeamMember.id
    })

}

//update:
export const useUpdateTeamMember = (teamMember: TeamMember) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useMutationHandler<CalendarSync>({
        query: `
            mutation updateTeamMember (
                
                $shop: String!,
                $id: String!,
                $request: UpdateTeamMemberGraphRequest!,

            ) {
                updateTeamMember (

                    shop: $shop,
                    id: $id,
                    request: $request

                ) {
                    calendarSync {
                        calendarSyncStatus
                        canUseCalendarSync
                    }
                }
            }
        `,
        variables: {

            shop: shopId,
            id: teamMember.id,

            request: {

                name: teamMember.name,
                email: teamMember.email,
                mobile: teamMember.mobile,
                description: teamMember.description,
                image: teamMember.image.id,

                status: teamMember.status,
                eventDescription: teamMember.eventDescription,
                notificationEmailStatus: teamMember.notificationEmailStatus,
                
                availabilities: teamMember.availabilities,
                
                services: teamMember.services.map(s => s.id)

            }

        },
        onSuccess: json => json.data.updateTeamMember.calendarSync
    })
}

//calendar invitation invite:
export const useTeamMemberCalendarInvitation = (id: string) => {

    //shop id:
    const shopId = getShopId()

    return useMutationHandler<boolean>({
        query: `
            mutation initCalendarSyncLink (
                $shop: String!,
                $id: String!
            ) {
                initCalendarSyncLink (
                    shop: $shop,
                    id: $id
                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.initCalendarSyncLink.message === 'Done'
    })

}

//calendar invitation revoke:
export const useTeamMemberCalendarRevoke = (id: string) => {

    //shop id:
    const shopId = getShopId()

    return useMutationHandler<boolean>({
        query: `
            mutation revokeCalendarSyncAuth(
                $shop: String!,
                $id: String!
            ) {
                revokeCalendarSyncAuth (
                    shop: $shop,
                    id: $id
                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.revokeCalendarSyncAuth.message === 'Done'
    })

}

//remove:
export const useRemoveTeamMember = (id: string) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useMutationHandler<boolean>({
        query: `
            mutation deleteTeamMember (

                $shop: String!,
                $id: String!

            ) {
                deleteTeamMember (

                    shop: $shop,
                    id: $id

                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.deleteTeamMember.message === 'Done'
    })
    
}

