/* 
    mostafa.mdzh@email.com | @MostafaMDZH
    Note: This hook runs 100ms after the window resizing finished,
    so if you call a function on widow size change, its gonna be debounced.
*/

import { useEffect, useRef, useState } from 'react'
import { ScreenSize }  from 'old/types'

export const useWindowDimensions = () => {

    const lastChangeTimeoutId = useRef(0)

    //getWindowDimensions:
    const getWindowDimensions = () => {

        const { innerWidth: width, innerHeight: height } = window

        const SM  = width >= ScreenSize.SM
        const MD  = width >= ScreenSize.MD
        const LG  = width >= ScreenSize.LG
        const XL  = width >= ScreenSize.XL
        const XXL = width >= ScreenSize.XXL

        return { width, height, SM, MD, LG, XL, XXL }
        
    }
    
    //state:
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    //listener:
    useEffect(() => {

        //handleResize:
        const handleResize = () => {
            clearTimeout(lastChangeTimeoutId.current)
            const timeoutId = setTimeout(() => setWindowDimensions(getWindowDimensions()), 100)
            lastChangeTimeoutId.current = timeoutId as unknown as number
        }

        //listener:
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)

    }, [])

    return windowDimensions

}
