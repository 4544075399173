import { accessToken, isNil, useAuthentication, useTranslate } from 'old/utils'
import { ReactNode, useEffect, useState } from 'react'
import { Modal, message, theme } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { LogoLoading, PageError, VerticalContainer } from 'old/components/styled'
import { LoginEmail } from 'old/components/login/login-email/login-email'
import { LoginPassword } from 'old/components/login/login-password/login-password'
import { FooterButtonAction } from 'old/components/login/login-footer/login-footer'
import { LoginForgot } from 'old/components/login/login-forgot/login-forgot'
import { LoginSendEmail } from 'old/components/login/login-send-email/login-send-email'
import { LoginSocialButtons } from 'old/components/login/login-social-buttons/login-social-buttons'
import { useAvailableLoginStrategies, useConfig, useLoginWithOTP } from 'old/api'
import { LoginStrategy }  from 'old/types'
import { LoginMagicLink } from 'old/components/login/login-magic-link/login-magic-link'
import { LoginOTP } from 'old/components/login/login-otp/login-otp'
import { LoginUiWrapper } from 'old/components/login/login-ui-wrapper/login-ui-wrapper'
import { useNavigate, useSearchParams } from 'react-router-dom'

type LoginState = {
    strategies: LoginStrategy[]
    email: string | null
    code: string | null
    title: string | null
    description: string | null
    isEmailSendVisible: boolean
    socialStrategies: string[]
    isPasswordVisible: boolean
    isOtpVisible: boolean
    isMagicLinkVisible: boolean
    isForgotVisible: boolean
    isPasswordResetVisible: boolean
    footerHelperText: string | null
    footerButtonAction: FooterButtonAction | null
    footerButtonIcon: ReactNode | null
}

const defaultState: LoginState = {
    strategies: [],
    email: null,
    code: null,
    title: null,
    description: null,
    isEmailSendVisible: false,
    socialStrategies: [],
    isPasswordVisible: false,
    isMagicLinkVisible: false,
    isOtpVisible: false,
    isForgotVisible: false,
    isPasswordResetVisible: false,
    footerHelperText: null,
    footerButtonAction: null,
    footerButtonIcon: null
}

export const Login = () => {

    //translate:
    const { __ } = useTranslate()

    //login:
    const { login } = useAuthentication()

    //config:
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //navigate:
    const navigate = useNavigate()

    //url params:
    const [ searchParams ] = useSearchParams()
    const email = searchParams.get('email') ?? ''
    const code = searchParams.get('code') ?? ''

    //login with otp:
    const {
        mutate: loginWithOTP,
        isLoading: isOtpLoginLoading,
        isError: isLoginWithOTPError,
        data: otpLoginResponse
    } = useLoginWithOTP(email, code)

    //login with otp:
    useEffect(() => {
        if(config){
            if(email !== '' && code !== ''){
                loginWithOTP()
            }
        }
    }, [email, code, config])

    //after login with otp:
    useEffect(() => {
        if(otpLoginResponse){
            switch(otpLoginResponse.result){
                case 'success':
                    login(otpLoginResponse.payload.refreshToken, otpLoginResponse.payload.accessToken)
                    break
                case 'invalidOTP':
                    Modal.info({
                    title: __`something_went_wrong`,
                        content: __`cannot_login_maybe_the_url_is_expired_please_try_to_send_another_one`,
                        okText: __`got_it`,
                        onOk: () => {}
                    })
                    break
                case 'userNotActivated':
                case 'userNotFound':
                default:
                    message.error(__`oops_something_went_wrong`)
                    break
            }
        }else if(otpLoginResponse === false){
            message.error(__`oops_something_went_wrong`)
        }
    }, [otpLoginResponse])

    //redirect if logged in:
    useEffect(() => {
        if(accessToken() && (email === '' || code === '')){
            navigate('/')
        }
    }, [accessToken(), email, code])

    //render:
    return (isConfigLoading || isOtpLoginLoading) ? <LogoLoading/> :
        (!config || isLoginWithOTPError) ? <PageError/> : <LoginForm onLogin={login}/>
    
}

type LoginFormProps = {
    onLogin: (accessToken: string, refreshToken: string) => void
}

export const LoginForm = ({ onLogin }: LoginFormProps) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { marginXL } } = theme.useToken()

    //available strategies:
    const { data: availableStrategies, isLoading: isAllStrategiesLoading, isError: isAllStrategiesError } = useAvailableLoginStrategies()

    //state:
    const [ state, setState ] = useState(defaultState)

    //effect:
    useEffect(() => updateState(null, availableStrategies), [availableStrategies])

    //updateState:
    const updateState = (email: string | null, strategies?: LoginStrategy[] | null | false) => {

        //return:
        if(!strategies){
            return
        }

        //default values:
        const nextState = { ...defaultState, strategies, email }

        //email send:
        if((strategies.includes(LoginStrategy.PASSWORD) || strategies.includes(LoginStrategy.MAGIC_LINK)) && isNil(nextState.email)){
            nextState.title = `welcome_to_sesami`
            nextState.description = `to_continue_choose_one_of_the_methods_below`
            nextState.isEmailSendVisible = true
            nextState.footerHelperText = `new_user`
            nextState.footerButtonAction = FooterButtonAction.CREATE_AN_ACCOUNT
        }

        //social:
        nextState.socialStrategies = strategies.filter(str =>
            str === LoginStrategy.GOOGLE
        )
        if(nextState.socialStrategies.length === 1 && !isNil(nextState.email)){
            if(nextState.socialStrategies.includes(LoginStrategy.GOOGLE)){
                nextState.title = `continue_with_google`
                nextState.description = `you_logged_in_with_your_google_account_before`
            }
        }

        //password:
        if(strategies.includes(LoginStrategy.PASSWORD) && !isNil(nextState.email)){
            nextState.title = `login_with_password`
            nextState.description = `if_you_forget_your_password_you_can_reset_it_below`
            nextState.isPasswordVisible = true
            nextState.footerButtonAction = FooterButtonAction.BACK_TO_STEP_ONE
            nextState.footerButtonIcon = <ArrowLeftOutlined/>
        }

        //magic link:
        if(strategies.includes(LoginStrategy.MAGIC_LINK) && !isNil(nextState.email)){
            nextState.isMagicLinkVisible = true
            //just magic link:
            if(strategies.length === 1){
                nextState.title = `login_with_magic_link`
                nextState.description = `there_is_only_the_magic_link_is_available_for_you`
            }
            nextState.footerButtonAction = FooterButtonAction.BACK_TO_STEP_ONE
            nextState.footerButtonIcon = <ArrowLeftOutlined/>
        }

        //update:
        setState({ ...nextState })
    }

    //handleFooterButton:
    const handleFooterButton = (textKey: FooterButtonAction) => {
        switch(textKey){
            case FooterButtonAction.CREATE_AN_ACCOUNT:
                message.info(__`sign_up_is_not_available_yet`)
                break
            case FooterButtonAction.BACK_TO_STEP_ONE:
            case FooterButtonAction.BACK_TO_LOGIN:
                updateState(null, availableStrategies)
                break
        }
    }

    //goToForgot:
    const goToForgot = () => {
        const nextState = {...defaultState}
        nextState.email = state.email
        nextState.title = `forgot_password_question`
        nextState.isForgotVisible = true
        nextState.description = `no_worries_well_send_you_reset_instructions`
        nextState.footerButtonAction = FooterButtonAction.BACK_TO_LOGIN
        nextState.footerButtonIcon = <ArrowLeftOutlined/>
        setState(nextState)
    }

    //goToOtp:
    const goToOtp = () => {
        const nextState = {...defaultState}
        nextState.email = state.email
        nextState.title = `login_with_otp`
        nextState.description = `a_one_time_password_and_a_link_sent_to_your_email_address_click_on_the_link_or_enter_the_code_below`
        nextState.isOtpVisible = true
        nextState.footerButtonAction = FooterButtonAction.BACK_TO_STEP_ONE
        nextState.footerButtonIcon = <ArrowLeftOutlined/>
        setState(nextState)
    }
    
    //render:
    return (
        (isAllStrategiesLoading) ? <LogoLoading/> : isAllStrategiesError ? <PageError/> :

        <LoginUiWrapper
            title={__(state.title)}
            description={__(state.description)}
            footerHelperText={__(state.footerHelperText)}
            footerButtonIcon={state.footerButtonIcon}
            footerButtonAction={state.footerButtonAction}
            onFooterSelect={handleFooterButton}
        >

            <VerticalContainer style={{ gap: marginXL }}>

                {/* email send */}
                {state.isEmailSendVisible && <LoginSendEmail onSend={updateState}/>}

                <VerticalContainer>

                    {/* email */}
                    {state.email !== null && <LoginEmail email={state.email}/>}

                    {/* social */}
                    {state.socialStrategies.length > 0 &&
                    <LoginSocialButtons
                        availableList={state.socialStrategies}
                        hideOr={state.socialStrategies.length === 1 && !isNil(state.email)}
                    />}

                    {/* password */}
                    {state.isPasswordVisible && state.email !== null &&
                    <LoginPassword
                        email={state.email}
                        onLogin={onLogin}
                        onForgotSelect={goToForgot}
                        onBackToLogin={() => updateState(null, availableStrategies)}
                    />}

                    {/* otp */}
                    {state.isOtpVisible && state.email !== null &&
                    <LoginOTP
                        email={state.email}
                        onLogin={onLogin}
                        onBackToLogin={() => updateState(null, availableStrategies)}
                    />}

                    {/* forgot */}
                    {state.isForgotVisible && state.email !== null &&
                    <LoginForgot
                        email={state.email}
                        onBackToLogin={() => updateState(null, availableStrategies)}
                    />}

                </VerticalContainer>

                {/* magic link */}
                {state.isMagicLinkVisible && state.email !== null &&
                <LoginMagicLink
                    email={state.email}
                    hideOr={state.strategies.length === 1}
                    onSend={goToOtp}
                    onBackToLogin={() => updateState(null, availableStrategies)}
                />}

            </VerticalContainer>

        </LoginUiWrapper>
    )

}
