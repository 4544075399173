import { InputRef, theme } from 'antd'
import { updateShopId, useTranslate, useWindowDimensions } from 'utils'
import { HorizontalLine, VerticalContainer } from 'components/styled'
import { useNavigate } from 'react-router-dom'
import { CheckOutlined } from '@ant-design/icons'
import { Avatar } from 'components/avatar/avatar'
import { useCurrentShop, useShops } from 'api'
import { Ref, useState } from 'react'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { Shop } from 'types'
import { FormSection } from 'components/form-section/form-section'

type Props = {
    listLength: number
    inputRef?: Ref<InputRef>
}

export const ShopSelect = ({ listLength, inputRef }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: {
        paddingXXS,
        paddingXS,
        paddingSM,
        paddingMD,
        paddingLG,
        marginXS,
        colorBorder,
        colorInfoBg,
        borderRadius,
        boxShadowTertiary
    }} = theme.useToken()
    const { SM, MD, LG } = useWindowDimensions()
    const navigate = useNavigate()

    //state:
    const [ search, setSearch ] = useState<string | null>(null)

    //api:
    const { data: selectedShop } = useCurrentShop()
    const {
        data: shops,
        pageNum,
        totalPages,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useShops(search, listLength)

    const updateSelectedShop = (shopId: string) => {
        updateShopId(shopId)
        const destination = location.pathname
        if(destination.includes('book')){ //exception: booking page should be reloaded
            window.location.pathname = destination
        }if(destination.includes('user/')){ //exception: user form should be navigated to the list
            navigate('/user')
        }else{
            navigate(destination)
        }
    }
    
    return (
        <FormSection
            title={__`shops`}
            searchInput={{
                placeholder: __`search_shops`,
                inputRef: inputRef,
                onSearch: setSearch
            }}
            style={{
                width: '100%',
                padding: LG ? `${paddingMD}px 0 ${paddingSM}px 0` : 0,
                border: LG ? `solid 1px ${colorBorder}` : 'none',
                boxShadow: LG ? boxShadowTertiary : 'none',
            }}
            childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
        >

            {selectedShop && (

                <VerticalContainer style={{ gap: marginXS }}>

                    {/* selected shop */}
                    {<Avatar
                        key={selectedShop.id}
                        size='tiny'
                        name={selectedShop.name}
                        image={selectedShop.image.path}
                        extraIcon={<CheckOutlined style={{ paddingRight: paddingXS }}/>}
                        style={{
                            width: '100%',
                            padding: MD ? `${paddingXXS}px ${paddingXS}px` : `${paddingXXS}px ${paddingLG}px`,
                            borderRadius: MD ? borderRadius : 0,
                            backgroundColor: colorInfoBg
                        }}
                    />}

                    <HorizontalLine/>

                    <VerticalPaginatedList
                        isFirstLoading={isLoading}
                        isForbidden={shops === null}
                        isError={shops === false}
                        notFound={(shops && shops.length === 0) ?? false}
                        totalPages={totalPages}
                        pageNum={pageNum}
                        isPreviousData={isPreviousData}
                        isNextDisabled={isNextDisabled}
                        isPreviousDisabled={isPreviousDisabled}
                        fetchNext={fetchNext}
                        fetchPrevious={fetchPrevious}
                    >

                            {/* list */}
                            {shops && shops?.length && (
                                shops.map((shop: Shop) =>
                                    <Avatar
                                        key={shop.id}
                                        size='tiny'
                                        name={shop.name}
                                        image={shop.image.path}
                                        style={{
                                            width: '100%',
                                            padding: MD ? `${paddingXXS}px ${paddingXS}px` : `${paddingXXS}px ${paddingLG}px`,
                                            borderRadius: MD ? borderRadius : 0,
                                            
                                        }}
                                        onClick={() => updateSelectedShop(shop.id)}
                                    />
                                )
                            )}

                    </VerticalPaginatedList>

                </VerticalContainer>)

            }

        </FormSection>
    )

}

// const refreshThePage = () => {



// }
