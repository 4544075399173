import { DateWrapper, MainWrapper, MenuWrapper, PickerWrapper, QueryWrapper, SearchWrapper, SelectWrapper, StartEndWrapper } from './appointment-navigation.style'
import { useEffect, useRef, useState } from 'react'
import { DatePicker, Radio, Select } from 'antd'
import { isNil, useTranslate, useWindowDimensions } from 'utils'
import dayjs, { Dayjs } from 'dayjs'
import { RangePickerProps } from 'antd/es/date-picker'
import { Search } from 'components/search/search'
const { RangePicker } = DatePicker

export enum AppointmentFilter{
    ALL = 'ALL',
    UPCOMING = 'UPCOMING',
    PAST = 'PAST',
    DATE = 'DATE',
    RANGE = 'RANGE'
}

export type AppointmentRange = {
    start: string | null,
    end: string | null
}
export const emptyAppointmentRange: AppointmentRange = { start: null, end: null }

type Props = {
    filter: AppointmentFilter
    range: AppointmentRange
    onQueryChange: (filter: AppointmentFilter) => void
    onRangeChange: (range: AppointmentRange) => void
    onSearchChange: (search: string) => void
}

export const AppointmentNavigation = ({ filter, range, onQueryChange, onRangeChange, onSearchChange }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //window dimensions:
    const { LG, XXL } = useWindowDimensions()

    //ref:
    const startDate = useRef<any>(null)
    const endDate = useRef<any>(null)

    //state:
    const [ tmpRange, setTmpRange ] = useState(range)
    const [ lastAppointmentFilter, setLastAppointmentFilter] = useState(AppointmentFilter.UPCOMING)
    const [ isRangeVisible, setIsRangeVisible ] = useState(true)
    const [ isStartPickerOpen , setIsStartPickerOpen] = useState(false)
    const [ isEndPickerOpen , setIsEndPickerOpen] = useState(false)

    //previous week:
    const firstDayOfPreviousLastWeek = dayjs().add(-dayjs().day() - 6, 'd')
    const lastDayOfThePreviousWeek = dayjs().add(-dayjs().day(), 'd')

    //previous month:
    const lastDayOfThePreviousMonth = dayjs().add(-dayjs().date(), 'd')
    const numberOfDaysInPreviousMonth = lastDayOfThePreviousMonth.daysInMonth()
    const firstDayOfThePreviousMonth = lastDayOfThePreviousMonth.add(-numberOfDaysInPreviousMonth + 1, 'd')

    //previous 2 month:
    const lastDayOfThe2PreviousMonth = firstDayOfThePreviousMonth.add(-1, 'd')
    const numberOfDaysIn2PreviousMonth = lastDayOfThe2PreviousMonth.daysInMonth()
    const firstDayOfThe2PreviousMonth = lastDayOfThe2PreviousMonth.add(-numberOfDaysIn2PreviousMonth + 1, 'd')

    //previous 3 month:
    const lastDayOfThe3PreviousMonth = firstDayOfThe2PreviousMonth.add(-1, 'd')
    const numberOfDaysIn3PreviousMonth = lastDayOfThe3PreviousMonth.daysInMonth()
    const firstDayOfThe3PreviousMonth = lastDayOfThe3PreviousMonth.add(-numberOfDaysIn3PreviousMonth + 1, 'd')

    //range:
    const rangePresets: { label: string, value: [Dayjs, Dayjs]}[] = [
        { label: __`last_7_days`, value: [dayjs().add(-6 , 'd'), dayjs()] },
        { label: __`last_30_days`, value: [dayjs().add(-29, 'd'), dayjs()] },
        { label: __`last_90_days`, value: [dayjs().add(-89, 'd'), dayjs()] },
        { label: __`previous_week`, value: [ firstDayOfPreviousLastWeek, lastDayOfThePreviousWeek ] },
        { label: __`previous_month`, value: [ firstDayOfThePreviousMonth, lastDayOfThePreviousMonth ] },
        { label: __`previous_3_months`, value: [ firstDayOfThe3PreviousMonth, lastDayOfThePreviousMonth ] }
    ]

    //disabledStartDate:
    const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
        return current && !isNil(tmpRange.end) && current >= dayjs(tmpRange.end).startOf('day')
    }

    //disabledEndDate:
    const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
        return current && !isNil(tmpRange.start) && current < dayjs(tmpRange.start).endOf('day')
    }

    //handleQueryUpdate:
    const handleQueryUpdate = (filter: AppointmentFilter) => {
        if(filter === AppointmentFilter.RANGE){
            onRangeChange(emptyAppointmentRange)
            setTmpRange(emptyAppointmentRange)
            setIsRangeVisible(true)
            setIsStartPickerOpen(true)
        }else if(filter === AppointmentFilter.DATE){
            onRangeChange(emptyAppointmentRange)
            setTmpRange(emptyAppointmentRange)
            setIsRangeVisible(false)
            setIsStartPickerOpen(true)
        }else{
            onQueryChange(filter)
            setLastAppointmentFilter(filter)
            onRangeChange(emptyAppointmentRange)
            setTmpRange(emptyAppointmentRange)
        }
    }

    //handleRangeUpdate:
    const handleRangeUpdate = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if(dates){
            onQueryChange(AppointmentFilter.RANGE)
            onRangeChange({ start: dateStrings[0], end: dateStrings[1] })
            setTmpRange({ start: dateStrings[0], end: dateStrings[1] })
            setIsStartPickerOpen(false)
        }else{
            onQueryChange(lastAppointmentFilter)
            onRangeChange(emptyAppointmentRange)
            setTmpRange(emptyAppointmentRange)
        }
    }

    //handleRangeStartDateUpdate:
    const handleRangeStartDateUpdate = (date: null | Dayjs, dateStrings: string) => {
        if(date){
            const newRange = { ...tmpRange, start: dateStrings }
            setTmpRange(newRange)
            if(tmpRange.end){
                onQueryChange(AppointmentFilter.RANGE)
                onRangeChange(newRange)
            }else{
                setIsStartPickerOpen(false)
                setIsEndPickerOpen(true)
                endDate.current?.focus()
            }
        }else{
            onQueryChange(lastAppointmentFilter)
            onRangeChange(emptyAppointmentRange)
            setTmpRange(emptyAppointmentRange)
        }
    }

    //handleRangeEndDateUpdate:
    const handleRangeEndDateUpdate = (date: null | Dayjs, dateStrings: string) => {
        if(date){
            const newRange = { ...tmpRange, end: dateStrings }
            setTmpRange(newRange)
            if(tmpRange.start){
                onQueryChange(AppointmentFilter.RANGE)
                onRangeChange(newRange)
            }else{
                setIsEndPickerOpen(false)
                setIsStartPickerOpen(true)
                startDate.current?.focus()
            }
        }else{
            onQueryChange(lastAppointmentFilter)
            onRangeChange(emptyAppointmentRange)
            setTmpRange(emptyAppointmentRange)
        }
    }

    //handleDateUpdate:
    const handleDateUpdate = (date: null | Dayjs, dateStrings: string) => {
        if(date){
            onQueryChange(AppointmentFilter.DATE)
            onRangeChange({ start: dateStrings, end: dateStrings })
            setIsStartPickerOpen(false)
        }else{
            onQueryChange(lastAppointmentFilter)
            onRangeChange(emptyAppointmentRange)
        }
    }

    useEffect(() => {
        if(!isStartPickerOpen && !isEndPickerOpen && range.start === null && range.end === null){
            onQueryChange(lastAppointmentFilter)
        }
    }, [isStartPickerOpen, isEndPickerOpen])

    return (
        <MainWrapper>

            <MenuWrapper>

                <QueryWrapper>

                    {XXL ? (

                        //radio filter:
                        <Radio.Group
                            buttonStyle="solid"
                            value={filter}
                            onChange={e => handleQueryUpdate(e.target.value)}
                        >
                            <Radio.Button value={AppointmentFilter.ALL}>{__`all`}</Radio.Button>
                            <Radio.Button value={AppointmentFilter.UPCOMING}>{__`upcoming`}</Radio.Button>
                            <Radio.Button value={AppointmentFilter.PAST}>{__`past`}</Radio.Button>
                            <Radio.Button value={AppointmentFilter.DATE}>{__`date`}</Radio.Button>
                            <Radio.Button value={AppointmentFilter.RANGE}>{__`range`}</Radio.Button>
                        </Radio.Group>
                    
                    ) : (

                        //select filter:
                        <SelectWrapper>
                            <Select
                                value={filter}
                                style={{ width: '100%' }}
                                options={[
                                    { value: AppointmentFilter.ALL, label: __`all` },
                                    { value: AppointmentFilter.UPCOMING, label: __`upcoming` },
                                    { value: AppointmentFilter.PAST, label: __`past` },
                                    { value: AppointmentFilter.DATE, label: __`date` },
                                    { value: AppointmentFilter.RANGE, label: __`range` }
                                ]}
                                onChange={handleQueryUpdate}
                            />
                        </SelectWrapper>

                    )}

                </QueryWrapper>

                {

                    <PickerWrapper isRangeOpen={isStartPickerOpen}>

                        {isRangeVisible ?

                            //range:
                            (!LG ?
                                
                                <StartEndWrapper>

                                    <DatePicker
                                        inputReadOnly
                                        value={tmpRange.start !== null ? dayjs(tmpRange.start) : null}
                                        placeholder={__`from`}
                                        disabledDate={disabledStartDate}
                                        style={{ width: '100%' }}
                                        open={isStartPickerOpen}
                                        onOpenChange={setIsStartPickerOpen}
                                        onChange={handleRangeStartDateUpdate}
                                        ref={startDate}
                                    />

                                    <DatePicker
                                        inputReadOnly
                                        value={tmpRange.end !== null ? dayjs(tmpRange.end) : null}
                                        placeholder={__`to`}
                                        disabledDate={disabledEndDate}
                                        style={{ width: '100%' }}
                                        open={isEndPickerOpen}
                                        onOpenChange={setIsEndPickerOpen}
                                        onChange={handleRangeEndDateUpdate}
                                        ref={endDate}
                                    />

                                </StartEndWrapper>

                                :

                                <RangePicker
                                    inputReadOnly
                                    value={[
                                        range.start !== null ? dayjs(range.start) : null,
                                        range.end !== null ? dayjs(range.end) : null
                                    ]}
                                    presets={rangePresets}
                                    style={{
                                        width: '100%',
                                        flex: 1
                                    }}
                                    open={isStartPickerOpen}
                                    onOpenChange={setIsStartPickerOpen}
                                    onChange={handleRangeUpdate}
                                />
                            )

                            :

                            //date:
                            <DateWrapper>
                                
                                <DatePicker
                                    inputReadOnly
                                    value={range.start !== null ? dayjs(range.start) : null}
                                    open={isStartPickerOpen}
                                    onOpenChange={setIsStartPickerOpen}
                                    style={{ width: '100%' }}
                                    onChange={handleDateUpdate}
                                />

                            </DateWrapper>

                        }

                    </PickerWrapper>

                }

            </MenuWrapper>

            <SearchWrapper>

                <Search
                    placeholder={__`search_appointments`}
                    onSearch={search => onSearchChange(search ?? '')}
                />

            </SearchWrapper>

        </MainWrapper>
    )

}
