import { BackgroundCover, ButtonWrapper, ContentWrapper, DivWrapper, MainContainer, TitleWrapper } from './calendar-time-slot.style'
import { useEffect, useMemo, useState } from 'react'
import { Tooltip, Typography, theme } from 'antd'
import { RGBtoRGBA, TranslateFunction, addMinutes, dateTimeToNumericHour, getColorById, isNil, useTranslate } from 'utils'
import { useConfig } from 'api'
import { CalendarSlot, CalendarSlotItem, CalendarSlotType } from 'types'
const { Text } = Typography

export type Mode = 'multiLines' | 'singleLine' | 'compact' | 'noText' | 'justLine'

type Props = {
    slot: CalendarSlot
    date: Date
    dayStartHour: number
    scale: number
    onClick: () => void
}

export const CalendarTimeSlot = ({
    slot,
    date,
    dayStartHour,
    scale,
    onClick
}: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSizeSM, paddingXXS, borderRadius } } = theme.useToken()

    //config:
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //state:
    const [ height, setHeight ] = useState(slot.duration*scale)
    const [ mode, setMode ] = useState(findMode(slot.duration*scale))

    //after scale update:
    useEffect(() => {
        const height = slot.duration*scale
        setHeight(height)
        setMode(findMode(height))
    }, [scale])

    //values:
    const slotType = useMemo(() => findSlotType(slot.items), [slot.items])
    const serviceName = useMemo(() => findServiceName(slot, slotType, __), [slot.service])
    const customerName = useMemo(() => findCustomerName(slot, slotType, __), [slot])
    const timeRange = useMemo(() => findTimeRange(slot.dateTime, slot.duration), [slot])
    const colorRGB = useMemo(() => getColorById(slot.service?.id), [slot.service])

    //getSlotContent:
    const getSlotContent = () =>
        <BackgroundCover mode={mode} colorRGB={colorRGB}>

            <MainContainer type={slotType} mode={mode} colorRGB={colorRGB}>

                <ContentWrapper>

                    <TitleWrapper mode={mode}>

                        {/* service name */}
                        <Text
                            style={{
                                color: slotType === CalendarSlotType.EXTERNAL ? RGBtoRGBA(colorRGB, .9) : colorRGB,
                                fontFamily: 'inter-medium',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                        >
                            {slotType !== CalendarSlotType.BUFFER && serviceName}
                        </Text>

                        {/* duration */}
                        {slotType !== CalendarSlotType.BUFFER &&
                            <Text
                                style={{
                                    padding: `${paddingXXS}px`,
                                    backgroundColor: RGBtoRGBA(colorRGB, .08),
                                    borderRadius: `${borderRadius}px`,
                                    fontSize: fontSizeSM,
                                    lineHeight: `${fontSizeSM}px`,
                                    whiteSpace: 'nowrap',
                                    color: RGBtoRGBA(colorRGB, .8)
                                }}
                            >
                                {__('duration_min', { duration: slot.duration })}
                            </Text>
                        }
                        
                    </TitleWrapper>

                    {/* start time and customer */}
                    <Text
                        style={{
                            width: '100%',
                            fontSize: mode === 'compact' ? fontSizeSM*10/12 : fontSizeSM,
                            color: RGBtoRGBA(colorRGB, mode === 'multiLines' ? .7 : 1),
                            textAlign: 'start',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            display: mode === 'noText' || mode === 'justLine' ? 'none' : 'block'
                        }}
                    >
                        {slotType !== CalendarSlotType.BUFFER && !isConfigLoading && config && getInfo(slot, customerName, timeRange, config.locale)}
                    </Text>

                </ContentWrapper>

            </MainContainer>

        </BackgroundCover>
    
    return (
        <Tooltip
            title={getTooltipContent(slotType, timeRange, slot.duration, serviceName, customerName, mode, __)}
            color={colorRGB}
            overlayClassName='calendarTimeSlotTooltip' // styled in index.scss
            trigger={['focus', 'hover']}
            overlayStyle={{ fontSize: fontSizeSM }}
        >
            {
                (slotType === CalendarSlotType.APPOINTMENT || slotType === CalendarSlotType.RESERVATION) ?
                    <ButtonWrapper
                        mode={mode}
                        // isAvailable={isAvailable}
                        height={height}
                        top={calcTop(slot.dateTime, date, dayStartHour, scale)}
                        colorRGB={colorRGB}
                        onClick={onClick}
                    >
                        {getSlotContent()}
                    </ButtonWrapper>
                :
                    <DivWrapper
                        mode={mode}
                        // isAvailable={isAvailable}
                        height={height}
                        top={calcTop(slot.dateTime, date, dayStartHour, scale)}
                        colorRGB={colorRGB}
                    >
                        {getSlotContent()}
                    </DivWrapper>
            }

        </Tooltip>
    )
    
}

//findSlotType:
const findSlotType = (items: CalendarSlotItem[]): CalendarSlotType => {
    if(items.length){
        if(items[0].type === CalendarSlotType.BUFFER || items[0].type === CalendarSlotType.EXTERNAL){
            return items[0].type
        }else{
            return items.some(i => i.type === CalendarSlotType.APPOINTMENT) ? CalendarSlotType.APPOINTMENT : CalendarSlotType.RESERVATION
        }
    }else{ // never should happen
        return CalendarSlotType.APPOINTMENT
    }
}

//findServiceName:
const findServiceName = (slot: CalendarSlot, slotType: CalendarSlotType, __: TranslateFunction) => (
    slotType === CalendarSlotType.EXTERNAL ? __`synced_event` : (slot.service?.title ?? `[${__`deleted_service`}]`)
)

//findCustomerName:
const findCustomerName = (slot: CalendarSlot, slotType: CalendarSlotType, __: TranslateFunction): string | null => {

    // if it is an external event or a buffer time, return null
    if(slotType === CalendarSlotType.EXTERNAL || slotType === CalendarSlotType.BUFFER){
        return null
    }

    // if it is a single appointment/reservation:
    if(!slot.service?.groupAppointments && slot.items.length === 1){
        if(slotType === CalendarSlotType.APPOINTMENT){
            return (
                slot.items[0].customer?.name ??
                slot.items[0].customer?.email ??
                slot.items[0].customer?.phone ??
                slot.items[0].customer?.billingPhone ??
                __`unknown`
            )
        }else{
            return __`reserved`
        }
    }

    // multiple items:
    let participantsNum = 0
    let reservedNum = 0
    slot.items.map(item => {
        if(item.type === CalendarSlotType.APPOINTMENT){
            participantsNum++
        }else if(item.type === CalendarSlotType.RESERVATION){
            reservedNum++
        }
    })

    let result = ''
    if(participantsNum){
        result = `${participantsNum} ${__(participantsNum === 1 ? 'participant' : 'participants').toLowerCase()}`
    }
    if(reservedNum){
        if(result !== ''){
            result += ' - '
        }
        result += `${reservedNum} ` + __`reserved`.toLowerCase()
    }

    return result

}

//findTimeRange:
const findTimeRange = (slotDateTime: string, duration: number) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' })
    const startDate = new Date(slotDateTime)
    const endDate = new Date(slotDateTime)
    addMinutes(endDate, duration)
    return dateTimeFormat.formatRange(startDate, endDate)
}

//findMode:
const findMode = (height: number): Mode => {
    if(height >= 34)
        return 'multiLines'
    if(height >= 18)
        return 'singleLine'
    if(height >= 17)
        return 'compact'
    if(height >= 4)
        return 'noText'
    return 'justLine'
}

//getTooltipContent
const getTooltipContent = (
    slotType: CalendarSlotType,
    timeRange: string,
    duration: number,
    serviceName: string | null,
    customerName: string | null,
    mode: Mode,
    __: TranslateFunction
) => {
    if(slotType === CalendarSlotType.BUFFER){
        return `${__`buffer_time`} • ${__('duration_min', { duration })}`
    }
    if(mode === 'multiLines'){
        if(customerName){
            return timeRange + ` • ` + (slotType === CalendarSlotType.EXTERNAL ? __`synced_event` : customerName)
        }else{
            return timeRange
        }
    }else{
        if(isNil(customerName)){
            return (
                <>
                    <div>{`${serviceName} - ${__('duration_min', { duration })}`}</div>
                    <div>{timeRange}</div>
                </>
            )
        }else{
            return (
                <>
                    <div>{`${serviceName} - ${__('duration_min', { duration })}`}</div>
                    <div>{timeRange + (slotType === CalendarSlotType.EXTERNAL ? '' : ` • ${customerName}`)}</div>
                </>
            )
        }
    }
}

//getInfo:
const getInfo = (
    slot: CalendarSlot,
    customerName: string | null,
    timeRange: string,
    locale: string
) => {
    if(customerName){
        return new Date(slot.dateTime).toLocaleString([locale, 'en-US'], { hour: 'numeric', minute: 'numeric' }) + ' • ' + customerName
    }else{
        return timeRange
    }
}

//calcTop:
const calcTop = (
    slotDateTime: string,
    date: Date,
    dayStartHour: number,
    scale: number
) => (dateTimeToNumericHour(slotDateTime, date) - dayStartHour)*60*scale
