import { message, theme, Typography } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useTranslate, useWindowDimensions } from 'utils'
import { useState } from 'react'
import { FormSection } from 'components/form-section/form-section'
import { useField } from 'react-final-form'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
import { AvailabilityCalendar } from 'components/availability/availability-calendar/availability-calendar'
import { CopyWrapper } from './resource-availabilities.style'
import { ResourceCopy } from '../resource-copy/resource-copy'
const  { Title } = Typography

export const ResourceAvailabilities = () => {

    //hooks:
    const { token: { fontSizeSM, paddingXXS, paddingXS, marginXXS, marginXS, margin } } = theme.useToken()
    const { __ } = useTranslate()
    const { SM } = useWindowDimensions()

    //field:
    const { input: { value: availabilities, onChange: updateAvailabilities } } = useField('availabilities')

    //state:
    const [ isResourcesOpen, setIsResourcesOpen ] = useState(false)
    
    return (
        <FormSection
            title={
                <HorizontalContainer style={{ minWidth: 400, justifyContent: 'flex-start', gap: marginXS }}>
                    <Title
                        level={5}
                        style={{
                            paddingTop: SM ? 0 : paddingXXS,
                            margin: `${marginXXS}px 0`
                        }}
                    >
                        {__`availabilities`}
                    </Title>
                </HorizontalContainer>
            }
            topButton={{
                label: __`copy_from_another_resource`,
                type: 'link',
                icon: isResourcesOpen ? <UpOutlined style={{ fontSize: fontSizeSM }}/> : <DownOutlined style={{ fontSize: fontSizeSM }}/>,
                style: {
                    padding: `${paddingXS}px ${paddingXXS}px`,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    gap: marginXS
                },
                onClick: () => setIsResourcesOpen(!isResourcesOpen)
            }}
        >

            <VerticalContainer style={{ gap: margin }}>

                {/* copy */}
                {isResourcesOpen && (
                    <CopyWrapper>
                        <ResourceCopy
                            onSelect={availabilities => {
                                setIsResourcesOpen(false)
                                updateAvailabilities(availabilities)
                                message.success(__`availabilities_are_copied_from_the_resource`)
                            }}
                        />
                    </CopyWrapper>
                )}
                
                {/* calendar */}
                <AvailabilityCalendar
                    availabilities={availabilities}
                    updateAvailabilities={updateAvailabilities}
                />

            </VerticalContainer>

        </FormSection>
    )
    
}
