import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { VerticalContainer } from 'old/components/styled'
import { useTranslate }  from 'old/utils'
import { Button, Modal, theme, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
const { Text, Paragraph } = Typography

type Props = {
    clientId: string
    token: string
    isModalOpen: boolean
}

export const TokenModal = ({
    clientId,
    token,
    isModalOpen
}: Props) => {

    //hooks:
    const { token: { 
        colorBgTextHover,
        colorTextTertiary,
        paddingXXS,
        borderRadius,
        paddingXS,
        paddingXL,
        marginXS,
        marginSM,
        margin
    }} = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    return (
        <Modal
            title={__`token_created`}
            open={isModalOpen}
            style={{ width: 800 }}
            okText={__`got_it`}
            closable={false}
            footer={[
                <Button
                    key='submit'
                    size='large'
                    type='primary'
                    style={{ marginTop: marginSM }}
                    onClick={() => navigate('/token')}
                >
                    {__`got_it`}
                </Button>
            ]}
        >

            <VerticalContainer>

                <Paragraph style={{ marginTop: marginXS }}>
                    {__`please_copy_the_token_and_keep_it_somewhere_safe_after_this_you_cannot_access_the_token_anymore`}
                </Paragraph>

                <VerticalContainer style={{ gap: margin }}>

                    {/* client id */}
                    <VerticalContainer style={{ alignItems: 'flex-start' }}>

                        <Text>{__`client_id`}:</Text>

                        <Paragraph
                            style={{
                                width: 'fit-content',
                                paddingRight: paddingXL,
                                margin: 0,
                                padding: paddingXS,
                                fontWeight: 'bold',
                                background: colorBgTextHover,
                                borderRadius: borderRadius
                            }}
                            copyable={{
                                icon: [
                                    <CopyOutlined  key={1} style={{ padding: paddingXXS, color: colorTextTertiary }}/>,
                                    <CheckOutlined key={2} style={{ padding: paddingXXS, color: colorTextTertiary }}/>
                                ],
                                tooltips: [__`copy`, __`copied`],
                                text: clientId ?? ''
                            }}
                        >
                            {clientId}
                        </Paragraph>

                    </VerticalContainer>

                    {/* token */}
                    <VerticalContainer style={{ alignItems: 'flex-start' }}>

                        <Text>{__`token`}:</Text>

                        <Paragraph
                            style={{
                                width: 'fit-content',
                                paddingRight: paddingXL,
                                margin: 0,
                                padding: paddingXS,
                                fontWeight: 'bold',
                                background: colorBgTextHover,
                                borderRadius: borderRadius
                            }}
                            copyable={{
                                icon: [
                                    <CopyOutlined  key={1} style={{ padding: paddingXXS, color: colorTextTertiary }}/>,
                                    <CheckOutlined key={2} style={{ padding: paddingXXS, color: colorTextTertiary }}/>
                                ],
                                tooltips: [__`copy`, __`copied`],
                                text: token ?? ''
                            }}
                        >
                            {token}
                        </Paragraph>

                    </VerticalContainer>

                </VerticalContainer>

            </VerticalContainer>

        </Modal>
    )
    
}
