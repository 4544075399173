import { ConfirmationPage } from '../../confirmation-page/confirmation-page'
import { message } from 'antd'
import { useCompleteDraftOrder } from 'api'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getShopId, queryClient, useTranslate } from 'utils'

export const BookingDraft = () => {

    //hooks:
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const params = useParams()

    const shopifyBookingId = params.shopifyBookingId
    if(!shopifyBookingId){
        navigate('/404')
    }

    //api:
    const { mutate: completeOrder, isLoading, data: response } = useCompleteDraftOrder(shopifyBookingId ?? '')

    //after complete:
    useEffect(() => {
        if(response){
            message.success(__`your_order_is_completed`)
            queryClient.invalidateQueries(['service'])
            queryClient.invalidateQueries(['services', getShopId()])
            if(location.pathname.includes('/service')){
                navigate(-2)
            }else{
                navigate('/calendar')
            }
        }
    }, [isLoading])

    return shopifyBookingId ? (
        <ConfirmationPage
            status='success'
            pageTitle={''}
            title={__`draft_order_created_successfully`}
            message={__`mark_as_paid_or_send_an_invoice_appointment_will_be_booked_as_soon_as_the_order_is_completed`}
            confirmText={__`complete_draft_order`}
            isLoading={isLoading}
            onConfirm={completeOrder}
            onBack={() => navigate(-1)}
        />
    ) : <></>
    
}
