import { useEffect } from 'react'
import { theme } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { HorizontalContainer, LogoLoading, PageError, StaticPageError, VerticalContainer } from 'old/components/styled'
import { useAuthentication, useWindowDimensions } from 'old/utils'
import { useConfig, useCurrentShop, useLocations } from 'old/api'
import { SideMenu } from 'old/components/layout/side-menu/side-menu'
import { BottomMenu } from 'old/components/layout/bottom-menu/bottom-menu'
import { LayoutHeader } from 'old/components/layout/layout-header/layout-header'

export const Layout = () => {

    //hooks:
    const { token: { colorBgLayout, colorBgContainer, paddingXXS, margin }} = theme.useToken()
    const { SM, LG } = useWindowDimensions()
    const navigate = useNavigate()
    const { data: locations } = useLocations(null, 1)
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const { data: currentShop, isLoading: isShopLoading } = useCurrentShop()
    const { isLoggedIn } = useAuthentication()
    
    locations

    //redirect:
    useEffect(() => { !isLoggedIn && navigate('/login') }, [isLoggedIn])

    //render:
    return (
        !isLoggedIn ? <></> :
        (isConfigLoading || isShopLoading) ? <LogoLoading/> :
        !config ? <StaticPageError/> :
        !currentShop ? <PageError/> : (

            <VerticalContainer style={{
                width: '100%',
                height: '100%',
                background: SM ? colorBgLayout : colorBgContainer,
                gap: 0
            }}>

                <VerticalContainer id='layoutScrollContainer' style={{ flex: 1, overflowY: 'auto', gap: 0 }}>

                    {/* header */}
                    {LG && <LayoutHeader/>}

                    <VerticalContainer style={{
                        flex: 1,
                        justifyContent: 'space-between'
                    }}>
                    
                        <HorizontalContainer style={{
                            flex: 1,
                            padding: LG ? `0 ${paddingXXS}px` : 0,
                            alignItems: 'stretch',
                            gap: margin
                        }}>

                            {/* side menu */}
                            {LG && (<div><SideMenu/></div>)}

                            {/* outlet */}
                            <div style={{ maxWidth: LG ? 'calc(100% - 243px - 8px)' : '100%', flex: 1, display: 'flex' }}>
                                <Outlet/>
                            </div>

                        </HorizontalContainer>

                    </VerticalContainer>

                </VerticalContainer>

                {/* bottom menu */}
                {!LG && <BottomMenu/>}

            </VerticalContainer>
        )
    )

}
