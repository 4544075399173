import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'


export const TimezoneWrapper = styled.div`
    width: 200px;
`

export const DatePickerWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const MenuWrapper = styledContainerQuery.div`
    height: fit-content;
    width: 450px;
    display: flex;
    gap: ${(props: any) => `${props.theme.marginXS}px`};
    &:container(max-width: 400px){
        flex-direction: column;
        align-items: flex-start;
        ${DatePickerWrapper}{
            width: 100%;
        }
        ${TimezoneWrapper}{
            width: 100%;
        }
    }
`

export const NavigationContainer = styledContainerQuery.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${(props: any) => `${props.theme.marginXS}px`};
    &:container(max-width: 650px){
        flex-direction: column;
        align-items: flex-start;
        ${MenuWrapper}{
            width: 100%;
        }
    }
`

export const ArrowsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${props => `${props.theme.marginXXS}px`};
`
