import ImgCrop from 'antd-img-crop'
import { Upload, UploadFile, UploadProps, message, theme } from 'antd'
import { useEffect, useState } from 'react'
import { IMAGE_VALID_TYPES, MAX_IMAGE_SIZE } from 'app.constants'
import { RcFile } from 'antd/es/upload'
import { TranslateFunction, accessToken, env, useTranslate } from 'utils'
import { useField } from 'react-final-form'
const { Dragger } = Upload

type Props = {
    type: string
    nameField: string
    imageField: string
}

export const ImageUpload = ({ type, nameField, imageField }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingSM } } = theme.useToken()
    const { input: { value: name } } = useField(nameField)
    const { input: { value: image, onChange: updateImage } } = useField(imageField)

    //state:
    const [ fileList, setFileList ] = useState<UploadFile[]>([])

    //after image change:
    useEffect(() => {
        if(image.path && (
            fileList.length === 0 || fileList[0].url !== image.path
        )){
            setFileList([{
                uid: image.id,
                name: name,
                url: image.path,
                status: 'done'
            }])
        }
        if(!image.path || image.path?.includes('default')){
            setFileList([])
        }
    }, [image])
    
    //onChange:
    const onChange: UploadProps['onChange'] = ({ fileList, file }) => {
        const { status, size, type, response } = file
        if(status === 'done'){
            updateImage({
                id: response.id,
                path: `${env().CDN_URL}${response.path}`,
            })
        }
        if(fileList.length >= 1 && isFileSizeValid(size ?? 9999999) && isFileTypeValid(type ?? 'undefined')){
            const { length, [length-1]: lastFile } = fileList
            setFileList([lastFile])
        }else{
            setFileList([])
            updateImage({
                id: null,
                path: null
            })
        }
    }

    //uploadProps:
    const uploadProps: UploadProps = {
        name: 'file',
        headers: { 'authorization': `Bearer ${accessToken()}` },
        action: `${env().AVATAR_UPLOAD_URL}/?type=${type}`,
        accept: IMAGE_VALID_TYPES,
        fileList: fileList,
        beforeUpload: file => beforeUpload(file, __),
        onChange: onChange
    }
    
    return (
        <div style={{ flex: 1 }}>
        
            <ImgCrop rotationSlider>

                <Dragger
                    {...uploadProps}
                    showUploadList={false}
                    style={{ padding: paddingSM }}
                >
                        
                        <div
                            onClick={e => e.stopPropagation()}
                            onDrop={e => e.stopPropagation()}
                        >
                            <ImgCrop rotationSlider>
                                <Upload
                                    {...uploadProps}
                                    listType='picture-card'
                                >
                                    {'Upload'}
                                </Upload>
                            </ImgCrop>
                        </div>
                        
                    <p className='ant-upload-text'>
                        {__`click_or_drag_the_file_here_to_upload`}
                    </p>
                    <p className='ant-upload-hint' style={{ margin: 0 }}>
                        {__`max_file_size_is_5_mb_and_the_valid_file_formats_are_jpg_and_png`}
                    </p>

                </Dragger>

            </ImgCrop>
            
        </div>
    )
}

//isFileSizeValid:
const isFileSizeValid = (size: number) => (size / 1024) <= MAX_IMAGE_SIZE

//isFileTypeValid:
const isFileTypeValid = (type: string) => IMAGE_VALID_TYPES.includes(type)

//beforeUpload:
const beforeUpload = (file: RcFile, __: TranslateFunction) => {
    if(!isFileTypeValid(file.type)){
        message.error(__`you_can_only_upload_jpg_png_file`)
        return false
    }else if(!isFileSizeValid(file.size)){
        message.error(__`image_must_be_smaller_than_5_MB`)
        return false
    }else{
        return true
    }
}
