import { ContentWrapper, MainWrapper } from './confirmation-page.style'
import { Alert, Button, Typography, theme } from 'antd'
import { PageHeader } from 'old/components/page-header/page-header'
import { LoadingOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { HorizontalContainer, PageContentWrapper, VerticalContainer } from 'old/components/styled'
import { useTranslate }  from 'old/utils'
const { Paragraph, Text } = Typography

type Props = {
    status?: 'info' | 'success' | 'warning' | 'error'
    pageTitle: string
    pageDescription?: string
    title: string
    message: string
    confirmText?: string
    declineText?: string
    confirmButtonType?: 'link' | 'text' | 'default' | 'primary' | 'dashed'
    declineButtonType?: 'link' | 'text' | 'default' | 'primary' | 'dashed'
    isLoading?: boolean
    onConfirm?: () => void
    onDecline?: () => void
    onBack: () => void
}

export const ConfirmationPage = ({
    status = 'info',
    pageTitle,
    pageDescription,
    title,
    message,
    confirmText,
    declineText,
    confirmButtonType = 'primary',
    declineButtonType = 'link',
    isLoading = false,
    onConfirm,
    onDecline,
    onBack
}: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontFamily } } = theme.useToken()

    //render:
    return (
        <PageContentWrapper>

            <MainWrapper>

                {/* header */}
                <PageHeader
                    title={pageTitle}
                    description={pageDescription}
                    backButton={{
                        name: __`back`,
                        onClick: onBack
                    }}
                />

                {/* message */}
                <ContentWrapper>
                    <Alert
                        message={title}
                        type={status}
                        showIcon
                        description={
                            <VerticalContainer>

                                <Paragraph>{message}</Paragraph>

                                <HorizontalContainer>
                                    
                                    {/* confirm button */}
                                    {confirmText &&
                                        <Button
                                            type={confirmButtonType}
                                            size='large'
                                            icon={isLoading ? <LoadingOutlined/> : <CloudUploadOutlined/>}
                                            onClick={() => {!isLoading && onConfirm?.()}}
                                        >
                                            <Text style={{ fontFamily, color: 'unset' }}>{confirmText}</Text>
                                        </Button>
                                    }

                                    {/* decline button */}
                                    {declineText && <Button type={declineButtonType} onClick={onDecline}>{declineText}</Button>}

                                </HorizontalContainer>

                            </VerticalContainer>
                        }
                    />
                </ContentWrapper>
                
            </MainWrapper>

        </PageContentWrapper>
    )
    
}
