import { PageHeader } from 'components/page-header/page-header'
import { PlusOutlined } from '@ant-design/icons'
import { isNil, useTranslate, useWindowDimensions } from 'utils'
import { useConfig, useCurrentShop, useLocations } from 'api'
import { useNavigate } from 'react-router-dom'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { useState } from 'react'
import { PageContentWrapper, PageError, VerticalContainer } from 'components/styled'
import { theme } from 'antd'
import { FormSection } from 'components/form-section/form-section'
import { Config } from 'types'
import { LocationItem } from 'components/location/location-item/location-item'

export const LocationList = () => {

    //hooks:
    const { token: { paddingMD, marginXXS, margin, padding } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config } = useConfig()
    const { data: shop } = useCurrentShop()
    const navigate = useNavigate()

    //state:
    const [ search, setSearch ] = useState<string | null>(null)
    
    //location:
    const {
        data: locations,
        total,
        pageNum,
        totalPages,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useLocations(search, 15)


    return (!config || !shop) ? <PageError/> : (
        
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`locations`}
                    description={__`manage_locations_where_bookings_are_being_offered`}
                    primaryButton={{
                        name: SM ? __`add_location` : __`add`,
                        icon: <PlusOutlined/>,
                        mode: canCreateLocation(config, !isNil(locations), total) ? 'normal' : 'disabled',
                        title: canCreateLocation(config, !isNil(locations), total) ? undefined : __`you_reached_your_plans_max_limit`,
                        onClick: () => navigate('/location/new')
                    }}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>
                
                    <FormSection
                        title={SM ? __`locations` : undefined }
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        searchInput={{
                            placeholder: __`search_locations`,
                            onSearch: setSearch
                        }}
                    >

                        {/* list */}
                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={locations === null}
                            isError={locations === false}
                            notFound={(locations && locations.length === 0) ?? false}
                            totalPages={totalPages}
                            pageNum={pageNum}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            {locations && locations?.map(resource =>
                                <LocationItem
                                    key={resource.id}
                                    location={resource}
                                />
                            )}
                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}

//canCreateLocation:
export const canCreateLocation = (config: Config, dataLoaded: boolean, total: number) => {
    if(dataLoaded){
        if(config.plan?.maxLocations && total < config.plan.maxLocations){
            return true
        }
    }
    return false
}
