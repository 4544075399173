import { getShopId, useMutationHandler, usePaginationHandler, useQueryHandler } from 'utils'
import { ResourceType } from 'types'

export const useResourceTypes = (search: string | null, limit: number) => {
    const shopId = getShopId()
    return usePaginationHandler<ResourceType[]>({
        query: `
            query getResourceTypes(
                $shop: String!,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String
            ){
                getResourceTypes(
                    shop: $shop,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                ){
                    data{
                        id
                        name
                    }

                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor

                }
            }
        `,
        variables: {
            shop: shopId,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['resourceTypes', shopId],
        limit,
        getResponse: json => json.data.getResourceTypes,
        onSuccess: response => response.data
    })
}

//useResourceType:
export const useResourceType = (id: string, onNotFound?: (json: any) => void) => {
    const shopId = getShopId()
    return useQueryHandler<ResourceType>({
        query: `
            query getResourceType (
                $shop: String!,
                $id: String!
            ) {
                getResourceType (
                    shop: $shop,
                    id: $id
                ) {
                    id
                    name
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        cacheKey: ['resourceType', id, shopId],
        onSuccess: json => json.data.getResourceType,
        onNotFound
    })
}

//create:
export const useCreateResourceType = (name: string) => {
    const shopId = getShopId()
    return useMutationHandler<string>({
        query: `
            mutation createResourceType (
                
                $shop: String!,
                $request: CreateResourceTypeRequest!,

            ) {
                createResourceType (

                    shop: $shop,
                    request: $request
                    
                ) {
                    id
                }
            }
        `,
        variables: {
            shop: shopId,
            request: {
                name
            }
        },
        onSuccess: json => json.data.createResourceType.id
    })

}
