import { UserPermissionHeader } from 'old/components/user/user-permission-header/user-permission-header'
import { InheritedPermissions, getTypeInheritedLevel } from 'old/components/user/user-permission-list/user-permission-list'
import { useTranslate } from 'old/utils'
import { theme } from 'antd'
import { RightOutlined, UserOutlined, CustomerServiceOutlined, CloudServerOutlined, ShopOutlined } from '@ant-design/icons'
import { VerticalContainer } from 'old/components/styled'
import { Avatar } from 'old/components/avatar/avatar'
import { PermissionLevel, PermissionType, PermissionsUiWhiteList }  from 'old/types'

type Props = {
    inheritedPermissions: InheritedPermissions
    onBack: () => void
    onSelect: (type: PermissionType) => void
}

export const UserPermissionTypeSelect = ({ inheritedPermissions, onBack, onSelect }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: {
        marginMD,
        marginXS,
        fontSizeLG,
        fontSizeSM,
        paddingSM,
        paddingLG,
        colorText,
        colorBgSpotlight
    }} = theme.useToken()

    //getIcon:
    const getIcon = (type: PermissionType) => {
        switch(type){
            case PermissionType.Shop:
                return <ShopOutlined/>
            case PermissionType.TeamMember:
                return <UserOutlined/>
            case PermissionType.Service:
                return <CustomerServiceOutlined/>
            case PermissionType.Flow:
                return <CloudServerOutlined/>
        }
    }

    //getName:
    const getName = (type: PermissionType) => {
        switch(type){
            case PermissionType.Shop:
                return __`shop`
            case PermissionType.TeamMember:
                return __`team_member`
            case PermissionType.Service:
                return __`service`
            case PermissionType.Flow:
                return __`flow`
        }
    }

    //getDescription:
    const getDescription = (type: PermissionType) => {
        switch(type){
            case PermissionType.Shop:
                return __`grant_shop_permission_to_create_an_admin`
            case PermissionType.TeamMember:
                return __`user_can_access_to_a_specific_team_member`
            case PermissionType.Service:
                return __`user_can_read_write_or_manage_a_service`
            case PermissionType.Flow:
                return __`user_can_read_write_or_manage_a_flow`
        }
    }

    //render:
    return (
        <VerticalContainer style={{ gap: marginMD }}>

            {/* header */}
            <UserPermissionHeader
                title={__`permission`}
                description={__`select_the_type_of_permission_you_want_to_grant`}
                onBack={onBack}
            />

            <VerticalContainer style={{ paddingBottom: paddingSM, gap: marginXS }}>

                {/* list */}
                {(Object.keys(PermissionType) as PermissionType[]).filter(type => PermissionsUiWhiteList.indexOf(type) >= 0).map(type =>
                    <Avatar
                        key={type}
                        name={getName(type)}
                        image={null}
                        placeholder={<div style={{ fontSize: fontSizeLG, color: colorBgSpotlight }}>{getIcon(type)}</div>}
                        description={getDescription(type)}
                        color={colorText}
                        backgroundColor='transparent'
                        size='small'
                        extraIcon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                        style={{ paddingLeft: paddingSM, paddingRight: paddingLG, borderRadius: 0 }}
                        isDisabled={getTypeInheritedLevel(inheritedPermissions, type) === PermissionLevel.Manage}
                        onClick={() => onSelect(type)}
                    />
                )}

            </VerticalContainer>

        </VerticalContainer>
    )
    
}
