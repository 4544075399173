import { ActionsWrapper, MainWrapper } from './appointment-details-actions.style'
import { Button, Typography, message, theme } from 'antd'
import { Appointment, FlowMeta }  from 'old/types'
import { MailOutlined } from '@ant-design/icons'
import { TranslateFunction, getIsoStringYearMonthDay, queryClient, useTranslate, useWindowDimensions }  from 'old/utils'
import { ReactNode, useEffect, useState } from 'react'
import { ConfirmMessage, ConfirmMessageProps } from 'old/components/confirm-message/confirm-message'
import { useAppointmentCancel, useAppointmentCheckIn, useAppointmentNoShow, useAppointmentResendConfirmEmail } from 'old/api'
import { useNavigate } from 'react-router-dom'
const { Text } = Typography

type AppointmentDetailsActionsProps = {
    appointment: Appointment
}

export const AppointmentDetailsActions = ({ appointment }: AppointmentDetailsActionsProps) => {

    //hooks:
    const { token: { fontSizeLG, padding, paddingLG, marginXXS, colorBgLayout, colorErrorBg } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ isRefund, setIsRefund ] = useState(false)
    const [ isConfirmVisible, setIsConfirmVisible ] = useState(false)
    const [ confirmInfo, setConfirmInfo ] = useState({} as ConfirmMessageProps)

    //updateConfirmState:
    const updateConfirmState = (isLoading: boolean) => {
        setConfirmInfo({...confirmInfo, status: isLoading ? 'loading' : 'normal'})
    }
    
    //actions:
    const { mutate: checkIn, isLoading: isCheckInLoading, data: checkInResponse } = useAppointmentCheckIn(appointment.id)
    const { mutate: resend , isLoading: isResendLoading , data: resendResponse  } = useAppointmentResendConfirmEmail(appointment.id)
    const { mutate: noShow , isLoading: isNoShowLoading , data: NoShowResponse  } = useAppointmentNoShow(appointment.id)
    const { mutate: cancel , isLoading: isCancelLoading , data: CancelResponse  } = useAppointmentCancel(appointment.id, isRefund)

    //showCheckInConfirm:
    const showCheckInConfirm = () => {
        setConfirmInfo({
            title: __`check_in`,
            message: __`are_you_sure_you_want_to_mark_the_customer_as_checked_in`,
            errorMessage: '',
            backgroundColor: colorBgLayout,
            confirmText: __`mark_as_checked_in`,
            declineText: __`cancel`,
            status: isCheckInLoading ? 'loading' : 'normal',
            onConfirm: () => checkIn(),
            onDecline: () => setIsConfirmVisible(false)
        })
        setIsConfirmVisible(true)
    }

    //showResendEmailConfirm:
    const showResendEmailConfirm = () => {
        setConfirmInfo({
            title: __`email_confirmation`,
            message: __`do_you_want_to_resend_the_email_confirmation_to` + ` "${appointment.customer?.email}"`,
            errorMessage: '',
            backgroundColor: colorBgLayout,
            confirmText: __`resend_email_confirmation`,
            declineText: __`cancel`,
            status: isResendLoading ? 'loading' : 'normal',
            onConfirm: () => resend(),
            onDecline: () => setIsConfirmVisible(false)
        })
        setIsConfirmVisible(true)
    }

    //showMarkNoShowConfirm:
    const showMarkNoShowConfirm = () => {
        setConfirmInfo({
            title: __`no_show_question_mark`,
            message: __`are_you_sure_you_want_to_mark_the_customer_to_mark_as_a_no_show`,
            errorMessage: '',
            backgroundColor: colorBgLayout,
            confirmText: __`mark_as_no_show`,
            declineText: __`cancel`,
            status: isNoShowLoading ? 'loading' : 'normal',
            onConfirm: () => noShow(),
            onDecline: () => setIsConfirmVisible(false)
        })
        setIsConfirmVisible(true)
    }

    //showCancelConfirm:
    const showCancelConfirm = () => {
        setConfirmInfo({
            title: __`notice`,
            message: __`are_you_sure_you_want_to_cancel_the_appointment_this_is_a_one_way_operation`,
            errorMessage: '',
            checkboxText: appointment.price > 0 ? __`automatically_refund_money_on_cancellation` : undefined,
            checkboxPreselect: isRefund,
            backgroundColor: colorErrorBg,
            confirmText: __`cancel_the_appointment`,
            declineText: __`decline`,
            isDanger: true,
            status: isCancelLoading ? 'loading' : 'normal',
            onConfirm: isChecked => {
                setIsRefund(isChecked)
                cancel()
            },
            onDecline: () => setIsConfirmVisible(false)
        })
        setIsConfirmVisible(true)
    }

    //after check in:
    useEffect(() => {
        updateConfirmState(isCheckInLoading)
        if(checkInResponse){
            setIsConfirmVisible(false)
            queryClient.invalidateQueries(['calendarSlots', getIsoStringYearMonthDay(new Date(appointment.datetime))])
            queryClient.invalidateQueries(['appointment', appointment.id])
            queryClient.invalidateQueries(['appointments'])
            message.success(__`the_appointment_marked_as_checked_in`)
        }
    }, [isCheckInLoading])

    //after resend:
    useEffect(() => {
        updateConfirmState(isResendLoading)
        if(resendResponse){
            setIsConfirmVisible(false)
            message.success(__`the_email_confirmation_has_been_sent_to_the_customer`)
        }
    }, [isResendLoading])

    //after no show:
    useEffect(() => {
        updateConfirmState(isNoShowLoading)
        if(NoShowResponse){
            setIsConfirmVisible(false)
            queryClient.invalidateQueries(['calendarSlots', getIsoStringYearMonthDay(new Date(appointment.datetime))])
            queryClient.invalidateQueries(['appointment', appointment.id])
            queryClient.invalidateQueries(['appointments'])
            message.success(__`the_appointment_marked_as_no_show`)
        }
    }, [isNoShowLoading])

    //after cancel:
    useEffect(() => {
        updateConfirmState(isCancelLoading)
        if(CancelResponse){
            setIsConfirmVisible(false)
            queryClient.invalidateQueries(['calendarSlots', getIsoStringYearMonthDay(new Date(appointment.datetime))])
            queryClient.invalidateQueries(['appointment', appointment.id])
            queryClient.invalidateQueries(['appointments'])
            message.success(__`the_appointment_cancelled`)
        }
    }, [isCancelLoading])

    //render:
    return (
        <MainWrapper>

            {/* title */}
            <Text style={{ padding: `0 ${SM ? paddingLG : padding}px`, fontFamily: 'inter-medium' }}>
                {__`actions`}
            </Text>

            {!isConfirmVisible ? (

                <ActionsWrapper>

                    {/* checked in */}
                    <ActionButton
                        name={__`customer_checkin`}
                        icon={FlowMeta.CUSTOMER_CHECKED_IN.icon}
                        disabledTitle={getCheckinDisabledTitle(appointment, __)}
                        onClick={showCheckInConfirm}
                    />

                    {/* resend confirmation */}
                    <ActionButton
                        name={__`resend_email_confirmation`}
                        icon={<MailOutlined/>}
                        disabledTitle={getResendDisabledTitle(appointment, __)}
                        onClick={showResendEmailConfirm}
                    />

                    {/* reschedule/reassign */}
                    <ActionButton
                        name={__`reschedule_reassign`}
                        icon={<div style={{ fontSize: fontSizeLG }}>{FlowMeta.APPOINTMENT_RESCHEDULED.icon}</div>}
                        disabledTitle={getRescheduleDisabledTitle(appointment, __)}
                        onClick={() => navigate(`/calendar/reschedule/${appointment.id}`)}
                    />

                    {/* no show */}
                    <ActionButton
                        name={__`mark_as_no_show`}
                        icon={<div style={{ fontSize: fontSizeLG }}>{FlowMeta.NO_SHOW.icon}</div>}
                        disabledTitle={getNoShowDisabledTitle(appointment, __)}
                        onClick={showMarkNoShowConfirm}
                    />

                    {/* cancel */}
                    <ActionButton
                        name={__`cancel_appointment`}
                        icon={FlowMeta.APPOINTMENT_CANCELLED.icon}
                        disabledTitle={getCancelDisabledTitle(appointment, __)}
                        onClick={showCancelConfirm}
                    />

                </ActionsWrapper>

            ) : (

                <div style={{
                    padding: `0 ${SM ? paddingLG : padding}px`,
                    marginTop: marginXXS
                }}>
                    <ConfirmMessage
                        title={confirmInfo.title}
                        message={confirmInfo.message}
                        errorMessage={confirmInfo.errorMessage}
                        checkboxText={confirmInfo.checkboxText}
                        checkboxPreselect={confirmInfo.checkboxPreselect}
                        backgroundColor={confirmInfo.backgroundColor}
                        confirmText={confirmInfo.confirmText}
                        declineText={confirmInfo.declineText}
                        isDanger={confirmInfo.isDanger}
                        status={confirmInfo.status}
                        onConfirm={confirmInfo.onConfirm}
                        onDecline={confirmInfo.onDecline}
                    />
                </div>
                
            )
        }
        </MainWrapper>
    )
}

type ActionButtonProps = {
    name: string
    icon: ReactNode
    disabledTitle: string | null
    onClick: () => void
}

export const ActionButton = ({ name, icon, disabledTitle, onClick }: ActionButtonProps) => {

    //theme:
    const { token: { colorText, colorTextDisabled, padding, paddingLG, margin } } = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //render:
    return (
        <Button
            type='text'
            title={(disabledTitle) ? disabledTitle : undefined}
            style={{
                width: '100%',
                height: `${paddingLG*2}px`,
                padding: `0 ${SM ? paddingLG : padding}px`,
                borderRadius: 0,
                display: 'flex',
                alignItems: 'center',
                gap: `${margin}px`
            }}
            disabled={disabledTitle !== null}
            onClick={onClick}
        >    
            
            {icon}

            <Text
                style={{
                    fontFamily: 'inter-medium',
                    color: disabledTitle ? colorTextDisabled : colorText,
                    margin: 0
                }}
            >
                {name}
            </Text>

        </Button>
    )
}

const getCheckinDisabledTitle = (appointment: Appointment, __: TranslateFunction) => {
    const eventsLength = appointment.events.length
    if(appointment.status === 'cancelled'){
        return __`the_appointment_has_been_cancelled`
    }else if(eventsLength > 0 && appointment.events[eventsLength - 1].type === 'NO_SHOW'){
        return __`the_appointment_has_been_marked_as_no_show`
    }else if(eventsLength > 0 && appointment.events[eventsLength - 1].type === 'CUSTOMER_CHECKIN'){
        return __`the_customer_has_been_checked_in`
    }else{
        return null
    }
}

const getResendDisabledTitle = (appointment: Appointment, __: TranslateFunction) => {
    if(appointment.status === 'cancelled'){
        return __`the_appointment_has_been_cancelled`
    }else if(!appointment.customerManagementLink || !appointment.teamMember){
        return __`there_is_no_management_link_or_team_member`
    }else{
        return null
    }
}

const getRescheduleDisabledTitle = (appointment: Appointment, __: TranslateFunction) => {
    if(appointment.status === 'cancelled'){
        return __`the_appointment_has_been_cancelled`
    }else if(!appointment.service){
        return __`the_service_is_not_available`
    }else{
        return null
    }
}

const getNoShowDisabledTitle = (appointment: Appointment, __: TranslateFunction) => {
    const eventsLength = appointment.events.length
    if(appointment.status === 'cancelled'){
        return __`the_appointment_has_been_cancelled`
    }else if(eventsLength > 0 && appointment.events[eventsLength - 1].type === 'CUSTOMER_CHECKIN'){
        return __`the_customer_has_been_checked_in`
    }else if(eventsLength > 0 && appointment.events[eventsLength - 1].type === 'NO_SHOW'){
        return __`the_appointment_has_been_marked_as_no_show`
    }else{
        return null
    }
}

const getCancelDisabledTitle = (appointment: Appointment, __: TranslateFunction) => {
    if(appointment.status === 'cancelled'){
        return __`the_appointment_has_been_cancelled`
    }else{
        return null
    }
}
