import { DateContainer, MainContainer, MainWrapper } from './booking-calendar-header.style'
import { TimelineColumn } from '../booking-calendar-cell/booking-calendar-cell.style'
import { useConfig } from 'old/api'
import { Typography, theme } from 'antd'
import { Config }  from 'old/types'
const { Text } = Typography

type Props = {
    date: Date
    index: number,
    numberOfDays: number
}

export const BookingCalendarHeader = ({ date, index, numberOfDays }: Props) => {

    //theme:
    const { token: { fontSizeSM, fontSizeHeading4, colorTextDescription } } = theme.useToken()
    
    //config:
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //getDay:
    const getDay = (date: Date, config: Config) => date.toLocaleString([config.locale, 'en-US'], { day: '2-digit' })

    //getWeekday:
    const getWeekday = (date: Date, config: Config) => date.toLocaleString([config.locale, 'en-US'], { weekday: 'short' })
    
    //render:
    return ((isConfigLoading || !config) ? null :
        <MainWrapper>

            {(index === 0 && numberOfDays > 1) && (
                <TimelineColumn style={{ border: 'none' }}/>
            )}

            <MainContainer>

                <DateContainer>

                    <Text
                        style={{
                            fontSize: fontSizeHeading4,
                            fontFamily: 'inter-medium',
                            fontWeight: 'normal'
                        }}
                    >
                        {getDay(date, config)}
                    </Text>
                    
                    <Text
                        style={{
                            fontSize: fontSizeSM,
                            fontFamily: 'inter-medium',
                            color: colorTextDescription
                        }}
                    >
                        {getWeekday(date, config)}
                    </Text>

                </DateContainer>

            </MainContainer>
            
        </MainWrapper>
    )
    
}
