import { Cell, Head, Row, Table } from './calendar.style'
import { ReactElement, ReactNode } from 'react'
import { isNil, objectAssign }  from 'old/utils'
import { useCalendar }  from 'old/context/calendar-provider'

type Props = {
    headerRenderer?: (date: Date, index: number) => ReactElement
    cellRenderer: (date: Date, index: number) => ReactNode
    headerStyle?: object
}

export const Calendar = ({ headerRenderer, cellRenderer, headerStyle }: Props) => {

    //hook:
    const data = useCalendar()
    
    //render:
    return (
        <Table>
            <tbody>
            
                {/* headers */}
                {!isNil(headerRenderer) && (
                    <tr style={objectAssign({ width: '100%' }, headerStyle)}>

                        {data[0].map((cell, index) => (

                            <Head key={cell.date.toString()}>

                                {headerRenderer?.(cell.date, index)}

                            </Head>

                        ))}

                    </tr>
                )}
            
                {/* rows */}
                {data.map(row =>
                    <Row key={row[0].date.toString()}>{

                        //columns:
                        row.map((cell, index) =>
                            <Cell key={cell.date.toString()}>

                                {cellRenderer(cell.date, index)}

                            </Cell>
                        )

                    }</Row>
                )
            }

            </tbody>
        </Table>
    )
    
}
