import { Mode } from './calendar-time-slot'
import styled from 'styled-components'
import { CalendarSlotType }  from 'old/types'
import { RGBtoRGBA }  from 'old/utils'
import { TIME_SLOT_WRAPPER_Z_INDEX } from 'z-indexes'

type MainWrapperProps = {
    mode: Mode
    height: number
    top: number
    colorRGB: string
}

export const DivWrapper = styled.div<MainWrapperProps>`
    width: 100%;
    height: ${props => `${props.height}px`};
    padding: ${props => `0 ${props.theme.paddingXXS*3/4}px 0 ${props.theme.paddingXXS*3/4}px`};
    background-color: ${props => props.mode === 'justLine' ? RGBtoRGBA(props.colorRGB, .4) : 'transparent' };
    position: absolute;
    top: ${props => `${props.top}px`};
    left: 0;
    display: flex;
    z-index: ${TIME_SLOT_WRAPPER_Z_INDEX};
    cursor: default;
`

export const ButtonWrapper = styled.button<MainWrapperProps>`
    width: 100%;
    height: ${props => `${props.height}px`};
    padding: ${props => `0 ${props.theme.paddingXXS/2}px 0 ${props.theme.paddingXXS*3/4}px`};
    border: none;
    outline: none;
    background-color: ${props => props.mode === 'justLine' ? RGBtoRGBA(props.colorRGB, .4) : 'transparent' };
    border-left: ${props => `solid 1px ${props.mode === 'justLine' ? 'transparent' : props.colorRGB}`};
    border-right: solid 1px transparent;
    position: absolute;
    top: ${props => `${props.top}px`};
    left: 0;
    display: flex;
    z-index: ${TIME_SLOT_WRAPPER_Z_INDEX};
    cursor: pointer;
    &:focus{
        border: ${props => `solid 1px ${props.colorRGB}`};
        border-radius: ${props => (props.mode === 'justLine' || props.mode === 'noText') ?
            `${props.theme.borderRadiusXS}px` : `${props.theme.borderRadiusSM}px`
        };
        background-color: ${props => RGBtoRGBA(props.colorRGB, .08)};
    }
`

type BackgroundCover = {
    colorRGB: string
    mode: Mode
}

export const BackgroundCover = styled.div<BackgroundCover>`
    width: 100%;
    height: 100%;
    background-color: ${props => props.mode === 'justLine' ? 'transparent' : props.theme.colorBgContainer };
    border-radius: ${props =>
        (props.mode === 'justLine' || props.mode === 'noText') ? `${props.theme.borderRadiusXS}px`
        : `${props.theme.borderRadiusSM}px`
    };
    position: relative;
`

type MainContainerProps = {
    type: CalendarSlotType
    mode: Mode
    colorRGB: string
}

export const MainContainer = styled.div<MainContainerProps>`
    width: 100%;
    height: 100%;
    padding: ${props =>
        props.mode === 'multiLines' ? `${props.theme.paddingXXS}px` : '0'
        + ` ${props.theme.paddingXS*6/8}px`
    };
    background: ${props =>
        (props.type === CalendarSlotType.BUFFER || props.type === CalendarSlotType.RESERVATION) ? (
            `repeating-linear-gradient(
                -45deg,
                ${RGBtoRGBA(props.colorRGB, .15)},
                ${RGBtoRGBA(props.colorRGB, .15)} 20%,
                rgba(255, 255, 255, 0.3) 20%,
                rgba(255, 255, 255, 0.3) 50%,
                rgba(255,255,255,0) 50%
            ) top left;`
        ) : (
            props.mode === 'justLine' ? 'transparent'
          : props.mode === 'noText' ? RGBtoRGBA(props.colorRGB, .1)
          : RGBtoRGBA(props.colorRGB, .1)
        )
    };
    background-size: ${props => `${props.theme.paddingXXS*6/4}px ${props.theme.paddingXXS*6/4}px`};
    border-radius: ${props =>
        (props.mode === 'justLine' || props.mode === 'noText') ? `${props.theme.borderRadiusXS}px`
        : `${props.theme.borderRadiusSM}px`
    };
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    left: 0;
    right: 0;
`

export const ContentWrapper = styled.div`
    min-width: ${props => `calc(100% - ${props.theme.marginXS}px)`};
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

type TitleWrapperProps = {
    mode: Mode
}

export const TitleWrapper = styled.div<TitleWrapperProps>`
    width: 100%;
    display: ${props => props.mode === 'multiLines' ? 'flex' : 'none'};
    align-items: center;
    gap: ${props => `${props.theme.marginXXS}px`};
    overflow: hidden;
`
