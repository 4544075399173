import {FormSection} from 'old/components/form-section/form-section'
import { Typography, theme } from 'antd'
import { useTranslate , useTouchDetector }  from 'old/utils'
import { VerticalContainer } from 'old/components/styled'
import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { flowVariables } from 'old/data'
import { useState } from 'react'
const { Text } = Typography

export const FlowVariables = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { colorTextTertiary, colorInfoActive, paddingXXS } } = theme.useToken()

    //touchscreen
    const { isTouchscreen } = useTouchDetector()

    //state:
    const [ hoveredText, setHoveredText ] = useState('')

    //render:
    return (
        <FormSection title={__`available_variables`} style={{ height: '100%' }}>
            <VerticalContainer style={{ gap: 0 }}>

                {flowVariables.map(variable => (
                    <Text
                        key={variable}
                        style={{
                            width: '100%',
                            minHeight: 30,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                        copyable={hoveredText === variable || isTouchscreen ? {
                            icon: [
                                <CopyOutlined  key={1} style={{ padding: paddingXXS, color: colorTextTertiary }}/>,
                                <CheckOutlined key={2} style={{ padding: paddingXXS, color: colorInfoActive   }}/>
                            ],
                            tooltips: [__`copy`, __`copied`]
                        } : undefined}
                        onMouseEnter={() => setHoveredText(variable)}
                        onMouseLeave={() => setHoveredText('')}
                    >
                        {variable}
                    </Text>
                ))}

            </VerticalContainer>
        </FormSection>
    )

}
