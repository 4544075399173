import { LOCATION_ID_LCL_STR_KEY } from 'app.constants'

// an interface declaration to handle the window.locationId type:
declare global {
    interface Window {
        locationId: string | undefined
    }
}

//getLocationId:
export const getLocationId = () => {
    let id = ''
    try{
        id = localStorage.getItem(LOCATION_ID_LCL_STR_KEY) ?? ''
    }catch(e){
        id = window.locationId ?? ''
    }
    return id
}

//updateLocationId:
export const updateLocationId = (id: string | null) => {
    try{
        if(id){
            localStorage.setItem(LOCATION_ID_LCL_STR_KEY, id)
        }else{
            localStorage.removeItem(LOCATION_ID_LCL_STR_KEY)
        }
    }catch(e){
        window.locationId = id ?? undefined
    }
}
