import { ReactNode } from 'react'
import {
    CloseOutlined,
    LogoutOutlined,
    RetweetOutlined,
    SettingOutlined,
    QuestionCircleOutlined,
    FileTextOutlined,
    UserOutlined,
    AppstoreAddOutlined,
    CodeOutlined,
    TeamOutlined,
    SecurityScanOutlined
} from '@ant-design/icons'
import { Button, Typography, theme } from 'antd'
import { useNavigate } from 'react-router-dom'
import { HorizontalLine, HorizontalContainer, VerticalContainer, Footer } from 'old/components/styled'
import { useAuthentication, useTranslate, useWindowDimensions } from 'old/utils'
import { ShopSelect } from 'old/components/layout/shop-select/shop-select'
import { HELP_CENTER_URL, PRIVACY_POLICY_URL } from 'app.constants'
import { isMenuEnable, isMenuVisible } from 'old/data'
import { useConfig } from 'old/api'
const { Text } = Typography

//menuItems:
const menuItems = [
    { title: 'flows'       , navTo: '/flow'           , getIcon: (fontSize: number, color: string) => <RetweetOutlined         style={{ fontSize, color }} />},
    { title: 'users'       , navTo: '/user'           , getIcon: (fontSize: number, color: string) => <TeamOutlined            style={{ fontSize, color }} />},
    { title: 'apps'        , navTo: '/app'            , getIcon: (fontSize: number, color: string) => <AppstoreAddOutlined     style={{ fontSize, color }} />},
    { title: 'developers'  , navTo: '/developers'     , getIcon: (fontSize: number, color: string) => <CodeOutlined            style={{ fontSize, color }} />},
    { title: 'tokens'      , navTo: '/token'          , getIcon: (fontSize: number, color: string) => <SecurityScanOutlined    style={{ fontSize, color }} />},
    { title: 'settings'    , navTo: '/settings'       , getIcon: (fontSize: number, color: string) => <SettingOutlined         style={{ fontSize, color }} />},
    { title: 'profile'     , navTo: '/profile'        , getIcon: (fontSize: number, color: string) => <UserOutlined            style={{ fontSize, color }} />},
    { title: 'help'        , redTo: HELP_CENTER_URL   , getIcon: (fontSize: number, color: string) => <QuestionCircleOutlined  style={{ fontSize, color }} />},
    { title: 'privacy'     , redTo: PRIVACY_POLICY_URL, getIcon: (fontSize: number, color: string) => <FileTextOutlined        style={{ fontSize, color }} />}
]

type Props = {
    onClose: () => void
}

export const DrawerMenu = ({ onClose }: Props) => {

    //hooks:
    const { token: { fontSize, paddingXS, paddingSM, padding, marginXS, marginSM, marginLG }} = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { logout } = useAuthentication()
    const navigate = useNavigate()

    return (
        <VerticalContainer style={{ minHeight: '100%', justifyContent: 'space-between' }}>

            <VerticalContainer style={{ gap: 0 }}>

            <HorizontalContainer style={{
                padding: SM ? `${paddingSM}px ${padding}px 0 0` : `${paddingXS}px ${paddingXS}px 0 0`,
                justifyContent: 'flex-end',
                marginBottom: SM ? marginXS : -marginLG
            }}>
                <Button
                    size='large'
                    shape='circle'
                    type='text'
                    icon={<CloseOutlined style={{ fontSize }}/>}
                    onClick={onClose}
                />
            </HorizontalContainer>

            <VerticalContainer style={{ gap: marginSM }}>

                <ShopSelect listLength={SM ? 5 : 3}/>

                <HorizontalLine/>

                <VerticalContainer>

                    {/* menu */}
                    {menuItems.map(item => (
                        <MenuItem
                            key={item.title}
                            title={__(item.title)}
                            navTo={item.navTo}
                            getIcon={item.getIcon}
                            onSelect={() => {
                                if(item.navTo){
                                    navigate(item.navTo)
                                    onClose()
                                }
                                if(item.redTo){
                                    window.location.replace(item.redTo)
                                }
                            }}
                        />
                    ))}

                    {/* logout */}
                    <MenuItem
                        title={__`logout`}
                        getIcon={
                            (fontSize: number, color: string) =>
                                <LogoutOutlined style={{ fontSize, color }}/>
                        }
                        onSelect={() => logout()}
                    />

                </VerticalContainer>

            </VerticalContainer>

            </VerticalContainer>

            {/* footer */}
            {!SM && <Footer/>}

        </VerticalContainer>
    )

}

type MenuItemProps = {
    title: string
    navTo?: string
    getIcon: (fontSize: number, color: string) => ReactNode
    onSelect: () => void
}

const MenuItem = ({ title, navTo, getIcon, onSelect }: MenuItemProps) => {

    //hooks:
    const { token: {
        fontSizeLG,
        paddingXXS,
        paddingXL,
        margin,
        colorText
    }} = theme.useToken()
    const { data: config } = useConfig()

    return config && isMenuVisible(navTo ?? '', config) ? (
        <Button
            type='text'
            size='large'
            style={{
                height: 43,
                padding: `${paddingXXS}px ${paddingXL}px`,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRadius: 0,
                gap: margin
            }}
            disabled={!isMenuEnable(navTo ?? '', config)}
            onClick={onSelect}
        >
            {getIcon(fontSizeLG, colorText)}
            <Text>
                {title}
            </Text>
        </Button>
    ) : <></>
    
}
