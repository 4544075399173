import { ButtonsWrapper, LoadingContainer, MainWrapper } from './confirm-message.style'
import { Button, Checkbox, Typography, theme } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { isNil } from 'utils'
import { useState } from 'react'
const  { Text, Paragraph } = Typography

export type ConfirmMessageProps = {
    title?: string
    message: string
    errorMessage: string
    checkboxText?: string
    checkboxPreselect?: boolean
    backgroundColor: string
    confirmText: string
    declineText: string
    isDanger?: boolean
    status: 'normal' | 'loading' | 'error'
    onConfirm: (isChecked: boolean) => void
    onDecline: () => void
}

export const ConfirmMessage = ({
    title,
    message,
    errorMessage,
    checkboxText,
    checkboxPreselect = false,
    backgroundColor,
    isDanger = false,
    confirmText,
    declineText,
    status,
    onConfirm,
    onDecline
}: ConfirmMessageProps) => {

    //theme:
    const { token: { margin, marginXS, marginXXS } } = theme.useToken()

    //status:
    const [ isChecked, setIsChecked ] = useState(isNil(checkboxText) ? false : checkboxPreselect)

    return (
        <MainWrapper backgroundColor={backgroundColor}>

            {/* title */}
            {isNil(title) ? null : <Text style={{ fontFamily: 'inter-medium', marginBottom: marginXS }}>{title}</Text>}

            {/* message */}
            <Paragraph>{message}</Paragraph>

            {/* checkbox */}
            {isNil(checkboxText) ? null :
                <Checkbox
                    checked={isChecked}
                    disabled={status === 'loading'}
                    style={{ marginBottom: margin }}
                    onChange={e => setIsChecked(e.target.checked)}
                >
                    {checkboxText}
                </Checkbox>
            }

            {/* error message */}
            {status !== 'error' ? null : <Paragraph type='danger'>{errorMessage}</Paragraph>}

            <ButtonsWrapper>

                {/* decline button */}
                <Button
                    type='text'
                    disabled={status === 'loading'}
                    onClick={onDecline}
                >
                    {declineText}
                </Button>

                {/* confirm button */}
                <Button
                    type='primary'
                    danger={isDanger}
                    style={{
                        marginLeft: marginXXS,
                        position: 'relative'
                    }}
                    onClick={() => status !== 'loading' && onConfirm(isChecked)}
                >
                    {confirmText}
                    {status === 'loading' &&
                        <LoadingContainer isDanger={isDanger}>
                            <LoadingOutlined/>
                        </LoadingContainer>
                    }
                </Button>

            </ButtonsWrapper>
            
        </MainWrapper>
    )

}
