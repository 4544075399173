import { useEffect, useState } from 'react'
import { useConfig, useCreateLocation, useLocation, useLocations, useUpdateLocation } from 'api'
import { Location, LocationFormSchema } from 'types'
import { Loading, PageContentWrapper, PageError, VerticalContainer } from 'components/styled'
import { CloudUploadOutlined } from '@ant-design/icons'
import { browserTimezone, getShopId, queryClient, useTranslate, useWindowDimensions, validateFormValues } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { message, theme } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { Form } from 'react-final-form'
import { canCreateLocation } from '../location-list/location-list'
import { LocationInfo } from '../location-info/location-info'
import { LocationBusinessAddress } from '../location-address/location-address'
import { LocationStatus } from 'components/location/location-status/location-status'

export const LocationForm = () => {
    const params = useParams()
    return params.id ? <EditLocation id={params.id}/> : <CreateLocation/>
}

const CreateLocation = () => {

    //hooks:
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const navigate = useNavigate()

    //api:
    const { data: locations, total, isLoading: isLocationsLoading } = useLocations(null, 15)

    //redirect if reached max limit:
    useEffect(() => {
        if(config && locations){
            if(!canCreateLocation(config, true, total)){
                navigate('/location')
            }
        }
    }, [config, locations])

    return (
        (isConfigLoading || isLocationsLoading) ? <Loading/> :
        (!config || !locations) ? <PageError/> :
        <LocationFormController location={null}/>
    )

}

type EditLocationParams = { id: string }
const EditLocation = ({ id }: EditLocationParams) => {

    //hooks:
    const navigate = useNavigate()
    const { data: location, isLoading } = useLocation(id, () => navigate('/location'))

    //redirect if not found:
    useEffect(() => {
        if(location === null || location === false){
            navigate('/location')
        }
    }, [isLoading])

    return isLoading ? <Loading/> : !location ? <PageError/> : <LocationFormController location={location}/>

}

type LocationControllerProps = { location: Location | null }
export const LocationFormController = ({ location }: LocationControllerProps) => {

    const isCreate = !location

    //hooks:
    const { token: { padding, margin, marginLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ initialValues, setInitialValues ] = useState(location ?? getDefaultValues())
    const [ savingValues , setSavingValues  ] = useState(location ?? getDefaultValues())

    //api:
    const { mutate: createLocation, isLoading: isCreateLoading, data: createResponse } = useCreateLocation(savingValues)
    const { mutate: updateLocation, isLoading: isUpdateLoading, data: updateResponse } = useUpdateLocation(savingValues)

    const create = (values: Location) => {
        setSavingValues(values)
        createLocation()
    }
    const update = (values: Location) => {
        setSavingValues(values)
        updateLocation()
    }

    //after create:
    useEffect(() => {
        if(createResponse === true){
            queryClient.invalidateQueries(['locations', getShopId()])
            message.success(__`location_added`)
            navigate('/location')
        }else if(updateResponse === 'name_is_used'){
            message.error(__`location_names_have_to_be_unique`)
        }
    }, [isCreateLoading])

    //after update:
    useEffect(() => {
        if(updateResponse === true){
            setInitialValues({ ...savingValues })
            queryClient.invalidateQueries(['location', savingValues.id])
            invalidateRelatedCache()
            message.success(__`location_updated`)
        }else if(updateResponse === 'name_is_used'){
            message.error(__`location_names_have_to_be_unique`)
        }
    }, [isUpdateLoading])

    const invalidateRelatedCache = () => {
        queryClient.invalidateQueries(['locations', getShopId()])
        queryClient.invalidateQueries(['services' , getShopId()])
        queryClient.resetQueries(['service'])
    }

    return (
        <PageContentWrapper>

            <Form
                initialValues={initialValues}
                subscription={{ dirty: true }}
                validate={validateFormValues(LocationFormSchema(__))}
                onSubmit={values => (
                    LocationFormSchema(__).validate(values).then(
                        data => isCreate ? create(data as Location) : update(data as Location)
                    )
                )}
                render={({ form, dirty, handleSubmit }) => (

                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={isCreate ? __`add_new_location` : initialValues.name}
                            description={__`manage_locations_where_bookings_are_being_offered`}
                            primaryButton={{
                                name: isCreate ? __`add` : __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: (isCreateLoading || isUpdateLoading) ? 'loading' : dirty ? 'normal': 'disabled',
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: (isCreateLoading || isUpdateLoading) ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: () => navigate('/location')
                            }}
                            isFormDirty={dirty}
                        />
                        
                        <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: marginLG }}>
                
                            {/* state */}
                            <LocationStatus/>

                            {/* info */}
                            <LocationInfo/>

                            {/* business address */}
                            <LocationBusinessAddress/>

                        </VerticalContainer>

                    </VerticalContainer>

                )}
            />
            
        </PageContentWrapper>
    )
    
}

const getDefaultValues = (): Location => ({

    id: '',
    name: '',
    description: null,
    phone: null,
    email: null,
    image: {
        id: null,
        path: null
    },

    timezone: browserTimezone(),

    businessAddress: {
        country: '',
        address: '',
        suite: null,
        city: '',
        state: null,
        zipCode: '',
        latitude: 0,
        longitude: 0
    },
    
    isDefault: false,
    status: true

})
