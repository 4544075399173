import styled from 'styled-components'

type ResourcesWrapperProps = {
    isEmpty: boolean
}

export const ResourcesWrapper = styled.div<ResourcesWrapperProps>`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${props => props.isEmpty ? 'center' : 'flex-start'};
    gap: ${props => `${props.theme.marginXS}px`};
`
