import { Button, theme } from 'antd'
import { getShopId, setUnavailableCache, useTranslate, useWindowDimensions }  from 'old/utils'
import { HorizontalContainer, Label } from 'old/components/styled'
import { Avatar } from 'old/components/avatar/avatar'
import { Flow, getFlowIcon }  from 'old/types'
import { useNavigate } from 'react-router-dom'

type Props = {
    flow: Flow
}

export const FlowItem = ({ flow }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //navigation:
    const navigate = useNavigate()

    //theme:
    const { token: { fontSize, colorBgSpotlight, paddingXXS, paddingXS, padding, borderRadius } } = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //render:
    return (
        <Button
            type='text'
            style={{
                width: '100%',
                height: 'auto',
                padding: SM ? `${paddingXXS}px ${paddingXS}px ${paddingXXS}px 0` : `${paddingXXS}px ${padding}px ${paddingXXS}px ${paddingXS}px`,
                borderRadius: SM ? borderRadius : 0
            }}
            onClick={() => {
                setUnavailableCache(['flow', flow.id, getShopId()], flow)
                navigate(`/flow/${flow.id}`)}
            }
        >

            <HorizontalContainer style={{ width: '100%' }}>

                {/* image and title */}
                <Avatar
                    name={flow.name}
                    description={__(flow.trigger.toLowerCase())}
                    image={<div style={{ color: colorBgSpotlight, fontSize }}>{getFlowIcon(flow.trigger)}</div>}
                    size='small'
                    style={{ padding: 0 }}
                />

                {/* status */}
                <Label
                    type={flow.status ? 'success' : 'disable'}
                    text={flow.status ? __`enabled` : __`disabled` }
                />

            </HorizontalContainer>

        </Button>
    )
    
}
