export const ENV = {

    //local:
    'localhost': {
        REST_API_BASE_URL: 'https://api.sesami.dev/api/v1',
        GRAPHQL_API_BASE_URL: 'https://api.sesami.dev/graphql',
        GOOGLE_LOGIN_ENDPOINT: 'https://api.sesami.dev/api/v1/users/login/google',
        GOOGLE_SYNC_ENDPOINT: 'https://api.sesami.dev/api/v1/users/sync/google',
        AVATAR_UPLOAD_URL: 'https://api.sesami.dev/api/v1/uploads',
        CDN_URL: 'https://cdn.sesami.dev'
    },

    //stage:
    'admin.sesami.dev': {
        REST_API_BASE_URL: 'https://api.sesami.dev/api/v1',
        GRAPHQL_API_BASE_URL: 'https://api.sesami.dev/graphql',
        GOOGLE_LOGIN_ENDPOINT: 'https://api.sesami.dev/api/v1/users/login/google',
        GOOGLE_SYNC_ENDPOINT: 'https://api.sesami.dev/api/v1/users/sync/google',
        AVATAR_UPLOAD_URL: 'https://api.sesami.dev/api/v1/uploads',
        CDN_URL: 'https://cdn.sesami.dev'
    },
    
    //production:
    'admin.sesami.co': {
        REST_API_BASE_URL: 'https://api.sesami.co/api/v1',
        GRAPHQL_API_BASE_URL: 'https://api.sesami.co/graphql',
        GOOGLE_LOGIN_ENDPOINT: 'https://api.sesami.co/api/v1/users/login/google',
        GOOGLE_SYNC_ENDPOINT: 'https://api.sesami.co/api/v1/users/sync/google',
        AVATAR_UPLOAD_URL: 'https://api.sesami.co/api/v1/uploads',
        CDN_URL: 'https://cdn.sesami.co'
    }

}

//url:
export const PRIVACY_POLICY_URL = 'https://sesami.co/privacy-policy/'
export const HELP_CENTER_URL = 'https://help.sesami.co/hc/en-us'
export const HOW_SETUP_ACCOUNT_URL = 'https://help.sesami.co/hc/en-us/articles/360037521993-How-do-I-set-up-my-Sesami-account-'

//validation:
export const MAX_IMAGE_SIZE = 5*1024
export const PASSWORD_MAX_LENGTH = 50
export const IMAGE_VALID_TYPES = 'image/png, image/jpeg, image/jpg'

//local storage keys:
export const REFRESH_TOKEN_LCL_STR_KEY = 'refreshToken'
export const ACCESS_TOKEN_LCL_STR_KEY = 'accessToken'
export const ADMIN_VERSION_LCL_STR_KEY = 'adminVersion'
export const SHOP_ID_LCL_STR_KEY = 'shopId'
export const LOCATION_ID_LCL_STR_KEY = 'locationId'
export const CALENDAR_TEAM_MEMBER_LCL_STR_KEY = 'calendarResources'
export const CALENDAR_LCL_STR_SCALE_KEY = 'calendarScale'
export const BOOKING_SCALE_LCL_STR_KEY = 'bookingScale'

//scale:
export const CALENDAR_DEFAULT_SCALE = 2
export const BOOKING_DEFAULT_SCALE = 2

//cache:
export const CACHE_REFETCH_INTERVAL = 2*60*1000
export const CACHE_DEFAULT_STALE_TIME = 1*60*1000
export const USER_LOGIN_STRATEGIES_STALE_TIME = 0
export const CALENDAR_SLOTS_CACHE_STALE_TIME = 0
export const BOOKING_SLOTS_CACHE_STALE_TIME = 0
export const APPOINTMENTS_CACHE_STALE_TIME = 0
export const APPOINTMENT_CACHE_STALE_TIME = 0
export const RESERVATION_CACHE_STALE_TIME = 0

//error:
export const CONNECTION_ERROR = 'Connection error, please try again...'
export const OOPS_ERROR = 'Oops! something went wrong.'
export const FORBIDDEN_ERROR = 'Forbidden access denied.'

//other:
export const ACCESS_TOKEN_REFRESHING_TIMEOUT = 60*1000
export const DEBOUNCING_TIMEOUT = 300
