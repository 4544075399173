import { SettingsForm } from './settings-form'
import { useEffect, useState } from 'react'
import { useSettings, useUpdateSettings } from 'api'
import { Settings, SettingsFormSchema } from 'types'
import { Loading, PageError } from 'components/styled'
import { getShopId, queryClient, useTranslate, validateFormValues } from 'utils'
import { message } from 'antd'

export const SettingsPage = () => {

    //hooks:
    const { data: settings, isLoading: isSettingsLoading } = useSettings()
    const shopId = getShopId()

    return isSettingsLoading ? <Loading/> : (!shopId || !settings) ? <PageError/> : (
        <SettingsContent shopId={shopId} settings={settings}/>
    )

}

type ContentProps = {
    shopId: string
    settings: Settings
}

export const SettingsContent = ({ shopId, settings }: ContentProps) => {
    
    //hooks:
    const { __ } = useTranslate()

    //state:
    const [ initialValues, setInitialValues ] = useState(settings)
    const [ savingValue, setSavingValue ] = useState(settings)

    //update the form after shop switch:
    useEffect(() => {
        if(settings){
            setInitialValues(settings)
        }
    }, [settings])

    //mutation:
    const { mutate, isLoading, data: response } = useUpdateSettings(shopId, savingValue)

    //save:
    const save = (values: Settings) => {
        setSavingValue(values)
        mutate()
    }

    //after save:
    useEffect(() => {
        if(response){
            setInitialValues(savingValue)
            queryClient.invalidateQueries(['settings', shopId])
            message.success(__`settings_updated`)
        }
    }, [response])

    return (
        <SettingsForm
            initialValues={initialValues}
            isSaving={isLoading}
            onValidate={validateFormValues(SettingsFormSchema)}
            onSave={values => SettingsFormSchema.validate(values).then(
                data => save(data as Settings)
            )}
        />
    )
    
}
