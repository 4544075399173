import { Button, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { getShopId, setCacheManually, useTranslate, useWindowDimensions } from 'utils'
import { HorizontalContainer, Label } from 'components/styled'
import { Location } from 'types'
import { useNavigate } from 'react-router-dom'

type Props = {
    location: Location
}

export const LocationItem = ({ location }: Props) => {

    //hooks:
    const { token: { paddingXXS, paddingXS, paddingSM, padding, borderRadius } } = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const { SM } = useWindowDimensions()

    return (
        <Button
            type='text'
            style={{
                width: '100%',
                height: 'auto',
                padding: SM ? `0 ${paddingXS}px 0 0` : `0 ${padding}px 0 ${paddingSM}px`,
                borderRadius: SM ? borderRadius : 0
            }}
            onClick={() => {
                setCacheManually(['location', location.id, getShopId()], location)
                navigate(`/location/${location.id}`)
            }}
        >

            <HorizontalContainer style={{ width: '100%' }}>

                {/* image and title */}
                <Avatar
                    name={location.name}
                    image={location.image.path}
                    description={location.description}
                    style={{ paddingLeft: paddingXXS }}
                />

                {/* labels */}
                <HorizontalContainer style={{ gap: paddingXS }}>
                    
                    {location.isDefault && <Label
                        type='info'
                        text={__`default`}
                    />}

                    <Label
                        type={location.status ? 'success' : 'disable'}
                        text={location.status ? __`enabled`: __`disabled`}
                    />

                </HorizontalContainer>

            </HorizontalContainer>

        </Button>
    )
    
}
