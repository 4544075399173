import { LoadingContainer } from './resource-google-sync.style'
import { FormSection } from 'components/form-section/form-section'
import { TranslateFunction, queryClient, useTranslate } from 'utils'
import { CalendarSyncStatus, Feature } from 'types'
import { Alert, Button, Input, InputRef, Typography, theme } from 'antd'
import { HorizontalContainer, Loading, Message, VerticalContainer } from 'components/styled'
import { useEffect, useRef, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useField } from 'react-final-form'
import { useConfig, useResourceCalendarInvitation, useResourceCalendarRevoke } from 'api'
const { Paragraph, Text } = Typography

type Props = {
    dirty: boolean
}

export const ResourceGoogleSync = ({ dirty }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { colorBorder, borderRadius, paddingXS, paddingSM, colorPrimary, marginXS } } = theme.useToken()
    const { data: config } = useConfig()
    const confirmInput = useRef<InputRef>(null)

    //fields:
    const { input: { value: resourceId } } = useField('id')
    const { input: { value: email } } = useField('email')
    const { input: { value: calendarSync } } = useField('calendarSync')

    //state:
    const [ isConfirmVisible, setIsConfirmVisible ] = useState(false)
    const [ confirmationValue, setConfirmationValue ] = useState('')
    const [ canUseCalendarSync, setCanUseCalendarSync ] = useState(calendarSync.canUseCalendarSync as boolean)
    const [ calendarSyncStatus, setCalendarSyncStatus ] = useState(calendarSync.calendarSyncStatus as CalendarSyncStatus)

    //invite:
    const { mutate: invite, isLoading: isInviteLoading, data: inviteResult } = useResourceCalendarInvitation(resourceId)
    
    //revoke:
    const { mutate: revoke, isLoading: isRevokeLoading, data: revokeResult } = useResourceCalendarRevoke(resourceId)

    //handleClick:
    const handleClick = (calendarSyncStatus: CalendarSyncStatus) => {
        switch(calendarSyncStatus){
            case 'Active':
            case 'Pending':
                if(!isConfirmVisible){
                    setIsConfirmVisible(true)
                    setTimeout(() => confirmInput.current?.focus(), 300)
                }else{
                    if(confirmationValue.toLowerCase() === __`revoke`.toLowerCase()){
                        revoke()
                        setConfirmationValue('')
                    }else{
                        confirmInput.current?.focus()
                    }
                }
                break
            case null:
                invite()
                break
        }
    }

    //invite response:
    useEffect(() => {
        if(inviteResult){
            // updateFormCalendarSync({
            //     ...calendarSync,
            //     calendarSyncStatus: 'Pending'
            // })
            setCalendarSyncStatus('Pending')
            queryClient.invalidateQueries(['resource', resourceId])
        }
    } , [isInviteLoading])
    
    //revoke response:
    useEffect(() => {
        if(revokeResult){
            // updateFormCalendarSync({
            //     ...calendarSync,
            //     calendarSyncStatus: null
            // })
            setCalendarSyncStatus(null)
            setIsConfirmVisible(false)
            queryClient.invalidateQueries(['resource', resourceId])
        }
    } , [isRevokeLoading])

    //if form updated:
    useEffect(() => {
        setCanUseCalendarSync(calendarSync.canUseCalendarSync)
        setCalendarSyncStatus(calendarSync.calendarSyncStatus)
    }, [calendarSync])

    //haveCalendarSync: should be able to revoke, even after changing the plan
    const haveCalendarSync = calendarSyncStatus ? true : (config && config.features.includes(Feature.CALENDAR_SYNC))

    return (
        <FormSection title={__`google_calendar_sync`}>

            {calendarSync === '' ? <Loading/> : (
            
            <Alert
                message={getTitle(calendarSyncStatus, __)}
                type={getAlertType(calendarSyncStatus)}
                style={{ marginBottom: marginXS }}
                description={

                    <VerticalContainer>

                        {/* message */}
                        <Paragraph style={{ margin: 0 }}>
                            {getMessage(calendarSyncStatus, __)}
                        </Paragraph>

                        {/* description */}
                        <Paragraph
                            style={{
                                border: `solid 1px ${colorBorder}`,
                                padding: `${paddingXS}px ${paddingSM}px`,
                                borderRadius: borderRadius
                            }}
                        >
                            {getNote(email, dirty, canUseCalendarSync, calendarSyncStatus, __)}
                        </Paragraph>

                        {/* confirm */}
                        {isConfirmVisible &&

                            <VerticalContainer style={{ marginBottom: marginXS }}>
                                
                                <Paragraph style={{ margin: 0 }}>{__`please_type_revoke_to_confirm`}</Paragraph>

                                <Input
                                    placeholder={__`revoke`}
                                    defaultValue={confirmationValue}
                                    style={{ backgroundColor: 'transparent' }}
                                    onChange={e => setConfirmationValue(e.target.value)}
                                    onKeyDown={e => e.key === 'Enter' && handleClick(calendarSyncStatus)}
                                    ref={confirmInput}
                                />

                            </VerticalContainer>
                        }

                        {/* buttons */}
                        {isButtonVisible(email, dirty, canUseCalendarSync, calendarSyncStatus) &&

                            <HorizontalContainer style={{ justifyContent: 'flex-start' }}>

                                {/* main button */}
                                <Button
                                    type={getButtonType(calendarSyncStatus)}
                                    size='large'
                                    style={{
                                        width: 'fit-content',
                                        background: getButtonBackground(calendarSyncStatus, !haveCalendarSync, colorPrimary)
                                    }}
                                    disabled={!haveCalendarSync}
                                    onClick={() => handleClick(calendarSyncStatus)}
                                >

                                    <Text style={{ color: (isInviteLoading || isRevokeLoading) ? 'transparent' : 'unset' }}>
                                        {getButtonText(calendarSyncStatus, __)}
                                    </Text>

                                    {(isInviteLoading || isRevokeLoading) &&
                                        <LoadingContainer>
                                            <LoadingOutlined/>
                                        </LoadingContainer>
                                    }

                                </Button>
                                
                                {/* decline button */}
                                {isConfirmVisible &&
                                    <Button
                                        type='text'
                                        onClick={() => setIsConfirmVisible(false)}
                                    >
                                        <Text>{__`decline`}</Text>
                                    </Button>
                                }

                                {!haveCalendarSync && <Message type='warning' message={__`this_feature_is_not_available_due_to_your_plan`}/>}

                            </HorizontalContainer>

                        }

                    </VerticalContainer>

                }

            />
            
            )}

        </FormSection>
    )

}

//getTitle:
const getTitle = (calendarSyncStatus: CalendarSyncStatus, __: TranslateFunction) => {
    switch(calendarSyncStatus){
        case 'Active':
            return __`google_calendar_sync_is_enabled`
        case 'Pending':
            return __`linking_google_calendar_is_pending`
        case null:
            return __`sync_your_events_to_calendar`
    }
}

//getMessage:
const getMessage = (calendarSyncStatus: CalendarSyncStatus, __: TranslateFunction) => {
    switch(calendarSyncStatus){
        case 'Active':
            return __`you_can_disconnect_sesami_from_the_resources_calendar_by_selecting_revoke_button`
        case 'Pending':
            return __`an_email_has_been_sent_to_the_resources_email`
        case null:
            return __`you_can_sync_sesami_resource_calendars_to_google_calendar`
    }
}

//getNote:
const getNote = (
    email: string,
    dirty: boolean,
    canUseCalendarSync: boolean,
    calendarSyncStatus: CalendarSyncStatus,
    __: TranslateFunction
) => {
    switch(calendarSyncStatus){
        case 'Active':
            return __`note_if_you_revoke_the_sync_events_from_google_calendar_will_no_longer_affect_availabilities`
        case 'Pending':
            return __`to_cancel_this_request_click_on_revoke`
        case null:
            if(!canUseCalendarSync || email === '' || dirty){
                return __`please_enter_and_save_a_unique_email_address_connected_to_google_calendar`
            }else{
                return __`note_this_will_enable_your_resources_to_see_their_appointments`
            }
    }
}

//getAlertType:
const getAlertType = (calendarSyncStatus: CalendarSyncStatus): 'error' | 'info' | 'warning' | 'success' => {
    switch(calendarSyncStatus){
        case 'Active':
            return 'success'
        case 'Pending':
        case null:
            return 'info'
    }
}

//isButtonVisible:
const isButtonVisible = (email: string, dirty: boolean, canUseCalendarSync: boolean, calendarSyncStatus: CalendarSyncStatus) =>
    calendarSyncStatus !== null || (canUseCalendarSync && email !== '' && !dirty)

//getButtonType:
const getButtonType = (calendarSyncStatus: CalendarSyncStatus) => {
    switch(calendarSyncStatus){
        case 'Active':
        case 'Pending':
            return 'default'
        case null:
            return 'primary'
    }
}

//getButtonType:
const getButtonBackground = (calendarSyncStatus: CalendarSyncStatus, disabled: boolean, primaryColor: string) => {
    switch(calendarSyncStatus){
        case 'Active':
        case 'Pending':
            return 'transparent'
        case null:
            return disabled ? 'transparent' : primaryColor
    }
}

//getButtonText:
const getButtonText = (calendarSyncStatus: CalendarSyncStatus, __: TranslateFunction) => {
    switch(calendarSyncStatus){
        case 'Active':
        case 'Pending':
            return __`revoke`
        case null:
            return __`send_calendar_invite`
    }
}
