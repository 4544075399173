import { PageHeader } from 'old/components/page-header/page-header'
import { theme } from 'antd'
import { setUnavailableCache, useTranslate, useWindowDimensions }  from 'old/utils'
import { PageContentWrapper, VerticalContainer } from 'old/components/styled'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useConfig, useInstalledApps } from 'old/api'
import { Feature }  from 'old/types'
import { FormSection } from 'old/components/form-section/form-section'
import { VerticalPaginatedList } from 'old/components/vertical-paginated-list/vertical-paginated-list'
import { InstalledAppItem } from 'old/components/app/installed-app-item/installed-app-item'

export const InstalledAppsList = () => {

    //hooks:
    const { token: { padding, paddingMD, marginXXS, margin } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { data: config } = useConfig()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ search, setSearch ] = useState(null as string | null)

    //installed apps:
    const {
        data: apps,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useInstalledApps(search, 15)

    //if apps are not enabled:
    useEffect(() => { config && !config.features.includes(Feature.APPS) && navigate('/') }, [config])
    
    return (!config || !config.features.includes(Feature.APPS)) ? <></> : (
        
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`apps`}
                    description={__`manage_all_your_apps`}
                    primaryButton={{
                        name: __`install_app`,
                        icon: <PlusOutlined/>,
                        onClick: () => navigate('/app/all')
                    }}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>

                    <FormSection
                        title={SM ? __`installed_apps` : undefined }
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        searchInput={{
                            placeholder: __`search_apps`,
                            onSearch: setSearch
                        }}
                    >

                        {/* list */}
                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={apps === null}
                            isError={false}
                            notFound={(apps && apps.length === 0) ?? false}
                            totalPage={totalPage}
                            pageNum={pageNum}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            
                            {apps && apps.map(app => (
                                <InstalledAppItem
                                    key={app.id}
                                    app={app}
                                    onClick={() => {
                                        setUnavailableCache(['app', app.id], app)
                                        navigate(`/app/${app.id}`)
                                    }}
                                />
                            ))}

                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}
