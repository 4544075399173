import { useCurrentShop } from './shop.api'
import { ExternalCustomer } from 'types'
import { usePaginationHandler, getShopId } from 'utils'

//get external customers:
export const useExternalCustomers = (search: string | null, limit: number) => {

    //shop id:
    const shopId = getShopId()

    return usePaginationHandler<ExternalCustomer[]>({
        query: `
            query getCustomers (
                
                $shop: String!,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String

            ) {
                getCustomers(

                    shop: $shop,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before

                ) {
                
                    id
                    firstName
                    lastName
                    email
                    phone

                    image {
                        id
                        path
                    }

                }
            }
        `,
        variables: {
            shop: shopId,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['customers', shopId],
        limit,
        getResponse: json => json.data.getCustomers,
        onSuccess: response => response
    })

}

//customer url:
export const useCustomerURL = () => {
    const { data: shop } = useCurrentShop()
    return (shop && shop?.url) ? `https://${shop?.url}/customers/new` : undefined
}
