import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Slider, theme } from 'antd'
import { HorizontalLine, VerticalContainer } from 'old/components/styled'
import { objectAssign, useWindowDimensions } from 'old/utils'

const scales = [
    { slider: 0, value: .5 , label: '25%' },
    { slider: 1, value: 1  , label: '50%' },
    { slider: 2, value: 1.5, label: '75%' },
    { slider: 3, value: 2  , label: '100%'},
    { slider: 4, value: 2.5, label: '125%'},
    { slider: 5, value: 3  , label: '150%'},
    { slider: 6, value: 4  , label: '200%'}
]

type Props = {
    scale: number
    style?: object
    onScaleChange: (scale: number) => void
}

export const Scale = ({ scale, style, onScaleChange }: Props) => {

    //theme:
    const { token: {
        paddingXXS,
        paddingXS,
        padding,
        marginXXS,
        fontSizeSM,
        colorPrimary,
        colorBgContainer,
        colorBorderSecondary
    }} = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //calcScaleSliderDefaultValue:
    const calcScaleSliderDefaultValue = () => {
        const scaleOnj = scales.filter(scl => scl.value === scale)
        return scaleOnj.length > 0 ? scaleOnj[0].slider : 3
    }
    
    //render:
    return (
        <VerticalContainer style={{ backgroundColor: colorBgContainer, alignItems: 'flex-end', gap: 0 }}>
            
            <HorizontalLine style={{ borderColor: colorBorderSecondary }}/>

            <div style={objectAssign({
                width: '100%',
                maxWidth: 240,
                // preventing the CSS conflict on re-rendering by not using the shortened names
                paddingTop: SM ? paddingXS : paddingXXS,
                paddingBottom: SM ? paddingXS : paddingXXS,
                paddingLeft: padding,
                paddingRight: padding,
                display: 'flex',
                gap: marginXXS
            }, style)}>

                <MinusOutlined style={{ fontSize: fontSizeSM, color: colorPrimary }}/>
                
                <Slider
                    min={0}
                    max={6}
                    step={1}
                    onChange={val => onScaleChange(scales[val].value)}
                    defaultValue={calcScaleSliderDefaultValue()}
                    style={{ width: '100%' }}
                    styles={{ track: { background: 'none' } }}
                    tooltip={{
                        formatter: val => scales[Number(val)].label
                    }}
                />

                <PlusOutlined style={{ fontSize: fontSizeSM, color: colorPrimary }}/>

            </div>

        </VerticalContainer>
    )
    
}
