import { FlexSpacer, MainContainer, MainWrapper } from '../appointment-details/appointment-details.style'
import { AppointmentDetailNavigation } from '../appointment-details-navigation/appointment-details-navigation'
import { AppointmentDetailsHeader } from '../appointment-details-header/appointment-details-header'
import { AppointmentDetailsCustomer } from '../appointment-details-customer/appointment-details-customer'
import { useConfig, useReservation } from 'api'
import { useTranslate, useWindowDimensions } from 'utils'
import { theme } from 'antd'
import { ColumnContainer, Loading, PageError } from 'components/styled'
import { getDateTimeRange } from 'components/calendar/slot-details/slot-details'

type Props = {
    reservationId: string
    onClose: () => void
}

export const ReservationDetails = ({ reservationId, onClose }: Props) => {

    //hooks:
    const { token: { paddingXXS, paddingXS, marginXXS, marginMD, }} = theme.useToken()
    const { data: config } = useConfig()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    
    //reservation:
    const { data: reservation, isLoading: isReservationLoading } = useReservation(reservationId)

    return config ? (
    
        isReservationLoading ? <Loading/> : (
    
            !reservation ? <PageError/> : (

                <MainWrapper style={{ paddingBottom: SM ? 0 : `${paddingXXS}px` }}>

                    <MainContainer>

                        <div style={{ padding: `0 ${SM ? paddingXS : 0}px` }}>

                            {/* navigation */}
                            <AppointmentDetailNavigation onClose={onClose}/>

                            {/* header */}
                            <ColumnContainer marginTop={marginXXS}>
                                <AppointmentDetailsHeader
                                    title={reservation.service?.title ?? __`deleted_service`}
                                    description={getDateTimeRange(reservation.dateTime, reservation.duration, config)}
                                    tag={__('duration_min', { duration: reservation.duration })}
                                />
                            </ColumnContainer>

                            {/* customer */}
                            <ColumnContainer marginTop={marginMD}>
                                <AppointmentDetailsCustomer customer={null} isReserved={true}/>
                            </ColumnContainer>

                        </div>

                        {/* spacer to force the events button to move to the bottom if there is space */}
                        <FlexSpacer/>
                    
                    </MainContainer>

                </MainWrapper>

            )
        )
    ) : <></>

}
