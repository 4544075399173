import styled from 'styled-components'
import { Config }  from 'old/types'
import { useNow, useTranslate }  from 'old/utils'
import { CALENDAR_PAGE_CURRENT_BADGE_Z_INDEX } from 'z-indexes'

type Props = {
    dayStartHour: number
    timezoneOffset: number
    scale: number
    config: Config
}

export const CalendarPageCurrentBadge = ({ dayStartHour, timezoneOffset, scale, config }: Props) => {

    const { __ } = useTranslate()

    //now:
    const now = useNow(timezoneOffset)
    const nowHour = now.getHours()
    const nowMinute = now.getMinutes()

    //top:
    const top = ((nowHour - dayStartHour)*60 + nowMinute)*scale
    
    //render:
    return top >= 0 ? (
        <TimeLabel style={{ top: top }}>{
            now.toLocaleString([config.locale, 'en-US'], {
                hour: '2-digit',
                minute: '2-digit'
            }).replace(__`am`, '').replace(__`pm`, '') // there is not enough room for AM/PM !
        }</TimeLabel>
    ) : <></>
    
}

const TimeLabel = styled.div`
    width: 100%;
    max-width: 50px;
    height: ${props => `${props.theme.marginMD}px`};
    background-color: ${props => props.theme.colorError};
    border-radius: ${props => `${props.theme.borderRadiusSM}px`};
    font-family: 'inter-medium';
    font-size: ${props => `${props.theme.fontSizeSM}px`};
    color: ${props => props.theme.colorBgContainer};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    transform: translate(0%, -50%);
    z-index: ${CALENDAR_PAGE_CURRENT_BADGE_Z_INDEX};
`
