import { PageHeader } from 'components/page-header/page-header'
import { ExportOutlined } from '@ant-design/icons'
import { TranslateFunction, isNil, useTranslate, useWindowDimensions } from 'utils'
import { PageContentWrapper, VerticalContainer } from 'components/styled'
import { useEffect, useState } from 'react'
import { Alert, Button, Modal, Typography, message, theme } from 'antd'
import { AppointmentNavigation, AppointmentFilter, emptyAppointmentRange } from 'components/appointment/appointment-navigation/appointment-navigation'
import { FormSection } from 'components/form-section/form-section'
import { useGenerateReport, useConfig } from 'api'
import { AppointmentList } from 'components/appointment/appointment-list/appointment-list'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Feature } from 'types'
import { ResourceSelect } from '../../components/resource/resource-select/resource-select'
const { Text, Paragraph } = Typography

//getTitle:
const getTitle = (filter: AppointmentFilter, __: TranslateFunction) => {
    switch(filter){
        case AppointmentFilter.ALL:
            return __`all_appointments`
        case AppointmentFilter.UPCOMING:
            return __`upcoming_appointments`
        case AppointmentFilter.PAST:
            return __`past_appointments`
        case AppointmentFilter.DATE:
            return __`appointments_in_a_date`
        case AppointmentFilter.RANGE:
            return __`in_range_appointments`
    }
}

//getApiStart:
const getApiStart = (start: string | null) => {
    if(!isNil(start)){
        const date = new Date(`${start} 00:00:00:000`)
        return date.toISOString()
    }else{
        return null
    }
}

//getApiEnd:
const getApiEnd = (end: string | null) => {
    if(!isNil(end)){
        const date = new Date(`${end} 23:59:59:999`)
        return date.toISOString()
    }else{
        return null
    }
}

//getApiQuery:
const getApiQuery = (filter: AppointmentFilter) => filter === AppointmentFilter.DATE ? AppointmentFilter.RANGE : filter

export const AppointmentPage = () => {

    //hooks:
    const { token: { margin, paddingXS, paddingSM, padding, paddingLG, colorBorder, borderRadius } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //url params:
    const [ searchParams, setSearchParams ] = useSearchParams()
    const resourceId = searchParams.get('resourceId')

    //state:
    const [ isModalOpen, setIsModalOpen] = useState(false)
    const [ isExportSuccess, setIsExportSuccess] = useState(false)
    const [ filter, setFilter] = useState(AppointmentFilter.UPCOMING)
    const [ range, setRange] = useState(emptyAppointmentRange)
    const [ search, setSearch] = useState<string | null>(null)

    //useGenerateReport:
    const { mutate: generateReport, isLoading: isReportLoading, data: reportResponse } = useGenerateReport(
        resourceId,
        getApiQuery(filter),
        getApiStart(range.start),
        getApiEnd(range.end)
    )

    //handleModalOk:
    const handleModalOk = () => {
        if(!isReportLoading){
            if(isExportSuccess){
                setIsExportSuccess(false)
                setIsModalOpen(false)
            }else{
                generateReport()
            }
        }
    }

    //handleModalCancel:
    const handleModalCancel = () => {
        setIsExportSuccess(false)
        setIsModalOpen(false)
    }

    //handle the response:
    useEffect(() => {
        if(reportResponse){
            if(reportResponse.result === 'success'){
                setIsExportSuccess(true)
            }else{
                message.error(__(
                    'you_reached_the_limit_please_try_again_in_minutes_later',
                    { time: Math.ceil(reportResponse.payload / 60) }
                ))
            }
        }
    }, [reportResponse])

    return (
        (isConfigLoading || !config) ? null : (

            <PageContentWrapper>

                <VerticalContainer style={{ gap: margin }}>

                    {/* header */}
                    <PageHeader
                        title={__`appointments`}
                        description={__`view_or_export_appointments`}
                        backButton={{
                            name: __`back`,
                            onClick: () => navigate('/')
                        }}
                    />

                    <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: paddingLG }}>

                        {/* resources */}
                        <ResourceSelect
                            selectedId={resourceId}
                            showAll={true}
                            onSelect={resource => setSearchParams(resource?.id ? { resourceId: resource?.id } : undefined )}
                        />

                        <FormSection
                            title={getTitle(filter, __)}
                            topButton={config.features.includes(Feature.APPOINTMENT_REPORT) ? {
                                label: __`export`,
                                icon: <ExportOutlined/>,
                                onClick: () => setIsModalOpen(true)
                            }: undefined}
                            style={{ paddingBottom: padding }}
                            childrenWrapperStyle={{ padding: 0 }}
                        >

                            <VerticalContainer style={{ gap: margin }}>

                                {/* navigation */}
                                <div style={{ padding: SM ? `0 ${paddingLG}px` : `0 ${padding}px`}}>
                                    <AppointmentNavigation
                                        filter={filter}
                                        range={range}
                                        onQueryChange={setFilter}
                                        onRangeChange={setRange}
                                        onSearchChange={setSearch}
                                    />
                                </div>

                                {/* list */}
                                <AppointmentList
                                    resourceId={resourceId}
                                    search={search}
                                    filter={getApiQuery(filter)}
                                    start={getApiStart(range.start)}
                                    end={getApiEnd(range.end)}
                                />

                            </VerticalContainer>

                        </FormSection>

                    </VerticalContainer>

                    {/* modal */}
                    <Modal
                        title={__`appointments_report`}
                        open={isModalOpen}
                        confirmLoading={isReportLoading}
                        okText={isExportSuccess ? __`got_it` : __`export_appointments`}
                        cancelText={__`cancel`}
                        footer={isExportSuccess ? [<Button key="submit" type="primary" onClick={handleModalOk}>{__`got_it`}</Button>] : undefined}
                        onOk={handleModalOk}
                        onCancel={handleModalCancel}
                    >

                        {/* save alert */}
                        {isExportSuccess ?

                            <Alert
                                type="success"
                                message={__`your_appointments_report_is_being_generated`}
                                showIcon
                                description={
                                    <Paragraph style={{ marginBottom: 0 }}>
                                        {__`we_are_generating_your_report_you_will_receive_it_soon_on`}
                                        &nbsp;
                                        <Text style={{ fontFamily: 'inter-medium' }}>
                                            {reportResponse && reportResponse.payload}
                                        </Text>
                                    </Paragraph>
                                }
                            />

                            :

                            <VerticalContainer>
                                
                                <Paragraph>
                                    {__`a_report_with_currently_selected_filters_will_be_generated_and_sent_to_the_admin_email_address`}
                                </Paragraph>

                                <Paragraph
                                    style={{
                                        border: `solid 1px ${colorBorder}`,
                                        padding: `${paddingXS}px ${paddingSM}px`,
                                        marginBottom: margin,
                                        borderRadius: borderRadius
                                    }}
                                >
                                    {__`note_a_limited_number_of_reports_can_be_generated_per_day`}
                                </Paragraph>

                            </VerticalContainer>

                        }

                    </Modal>

                </VerticalContainer>

            </PageContentWrapper>
        )
    )
}
