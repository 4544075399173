import { FooterLink } from './login-footer.style'
import { Button, Typography, theme } from 'antd'
import { useTranslate } from 'old/utils'
import { HorizontalContainer, VerticalContainer } from 'old/components/styled'
import { ReactNode } from 'react'
import { PRIVACY_POLICY_URL } from 'app.constants'
const  { Text } = Typography

export enum FooterButtonAction {
    CREATE_AN_ACCOUNT = 'create_an_account',
    BACK_TO_STEP_ONE = 'back_to_step_one',
    BACK_TO_LOGIN = 'back_to_login',
    GO_TO_LOGIN = 'go_to_login'
}

type Props = {
    helperText: string | null
    buttonTextKey: FooterButtonAction | null
    buttonIcon: ReactNode | null
    onSelect: (action: FooterButtonAction) => void
}

export const LoginFooter = ({ helperText, buttonTextKey, buttonIcon, onSelect }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { colorTextDescription, marginSM } } = theme.useToken()
    
    //render:
    return (
        <VerticalContainer style={{ alignItems: 'center', gap: marginSM }}>

            <HorizontalContainer style={{ justifyContent: 'center', gap: 0 }}>

                {/* helper */}
                {helperText &&
                <Text style={{ color: colorTextDescription }}>
                    {helperText}
                </Text>}

                {/* button */}
                {buttonTextKey !== null &&
                <Button
                    type='link'
                    size='small'
                    icon={buttonIcon}
                    style={{ width: 'fit-content' }}
                    onClick={() => onSelect(buttonTextKey)}
                >
                    {__(buttonTextKey)}
                </Button>}

            </HorizontalContainer>

            {/* links */}
            <HorizontalContainer style={{ justifyContent: 'center', gap: marginSM }}>

                <FooterLink href={PRIVACY_POLICY_URL} target='_blank'>
                    {__`privacy_policy`}
                </FooterLink>

                {/* <Text style={{ fontSize: fontSizeSM, color: colorTextDescription, fontFamily: 'inter-medium' }}>•</Text> */}

                {/* <FooterLink href={TERMS_OF_USE_URL} target='_blank'>
                    {__`terms_of_use`}
                </FooterLink> */}

            </HorizontalContainer>

        </VerticalContainer>
    )

}
