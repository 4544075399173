import { theme } from 'antd'
import { FormTextarea } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { NullableField, useTranslate } from 'utils'

export const ServiceAppointment = () => {

    //theme:
    const { token: { margin } } = theme.useToken()

    //translate:
    const { __ } = useTranslate()

    return (
        <FormSection
            title={__`appointment_details`}
            childrenWrapperStyle={{ display: 'flex', flexDirection: 'column', gap: margin }}
        >
            
            {/* location */}
            <NullableField
                name='location.content'
                label={__`location`}
                placeholder={__`location_example`}
                description={__`this_will_be_included_in_the_notification_and_calendar_events`}
                component={FormTextarea}
            />

            {/* eventDescription */}
            <NullableField
                name="eventDescription"
                label={__`description`}
                placeholder={__`please_fill_up_this_online_form`}
                description={__`this_will_be_included_in_the_notification_and_calendar_events`}
                rows={4}
                component={FormTextarea}
            />

        </FormSection>
    )
}
