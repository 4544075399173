import { useCurrentShop } from 'old/api'
import { APPOINTMENTS_CACHE_STALE_TIME, APPOINTMENT_CACHE_STALE_TIME, CACHE_REFETCH_INTERVAL } from 'app.constants'
import { AppointmentFilter } from 'old/components/appointment/appointment-navigation/appointment-navigation'
import { useMutationHandler, useQueryHandler, getShopId, usePaginationHandler }  from 'old/utils'
import { Appointment }  from 'old/types'

const appointmentQuery = `
    id
    orderId
    orderName
    datetime
    createdAt
    
    customer {
        id
        name
        email
        image {
            id
            path
        }
        phone
        billingPhone
    }
    customerManagementLink

    price
    currency
    status

    service {
        id
        title
    }

    externalVariantId
    variantTitle
    duration
    quantity

    teamMember {
        id
        name
        image {
            id
            path
        }
    }

    lineItemProperties {
        name
        value
    }

    events {
        id
        type
        dateTime
        initiatedBy
        content
    }
`

//useAppointments:
export const useAppointments = (
    teamMemberId: string | null,
    ids: string[] | null,
    search: string | null,
    filter: AppointmentFilter,
    start: string | null,
    end: string | null,
    limit: number,
    enabled?: boolean,
    keepPreviousData?: boolean
) => {

    //shop id:
    const shopId = getShopId()

    //query handler:
    return usePaginationHandler<Appointment[]>({
        query: `
            query getAppointments (
                $shop: String!,
                $teamMember: String,
                $ids: [String!],
                $filterType: AppointmentFilterType,
                $start: Date,
                $end: Date,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String
            ) {
                getAppointments (
                    shop: $shop,
                    teamMember: $teamMember,
                    ids: $ids
                    filterType: $filterType,
                    start: $start,
                    end: $end,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                ) {
                    data {
                        ${appointmentQuery}
                    }
                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                }
            }
        `,
        variables: {
            shop: shopId,
            teamMember: teamMemberId,
            ids,
            filterType: filter,
            start,
            end,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['appointments', teamMemberId, ids, search, filter, start, end, shopId],
        limit,
        enabled,
        staleTime: APPOINTMENTS_CACHE_STALE_TIME,
        refetchInterval: CACHE_REFETCH_INTERVAL,
        keepPreviousData: keepPreviousData !== undefined ? keepPreviousData : true,
        getResponse: json => json.data.getAppointments,
        onSuccess: response => response.data
    })

}

//useAppointment:
export const useAppointment = (id: string, enabled?: boolean) => {

    //shop id:
    const shopId = getShopId()
    
    return useQueryHandler<Appointment>({
        query: `
            query getAppointment (
                $id: String!
            ) {
                getAppointment (
                    id: $id
                ) {
                    ${appointmentQuery} 
                }
            }
        `,
        variables: {
            id
        },
        cacheKey: ['appointment', id, shopId],
        enabled,
        staleTime: APPOINTMENT_CACHE_STALE_TIME,
        refetchInterval: CACHE_REFETCH_INTERVAL,
        onSuccess: response => response.data.getAppointment
    })
}

//order url:
export const useOrderURL = (orderId: string) => {
    const { data: shop } = useCurrentShop()
    if(shop && shop.url){
        const shopName = shop.url.replace('.myshopify.com', '')
        return `https://admin.shopify.com/store/${shopName}/orders/${orderId}`
    }else{
        return null
    }
}

//useGenerateReport:
type generateReportResponse = null | {
    result: 'success'
    payload: string
} | {
    result: 'too_many_requests'
    payload: number
}

export const useGenerateReport = (
    teamMemberId: string | null,
    filter: AppointmentFilter,
    start: string | null,
    end: string | null
) => {

    //shop id:
    const shopId = getShopId()

    return useMutationHandler<generateReportResponse>({
        query: `
            mutation generateReport (
                $shop: String!,
                $teamMember: String,
                $filterType: AppointmentFilterType!,
                $start: Date,
                $end: Date
            ) {
                generateReport (
                    shop: $shop,
                    teamMember: $teamMember,
                    filterType: $filterType,
                    start: $start,
                    end: $end
                ) {
                    email
                }
            }
        `,
        variables: {
            shop: shopId,
            teamMember: teamMemberId,
            filterType: filter,
            start,
            end
        },
        onSuccess: json => {
            return {
                result: 'success',
                payload: json.data.generateReport.email
            }
        },
        onError: json => {
            if(json.errors[0].extensions.errors[0].constraints[0].name === 'too_many_requests'){
                return {
                    result: 'too_many_requests',
                    payload: json.errors[0].extensions.errors[0].constraints[0].metaData.retryAfter
                }
            }else{
                return null
            }
        }
    })

}
