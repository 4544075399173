import { SwitchWrapper } from './flow-status.style'
import { FormSection } from 'old/components/form-section/form-section'
import { Typography } from 'antd'
import { useTranslate }  from 'old/utils'
import { VerticalContainer } from 'old/components/styled'
import { FormSwitch } from 'old/components/form-inputs'
import { Field, useField } from 'react-final-form'
const { Text, Paragraph } = Typography

export const FlowStatus = () => {

    //translate:
    const { __ } = useTranslate()
    
    //field:
    const { input: { value: status } } = useField('status')

    //render:
    return (
        <FormSection>

            <VerticalContainer>

                <SwitchWrapper>

                    {/* title */}
                    <Text>

                        {__`this_flow_is`}
                        &nbsp;
                        <Text style={{ fontWeight: 'bold' }}>
                            {status ? __`enabled` : __`disabled`}
                        </Text>

                    </Text>

                    {/* switch */}
                    <Field
                        name="status"
                        type="switch"
                        component={FormSwitch}
                    />

                </SwitchWrapper>

                <Paragraph type='secondary' style={{ marginBottom: 0 }}>{__`you_can_enable_disable_the_flow_whenever_you_want`}</Paragraph>

            </VerticalContainer>

        </FormSection>
    )

}
