import { Typography, theme } from 'antd'
import { Avatar } from 'old/components/avatar/avatar'
import { AppointmentTeamMember }  from 'old/types'
import { useTranslate }  from 'old/utils'
import { ColumnContainer } from 'old/components/styled'
const { Text } = Typography

type Props = {
    teamMember: AppointmentTeamMember | null
}

export const AppointmentDetailsTeamMember = ({ teamMember }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { paddingXS, padding, marginXS } } = theme.useToken()

    //render:
    return (
        <ColumnContainer gap={marginXS}>

            <Text
                style={{
                    padding: `0 ${padding}px`,
                    fontFamily: 'inter-medium'
                }}
            >
                {__`team_member`}
            </Text>

            <Avatar
                name={teamMember?.name ?? __`unknown`}
                image={teamMember?.image.path ?? null}
                description={__`team_member`}
                style={{
                    padding: `${paddingXS}px ${padding}px`,
                    borderRadius: 0
                }}
            />
            
        </ColumnContainer>
    )
}
