import { Customer, CalendarSlotService, CalendarSlotTeamMember }  from 'old/types'

export type CalendarSlotApiResponse = {
    date: string
    now: string
    teamMembers: Omit<CalendarSlotTeamMember, 'image'>[]
}

export type CalendarSlot = {
    dateTime: string
    duration: number
    service: CalendarSlotService | null
    variantTitle: string | null
    items: CalendarSlotItem[]
}

export type CalendarSlotItem = {
    id: string
    type: CalendarSlotType.APPOINTMENT
    customer: Customer | null
} | {
    id: string
    type: CalendarSlotType.RESERVATION
    customer: null
} | {
    id: null
    type: CalendarSlotType.BUFFER
    customer: null
} | {
    id: null
    type: CalendarSlotType.EXTERNAL
    customer: null
}

export enum CalendarSlotType {
    APPOINTMENT = 'APPOINTMENT',
    RESERVATION = 'RESERVATION',
    BUFFER = 'BUFFER',
    EXTERNAL = 'EXTERNAL'
}
