import { PageHeader } from 'old/components/page-header/page-header'
import { PlusOutlined } from '@ant-design/icons'
import { isNil, useTranslate, useWindowDimensions }  from 'old/utils'
import { useConfig, useCurrentShop, useTeamMembers } from 'old/api'
import { TeamMemberItem } from 'old/components/team-member/team-member-item/team-member-item'
import { useNavigate } from 'react-router-dom'
import { VerticalPaginatedList } from 'old/components/vertical-paginated-list/vertical-paginated-list'
import { useState } from 'react'
import { PageContentWrapper, PageError, VerticalContainer } from 'old/components/styled'
import { theme } from 'antd'
import { FormSection } from 'old/components/form-section/form-section'
import { Config }  from 'old/types'

export const TeamMembersList = () => {

    //hooks:
    const { token: { paddingMD, marginXXS, margin, padding } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config } = useConfig()
    const { data: shop } = useCurrentShop()
    const navigate = useNavigate()

    //state:
    const [ search, setSearch ] = useState(null as string | null)
    
    //team members:
    const {
        data: teamMembers,
        total,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useTeamMembers(search, 15)

    return (!config || !shop) ? <PageError/> : (
        
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`team_members`}
                    description={__`manage_all_your_team_members`}
                    primaryButton={{
                        name: SM ? __`create_team_member` : __`create`,
                        icon: <PlusOutlined/>,
                        mode: canCreateTeamMember(config, !isNil(teamMembers), total) ? 'normal' : 'disabled',
                        onClick: () => navigate('/team/new')
                    }}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>
                
                    <FormSection
                        title={SM ? __`team_members` : undefined }
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        searchInput={{
                            placeholder: __`search_team_members`,
                            onSearch: setSearch
                        }}
                    >

                        {/* list */}
                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={teamMembers === null}
                            isError={teamMembers === false}
                            notFound={(teamMembers && teamMembers.length === 0) ?? false}
                            totalPage={totalPage}
                            pageNum={pageNum}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            {teamMembers && teamMembers?.map(member =>
                                <TeamMemberItem
                                    key={member.id}
                                    teamMember={member}
                                />
                            )}
                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}

//canCreateTeamMember:
export const canCreateTeamMember = (config: Config, dataLoaded: boolean, total: number) => {
    if(dataLoaded){
        if(config.plan?.maxTeamMembers && total < config.plan.maxTeamMembers){
            return true
        }
    }
    return false
}
