import { Typography, theme } from 'antd'
import { useConfig } from 'api'
import { FormCheckbox, FormDatePicker } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { HorizontalContainer, Label, Message } from 'components/styled'
import { Field, useField } from 'react-final-form'
import { NullableField, useTranslate } from 'utils'
import { Feature } from 'types'
import { useEffect } from 'react'
const  { Text } = Typography

export const ServiceStorefront = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginXXS, marginXS, margin } } = theme.useToken()
    const { data: config } = useConfig()
    const { input: { value: instantBooking } } = useField('instantBooking')
    const { input: { onChange: updateCreateOrder } } = useField('createOrder')

    //uncheck the createOrder on instantBooking uncheck:
    useEffect(() => {
        if(instantBooking === false){
            updateCreateOrder(false)
        }
    }, [instantBooking])

    const hasInstantBooking = config && config.features.includes(Feature.INSTANT_BOOKING)
    
    return (
        <FormSection
            title={__`storefront`}
            childrenWrapperStyle={{ display: 'flex', flexDirection: 'column', gap: margin }}
        >
                
            {/* Storefront start date */}
            <NullableField
                name="storefrontStartDate"
                label={__`storefront_start_date`}
                placeholder={__`select_a_date`}
                description={__`leave_it_blank_to_start_from_the_current_date_default`}
                component={FormDatePicker}
                parse={(value: any) => value ? value.format('YYYY-MM-DD') : null}
            />

            {/* instant booking */}
            <Field
                name="instantBooking"
                type="checkbox"
                label={
                    <HorizontalContainer style={{ width: 'fit-content', marginBottom: marginXS }}>
                        <Text style={{ fontFamily: 'inter-medium' }}>{__`instant_booking`}</Text>
                        <Label size='medium' type='info' text={__`beta`} style={{ marginLeft: marginXXS }}/>
                    </HorizontalContainer>
                }
                text={
                    <>
                        <Text style={{ fontFamily: 'inter-medium' }}>{__`enable_instant_booking_for_this_service`}</Text>
                        {!hasInstantBooking && <Message type='warning' message={__`this_feature_is_not_available_due_to_your_plan`}/>}
                    </>
                }
                description={__`customers_can_complete_the_booking_in_the_sesami_pop_up_and_skip_the_checkout_recommended_for_free_or_post_paid_appointments`}
                component={FormCheckbox}
                disabled={!hasInstantBooking}
            />

            {/* create order */}
            <Field
                name="createOrder"
                type="checkbox"
                text={<Text style={{ fontFamily: 'inter-medium' }}>{__`create_shopify_order_for_appointments`}</Text>}
                description={__`appointments_booked_through_instant_booking_can_skip_creating_an_order_or_postpone_it_to_a_later_time_via_pos`}
                component={FormCheckbox}
                disabled={!instantBooking}
            />

        </FormSection>
    )

}
