import { ArrowsWrapper, DatePickerWrapper, MainWrapper, MenuWrapper } from './booking-calendar-navigation.style'
import { Button, DatePicker, Typography, theme } from 'antd'
import { LoadingOutlined, ReloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useTranslate , addDays, getDateRange, useWindowDimensions, queryClient }  from 'old/utils'
import { useConfig } from 'old/api'
import dayjs from 'dayjs'
import { BookingAction, BookingPageDispatch, BookingPageState } from 'old/pages/booking/booking-page/booking-page'
import { BOOKING_CALENDAR_NAVIGATION_Z_INDEX } from 'z-indexes'
import { useEffect, useState } from 'react'
const { Text } = Typography

type Props = {
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const BookingCalendarNavigation = ({ pageState, pageStateDispatch }: Props) => {

    //hooks:
    const { token: { fontSizeSM, fontSizeLG } } = theme.useToken()
    const { LG } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const [ isRefreshSelected, setIsRefreshSelected ] = useState(false)

    // disable refresh button loading for other fetches:
    useEffect(() => { !pageState.areSlotsLoading && setIsRefreshSelected(false) }, [pageState])
    
    return isConfigLoading || !config ? null : (
        <MainWrapper
            top={LG ? 60 : 0}
            zIndex={BOOKING_CALENDAR_NAVIGATION_Z_INDEX}
        >

            {/* date */}
            <Text style={{ fontSize: fontSizeLG, fontFamily: 'inter-medium' }}>
                {getDateRange(pageState.startDate, pageState.numberOfDays, config, false)}
            </Text>

            <MenuWrapper>

                <DatePickerWrapper>

                    {/* date picker */}
                    <DatePicker
                        inputReadOnly
                        defaultValue={dayjs(pageState.startDate)}
                        value={dayjs(pageState.startDate)}
                        allowClear={false}
                        style={{ width: '100%' }}
                        onChange={val => setStartAndEnd(dayjs(val).toDate(), pageState.numberOfDays, pageStateDispatch)}
                    />

                    <ArrowsWrapper>

                        {/* left arrow */}
                        <Button
                            icon={<LeftOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`previous_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => setStartAndEnd(addDays(pageState.startDate, -pageState.numberOfDays), pageState.numberOfDays, pageStateDispatch)}
                        />
                        
                        {/* right arrow */}
                        <Button
                            icon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`next_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => setStartAndEnd(addDays(pageState.startDate, +pageState.numberOfDays), pageState.numberOfDays, pageStateDispatch)}
                        />

                        {/* refresh */}
                        <Button
                            icon={(pageState.areSlotsLoading && isRefreshSelected) ? <LoadingOutlined style={{ fontSize: fontSizeSM }}/> : <ReloadOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`refresh`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => {
                                setIsRefreshSelected(true)
                                queryClient.invalidateQueries(['bookingSlots'])
                            }}
                        />
                        
                    </ArrowsWrapper>

                </DatePickerWrapper>

            </MenuWrapper>

        </MainWrapper>
    )
    
}

//setStartAndEnd:
export const setStartAndEnd = (start: Date, numberOfColumns: number, pageStateDispatch: BookingPageDispatch) => {
    pageStateDispatch({ type: BookingAction.SET_START_DATE, payload: { startDate: start } })
    pageStateDispatch({ type: BookingAction.SET_END_DATE, payload: { endDate: addDays(start, numberOfColumns) } })
}
