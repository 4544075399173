import { SelectedServiceIndicator, ServiceContainer, ServicesWrapper } from './booking-service.style'
import { useEffect, useState } from 'react'
import { InputNumber, Select, Typography, theme } from 'antd'
import { Avatar } from 'old/components/avatar/avatar'
import { FormSection } from 'old/components/form-section/form-section'
import { useTranslate , isNil }  from 'old/utils'
import { useService, useServices } from 'old/api'
import { Loading, NotFound, PageError, RWContainer, RWElement, VerticalContainer, WhiteShadowLoading } from 'old/components/styled'
import { BookingAction, BookingPageDispatch, BookingPageState } from 'old/pages/booking/booking-page/booking-page'
import { Service, Variant }  from 'old/types'
import { getDurationVariants } from 'old/components/service/service-duration/service-duration'
import { BOOKING_SERVICE_SHADOW_Z_INDEX } from 'z-indexes'
import { useNavigate } from 'react-router-dom'
const { Text } = Typography

export type ServicePreset = string | null | ServicePresetInfo

export type ServicePresetInfo = {
    serviceId: string
    externalVariantId: string
    participants: number
}

type BookingServiceProps = {
    servicePreset: ServicePreset
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const BookingService = ({ servicePreset, pageState, pageStateDispatch }: BookingServiceProps) => {
    
    //isReschedule:
    const isReschedule = servicePreset !== null && typeof servicePreset !== 'string'
    
    //render:
    return (
        isReschedule ? (
            <LockedService servicePresetInfo={servicePreset} pageState={pageState} pageStateDispatch={pageStateDispatch}/>
        ) : (
            <SelectService servicePresetId={servicePreset} pageState={pageState} pageStateDispatch={pageStateDispatch}/>
        )
    )

}

type LockedServiceProps = {
    servicePresetInfo: ServicePresetInfo
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const LockedService = ({ servicePresetInfo, pageState, pageStateDispatch }: LockedServiceProps) => {

    //theme:
    const { token: { padding, marginXS } } = theme.useToken()

    //translate:
    const { __ } = useTranslate()

    //navigate:
    const navigate = useNavigate()

    //service:
    const { data: service, isLoading } = useService(servicePresetInfo.serviceId)

    //findVariant:
    const findVariant = (service: Service, externalVariantId: string) => service.variants.find(variant => variant.externalId === externalVariantId) ?? null

    //findDuration:
    const findDuration = (variant: Variant | null) => variant?.options.find(option => option.name.toLowerCase() === 'duration')?.value

    //effect:
    useEffect(() => {
        if(service){
            handleServiceSelect(service, pageStateDispatch)
            pageStateDispatch({ type: BookingAction.SET_VARIANT, payload: { variant: findVariant(service, servicePresetInfo.externalVariantId) } })
            pageStateDispatch({ type: BookingAction.SET_PARTICIPANTS, payload: { participants: servicePresetInfo.participants } })
            if(service.teamMembers.length === 1){
                pageStateDispatch({ type: BookingAction.SET_TEAM_MEMBER, payload: { teamMember: service.teamMembers[0] } })
            }
        }else if(service === null || service === false){
            navigate('/service')
        }
    }, [isLoading])

    //render:
    return (
        <FormSection
            title={__`service`}
            style={{ gap: marginXS }}
            childrenWrapperStyle={{ paddingLeft: padding }}
        >

            {isLoading ? <Loading/> : !service ? <PageError/> : (

                <Avatar
                    image={pageState.service?.image.path}
                    name={pageState.service?.title}
                    description={`${findDuration(pageState.variant)} ${__`minutes`}`}
                />
                
            )}

        </FormSection>
    )

}

type SelectServiceProps = {
    servicePresetId: string | null
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const SelectService = ({ pageState, servicePresetId, pageStateDispatch }: SelectServiceProps) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { marginXXS, marginSM, margin, paddingXXS, padding } } = theme.useToken()

    //state:
    const [ search, setSearch ] = useState(null as string | null)

    //selected service:
    const { data: service, isLoading: isServiceLoading } = useService(servicePresetId ?? '', !isNil(servicePresetId))
    
    //services:
    const {
        data: services,
        pageNum,
        totalPage,
        isLoading: isServicesLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useServices(search, 15)

    //variantsSelectOptions:
    const variantsSelectOptions: { value: string, label: string }[] = []
    if(pageState.service !== null){
        for(let i = 0; i < pageState.service.variants.length; i++ ){
            const variant = pageState.service.variants[i]
            variantsSelectOptions.push({
                value: variant.id,
                label: variant.title
            })
        }
    }

    //getVariantFromId:
    const getVariantFromId = (id: string) => pageState.service?.variants.find(variant => variant.id === id) ?? null

    //after loading:
    useEffect(() => {
        if(service){
            handleServiceSelect(service, pageStateDispatch)
        }else if(services && services.length > 0){
            handleServiceSelect(services[0], pageStateDispatch)
        }
    }, [service, services])

    //LoadingMessageStyle:
    const LoadingMessageStyle = { padding: padding, marginTop: marginXXS, marginBottom: -margin }

    //getServiceJsx:
    const getServiceJsx = (service: Service, selectedServiceId?: string) => {

        const hasTeamMember = service.teamMembers.length > 0
        const hasDuration = getDurationVariants(service.variants).length > 0
        const isDisabled = !hasTeamMember || !hasDuration

        return (
            <SelectedServiceIndicator
                key={service.id}
                isSelected={selectedServiceId === service.id}
            >
                <Avatar
                    name={service.title}
                    title={!hasDuration ? __`no_durations` : !hasTeamMember ? __`no_team_members` : service.title}
                    image={service.image.path}
                    isVertical={true}
                    lineClamp={2}
                    shape='square'
                    style={{
                        height: '100%',
                        width: '100px',
                        paddingLeft: paddingXXS,
                        paddingRight: paddingXXS
                    }}
                    isDisabled={isDisabled}
                    onClick={() => handleServiceSelect(service, pageStateDispatch)}
                />
            </SelectedServiceIndicator>
        )
    }

    //render:
    return (
        <FormSection
            title={__`service`}
            searchInput={{
                placeholder:__`search_services`,
                onSearch: setSearch
            }}
            topNav={{
                pageNum,
                totalPage,
                isNextDisabled,
                isPreviousDisabled,
                onNext: fetchNext,
                onPrevious: fetchPrevious
            }}
        >

            <VerticalContainer style={{ gap: marginSM }}>

                <ServiceContainer isEmpty={(isServicesLoading || isServiceLoading) || (services ? services.length : 1) === 0}>

                    {
                        (isServicesLoading || (!isNil(servicePresetId) && isServiceLoading)) ? <Loading style={LoadingMessageStyle}/> :
                        !services ? <PageError style={LoadingMessageStyle}/> :
                        services.length === 0 ? <NotFound message={search ? undefined : __`no_services`} style={LoadingMessageStyle}/> : (

                            <>

                                {/* list */}
                                <ServicesWrapper>

                                    {/* preselected service */}
                                    {(!isNil(servicePresetId) && service) && getServiceJsx(service, pageState.service?.id)}

                                    {/* list */}
                                    {services.map(svc => (
                                        service && service.id === svc.id ? null : getServiceJsx(svc, pageState.service?.id)
                                    ))}

                                </ServicesWrapper>

                                {isPreviousData && <WhiteShadowLoading style={{ zIndex: BOOKING_SERVICE_SHADOW_Z_INDEX }}/>}
                            
                            </>

                        )
                    
                    }

                </ServiceContainer>

                <RWContainer>
                    
                    <RWElement breakPoint={250}>

                        <VerticalContainer>

                            <Text style={{ fontFamily: 'inter-medium' }}>{__`variant`}</Text>

                            <Select
                                options={variantsSelectOptions}
                                value={pageState.variant?.id}
                                notFoundContent={<NotFound message={isNil(pageState.service) ? __`select_a_service` : __`no_variants`}/>}
                                style={{ width: '100%' }}
                                onChange={value => pageStateDispatch({ type: BookingAction.SET_VARIANT, payload: { variant: getVariantFromId(value) } })}
                            />

                        </VerticalContainer>

                    </RWElement>


                    {pageState.service?.groupAppointments &&
                    
                        <RWElement breakPoint={250}>

                            <VerticalContainer>

                                <Text style={{ fontFamily: 'inter-medium' }}>{__`number_of_participants`}</Text>

                                <InputNumber
                                    min={1}
                                    max={pageState.service?.groupAppointmentSlots ?? 1}
                                    step={1}
                                    value={pageState.participants}
                                    keyboard
                                    style={{ width: '100%' }}
                                    onChange={value => pageStateDispatch({ type: BookingAction.SET_PARTICIPANTS, payload: { participants: value ?? 1 } })}
                                />

                            </VerticalContainer>

                        </RWElement>

                    }

                </RWContainer>

            </VerticalContainer>

        </FormSection>
    )

}

//handleServiceSelect:
const handleServiceSelect = (service: Service, pageStateDispatch: BookingPageDispatch) => {
    if(
        getDurationVariants(service.variants).length > 0 &&
        service.teamMembers.length > 0
    ){
        pageStateDispatch({ type: BookingAction.SET_SERVICE, payload: { service: service } })
        pageStateDispatch({ type: BookingAction.SET_TEAM_MEMBER, payload: {
            teamMember: service.teamMembers.length === 1 ? service.teamMembers[0] : null
        }})
    }
}
