import { PlansContainer } from './plans.style'
import { PageHeader } from 'old/components/page-header/page-header'
import { PlanCard } from 'old/components/plans-card/plan-card'
import { useTranslate }  from 'old/utils'
import { useConfig, usePlans } from 'old/api'
import { Plan }  from 'old/types'
import { Loading, PageContentWrapper, PageError, VerticalContainer } from 'old/components/styled'
import { useNavigate } from 'react-router-dom'
import { message, theme } from 'antd'

export const Plans = () => {
    const { data: plans, isLoading: isPlansLoading } = usePlans(null, 50)
    const { data: config, isLoading: isConfigLoading } = useConfig()
    return (
        isPlansLoading || isConfigLoading ? <Loading/> :
        !plans || !config ? <PageError/> :
        <PlansContent
            plans={plans}
            currentPlanID={config.plan?.planId ?? -1}
        />
    )
}

type ContentProps = {
    plans: Plan[]
    currentPlanID: number
}

const PlansContent = ({ plans, currentPlanID }: ContentProps) => {

    //hooks:
    const { token: { marginXS }} = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    return (
        <PageContentWrapper fullWidth>

            <VerticalContainer style={{ gap: marginXS }}>

                {/* header */}
                <PageHeader
                    title={__`plans`}
                    description={__`just_compare_and_choose_the_best_plan_for_your_needs`}
                    backButton={{
                        name: __`back`,
                        onClick: () => navigate('/settings')
                    }}
                />

                {/* content */}
                <PlansContainer>
                    {plans.map(plan => plan.listed ?
                        <PlanCard
                            key={plan.planId}
                            title={plan.name}
                            price={'$' + plan.price}
                            duration={'MO'}
                            type={getPlanType(plan.planId, currentPlanID)}
                            description={plan.description}
                            onSelect={() => message.info(__`not_available_yet`)}
                        /> : null
                    )}
                </PlansContainer>

            </VerticalContainer>

        </PageContentWrapper>
    )
}

//getPlanType:
const getPlanType = (planId: number, currentPlanID: number) => {
    if(planId < currentPlanID)
        return 'downgrade'
    if(planId > currentPlanID)
        return 'upgrade'
    return 'current'
}
