import { OOPS_ERROR } from 'app.constants'
import styled from 'styled-components'
import { useTranslate } from 'utils'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => `${props.theme.fontSize}px`};
    color: ${props => props.theme.colorError};
`

type PageErrorProps = {
    message?: string
    style?: object
}

export const PageError = ({ message, style }: PageErrorProps) => {
    const { __ } = useTranslate()
    return <Wrapper style={style}>{message ?? __`oops_something_went_wrong`}</Wrapper>
}

export const StaticPageError = ({ message, style }: PageErrorProps) =>
    <Wrapper style={style}>{message ?? OOPS_ERROR}</Wrapper>
