import { ConfirmationPage } from '../../confirmation-page/confirmation-page'
import { useCreateDraftOrder } from 'api'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { findBookingPathRoute } from '../booking-page/booking-page'
import { useTranslate } from 'utils'

export const BookingSuccess = () => {

    //hooks:
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const params = useParams()
    const appointmentId = params.appointmentId
    if(!appointmentId){
        navigate('/404')
    }

    //complete order:
    const { mutate: createDraftOrder, isLoading, data: response } = useCreateDraftOrder(appointmentId ?? '')

    //after create:
    useEffect(() => {
        if(response){
            navigate(`${findBookingPathRoute()}/book/draft/${response}`)
        }
    }, [isLoading])
    
    return appointmentId ? (
        <ConfirmationPage
            status='success'
            pageTitle={''}
            title={__`booked_successfully`}
            message={__`to_checkout_add_this_booking_to_the_cart_in_pos_or_to_a_draft_order_to_send_an_invoice_for_payment`}
            confirmText={__`create_draft_order`}
            isLoading={isLoading}
            onConfirm={createDraftOrder}
            onBack={() => navigate(-1)}
        />
    ) : <></>

}
