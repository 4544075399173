import { theme } from 'antd'
import { FormInputNumber } from 'old/components/form-inputs'
import { FormSection } from 'old/components/form-section/form-section'
import { RWContainer, RWElement } from 'old/components/styled'
import { Field } from 'react-final-form'
import { useTranslate } from 'old/utils'

export const ServiceBufferTime = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { margin } } = theme.useToken()

    //render:
    return (
        <FormSection
            title={__`buffer_time`}
            footer={__`set_buffer_time_before_and_or_after_appointments_for_breaks_preparation_and_travel`}
        >

            <RWContainer style={{ gap: margin }}>
                
                <RWElement numberOfElements={3} breakPoint={350}>
                    <Field
                        name="bufferTimeBefore"
                        min={0}
                        max={365*30*24*60}
                        keyboard
                        style={{ width: '100%' }}
                        component={FormInputNumber}
                        title={__`before_each_appointment`}
                        description={__`enter_time_in_minutes`}
                    />
                </RWElement>

                <RWElement numberOfElements={3} breakPoint={350}>
                    <Field
                        name="bufferTimeAfter"
                        min={0}
                        max={365*30*24*60}
                        keyboard
                        style={{ width: '100%' }}
                        component={FormInputNumber}
                        title={__`after_each_appointment`}
                        description={__`enter_time_in_minutes`}
                    />
                </RWElement>

                <RWElement numberOfElements={3} breakPoint={350}>
                    <Field
                        name="bufferFromNow"
                        min={0}
                        max={365*30*24*60}
                        keyboard
                        style={{ width: '100%' }}
                        component={FormInputNumber}
                        title={__`before_the_first_appointment_on_the_same_day`}
                        description={__`enter_time_in_minutes`}
                    />
                </RWElement>

            </RWContainer>

        </FormSection>
    )

}
