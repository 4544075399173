import styled from 'styled-components'

type ContainerProps = {
    isEmpty: boolean
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: ${props => props.isEmpty ? 'center' : 'flex-start'};
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
`

export const Wrapper = styled.div`
    height: 100%;
    padding-bottom: ${props => `${props.theme.marginXS}px`};
    display: flex;
    gap: ${props => `${props.theme.marginXS}px`};
`

type SelectedResourceIndicatorProps = {
    isSelected: boolean
}

export const SelectedResourceIndicator = styled.div<SelectedResourceIndicatorProps>`
    background-color: ${props => props.isSelected ? props.theme.colorFillSecondary : 'transparent'};
    border-radius: ${props => `${props.theme.borderRadius}px`};
    transition: all 200ms ease;
`
