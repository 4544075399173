import { theme } from 'antd'
import { timeToFloatHoursAndMinutes }  from 'old/utils'

type Props = {
    dayStartHour: number
    from: string
    to: string
    scale: number
}

export const CalendarPageAvailabilitySlot = ({ dayStartHour, from, to, scale }: Props) => {
    
    //theme:
    const { token: { colorBgContainer } } = theme.useToken()

    //start/end
    const startTime = timeToFloatHoursAndMinutes(from)
    const endTime = timeToFloatHoursAndMinutes(to)

    //render:
    return (
        <div
            style={{
                width: '100%',
                height: (endTime - startTime)*60*scale,
                backgroundColor: colorBgContainer,
                position: 'absolute',
                top: (startTime - dayStartHour)*60*scale,
                left: 0
            }}
        />
    )
}
