import { DatePicker, Radio, Typography, theme, Checkbox, Input, InputNumber, Select, Switch } from 'antd'
import { FormInputWrapper, RWContainer, RWElement, VerticalContainer } from 'components/styled'
import dayjs from 'dayjs'
const { TextArea } = Input
const { Text, Paragraph } = Typography

//text:
export const FormInput = ({ input, meta, ...rest }: any) =>
    <FormInputWrapper meta={meta} {...rest}>
        <Input
            {...input}
            {...rest}
            status={meta.error && meta.touched && 'error'}
        />
    </FormInputWrapper>

//password:
export const FormPassword = ({ input, meta, ...rest }: any) =>
    <FormInputWrapper meta={meta} {...rest}>
        <Input.Password
            {...input}
            {...rest}
            status={meta.error && meta.touched && 'error'}
        />
    </FormInputWrapper>

//textarea:
export const FormTextarea = ({ input, meta, ...rest }: any) =>
    <FormInputWrapper meta={meta} {...rest}>
        <TextArea
            {...input}
            {...rest}
            status={meta.error && meta.touched && 'error'}
        />
    </FormInputWrapper>

//number:
export const FormInputNumber = ({ input, meta, ...rest }: any) =>
    <FormInputWrapper meta={meta} {...rest}>
        <InputNumber
            {...input}
            {...rest}
            status={meta.error && meta.touched && 'error'}
        />
    </FormInputWrapper>

//checkbox:
export const FormCheckbox = ({ input, meta, ...rest }: any) =>
    <FormInputWrapper meta={meta} {...rest}>
        <Checkbox
            {...input}
            {...rest}
            style={{ marginLeft: 1 }}
            status={meta.error && meta.touched && 'error'}
        >
            <Text style={{ fontFamily: rest.label ? 'inter' : 'inter-medium' }}>
                {rest.text}
            </Text>
        </Checkbox>
    </FormInputWrapper>

//group checkbox:
export const FormGroupCheckbox = ({ input, meta, ...rest }: any) =>
    <FormInputWrapper meta={meta} {...rest}>
        <Checkbox.Group
            {...input}
            {...rest}
            status={meta.error && meta.touched && 'error'}
        />
    </FormInputWrapper>

//switch:
export const FormSwitch = ({ input, meta, ...rest }: any) => (
    <FormInputWrapper meta={meta} {...rest}>
        <Switch
            defaultChecked={input.value}
            checked={input.value}
            onChange={input.onChange}
            {...rest}
            status={meta.error && meta.touched && 'error'}
        />
    </FormInputWrapper>
)

//radio:
export const FormRadio = ({ input, meta, ...rest }: any) => {
    const { token: { marginXXS, marginXS } } = theme.useToken()
    return (
        <FormInputWrapper meta={meta} {...rest}>
            <Radio.Group {...input} style={{ marginTop: marginXXS}}>
                <RWContainer style={{ maxWidth: rest.maxWidth, alignItems: 'flex-start', gap: marginXS }}>
                    <RWElement breakPoint={rest.breakPoint} style={{ marginLeft: 1 }}>
                        <VerticalContainer>
                            <Radio value={rest.options[0].value}>{rest.options[0].text}</Radio>
                            <Paragraph type='secondary' style={{ margin: 0 }}>
                                {rest.options[0].description}
                            </Paragraph>
                        </VerticalContainer>
                    </RWElement>
                    <RWElement breakPoint={rest.breakPoint} style={{ marginLeft: 1 }}>
                        <VerticalContainer>
                            <Radio value={rest.options[1].value}>{rest.options[1].text}</Radio>
                            <Paragraph type='secondary' style={{ margin: 0 }}>
                                {rest.options[1].description}
                            </Paragraph>
                        </VerticalContainer>
                    </RWElement>
                </RWContainer>
            </Radio.Group>
        </FormInputWrapper>
    )
}

//select:
export const FormSelect = ({ input, meta, ...rest }: any) => (
    <FormInputWrapper meta={meta} {...rest}>
        <Select
            {...input}
            {...rest}
            status={meta.error && meta.touched && 'error'}
        />
    </FormInputWrapper>
)

//date picker:
export const FormDatePicker = ({ input, meta, ...rest }: any) => (
    <FormInputWrapper meta={meta} {...rest}>
        <DatePicker
            inputReadOnly
            defaultValue={(input.value !== '' && typeof input.value == 'string') ? dayjs(input.value) : undefined}
            onChange={input.onChange}
            {...rest}
            status={meta.error && meta.touched && 'error'}
        />
    </FormInputWrapper>
)
