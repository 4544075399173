import { Select } from 'antd'
import { updateLocationId, useTranslate } from 'utils'
import { useRef, useState } from 'react'
import { Loading, NotFound, PageError } from 'components/styled'
import { useCurrentLocation, useLocations } from 'api'
import { DEBOUNCING_TIMEOUT } from 'app.constants'
import { useNavigate } from 'react-router-dom'

export const LocationSelector = () => {

    //debounce timeout:
    const lastKeyPressTimeoutId = useRef(0 as number | NodeJS.Timeout)

    //hooks:
    const { __ } = useTranslate()
    const navigate = useNavigate()
    
    //state:
    const [ search, setSearch ] = useState<string | null>(null)
    const [ isTyping, setIsTyping ] = useState(false)

    //api:
    const { data: currentLocation } = useCurrentLocation()
    const {
        data: locations,
        isLoading: isLocationsLoading,
        isFetching: isLocationFetching,
        isError: isLocationsError
    } = useLocations(search, 5, true)

    //search locations
    const searchLocations = (searchPhrase: string) => {
        setIsTyping(true)
        clearTimeout(lastKeyPressTimeoutId.current)
        if(searchPhrase.length < 1){
            setSearch(null)
            setIsTyping(false)
        }else{
            lastKeyPressTimeoutId.current = setTimeout(() => {
                setSearch(searchPhrase)
                setIsTyping(false)
            }, DEBOUNCING_TIMEOUT)
        }
    }

    return (locations && currentLocation) ? (
        <Select
            options={
                (
                    (currentLocation && locations) ? (
                        search ? locations : (
                            (
                                // add current location as the first option:
                                [currentLocation]
                            ).concat(
                                // remove the current location from other options(if exists)
                                locations.filter(l => l.id !== currentLocation.id)
                            )
                        )
                    ) : []
                ).map(l => ({ value: l.id, label: l.name }))
            }
            value={currentLocation.id}
            showSearch
            onSearch={searchLocations}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            onChange={value => {
                updateLocationId(value)
                navigate(location.pathname)
            }}
            notFoundContent={
                (isLocationsLoading || isLocationFetching || isTyping) ? <Loading/> :
                isLocationsError ? <PageError message={__`error`}/> :
                <NotFound message={search ? undefined : __`no_locations`}/>
            }
            style={{ width: '100%' }}
        />
    ) : <></>
}
