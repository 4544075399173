import { CustomerWrapper } from './booking-customer.style'
import { Button, Select, theme } from 'antd'
import { TranslateFunction, useTranslate }  from 'old/utils'
import { useExternalCustomers } from 'old/api'
import { ExternalCustomer }  from 'old/types'
import { useEffect, useRef, useState } from 'react'
import { Avatar } from 'old/components/avatar/avatar'
import { Loading, NotFound, PageError } from 'old/components/styled'
import { CloseOutlined } from '@ant-design/icons'
import { DEBOUNCING_TIMEOUT } from 'app.constants'
import { FormSection } from 'old/components/form-section/form-section'
import { BookingAction, BookingPageDispatch, BookingPageState } from 'old/pages/booking/booking-page/booking-page'

type Props = {
    customerPreset: ExternalCustomer | null
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const BookingCustomer = ({ customerPreset, pageState, pageStateDispatch }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSizeSM, marginXS } } = theme.useToken()

    //debounce timeout:
    const lastKeyPressTimeoutId = useRef(0 as number | NodeJS.Timeout)

    //state:
    const [ search, setSearch ] = useState(null as string | null)
    const [ isTyping, setIsTyping ] = useState(false)

    //customers:
    const { data: customers, isLoading: isCustomersLoading } = useExternalCustomers(search, 5)

    //searchCustomers:
    const searchCustomers = (searchPhrase: string) => {
        setIsTyping(true)
        clearTimeout(lastKeyPressTimeoutId.current)
        if(searchPhrase.length < 1){
            setSearch(null)
            setIsTyping(false)
        }else{
            lastKeyPressTimeoutId.current = setTimeout(() => {
                setSearch(searchPhrase)
                setIsTyping(false)
            }, DEBOUNCING_TIMEOUT)
        }
    }
    
    // set page state customer:
    useEffect(() => {
        if(customerPreset && !pageState.customer){
            pageStateDispatch({ type: BookingAction.SET_CUSTOMER, payload: { customer: customerPreset } })
        }
    }, [customerPreset])

    //render:
    return (
        <FormSection title={__`customer`} style={{ gap: marginXS }}>

            {(pageState.customer || customerPreset) ? (
                
                <CustomerWrapper>

                    {pageState.customer && (
                        <>

                            {/* selected customer */}
                            <Avatar
                                name={getExternalCustomerName(pageState.customer) ?? __`unknown`}
                                description={pageState.customer.email ?? pageState.customer.phone}
                                image={pageState.customer.image.path}
                                style={{ marginLeft: -marginXS }}
                            />

                            {/* clear button */}
                            {!customerPreset && (
                                <Button
                                    type='text'
                                    size='large'
                                    shape='circle'
                                    onClick={() => pageStateDispatch({ type: BookingAction.SET_CUSTOMER, payload: { customer: null } })}
                                >
                                    <CloseOutlined style={{ fontSize: fontSizeSM }}/>
                                </Button>
                            )}

                        </>
                    )}

                </CustomerWrapper>

            ) : (

                //select:
                <Select
                    placeholder={__`search_name_email_phone`}
                    showSearch
                    style={{ width: '100%' }}
                    onSearch={value => searchCustomers(value)}
                    allowClear
                    onSelect={value => customers && pageStateDispatch({ type: BookingAction.SET_CUSTOMER, payload: { customer: getCustomerFromValue(customers, value) } })}
                    notFoundContent={
                        isCustomersLoading || isTyping ? <Loading/> :
                        !customers ? <PageError message={__`error`}/> :
                        <NotFound message={search ? undefined : __`no_customers`}/>
                    }
                >
                    {isTyping ? <Loading/> : customers && getOptions(customers, __)}
                </Select>
            
            )}
            
        </FormSection>
    )

}

//getOptions:
const getOptions = (customers: ExternalCustomer[], __: TranslateFunction) => customers.map(customer =>
    <Select.Option key={`${customer.id}_${customer.firstName}_${customer.lastName}_${customer.email}_${customer.phone}`}>
        <Avatar
            size='small'
            name={getExternalCustomerName(customer) ?? __`unknown`}
            description={customer.email ?? customer.phone}
            image={customer.image.path}
        />
    </Select.Option>
)

//getExternalCustomerName:
export const getExternalCustomerName = (customer: ExternalCustomer | null) => {
    if(customer){
        if(customer.firstName && customer.lastName){
            return `${customer.firstName} ${customer.lastName}`
        }else if(customer.firstName){
            return customer.firstName
        }else if(customer.lastName){
            return customer.lastName
        }else if(customer.email){
            return customer.email
        }else if(customer.phone){
            return customer.phone
        }else{
            return null
        }
    }else{
        return null
    }
}

//getCustomerFromValue:
const getCustomerFromValue = (customers: ExternalCustomer[], value: string) => {
    const splitValue = value.split('_')
    const id = splitValue[0]
    return customers.find(customer => customer.id === id) ?? null
}
