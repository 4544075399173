import { BOOKING_SLOTS_CACHE_STALE_TIME, CACHE_REFETCH_INTERVAL } from 'app.constants'
import { BookingSlot, BookingSlotsResponse, FlexStartInterval } from 'types'
import { getIsoStringYearMonthDay, useMutationHandler, useQueryHandler, getShopId, getLocationId } from 'utils'

//slots:
export const useBookingSlots = (
    serviceId: string,
    variantId: string,
    quantity: number | null,
    flexStartInterval: FlexStartInterval | null | undefined,
    roundStartInterval: number,
    resourceIds: string[],
    start: Date,
    end: Date,
    timezone: string,
    enabled: boolean
) => {
    const shopId = getShopId()
    const locationId = getLocationId()
    return useQueryHandler<BookingSlotsResponse>({
        query: `
            query getAvailableSlots(
                $shop: String!,
                $locationId: String!,
                $service: String!,
                $variantId: String!,
                $quantity: Float,
                $ids: [[String!]!]!,
                $start: String,
                $end: String,
                $timezone: String!
            ){
                getAvailableSlots(
                    shop: $shop,
                    locationId: $locationId,
                    service: $service,
                    variantId: $variantId,
                    quantity: $quantity,
                    ids: $ids,
                    start: $start,
                    end: $end,
                    timezone: $timezone
                ){
                    date
                    slots{
                        startTime
                        duration
                        status
                        remainingSlots
                    }
                }
            }
        `,
        variables: {
            shop: shopId,
            locationId,
            service: serviceId,
            variantId,
            quantity,
            ids: resourceIds,
            start: getIsoStringYearMonthDay(start),
            end: getIsoStringYearMonthDay(end),
            timezone
        },
        cacheKey: [
            'bookingSlots',
            serviceId,
            variantId,
            quantity,
            flexStartInterval,
            roundStartInterval,
            resourceIds,
            start,
            end,
            timezone,
            shopId,
            locationId
        ],
        onSuccess: json => {

            // temporary solution until reading the unavailable slots from another query:
            const response: BookingSlotsResponse = []
            json.data.getAvailableSlots.map((day: any) => {

                // add just the available slots to the day:
                const slots: BookingSlot[] = []
                day.slots.map((slot: any) => {
                    if(slot.status === 'available'){
                        slots.push({
                            startDateTime: `${day.date} ${slot.startTime}`,
                            duration: slot.duration,
                            type: 'APPOINTMENT',
                            status: slot.status,
                            remainingSlots: slot.remainingSlots,
                            data: null
                        })
                    }
                })

                // add the day to the response:
                response.push({
                    date: day.date,
                    slots: slots
                })

            })

            return response

        },
        enabled,
        staleTime: BOOKING_SLOTS_CACHE_STALE_TIME,
        refetchInterval: CACHE_REFETCH_INTERVAL,
        keepPreviousData: true
    })
}

//book:
export const useBook = (
    serviceId: string,
    variantId: string,
    quantity: number,
    resourceIds: string[],
    customerId: string,
    start: Date,
    time: string
) => useMutationHandler<string>({
    query: `
        mutation book(
            $request: BookRequest!
        ){
            book(
                request: $request
            ){
                id
            }
        }
    `,
    variables: {
        request: {
            shop: getShopId(),
            serviceId: serviceId,
            variantId,
            quantity,
            locationId: getLocationId(),
            resourceIds,
            customerId,
            date: getIsoStringYearMonthDay(start),
            time
        }
    },
    onSuccess: json => json.data.book.id
})

//create draft:
export const useCreateDraftOrder = (appointmentId: string) => useMutationHandler<string>({
    query: `
        mutation createDraftOrder(
            $request: GetBookingRequest!
        ){
            createDraftOrder(
                request: $request
            ){
                bookingId
            }
        }
    `,
    variables: {
        request: {
            shop: getShopId(),
            appointmentId
        }
    },
    onSuccess: json => json.data.createDraftOrder.bookingId
})

//complete order:
export const useCompleteDraftOrder = (shopifyBookingId: string) => useMutationHandler<boolean>({
    query: `
        mutation completeDraftOrder(
            $shop: String!,
            $draftOrderId: String!
        ){
            completeDraftOrder(
                shop: $shop,
                draftOrderId: $draftOrderId
            ){
                bookingId
            }
        }
    `,
    variables: {
        shop: getShopId(),
        draftOrderId: shopifyBookingId
    },
    onSuccess: json => json.data.completeDraftOrder.bookingId === shopifyBookingId
})

//reschedule:
export const useRescheduleAppointment = (
    appointmentId: string,
    resourceIds: string[],
    dateTime: string
) => useMutationHandler<boolean>({
    query: `
        mutation rescheduleAppointment(
            $id: String!,
            $request: RescheduleAppointmentRequest!
        ){
            rescheduleAppointment(
                id: $id,
                request: $request
            ){
                message
            }
        }
    `,
    variables: {
        id: appointmentId,
        request: {
            locationId: getLocationId(),
            resourceIds,
            dateTime,
            content: null
        }
    },
    onSuccess: json => json.data.rescheduleAppointment.message === 'Done'
})

