import { VerticalContainer } from 'components/styled'
import { Typography, theme } from 'antd'
import { PropsWithChildren } from 'react'
const { Text } = Typography

type Props = {
    title: string
}

export const UserPermissionGroup = ({ title, children }: PropsWithChildren<Props>) => {

    //theme:
    const { token: { marginXS } } = theme.useToken()

    return (
        <VerticalContainer style={{ gap: marginXS }}>

            <Text style={{ fontFamily: 'inter-medium' }}>{title}</Text>

            {children}

        </VerticalContainer>
    )
    
}
