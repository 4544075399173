import { SwitchWrapper } from './settings-sesami-status.style'
import { FormSection } from 'old/components/form-section/form-section'
import { Typography } from 'antd'
import { useTranslate } from 'old/utils'
import { VerticalContainer } from 'old/components/styled'
import { FormSwitch } from 'old/components/form-inputs'
import { Field, useField } from 'react-final-form'
const { Text, Paragraph } = Typography

export const SettingsSesamiStatus = () => {

    //translate:
    const { __ } = useTranslate()
    
    //field:
    const { input: { value: status } } = useField('status')

    //render:
    return (
        <FormSection>

            <VerticalContainer>

                <SwitchWrapper>

                    {/* title */}
                    <Text>

                        {__`sesami_is`}
                        &nbsp;
                        <Text style={{ fontWeight: 'bold' }}>
                            {status ? __`enabled` : __`disabled`}
                        </Text>

                    </Text>

                    {/* switch */}
                    <Field
                        name="status"
                        type="switch"
                        component={FormSwitch}
                    />

                </SwitchWrapper>

                <Paragraph type='secondary' style={{ marginBottom: 0 }}>
                    {status ? __`the_sesami_button_will_be_visible` : __`the_sesami_button_will_disappear`}
                </Paragraph>

            </VerticalContainer>

        </FormSection>
    )

}
