import { getAppToken, useAppURL, useConfig, useExtensions } from 'api'
import { useEffect, useRef, useState } from 'react'
import { getShopId, nanoId, showMessage, useTranslate, useWindowDimensions } from 'utils'
import { Appointment, Config, RegisteredExtension } from 'types'
import { Loading, VerticalContainer } from 'components/styled'
import { AdminHostAppointmentDetails, AppTarget } from '@sesamiapp/app-message'
import { message, theme } from 'antd'

type ExtensionLoaderProps = {
    appointment: Appointment
}

export const AppointmentDetailsInfoExtensionLoader = ({ appointment }: ExtensionLoaderProps) => {

    const { data: config } = useConfig()
    const { data: extensions } = useExtensions([AppTarget.ADMIN_APPOINTMENT_DETAILS], 15)

    return (config && extensions && extensions.length) ? (
        <VerticalContainer style={{ gap: 0 }}>
            {extensions.map(extension => (
                <AppointmentDetailsExtensionLoaderContent
                    key={extension.id}
                    extension={extension}
                    appointment={appointment}
                    config={config}
                />
            ))}
        </VerticalContainer>
    ) : null

}

type ExtensionLoaderContentProps = {
    extension: RegisteredExtension
    appointment: Appointment
    config: Config
}

const AppointmentDetailsExtensionLoaderContent = ({ extension, appointment, config }: ExtensionLoaderContentProps) => {

    //hooks:
    const { token: { paddingXS, marginMD, colorBgContainer }} = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const iframe = useRef<HTMLIFrameElement>(null)

    //state:
    const [ timestamp ] = useState((new Date()).getTime())
    const [ messageId ] = useState(nanoId([extension.appId, timestamp, config.locale], 8))
    const [ client, setClient ] = useState<AdminHostAppointmentDetails | null>(null)
    const [ height, setHeight ] = useState<number | null>(null)
    const [ isIframeLoading, setIsIframeLoading ] = useState(true)

    //api:
    const { data: url } = useAppURL(
        extension.appId,
        extension.id,
        AppTarget.ADMIN_APPOINTMENT_DETAILS,
        timestamp,
        messageId,
        config.locale,
        null
    )

    //startup:
    useEffect(() => {
        if(iframe.current && !client){
            const cl = new AdminHostAppointmentDetails({
                messageId,
                shopId: getShopId(),
                locale: config.locale,
                extra: { appointmentId: appointment.id },
                onInitEnded: isInitialized => {
                    if(isInitialized){
                        setTimeout(() => {
                            setIsIframeLoading(false)
                        }, 50) // Prevent blinking from loading to extension size adjustment
                    }else{
                        message.error(__`cannot_load_the_extension`)
                    }
                },
                getToken,
                onHeightChange: setHeight,
                onNotification: showMessage
            })
            setClient(cl)
        }
    }, [iframe])

    const getToken = async () => {
        const response: any = await getAppToken(extension.appId, extension.id)
        try{
            const token = response.data.createAccessToken.token
            return token
        }catch(e){e}
        return null
    }

    return (
        <div style={{
            height: isIframeLoading ? 24 : height ? undefined : 0,
            padding: (isIframeLoading || height) ? `0 ${SM ? paddingXS : 0}px` : undefined,
            marginTop: (isIframeLoading || height) ? marginMD : undefined,
            position: 'relative'
        }}>

            <iframe
                ref={iframe}
                src={(client && url) ? url : undefined}
                style={{
                    width: '100%',
                    height: height ?? 0,
                    border: 'none'
                }}
            />

            {isIframeLoading ? (
                <div style={{
                    width: '100%',
                    background: colorBgContainer,
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}>
                    <Loading style={{ padding: 0 }}/>
                </div>
            ) : <></>}

        </div>
    )
}
