import { Typography, theme } from 'antd'
import { FormInput } from 'components/form-inputs'
import { HorizontalContainer, RWContainer, RWElement, VerticalContainer } from 'components/styled'
import { Field, useField } from 'react-final-form'
import { NullableField, useTranslate, useWindowDimensions } from 'utils'
import { FormSection } from 'components/form-section/form-section'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import { useEffect, useState } from 'react'
const  { Title, Text } = Typography

export const LocationBusinessAddress = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginXS, marginLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { input: { value: businessAddress, onChange: updateBusinessAddress } } = useField('businessAddress')

    //state:
    const [ browserLan, setBrowserLan ] = useState(0)
    const [ browserLng, setBrowserLng ] = useState(0)
    const [ center, setCenter ] = useState(false)

    useEffect(() => {
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(position => {
                setBrowserLan(position.coords.latitude)
                setBrowserLng(position.coords.longitude)
            })
        }else{
            console.error('Geolocation is not supported by this browser.')
        }
    }, [])

    //update the current location if not set:
    useEffect(() => {
        if(
            (browserLan !== 0 && browserLng !== 0) &&
            (browserLan !== businessAddress.latitude || browserLng !== businessAddress.longitude)
        ){
            updateBusinessAddress({
                ...businessAddress,
                latitude: browserLan,
                longitude: browserLng
            })
            setCenter(true)
            setTimeout(() => {
                setCenter(false)
            }, 100)
        }
    }, [browserLan, browserLng])

    return (
        <FormSection style={{ minHeight: 0, paddingBottom: SM ? marginLG : 0 }}>
        
            <RWContainer style={{ alignItems: 'normal', gap: marginLG }}>

                {/* info */}
                <RWElement breakPoint={300}>
                    <LocationBusinessAddressForm/>
                </RWElement>

                {/* image */}
                <RWElement breakPoint={300}>

                    <VerticalContainer style={{ height: '100%', gap: marginXS }}>

                        <Text style={{ fontFamily: 'inter-medium' }}>
                            {__`map`}
                        </Text>

                        <APIProvider apiKey="AIzaSyBrVob-0ZvX6PhET1kXndp7AoeDiYT50iw">
                            <div style={{
                                width: '100%',
                                height: 352,
                                border: 'solid 1px #ddd',
                                borderRadius: 10,
                                overflow: 'hidden'
                            }}>
                                <Map
                                    style={{ width: '100%', height: '100%' }}
                                    defaultCenter={{ lat: businessAddress.latitude, lng: businessAddress.longitude }}
                                    center={center ? { lat: businessAddress.latitude, lng: businessAddress.longitude } : undefined}
                                    defaultZoom={15}
                                    gestureHandling={'greedy'}
                                    onClick={e => {
                                        if(e.detail.latLng){
                                            updateBusinessAddress({
                                                ...businessAddress,
                                                latitude: e.detail.latLng.lat,
                                                longitude: e.detail.latLng.lng
                                            })
                                        }
                                    }}
                                >
                                    <Marker position={{ lat: businessAddress.latitude, lng: businessAddress.longitude }} />
                                </Map>
                            </div>
                        </APIProvider>

                    </VerticalContainer>

                </RWElement>

            </RWContainer>

        </FormSection>

    )

}

const LocationBusinessAddressForm = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingXXS, marginXXS, marginSM } } = theme.useToken()
    const { SM } = useWindowDimensions()

    return (
        <VerticalContainer style={{ gap: marginSM }}>

            {/* title */}
            <Title
                level={5}
                style={{
                    paddingTop: SM ? 0 : paddingXXS,
                    margin: `${marginXXS}px 0`
                }}
            >
                {__`business_address`}
            </Title>
            
            {/* country */}
            <Field
                name="businessAddress.country"
                label={__`country`}
                placeholder={__`country_example`}
                component={FormInput}
            />

            {/* address */}
            <Field
                name="businessAddress.address"
                label={__`address`}
                placeholder={__`address_example`}
                component={FormInput}
            />
            
            {/* suite */}
            <NullableField
                name="businessAddress.suite"
                label={__`suite_optional`}
                placeholder={__`suite_example`}
                component={FormInput}
            />

            <HorizontalContainer style={{ alignItems: 'flex-start', gap: marginSM }}>

                {/* city */}
                <div style={{ flex: 1 }}>
                    <Field
                        name="businessAddress.city"
                        label={__`city`}
                        placeholder={__`city_example`}
                        component={FormInput}
                        style={{ flex: 1 }}
                    />
                </div>
                
                {/* state */}
                <div style={{ flex: 1 }}>
                    <NullableField
                        name="businessAddress.state"
                        label={__`state_optional`}
                        placeholder={__`state_sample`}
                        component={FormInput}
                    />
                </div>

            </HorizontalContainer>

            {/* zipCode */}
            <Field
                name="businessAddress.zipCode"
                label={__`zip_code`}
                placeholder={__`zip_code_example`}
                component={FormInput}
            />

        </VerticalContainer>
    )

}
