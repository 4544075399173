import { Button, theme, Typography } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { getShopId, setCacheManually, useTranslate, useWindowDimensions } from 'utils'
import { HorizontalContainer, Label } from 'components/styled'
import { Resource } from 'types'
import { useNavigate } from 'react-router-dom'
const { Text } = Typography

type Props = {
    resource: Resource
}

export const ResourceItem = ({ resource }: Props) => {

    //hooks:
    const { token: { colorTextDescription, paddingXXS, paddingXS, paddingSM, padding, borderRadius } } = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const { SM } = useWindowDimensions()

    return (
        <Button
            type='text'
            style={{
                width: '100%',
                height: 'auto',
                padding: SM ? `0 ${paddingXS}px 0 0` : `0 ${padding}px 0 ${paddingSM}px`,
                borderRadius: SM ? borderRadius : 0
            }}
            onClick={() => {
                setCacheManually(['resource', resource.id, getShopId()], resource)
                navigate(`/resource/${resource.id}`)
            }}
        >

            <HorizontalContainer style={{ width: '100%' }}>

                {/* image and title */}
                <Avatar
                    name={resource.name}
                    image={resource.image.path}
                    description={SM ? resource.description : resource.type.name}
                    style={{ paddingLeft: paddingXXS, flex: 6 }}
                />

                <HorizontalContainer style={{ flex: SM ? 7 : 0 }}>
                    
                    {/* type */}
                    {SM && <Text style={{ color: colorTextDescription }}>{resource.type.name}</Text>}
                    
                    {/* label */}
                    <Label
                        type={resource.status ? 'success' : 'disable'}
                        text={resource.status ? __`enabled`: __`disabled`}
                    />

                </HorizontalContainer>

            </HorizontalContainer>

        </Button>
    )
    
}
