import { FooterButtonAction } from 'old/components/login/login-footer/login-footer'
import { Button, Modal, Typography, message, theme } from 'antd'
import { ArrowLeftOutlined , LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { TranslateFunction, useTranslate }  from 'old/utils'
import { HorizontalContainer, VerticalContainer } from 'old/components/styled'
import { Field, Form } from 'react-final-form'
import { setIn } from 'final-form'
import { object, ref, string } from 'yup'
import { FormPassword } from 'old/components/form-inputs'
import { useResetPassword } from 'old/api'
import { useEffect, useState } from 'react'
import { LoginUiWrapper } from 'old/components/login/login-ui-wrapper/login-ui-wrapper'
import { PASSWORD_MAX_LENGTH } from 'app.constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
const  { Text } = Typography

export const PasswordReset = () => {

    //url params:
    const [ searchParams ] = useSearchParams()
    const email = searchParams.get('email')
    const code = searchParams.get('code')

    //navigate:
    const navigate = useNavigate()

    //if no payload:
    useEffect(() => { (!email || !code) && navigate('/') }, [email, code])

    //render:
    return (email && code) ? (
        <PasswordResetForm
            email={email}
            code={code}
            onNavToLogin={() => navigate('/')}
        />
    ) : <></>

}

const PasswordResetFormSchema = (__: TranslateFunction) => object({
    password: string().required(__`enter_new_password`),
    passwordRepeat: string().required(__`confirm_new_password`).oneOf([ref('password')], __`doesnt_match_the_password`)
})

type PasswordResetFormProps = {
    email: string
    code: string
    onNavToLogin: () => void
}

export const PasswordResetForm = ({ email, code, onNavToLogin }: PasswordResetFormProps) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSize, marginXS, colorBgContainer } } = theme.useToken()

    //state:
    const [ password, setPassword ] = useState('')

    //onValidate:
    const onValidate = (schema: any) => async (values: { password: string, passwordRepeat: string }) => {
        if(typeof schema === 'function')
            schema = schema()
        try{
            await schema.validate(values, { abortEarly: false })
        }catch(err: any){
            const errors = err.inner.reduce((formError: object, innerError: any) => {
                return setIn(formError, innerError.path, innerError.message)
            }, {})
            return errors
        }
    }

    //mutation:
    const { mutate, isLoading, data: response } = useResetPassword(email, code, password)

    //sent request:
    useEffect(() => { password !== '' && mutate() }, [password])

    //handle response:
    useEffect(() => {
        if(response){
            switch(response.result){
                case 'success':
                    message.success(__`your_password_has_been_reset`)
                    onNavToLogin()
                    break
                case 'invalidOTP':
                    Modal.info({
                        title: __`invalid_code`,
                        content: __`the_code_you_entered_is_wrong_please_try_to_send_another_one`,
                        okText: __`resend`,
                        onOk: onNavToLogin
                    })
                    break
                case 'passwordsNotMatch':
                case 'userNotFound':
                    message.error(__`oops_something_went_wrong`)
                    break
            }
        }
    }, [response])
    
    //render:
    return (
        <LoginUiWrapper
            title={__`forgot_your_password`}
            description={__`no_worries_well_send_you_reset_instructions`}
            footerHelperText={null}
            footerButtonIcon={<ArrowLeftOutlined/>}
            footerButtonAction={FooterButtonAction.GO_TO_LOGIN}
            onFooterSelect={onNavToLogin}
        >

            <Form
                initialValues={{ password: null, passwordRepeat: null }}
                validate={onValidate(PasswordResetFormSchema(__))}
                onSubmit={(values: { password: string, passwordRepeat: string }) =>
                    PasswordResetFormSchema(__).validate(values).then(
                        data => setPassword(data.password)
                    )
                }
                render={({ handleSubmit }) => (

                    <form onSubmit={handleSubmit}>

                        <VerticalContainer style={{ gap: marginXS }}>
                    
                            {/* password */}
                            <Field
                                name="password"
                                placeholder={__`enter_new_password`}
                                component={FormPassword}
                                size={'large'}
                                style={{ fontSize }}
                                maxLength={PASSWORD_MAX_LENGTH}
                            />

                            {/* password repeat */}
                            <Field
                                name="passwordRepeat"
                                placeholder={__`confirm_new_password`}
                                component={FormPassword}
                                size={'large'}
                                style={{ fontSize }}
                                maxLength={PASSWORD_MAX_LENGTH}
                            />

                            {/* button */}
                            <Button
                                type='primary'
                                size='large'
                                style={{ fontSize, color: colorBgContainer }}
                                onClick={() => {!isLoading && handleSubmit()}}
                            >

                                <HorizontalContainer style={{ justifyContent: 'space-between' }}>

                                    <RightOutlined style={{ visibility: 'hidden' }}/>

                                    <Text style={{ color: colorBgContainer, fontFamily: 'inter-medium' }}>{__`reset_password`}</Text>

                                    {isLoading ? <LoadingOutlined/> : <RightOutlined/>}

                                </HorizontalContainer>

                            </Button>

                        </VerticalContainer>

                        <input type='submit' hidden/>

                    </form>
                    
                )}
            />

        </LoginUiWrapper>
    )

}
