import { Typography, theme } from 'antd'
import { ReactNode } from 'react'
import { HorizontalContainer } from 'components/styled'
const  { Text } = Typography

type Props = {
    email: string
    icon?: ReactNode
}

export const LoginEmail = ({ email, icon }: Props) => {

    //theme:
    const { token: { paddingXS, colorBorder, borderRadiusLG, fontSizeLG, colorTextSecondary } } = theme.useToken()
    
    return (
        <HorizontalContainer
            style={{
                padding: paddingXS,
                border: `solid 1px ${colorBorder}`,
                borderRadius: borderRadiusLG,
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >

            {/* icon */}
            <Text
                style={{
                    fontSize: fontSizeLG,
                    color: colorTextSecondary
                }}
            >
                {icon}
            </Text>

            {/* email */}
            <Text
                style={{
                    fontSize: fontSizeLG,
                    color: colorTextSecondary,
                    fontFamily: 'inter-medium'
                }}
            >
                {email}
            </Text>

        </HorizontalContainer>
    )

}
