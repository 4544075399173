import { MembersContainer, MembersWrapper, SelectedMemberIndicator } from './team-member-select.style'
import { useState } from 'react'
import { theme } from 'antd'
import { Avatar } from 'old/components/avatar/avatar'
import { objectAssign, useTranslate }  from 'old/utils'
import { useTeamMembers } from 'old/api'
import { Loading, NotFound, PageError, WhiteShadowLoading } from 'old/components/styled'
import { SimpleTeamMember, TeamMember }  from 'old/types'
import { FormSection } from 'old/components/form-section/form-section'
import { RESOURCE_SELECT_SHADOW_Z_INDEX } from 'z-indexes'

type Props = {
    items?: SimpleTeamMember[]
    selectedId: string | null
    showAll?: boolean
    areItemsVisible?: boolean
    itemsNotVisibleMessage?: string
    wrapperStyle?: object
    contentWrapperStyle?: object
    onSelect: (member: TeamMember | SimpleTeamMember | null) => void
}

export const TeamMemberSelect = ({
    items,
    selectedId,
    showAll = false,
    areItemsVisible = true,
    itemsNotVisibleMessage = '',
    wrapperStyle,
    contentWrapperStyle,
    onSelect
}: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { paddingXXS, padding, paddingMD } } = theme.useToken()

    //state:
    const [ search, setSearch ] = useState(null as string | null)
    
    //fetchFromAPI:
    const fetchFromAPI = items === undefined

    //team members:
    const {
        data,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useTeamMembers(search, 15, fetchFromAPI)
    const teamMembers = fetchFromAPI ? data : items

    //render:
    return (
        <FormSection
            title={__`team_member`}
            style={objectAssign({ paddingBottom: padding }, wrapperStyle)}
            childrenWrapperStyle={contentWrapperStyle}
            searchInput={{
                placeholder:__`search_team_members`,
                onSearch: setSearch
            }}
            topNav={{
                pageNum: fetchFromAPI ? pageNum : areItemsVisible ? 1 : 0,
                totalPage: fetchFromAPI ? totalPage : areItemsVisible ? 1 : 0,
                isNextDisabled,
                isPreviousDisabled,
                onNext: fetchNext,
                onPrevious: fetchPrevious
            }}
        >

            <MembersContainer isEmpty={isLoading || (teamMembers ? teamMembers.length : 1) === 0}>
        
                {
                    (fetchFromAPI && isLoading) ? <Loading style={{ padding: padding }}/> :
                    !teamMembers ? <PageError style={{ padding: paddingMD }}/> :
                    !areItemsVisible ? <NotFound message={itemsNotVisibleMessage} style={{ padding: paddingMD }}/> :
                    teamMembers.length === 0 ? <NotFound message={search ? undefined : __`no_team_members`} style={{ padding: paddingMD }}/> : (

                        <>
                
                            {/* list */}
                            <MembersWrapper>

                                {/* all */}
                                {showAll &&
                                    <SelectedMemberIndicator
                                        key={'all'}
                                        isSelected={selectedId === null}
                                    >

                                        <Avatar
                                            name={__`all`}
                                            image={null}
                                            isVertical={true}
                                            style={{
                                                width: '80px',
                                                height: '100%',
                                                paddingLeft: paddingXXS,
                                                paddingRight: paddingXXS
                                            }}
                                            onClick={() => onSelect(null)}
                                        />

                                    </SelectedMemberIndicator>
                                }

                                {/* team members */}
                                {teamMembers.map(member =>

                                    <SelectedMemberIndicator
                                        key={member.id}
                                        isSelected={selectedId === member.id}
                                    >

                                        <Avatar
                                            name={member.name}
                                            image={member.image.path}
                                            isVertical={true}
                                            style={{
                                                width: '80px',
                                                height: '100%',
                                                paddingLeft: paddingXXS,
                                                paddingRight: paddingXXS
                                            }}
                                            onClick={() => onSelect(member)}
                                        />

                                    </SelectedMemberIndicator>
                                    
                                )}

                            </MembersWrapper>

                            {isPreviousData && <WhiteShadowLoading style={{ zIndex: RESOURCE_SELECT_SHADOW_Z_INDEX }}/>}
                            
                        </>

                    )

                }

            </MembersContainer>

        </FormSection>
        
    )

}
