import { PageError } from './page-error'
import { useTranslate } from 'utils'

type Props = {
    message?: string
    style?: object
}

export const Forbidden = ({ message, style }: Props) => {

    //translate:
    const { __ } = useTranslate()

    return <PageError message={message ?? __`forbidden_access_denied`} style={style}/>

}
