import { usePaginationHandler, getShopId, isNil, useMutationHandler }  from 'old/utils'
import { PersonalAccessToken } from '../types'
import { useMe } from './profile.api'

//tokens:
export const useTokens = (search: string | null, limit: number) => {
    const { data: me } = useMe()
    const shopId = getShopId()
    return usePaginationHandler<PersonalAccessToken[]>({
        query: `
            query getPersonalAccessTokens (
                
                $shop: String!,
                $user: String!,

            ) {
                getPersonalAccessTokens(

                    shop: $shop,
                    user: $user
                    
                ) {
                    
                    data {

                        name
                        permissions
                        clientId
                        expireAt

                    }

                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                    
                }
            }
        `,
        variables: {
            shop: shopId,
            user: me ? me.id : '',
        },
        cacheKey: ['tokens', shopId],
        limit,
        enabled: !isNil(me),
        getResponse: json => json.data.getPersonalAccessTokens,
        onSuccess: response => response.data
    })
}

type CreateTokenResponse = {
    clientId: string
    token: string
}

//create:
export const useCreateToken = (token: PersonalAccessToken) => {
    const { data: me } = useMe()
    const shopId = getShopId()
    return useMutationHandler<CreateTokenResponse | 'existingName' | 'exceedMaxToken'>({
        query: `
            mutation createAccessToken (
                $request: AccessTokenRequest!
            ) {
                createAccessToken (
                    request: $request
                ) {
                    clientId
                    token
                }
            }
        `,
        variables: {
            request: {
                type: 'PERSONAL_ACCESS_TOKEN',
                personalAccessTokenRequest: {
                    shop: shopId,
                    name: token.name,
                    user: me ? me.id : '',
                    permissions: token.permissions,
                    expireAt: token.expireAt
                }
            }
        },
        onSuccess: json => ({
            clientId: json.data.createAccessToken.clientId,
            token: json.data.createAccessToken.token
        }),
        onError: json => {
            if(json.errors[0].extensions.errors[0].constraints[0].name === 'is_unique'){
                return 'existingName'
            }else if(json.errors[0].extensions.errors[0].constraints[0].name === 'exceed_max_user_personal_access_token'){
                return 'exceedMaxToken'
            }
        }
    })
}

//remove:
export const useRemoveToken = (clientId: string) => {

    const shopId = getShopId()
    const { data: me } = useMe()
    
    return useMutationHandler<boolean>({
        query: `
            mutation revokePersonalAccessToken (

                $shop: String!,
                $user: String!
                $clientId: String!

            ) {
                revokePersonalAccessToken (

                    shop: $shop,
                    user: $user
                    clientId: $clientId

                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            user: me ? me.id : '',
            clientId
        },
        onSuccess: json => json.data.revokePersonalAccessToken.message === 'Done'
    })
    
}
