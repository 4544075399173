import { useTranslate } from 'utils'
import { theme } from 'antd'
import {
    RightOutlined,
    CloudUploadOutlined,
    SketchOutlined,
    CloudDownloadOutlined
} from '@ant-design/icons'
import { VerticalContainer } from 'components/styled'
import { Avatar } from 'components/avatar/avatar'
import { UserPermissionHeader } from 'components/user/user-permission-header/user-permission-header'
import { PermissionLevel } from 'types'

type Props = {
    onBack: () => void
    onSelect: (level: PermissionLevel) => void
}

export const UserPermissionLevelSelect = ({ onBack, onSelect }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: {
        marginMD,
        marginXS,
        fontSizeXL,
        fontSizeSM,
        paddingSM,
        paddingLG,
        colorText,
        colorBgSpotlight
    }} = theme.useToken()

    //getIcon:
    const getIcon = (type: PermissionLevel) => {
        switch(type){
            case PermissionLevel.Read:
                return <CloudDownloadOutlined/>
            case PermissionLevel.Write:
                return <CloudUploadOutlined/>
            case PermissionLevel.Manage:
                return <SketchOutlined/>
        }
    }

    //getDescription:
    const getDescription = (level: PermissionLevel) => {
        switch(level){
            case PermissionLevel.Read:
                return __`user_can_just_open_and_see_the_resource`
            case PermissionLevel.Write:
                return __`user_can_add_to_this_resource`
            case PermissionLevel.Manage:
                return __`user_can_access_to_all_the_features`
        }
    }

    return (
        <VerticalContainer style={{ gap: marginMD }}>

            {/* header */}
            <UserPermissionHeader
                title={__`permission_level`}
                description={__`select_the_level_of_permission_you_want_to_grant`}
                onBack={onBack}
            />
            
            <VerticalContainer style={{ paddingBottom: paddingSM, gap: marginXS }}>

                {/* list */}
                {(Object.keys(PermissionLevel) as PermissionLevel[]).map(level => level === PermissionLevel.Write ? <></> : (
                    <Avatar
                        key={level}
                        name={__(level.toLowerCase())}
                        description={getDescription(level)}
                        image={null}
                        placeholder={<div style={{ fontSize: fontSizeXL, color: colorBgSpotlight }}>{getIcon(level)}</div>}
                        color={colorText}
                        backgroundColor='transparent'
                        size='small'
                        extraIcon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                        style={{ paddingLeft: paddingSM, paddingRight: paddingLG, borderRadius: 0 }}
                        onClick={() => onSelect(level)}
                    />
                ))}

            </VerticalContainer>

        </VerticalContainer>
    )
    
}
