import { Button, Dropdown, MenuProps, theme } from 'antd'
import { nameShortener, useTranslate, useWindowDimensions } from 'utils'
import { MoreOutlined } from '@ant-design/icons'
import { Feature, ServiceLocation, SimpleResource } from '../../../types'
import { HorizontalContainer, Label } from '../../styled'
import { Avatar } from '../../avatar/avatar'
import { useConfig } from 'api'

type Props = {
    location: ServiceLocation
    onSelect: () => void
    onRemove: () => void
}

export const ServiceLocationItem = ({ location, onSelect, onRemove }: Props) => {

    //hooks:
    const { token: { marginXS, margin, marginMD, marginXL, colorBgLayout } } = theme.useToken()
    const { data: config } = useConfig()
    const { __ } = useTranslate()
    const { SM } = useWindowDimensions()

    const getDropdownOptions = (): MenuProps['items'] => (
        (
            SM && config && config.features.includes(Feature.MULTI_LOCATION)
        ) ? [
            { key: 'remove', label: __`remove`          }
        ] : (
            config && config.features.includes(Feature.MULTI_LOCATION)
        ) ? [
            { key: 'manage', label: __`manage_resources`},
            { key: 'remove', label: __`remove`          }
        ] : [
            { key: 'manage', label: __`manage_resources`}
        ]
    )

    //onClick:
    const onClick: MenuProps['onClick'] = ({ key }) => {
        switch(key){
            case 'manage':
                onSelect()
                break
            case 'remove':
                onRemove()
                break
        }
    }

    return config? (
        <HorizontalContainer>

            <Avatar
                name={location.name}
                description={location.description}
                style={{ paddingLeft: 0 }}
            />

            <HorizontalContainer style={{ minHeight: 55, gap: marginMD }}>

                {location.resources.length ? (

                    <HorizontalContainer style={{ flexDirection: 'row-reverse', marginRight: -margin }}>

                        {location.resources.map(slr => {
                            const sampleResources: SimpleResource[] = []
                            slr.resources.map(r => {
                                if(sampleResources.length < 5 && !sampleResources.includes(r)){
                                    sampleResources.push(r)
                                }
                            })
                            return sampleResources.map(resource => (
                                <Avatar
                                    key={resource.id}
                                    image={resource.image.path}
                                    placeholder={nameShortener(resource.name)}
                                    title={resource.name}
                                    style={{ marginLeft: sampleResources.length > 1 ? -marginXL : undefined }}
                                    size='small'
                                    antdAvatarStyle={{ border: `solid 2px ${colorBgLayout}` }}
                                />
                            ))
                        })}

                    </HorizontalContainer>

                ) : (
                    <Label
                        type='warning'
                        text={__`no_resources`}
                    />
                )}
                
                <HorizontalContainer style={{ marginLeft: SM ? undefined : -marginXS, marginRight: -marginXS }}>
                    
                    {SM && config.features.includes(Feature.MULTI_LOCATION) ? (
                        <Button
                            type='default'
                            size='small'
                            onClick={onSelect}
                        >
                            {__`manage_resources`}
                        </Button>
                    ) : <></>}
                    
                    <Dropdown menu={ { items: getDropdownOptions(), onClick } } trigger={['click']}>
                        <Button
                            type={'text'}
                            shape={'circle'}
                            size={'large'}
                            icon={<MoreOutlined/>}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                        />
                    </Dropdown>

                </HorizontalContainer>

            </HorizontalContainer>

        </HorizontalContainer>
    ) : <></>

}
