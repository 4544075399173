import { ShopSelect } from '../shop-select/shop-select'
import { Button, Dropdown, InputRef, MenuProps, Typography, theme } from 'antd'
import { nameShortener, useAuthentication, useTranslate } from 'utils'
import { HorizontalContainer, Logo } from 'components/styled'
import { useNavigate } from 'react-router-dom'
import { HELP_CENTER_URL, PRIVACY_POLICY_URL } from 'app.constants'
import { Avatar } from 'components/avatar/avatar'
import { useMe, useCurrentShop, useLocations } from 'api'
import { LAYOUT_HEADER_Z_INDEX } from 'z-indexes'
import { useRef } from 'react'
import { LocationSelector } from 'components/location/location-selector/location-selector'
const { Text } = Typography

const items: MenuProps['items'] = [{ key: '1', label: <div></div> }]

export const LayoutHeader = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: {
        paddingXS,
        paddingMD,
        paddingLG,
        paddingXL,
        marginSM,
        marginMD,
        colorBorder,
        colorBgBase,
        boxShadowTertiary,
        colorBorderSecondary
    }} = theme.useToken()
    const { logout } = useAuthentication()
    const navigate = useNavigate()
    const { data: selectedShop } = useCurrentShop()

    //user:
    const { data: me, isLoading: isMeLoading } = useMe()
    const { total } = useLocations(null, 5, true) // 5 is to match the existing location select cache

    //search input ref:
    const searchInputRef = useRef<InputRef>(null)
    
    return (
        <HorizontalContainer
            id='layoutHeader'
            style={{
                padding: `${paddingXS}px ${paddingLG}px ${paddingXS}px ${paddingXL + paddingMD - 2}px`,
                justifyContent: 'space-between',
                backgroundColor: colorBgBase,
                borderBottom: `solid 1px ${colorBorder}`,
                boxShadow: boxShadowTertiary,
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                zIndex: LAYOUT_HEADER_Z_INDEX
            }}
        >

            <HorizontalContainer style={{ gap: marginMD }}>
                
                <Logo withName={true} width={98}/>
                
                {total > 1 && (
                    <div style={{ width: 170 }}>
                        <LocationSelector/>
                    </div>
                )}

            </HorizontalContainer>

            <HorizontalContainer>
            
                {/* help */}
                <Button type='text' onClick={() => window.location.replace(HELP_CENTER_URL)}>
                    {__`help`}
                </Button>

                {/* privacy */}
                <Button type='text' onClick={() => window.location.replace(PRIVACY_POLICY_URL)}>
                    {__`privacy`}
                </Button>

                {/* logout */}
                <Button type='text' onClick={() => logout()}>
                    {__`logout`}
                </Button>

                {/* profile */}
                <Button type='text' onClick={() => navigate('/profile')}>
                    {__`profile`}
                </Button>

                {/* shop */}
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    dropdownRender={() => (
                        <div style={{ width: 430 }}>
                            <ShopSelect listLength={7} inputRef={searchInputRef}/>
                        </div>
                    )}
                    onOpenChange={(isOpen: boolean) => {
                        if(isOpen){
                            setTimeout(() => searchInputRef.current?.focus(), 100)
                        }
                    }}
                >
                    <Button
                        type='text'
                        style={{
                            height: 'fit-content',
                            padding: 0,
                            border: `solid 1px ${colorBorderSecondary}`,
                            borderRadius: 21,
                            backgroundColor: colorBorderSecondary,
                            overflow: 'hidden'
                        }}
                    >
                        <HorizontalContainer style={{
                            paddingLeft: selectedShop ? paddingLG : 0,
                            gap: marginSM
                        }}>

                            {/* shop name */}
                            {selectedShop && (
                                <Text style={{
                                    maxWidth: 150,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                }}>
                                    {selectedShop.name}
                                </Text>
                            )}

                            {/* user avatar */}
                            <Avatar
                                shape='circle'
                                size='tiny'
                                wrapperSize={41}
                                image={me ? me.image.path : null}
                                placeholder={isMeLoading ? '...' : nameShortener(me ? `${me.firstName} ${me.lastName}` : '!')}
                                backgroundColor={colorBorderSecondary}
                                style={{ padding: 0, borderRadius: '100%' }}
                            />

                        </HorizontalContainer>
                    </Button>
                </Dropdown>

            </HorizontalContainer>

        </HorizontalContainer>
    )

}
