import { useEffect, useState } from 'react'
import { getShopId, queryClient, useTranslate } from 'utils'
import { useField } from 'react-final-form'
import { FormRemove } from 'components/form-remove/form-remove'
import { RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useRemoveResource, useResourceService } from 'api'
import { Button, message, Modal, theme, Typography } from 'antd'
import { HorizontalContainer, Loading, PageError, VerticalContainer } from 'components/styled'
const { Text } = Typography

export const ResourceRemove = () => {

    //hooks:
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const { input: { value: id } } = useField('id')
    const { input: { value: name } } = useField('name')

    //state:
    const [ isModalOpen, setIsModalOpen ] = useState(false)

    //api:
    const { mutate, isLoading, data: response } = useRemoveResource(id)

    //after remove:
    useEffect(() => {
        if(response === true){
            queryClient.invalidateQueries(['resources', getShopId()])
            message.success(__`resource_removed`)
            navigate('/resource')
        }else if(response === 'assignedToService'){
            setIsModalOpen(true)
        }else if(response === false){
            message.error(__`oops_something_went_wrong`)
        }
    }, [response])
    
    return (
        <div>

            <FormRemove
                title={`${__`remove`} "${name}"`}
                message={__`all_settings_will_be_deleted_this_action_cant_be_undone`}
                description={__`the_resource_should_not_be_assigned_to_any_services`}
                isLoading={isLoading}
                onRemove={mutate}
            />

            <Modal
                title={__`cannot_remove_resource`}
                open={isModalOpen}
                footer={null}
                style={{ width: 800 }}
                onCancel={() => setIsModalOpen(false)}
                // closable={false}
            >

                <ModalContent
                    id={id}
                    name={name}
                ></ModalContent>

            </Modal>

        </div>
    )

}

type Props = {
    id: string
    name: string
}

const ModalContent = ({ id, name }: Props) => {

    //hooks:
    const { token: {
        colorTextDescription,
        marginXXS,
        marginXS,
        marginSM,
        fontSizeSM,
        paddingXXS
    }} = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    const { data: relatedServices, isLoading } = useResourceService(id)

    return (
        <VerticalContainer style={{ marginBottom: marginXS, gap: marginSM }}>

            <Text style={{ marginTop: marginXXS }}>{__('resource_is_assigned_to_the_following_services', { resource: name })}</Text>

            <VerticalContainer style={{ minHeight: 150, maxHeight: 250, overflowY: 'auto', gap: marginXS }}>

                {isLoading ? <Loading/> : !relatedServices ? <PageError/> : relatedServices.map((rs, i) => i < 3 ? (
                    
                    <Button
                        key={rs.id}
                        style={{ height: 'fit-content' }}
                        onClick={() => navigate(`/service/${rs.id}`)}
                    >
                        
                        <HorizontalContainer style={{ padding: `${paddingXXS}px 0` }}>

                            <VerticalContainer style={{ alignItems: 'flex-start', gap: 0 }}>

                                <Text>
                                    {rs.title}
                                </Text>

                                <HorizontalContainer style={{ gap: 0 }}>

                                    {rs.locations.map((l, j) => j < 5 ? (
                                        <Text key={l.id} style={{ fontSize: fontSizeSM, color: colorTextDescription }}>
                                            {j > 0 ? ', ' : ''}{l.name}
                                        </Text>
                                    ) : <></>)}
                                    
                                </HorizontalContainer>

                            </VerticalContainer>

                            <RightOutlined style={{ fontSize: fontSizeSM }}></RightOutlined>

                        </HorizontalContainer>

                    </Button>

                ) : <></>)}

            </VerticalContainer>

        </VerticalContainer>

    )

}
