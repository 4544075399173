import { useEffect, useState } from 'react'

export const useTouchDetector = () => {
    
    //state:
    const [isTouchscreen, setIsTouchscreen] = useState(false)

    //listener:
    useEffect(() => {
        //handelChange
        const handleTouchscreenChange = () => {
            setIsTouchscreen(
                window.matchMedia('(pointer: coarse)').matches,
            )
        }

        //called for the first time
        handleTouchscreenChange()

        //listener:
        window
            .matchMedia('(pointer: coarse)')
            .addEventListener('change', handleTouchscreenChange)

        return () =>
            window
                .matchMedia('(pointer: coarse)')
                .removeEventListener('change', handleTouchscreenChange)
    }, [])

    return { isTouchscreen }
}
