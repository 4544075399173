import { FlowForm } from '../flow-form/flow-form'
import { useEffect, useState } from 'react'
import { useConfig, useFlow, useUpdateFlow } from 'api'
import { Feature, Flow, FlowFormSchema, flowBodyExtensions } from 'types'
import { Loading, PageError } from 'components/styled'
import { getDefaultExtensionIndex } from 'components/flow/flow-template/flow-template'
import { beautifyJsonString, getShopId, queryClient, useTranslate, validateFormValues } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { message } from 'antd'

export const EditFlow = () => {
    
    //hooks:
    const { data: config } = useConfig()
    const navigate = useNavigate()
    const params = useParams()
    
    //flow:
    const { data: flow, isLoading } = useFlow(params.id as string, () => navigate('/flow'))

    //redirect if not found:
    useEffect(() => {
        if(flow === null || flow === false){
            navigate('/flow')
        }
    }, [isLoading])

    // redirect if it doesn't have access to flow:
    useEffect(() => {
        if(config){
            if(!config.features.includes(Feature.FLOWS)){
                navigate('/')
            }
        }
    }, [config])
    
    return isLoading ? <Loading/> : !flow ? <PageError/> : <EditFlowController flow={flow}/>
}

type EditFlowControllerProps = {
    flow: Flow
}

const EditFlowController = ({ flow }: EditFlowControllerProps) => {
    
    //hooks:
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ initialValues, setInitialValues ] = useState({
        ...flow,
        template: {
            headers: beautifyJsonString(flow.template.headers),
            body: beautifyJsonString(flow.template.body)
        }}
    )
    const [ savingValues, setSavingValues ] = useState(flow)
    const [ action, setAction ] = useState(initialValues.action)
    const [ bodyExt, setBodyExt ] = useState(flowBodyExtensions[getDefaultExtensionIndex(initialValues.action)][0])

    //mutation:
    const { mutate, isLoading, data: response } = useUpdateFlow(savingValues)

    //save:
    const save = (values: Flow) => {
        setSavingValues(values)
        mutate()
    }

    //after update:
    useEffect(() => {
        if(response){
            const headers = savingValues.template.headers
            const body = savingValues.template.body
            setInitialValues({
                ...savingValues,
                template: {
                    headers: beautifyJsonString(typeof headers === 'string' ? headers : JSON.stringify(headers)),
                    body: beautifyJsonString(typeof body === 'string' ? body : JSON.stringify(body))
                }
            })
            queryClient.invalidateQueries(['flow', savingValues.id])
            queryClient.invalidateQueries(['flows', getShopId()])
            message.success(__`flow_updated`)
        }
    }, [isLoading])

    return (
        <FlowForm
            initialValues={initialValues}
            onBodyExtensionChanged={i => setBodyExt(flowBodyExtensions[i][0])}
            isCreate={false}
            isSaving={isLoading}
            onValidate={values => {
                if(values.action !== action){
                    setAction(values.action)
                }
                return validateFormValues(FlowFormSchema(action, bodyExt as string, __))
            }}
            onSave={values =>
                FlowFormSchema(action, bodyExt as string, __).validate(values).then(
                    data => save(data as Flow)
                )
            }
            onBack={() => navigate('/flow')}
        />
    )
    
}
