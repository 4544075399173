import { Availability } from './availability.type'
import { object, string } from 'yup'
import { TranslateFunction } from 'utils'
import { CalendarSlot } from 'types'

export type Resource = {
    
    id: string
    type: {
        id: string
        name: string
    }
    name: string
    email: string | null
    mobile: string | null
    description: string | null
    image: {
        id: string | null
        path: string | null
    }
    
    status: boolean
    notificationEmailStatus: boolean
    eventDescription: string | null

    calendarSync?: CalendarSync

    timezone: string,

    availabilities: Availability[]

    availabilitiesRange: {
        availableFrom: {
            type: 'NOW'
            value: 0
        } | {
            type: 'EXACT_DATE'
            value: string
        } | {
            type: 'DAYS_INTO_THE_FUTURE'
            value: number
        }
        availableTo: {
            type: 'INDEFINITELY'
            value: 0
        } | {
            type: 'EXACT_DATE'
            value: string
        } | {
            type: 'DAYS_INTO_THE_FUTURE'
            value: number
        }
    }

}

export type CalendarSync = {
    canUseCalendarSync: boolean
    calendarSyncStatus: CalendarSyncStatus
}

export type CalendarSyncStatus = 'Active' | 'Pending' | null

export type SimpleResource = Pick<Resource, 'id' | 'type' | 'name' | 'description' | 'image'>

export type SimpleResourceWithAvailabilities = SimpleResource & Pick<Resource, 'availabilities'>

export type SimpleResourceWithSlots = SimpleResource & {
    slots: CalendarSlot[]
}

export const ResourceFormSchema = (__: TranslateFunction) => object({
    type:             object({
        id: string().min(1, __`please_specify_a_type`),
    }),
    name:             string().required(__`this_field_is_required`),
    email:            string().email(__`must_be_a_valid_email`).nullable(),
    mobile:           string().nullable(),
    description:      string().nullable(),
    eventDescription: string().nullable()
})
