import { theme } from 'antd'
import { PageHeader } from 'old/components/page-header/page-header'
import { CloudUploadOutlined } from '@ant-design/icons'
import { PageContentWrapper, VerticalContainer } from 'old/components/styled'
import { Form } from 'react-final-form'
import { useTranslate, useWindowDimensions }  from 'old/utils'
import { FormZone } from 'old/components/form-zone/form-zone'
import { SettingsPlan } from 'old/components/settings/settings-plan/settings-plan'
import { SettingsSesamiStatus } from 'old/components/settings/settings-sesami-status/settings-sesami-status'
import { SettingsStore } from 'old/components/settings/settings-store/settings-store'
import { SettingsStorefront } from 'old/components/settings/settings-storefront/settings-storefront'
import { Settings }  from 'old/types'

type Props = {
    initialValues: Settings
    isSaving: boolean
    onValidate: (values: Settings) => object | undefined
    onSave: (values: Settings) => void
}

export const SettingsForm = ({ initialValues, isSaving, onValidate, onSave }: Props) => {
    
    //hooks:
    const { token: { padding, margin, marginLG, marginXL } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    
    return (
        <PageContentWrapper>

            <Form
                subscription={{ dirty: true }}
                onSubmit={onSave}
                validate={onValidate}
                initialValues={initialValues}
                render={({ form, dirty, handleSubmit }) => (

                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={__`settings`}
                            description={__`change_your_settings_and_then_save_it`}
                            primaryButton={{
                                name: __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: (isSaving ? 'loading' : dirty ? 'normal': 'disabled'),
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: isSaving ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            isFormDirty={dirty}
                        />

                        <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: SM ? marginXL : marginLG }}>
                            
                            {/* current plan */}
                            <FormZone
                                title={__`current_plan`}
                                description={__`you_can_upgrade_or_downgrade_your_plan_whenever_you_want`}
                                divider={false}
                            >
                                <SettingsPlan/>
                            </FormZone>

                            {/* sesami status */}
                            <FormZone
                                title={__`sesami_status`}
                                description={__`the_the_sesami_button_will_disappear_by_disabling_sesami_here`}
                            >
                                <SettingsSesamiStatus/>
                            </FormZone>

                            {/* store */}
                            <FormZone
                                title={__`store_settings`}
                                description={__`localize_and_customize_sesami`}
                            >
                                <SettingsStore/>
                            </FormZone>

                            {/* storefront */}
                            <FormZone
                                title={__`storefront_settings`}
                                description={__`localize_and_customize_storefront`}
                            >
                                <SettingsStorefront/>
                            </FormZone>

                        </VerticalContainer>
                    
                    </VerticalContainer>
                    
                )}
            />

        </PageContentWrapper>
    )
}
