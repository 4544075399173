import { ArrowsWrapper, DatePickerWrapper, MenuWrapper, NavigationContainer, TimezoneWrapper } from './availability-calendar-navigation.style'
import { Button, DatePicker, Typography, theme } from 'antd'
import { addDays, getDateRange, useTranslate } from 'utils'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useConfig } from 'api'
import dayjs from 'dayjs'
import { NotFound, WeekPickerPanelWrapper } from 'components/styled'
import { Field } from 'react-final-form'
import { timezones } from 'data'
import { FormSelect } from 'components/form-inputs'
const { Text } = Typography

type Props = {
    startDate: Date
    numberOfDays: number
    onStartDateChange: (startDate: Date) => void
}

export const AvailabilityCalendarNavigation = ({
    startDate,
    numberOfDays,
    onStartDateChange
}: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { fontSizeSM, fontSizeLG } } = theme.useToken()
    
    //config:
    const { data: config, isLoading: isConfigLoading } = useConfig()
    
    return (
        (isConfigLoading || !config) ? null :
        <NavigationContainer>

            {/* date */}
            <Text style={{ fontSize: fontSizeLG, fontFamily: 'inter-medium' }}>
                {getDateRange(startDate, numberOfDays, config, false)}
            </Text>

            <MenuWrapper>

                {/* timezone */}
                <TimezoneWrapper>
                    
                    <Field
                        name="timezone"
                        type="select"
                        options={timezones}
                        filterOption={(input: string, option: { value: string, label: string}) => (
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        )}
                        showSearch
                        notFoundContent={<NotFound/>}
                        component={FormSelect}
                        style={{ width: '100%' }}
                    />

                </TimezoneWrapper>
                
                <DatePickerWrapper>

                    {/* date picker */}
                    <DatePicker
                        inputReadOnly
                        picker="week"
                        defaultValue={dayjs(startDate)}
                        value={dayjs(startDate)}
                        allowClear={false}
                        style={{ width: '100%' }}
                        panelRender={panelNode => <WeekPickerPanelWrapper>{panelNode}</WeekPickerPanelWrapper>}
                        onChange={val => onStartDateChange(dayjs(val).toDate())}
                    />

                    <ArrowsWrapper>

                        {/* left arrow */}
                        <Button
                            icon={<LeftOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`previous_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => onStartDateChange(addDays(startDate, -numberOfDays))}
                        />
                        
                        {/* right arrow */}
                        <Button
                            icon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`next_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => onStartDateChange(addDays(startDate, +numberOfDays))}
                        />
                        
                    </ArrowsWrapper>

                </DatePickerWrapper>

            </MenuWrapper>

        </NavigationContainer>
    )
    
}
