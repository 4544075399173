import { Button, Typography, message, theme } from 'antd'
import { LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { useTranslate } from 'old/utils'
import { HorizontalLine, HorizontalContainer, VerticalContainer } from 'old/components/styled'
import { useEffect, useState } from 'react'
import { useSendMagicLink } from 'old/api'
const  { Text, Paragraph } = Typography

type Props = {
    email: string
    hideOr: boolean
    onSend: () => void
    onBackToLogin: () => void
}

export const LoginMagicLink = ({ email, hideOr, onSend, onBackToLogin }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: {
        marginXXS,
        marginSM,
        marginXS,
        marginXL,
        fontSize,
        fontSizeSM,
        colorPrimary,
        colorFillTertiary,
        colorTextDescription
    }} = theme.useToken()

    //state:
    const [ isMagicButtonHovered, setIsMagicButtonHovered ] = useState(false)

    //mutation:
    const { mutate, isLoading, data } = useSendMagicLink(email)

    //handle response:
    useEffect(() => {
        if(data){
            switch(data.result){
                case 'success':
                    message.success(__`magic_link_sent`)
                    onSend()
                    break
                case 'existingOTP':
                    message.info(__`the_link_sent_to_you_less_than_5_min_ago`)
                    onSend()
                    break
                case 'wrongStrategy':
                case 'userNotActivated':
                case 'userNotFound':
                    message.error(__`oops_something_went_wrong`)
                    onBackToLogin()
                    break
            }
        }
    }, [data])

    //render:
    return (
        <VerticalContainer style={{ gap: marginSM, marginTop: marginXXS }}>

            {/* or */}
            {!hideOr && 
            <HorizontalContainer style={{ gap: marginXS }}>

                <HorizontalLine/>
                <Text style={{ fontSize: fontSizeSM, color: colorTextDescription, fontFamily: 'inter-medium' }}>
                    {__`or_login_with_otp`}
                </Text>
                <HorizontalLine/>

            </HorizontalContainer>}

            <VerticalContainer style={{ gap: marginXS, marginTop: hideOr ? -marginXL : 0 }}>

                {/* otp button */}
                <Button
                    size='large'
                    style={{ borderColor: colorPrimary, backgroundColor: isMagicButtonHovered ? colorFillTertiary : 'transparent' }}
                    onMouseEnter={() => setIsMagicButtonHovered(true)}
                    onMouseLeave={() => setIsMagicButtonHovered(false)}
                    onClick={() => !isLoading && mutate()}
                >

                    <HorizontalContainer style={{ justifyContent: 'space-between' }}>

                        <RightOutlined style={{ visibility: 'hidden' }}/>
                        <Text style={{ color: colorPrimary, fontFamily: 'inter-medium' }}>{__`send_magic_link`}</Text>
                        {isLoading ?
                            <LoadingOutlined style={{ fontSize, color: colorPrimary }}/>
                            :
                            <RightOutlined style={{ fontSize, color: colorPrimary }}/>
                        }

                    </HorizontalContainer>

                </Button>

                {/* otp description */}
                <Paragraph style={{ fontSize: fontSizeSM, color: colorTextDescription, marginBottom: 0 }}>
                    {__`a_one_time_password_and_a_link_will_be_sent_to_your_email_you_can_enter_the_code_or_click_on_the_link_to_login_without_password`}
                </Paragraph>

            </VerticalContainer>

        </VerticalContainer>
    )

}
