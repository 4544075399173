import { Button, Typography, theme } from 'antd'
import { ReactNode, useEffect } from 'react'
import { HorizontalContainer, Logo, LogoLoading, StaticPageError, VerticalContainer } from 'old/components/styled'
import { useTranslate, useWindowDimensions }  from 'old/utils'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { WarningOutlined, HomeOutlined, CheckCircleFilled } from '@ant-design/icons'
import { useConfig } from 'old/api'
import { FormSection } from 'old/components/form-section/form-section'
import { ScreenSize }  from 'old/types'
const  { Text, Paragraph } = Typography

export const MessagePage = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { colorPrimary } } = theme.useToken()

    //url params:
    const [ searchParams ] = useSearchParams()
    const action = searchParams.get('action')
    const payload = searchParams.get('payload')

    //navigate:
    const navigate = useNavigate()

    //config:
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //default props:
    let icon = <WarningOutlined/>
    let message = __`oops_something_went_wrong`
    let description = __`navigate_to_the_main_page_and_start_fresh`
    let actionButton = {
        text: __`go_to_home`,
        icon: <HomeOutlined/>,
        onSelect: () => navigate('/')
    }

    //extractError:
    const extractError = (payload: string) => {
        let error
        try{
            const result = JSON.parse(payload)
            error = result.errors?.[0].extensions?.errors?.[0].constraints?.[0].name
        }catch(e){error = null}
        return error
    }

    //props from url:
    if(action && payload){

        //invitation:
        if(action === 'invitation'){
            const error = extractError(payload)
            if(error === 'valid_otp'){
                message = __`the_invitation_link_has_been_expired`
            }
        }

        //login with Google:
        if(action === 'loginWithGoogle'){
            const error = extractError(payload)
            if(error === 'not_found'){
                message = __`user_not_found`
            }else if(error === 'login_strategy'){
                message = __`you_have_a_different_login_method`
            }
        }

        //user activated:
        if(action === 'userActivated'){
            icon = <CheckCircleFilled style={{ color: colorPrimary }}/>
            message = __`vola_your_account_has_been_activated`
            description = __`enter_the_admin_and_set_a_login_format_for_your_account`
            actionButton = {
                ...actionButton,
                text: __`go_to_login`
            }
        }

        //Google sync:
        if(action === 'syncGoogle'){
            //do nothing, just the default error...
        }

    }

    //if no payload:
    useEffect(() => { (!action || !payload) && navigate('/') }, [action, payload])

    //render:
    return isConfigLoading ? <LogoLoading/> : !config ? <StaticPageError/> : (action && payload) ? (
        <MessagePageUI
            icon={icon}
            message={message}
            description={description}
            actionButton={actionButton}
        />
    ) : <></>

}

type MessagePageUIProps = {
    icon?: ReactNode
    message: string
    description?: string
    actionButton?: {
        text: string
        icon?: ReactNode
        onSelect: () => void
    }
}

export const MessagePageUI = ({ icon, message, description, actionButton }: MessagePageUIProps) => {

    //theme:
    const { token: {
        marginXXS,
        marginXS,
        marginLG,
        paddingSM,
        paddingXS,
        paddingXL,
        fontSizeSM,
        fontSizeXL,
        colorBgLayout,
        colorBgContainer,
        colorTextDescription
    }} = theme.useToken()

    //screen:
    const { height, SM } = useWindowDimensions()
    
    //render:
    return (
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: (SM && height > ScreenSize.SM) ? colorBgLayout : colorBgContainer
        }}>

            <FormSection
                style={{
                    width: '100%',
                    maxWidth: 500,
                    maxHeight: '100%',
                    padding: `${SM ? paddingXL : paddingSM}px ${paddingXL}px `,
                    border: (height > ScreenSize.SM) ? undefined : 'none',
                    boxShadow: (height > ScreenSize.SM) ? undefined : 'none',
                    position: 'absolute',
                    top: SM ? '45%' : '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
                childrenWrapperStyle={{ padding: 0 }}
            >

                <VerticalContainer style={{ gap: marginLG, padding: `${paddingSM}px ${paddingXS}px` }}>
                
                    {/* logo */}
                    <Logo withName={true}/>

                    <VerticalContainer style={{ gap: marginXS, alignItems: 'center' }}>

                        <HorizontalContainer style={{ marginTop: marginXXS, justifyContent: 'center', gap: marginXS }}>
                            
                            <Text style={{ fontSize: fontSizeXL, fontFamily: 'inter-medium' }}>{icon}</Text>
                            <Text style={{ fontSize: fontSizeXL, fontFamily: 'inter-medium' }}>{message}</Text>

                        </HorizontalContainer>

                        <Paragraph style={{ color: colorTextDescription, fontSize: fontSizeSM, textAlign: 'center' }}>
                            {description}
                        </Paragraph>

                        <Button
                            type='link'
                            icon={actionButton?.icon}
                            onClick={actionButton?.onSelect}
                        >
                            {actionButton?.text}
                        </Button>

                    </VerticalContainer>

                </VerticalContainer>

            </FormSection>

        </div>
    )

}
