import { Button, Dropdown, MenuProps, theme, Typography } from 'antd'
import { HorizontalContainer, Label } from 'components/styled'
import { MoreOutlined } from '@ant-design/icons'
import { capitalizeFirstLetter, generatePhraseFromKey, objectAssign, useTranslate, useWindowDimensions } from 'utils'
const { Text } = Typography

type Props = {
    name: string
    target: string
    url: string
    style?: object,
    onDelete?: () => void
}

export const ExtensionItem = ({
    name,
    target,
    url,
    style,
    onDelete,
}: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { LG } = useWindowDimensions()
    const { token: {
        marginXXS,
        marginXS,
        paddingSM,
        marginSM,
        colorBorderSecondary
    }} = theme.useToken()

    const items: MenuProps['items'] = [{ key: 'remove', label: __`remove_extension` }]

    //onClick:
    const onClick: MenuProps['onClick'] = () => {
        onDelete?.()
    }

    return (
        <HorizontalContainer style={{
                paddingBottom: LG ? 0 : paddingSM,
                borderBottom: LG ? undefined : `solid 1px ${colorBorderSecondary}`,
                gap: 0,
                alignItems: LG ? 'center' : 'flex-start'
            }}
        >

            <HorizontalContainer style={{
                    width: 'calc(100% - 130px)',
                    flexDirection: LG ? 'row' : 'column',
                    alignItems: 'flex-start',
                    gap: LG ? marginSM : marginXXS
                }}
            >
                
                {getValueJSX(name, objectAssign({
                    width: LG ? '28%' : 'unset',
                    fontWeight: LG ? 'normal' : 'bold'
                }, style))}

                {getValueJSX(generatePhraseFromKey(target), objectAssign({
                    width: LG ? '36%' : 'unset'
                }, style))}

                {getValueJSX(url, objectAssign({
                    width: LG ? '36%' : 'unset'
                }, style))}

            </HorizontalContainer>

            <HorizontalContainer style={{ minWidth: 130 }}>

                {getTargetHost(target) !== '' && (
                    <HorizontalContainer style={{ flex: 1, justifyContent: 'center' }}>
                        <Label type='disable' text={getTargetHost(target)}/>
                    </HorizontalContainer>
                )}

                <div style={{
                        marginRight: -marginXS,
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    {onDelete && (
                        <Dropdown menu={{ items, onClick }} trigger={['click']}>
                            <Button
                                type={'text'}
                                shape={'circle'}
                                size={'large'}
                                icon={<MoreOutlined/>}
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}
                            />
                        </Dropdown>
                    )}
                </div>

            </HorizontalContainer>

        </HorizontalContainer>
    )

}

const getValueJSX = (value: string, style?: object) => (
    <Text
        title={value}
        style={objectAssign({
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }, style)}
    >
        {value}
    </Text>
)    

const getTargetHost = (target: string) => {
    const splitted = target.valueOf().split('_')
    return splitted.length > 1 ? capitalizeFirstLetter(splitted[0]) : ''
}
