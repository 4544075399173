import styled from 'styled-components'
import { useTranslate } from 'utils'

type Props = {
    message?: string
    style?: object
}

export const NotFound = ({ message, style }: Props) => {
    const { __ } = useTranslate()
    return <Wrapper style={style}>{message ?? __`not_found`}</Wrapper>
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    color: ${props => props.theme.colorTextDescription};
    padding: ${props => `${props.theme.paddingXS}px`};
    font-size: ${props => `${props.theme.fontSizeSM}px`};
    justify-content: center;
    align-items: center;
`
