import { PageHeader } from 'components/page-header/page-header'
import { theme } from 'antd'
import { isNil, setCacheManually, useTranslate, useWindowDimensions } from 'utils'
import { PageContentWrapper, VerticalContainer } from 'components/styled'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useConfig, useRegisteredApps } from 'api'
import { Config, Feature } from 'types'
import { FormSection } from 'components/form-section/form-section'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { RegisteredAppItem } from 'components/app/registered-app-item/registered-app-item'

export const RegisteredAppsList = () => {

    //hooks:
    const { token: { padding, paddingMD, marginXXS, margin } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { data: config } = useConfig()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ search, setSearch ] = useState<string | null>(null)

    //registered apps:
    const {
        data: apps,
        pageNum,
        totalPages,
        total,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useRegisteredApps(search, 15)

    //if apps are not enabled:
    useEffect(() => { config && !config.features.includes(Feature.APPS) && navigate('/') }, [config])
    
    return (!config || !config.features.includes(Feature.APPS)) ? <></> : (
        
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`apps`}
                    description={__`manage_all_your_registered_apps`}
                    primaryButton={{
                        name: __`register_a_new_app`,
                        icon: <PlusOutlined/>,
                        mode: canRegisterApp(config, !isNil(apps), total) ? 'normal' : 'disabled',
                        title: canRegisterApp(config, !isNil(apps), total) ? undefined : __`you_reached_your_plans_max_limit`,
                        onClick: () => navigate('/developers/register')
                    }}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>

                    <FormSection
                        title={SM ? __`registered_apps` : undefined }
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        searchInput={{
                            placeholder: __`search_apps`,
                            onSearch: setSearch
                        }}
                    >

                        {/* list */}
                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={apps === null}
                            isError={apps === false}
                            notFound={(apps && apps.length === 0) ?? false}
                            totalPages={totalPages}
                            pageNum={pageNum}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >

                            {apps && apps?.map(app => (
                                <RegisteredAppItem
                                    key={app.id}
                                    app={app}
                                    labelKey={app.distribution.toLocaleLowerCase()}
                                    labelType={app.distribution === 'PUBLIC' ? 'success' : 'info'}
                                    onClick={() => {
                                        setCacheManually(['registeredApp', app.id], app)
                                        navigate(`/developers/${app.id}`)
                                    }}
                                />
                            ))}

                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}

//canRegisterApp:
export const canRegisterApp = (config: Config, dataLoaded: boolean, total: number) => {
    if(dataLoaded){
        if(config.maxApp && total < config.maxApp){
            return true
        }
    }
    return false
}
