import { HorizontalContainer, Loading, PageError, VerticalContainer } from 'old/components/styled'
import { Button, Dropdown, MenuProps, Typography, message, theme } from 'antd'
import { PropsWithChildren } from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { useTranslate, useWindowDimensions } from 'old/utils'
import { Avatar } from 'old/components/avatar/avatar'
import { useFlow, useService, useCurrentShop, useTeamMember } from 'old/api'
import { getFlowIcon }  from 'old/types'
const { Text, Paragraph } = Typography

type Props = {
    permission: string
    onRemove: () => void
}

//UserShopPermissionItem:
export const UserShopPermissionItem = ({ permission, onRemove }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //info:
    const splitPermission = permission.split(':')
    const level = splitPermission[2]

    //shop:
    const { data: shop, isLoading } = useCurrentShop()

    //render:
    return (
        <UserPermissionItem
            level={__(level.toLowerCase())}
            note={null}
            onRemove={onRemove}
        >
            {isLoading ? <Loading/> : !shop ? <PageError/> :
                <Avatar
                    name={shop.name}
                    description={shop.url}
                    image={shop.image.path}
                />
            }
        </UserPermissionItem>
    )

}

//UserResourcePermissionItem
export const UserResourcePermissionItem = ({ permission, onRemove }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //info:
    const splitPermission = permission.split(':')
    const id = splitPermission[1]
    const level = splitPermission[2]

    //resource:
    const { data: resource, isLoading } = useTeamMember(id, () => {
        message.error(__`the_team_member_that_you_had_access_is_not_available`)
        onRemove()
    })

    //render:
    return (
        <UserPermissionItem
            level={__(level.toLowerCase())}
            note={null}
            onRemove={onRemove}
        >
            {isLoading ? <Loading style={{ paddingTop: 19, paddingBottom: 18 }}/> : !resource ? <></> :
                <Avatar
                    name={resource.name}
                    description={resource.email}
                    image={resource.image.path}
                />
            }
        </UserPermissionItem>
    )

}

//UserServicePermissionItem
export const UserServicePermissionItem = ({ permission, onRemove }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //info:
    const splitPermission = permission.split(':')
    const id = splitPermission[1]
    const level = splitPermission[2]

    //services:
    const { data: service, isLoading } = useService(id, true, () => {
        message.error(__`the_service_that_you_had_access_is_not_available`)
        onRemove()
    })

    //render:
    return (
        <UserPermissionItem
            level={__(level.toLowerCase())}
            note={null}
            onRemove={onRemove}
        >
            {isLoading ? <Loading style={{ paddingTop: 19, paddingBottom: 18 }}/> : !service ? <></> :
                <Avatar
                    name={service.title}
                    image={service.image.path}
                />
            }
        </UserPermissionItem>
    )

}

//UserFlowPermissionItem
export const UserFlowPermissionItem = ({ permission, onRemove }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { colorText, colorFillTertiary } } = theme.useToken()

    //info:
    const splitPermission = permission.split(':')
    const id = splitPermission[1]
    const level = splitPermission[2]

    //flows:
    const { data: flow, isLoading } = useFlow(id, () => {
        message.error(__`the_flow_that_you_had_access_is_not_available`)
        onRemove()
    })

    //render:
    return (
        <UserPermissionItem
            level={__(level.toLowerCase())}
            note={null}
            onRemove={onRemove}
        >
            {isLoading ? <Loading style={{ paddingTop: 19, paddingBottom: 18 }}/> : !flow ? <></> :
                <Avatar
                    name={flow.name}
                    description={__(flow.trigger.toLowerCase())}
                    image={null}
                    placeholder={getFlowIcon(flow.trigger)}
                    color={colorText}
                    backgroundColor={colorFillTertiary}
                />
            }
        </UserPermissionItem>
    )

}

type ItemProps = {
    level: string
    note: string | null
    onRemove: () => void
}

export const UserPermissionItem = ({ level, note, children, onRemove }: PropsWithChildren<ItemProps>) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSizeSM, borderRadiusLG, paddingXXS, paddingXS, paddingSM, colorBorder } } = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //menu:
    const items: MenuProps['items'] = [{ key: 'remove', label: __`remove_permission` }]

    //onClick:
    const onClick: MenuProps['onClick'] = () => onRemove()

    //render:
    return (
        <VerticalContainer
            style={{
                padding: `${paddingXS}px ${SM ? paddingXS : paddingXXS}px ${paddingXS}px ${SM ? paddingXS : 0}px`,
                border: `solid 1px ${colorBorder}`,
                borderRadius: borderRadiusLG,
                alignItems: 'flex-start'
            }}
        >

            <HorizontalContainer style={{ width: '100%' }}>

                {/* avatar */}
                {children}

                <HorizontalContainer>

                    {/* permission level */}
                    <Text style={{ whiteSpace: 'nowrap' }}>{level}</Text>

                    {/* remove button */}
                    <Dropdown menu={{ items, onClick }} trigger={['click']}>
                        <Button
                            type={'text'}
                            shape={'circle'}
                            size={'large'}
                            onClick={e => e.preventDefault()}
                        >
                            <MoreOutlined/>
                        </Button>
                    </Dropdown>

                </HorizontalContainer>

            </HorizontalContainer>

            {/* note */}
            {note && <Paragraph type='secondary' style={{ fontSize: fontSizeSM, margin: 0, paddingLeft: paddingSM }}>{note}</Paragraph>}

        </VerticalContainer>
    )
    
}
