import { PageHeader } from 'components/page-header/page-header'
import { PlusOutlined } from '@ant-design/icons'
import { isNil, useTranslate, useWindowDimensions } from 'utils'
import { useConfig, useCurrentShop, useResources } from 'api'
import { useNavigate } from 'react-router-dom'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { useState } from 'react'
import { PageContentWrapper, PageError, VerticalContainer } from 'components/styled'
import { theme } from 'antd'
import { FormSection } from 'components/form-section/form-section'
import { ResourceItem } from 'components/resource/resource-item/resource-item'
import { Config } from 'types'
import { ResourceTypeSelect } from 'components/resource/resource-type-select/resource-type-select'

export const ResourcesList = () => {

    //hooks:
    const { token: { paddingMD, marginXXS, margin, padding } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config } = useConfig()
    const { data: shop } = useCurrentShop()
    const navigate = useNavigate()

    //state:
    const [ typeId, setTypeId ] = useState<string>('all')
    const [ searchResources, setSearchResources ] = useState<string | null>(null)
    
    //api:
    const {
        data: resources,
        pageNum,
        totalPages,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useResources(null, typeId === 'all' ? null : typeId, searchResources, 15)

    const {
        data: allResources,
        total: allTotal
    } = useResources(null, null, null, 1) // to get the total resources number, without any filter or search

    const allTypesOption = { value: 'all', label: __`all_types` }

    return (!config || !shop) ? <PageError/> : (
        
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`resources`}
                    description={__`manage_resources_things_like_team_members_rooms_and_machines`}
                    primaryButton={{
                        name: SM ? __`create_resource` : __`create`,
                        icon: <PlusOutlined/>,
                        mode: canCreateResource(config, !isNil(allResources), allTotal) ? 'normal' : 'disabled',
                        title: canCreateResource(config, !isNil(allResources), allTotal) ? undefined : __`you_reached_your_plans_max_limit`,
                        onClick: () => navigate('/resource/new')
                    }}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>
                
                    <FormSection
                        title={SM ? __`resources` : undefined }
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        topNode={
                            <ResourceTypeSelect
                                extraOptions={[allTypesOption]}
                                value={typeId}
                                onResourceTypeSelect={type => setTypeId(type.id)}
                                style={{ minWidth: SM ? 190 : 150, maxWidth: SM ? 190 : 150 }}
                            ></ResourceTypeSelect>
                        }
                        searchInput={{
                            placeholder: __`search_resources`,
                            onSearch: setSearchResources
                        }}
                    >

                        {/* list */}
                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={resources === null}
                            isError={resources === false}
                            notFound={(resources && resources.length === 0) ?? false}
                            totalPages={totalPages}
                            pageNum={pageNum}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            {resources && resources?.map(resource => (
                                <ResourceItem
                                    key={resource.id}
                                    resource={resource}
                                />
                            ))}
                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}

//canCreateResource:
export const canCreateResource = (config: Config, dataLoaded: boolean, total: number) => {
    if(dataLoaded){
        if(config.plan?.maxResources && total < config.plan.maxResources){
            return true
        }
    }
    return false
}
