import styled from 'styled-components'

export const MainContainer = styled.div`
    width: calc(100% - ${props => props.theme.margin*2 + 'px'});
    max-width: ${props => props.theme.marginXXL*9.4 + 'px'};
    margin: ${props => props.theme.margin + 'px'};
    display: inline-block;
`

export const MainWrapper = styled.div`
    width: 100%;
    min-height: ${props => props.theme.marginXXL*8 + 'px'};
    padding: ${props => `
        ${props.theme.paddingXL}px
        ${props.theme.paddingLG}px
        ${props.theme.paddingLG}px
        ${props.theme.paddingXL}px
    `};
    border-radius: ${props => props.theme.borderRadiusLG*2 + 'px'};
    background-color: ${props => props.theme.colorBgContainer};
    border: ${props => 'solid 1px ' + props.theme.colorBorderSecondary};
    box-shadow: ${props => props.theme.boxShadow};
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.margin}px`};
`

export const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`

export const NameDescriptionWrapper = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    gap: ${props => `${props.theme.marginXS}px`};
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`
