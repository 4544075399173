import { useConfig } from 'old/api'
import { useEffect, useState } from 'react'
import { replaceVariables } from '@sesamiapp/translation'

export type TranslateKey = null | string | TemplateStringsArray
export type TranslateFunction = (key: TranslateKey) => string

export const useTranslate = () => {
    
    const { data: config, isLoading } = useConfig()
    const [ translations, setTranslations ] = useState({})
    
    useEffect(() => {
        if(config){
            import(`../translations/${config.locale}.json`).then(module => {
                setTranslations(module.default)
            }).catch(() => {
                setTranslations({})
            })
        }
    }, [config])

    return isLoading ? {
        __: () => '',
        isLoading: true,
        isError: false
    } : !config ? {
        __: () => '',
        isLoading: false,
        isError: true
    } : {
        __: (key: TranslateKey, replacements?: any) => {
            const dictionaryKey = key as keyof typeof translations
            const translation = translations[dictionaryKey]
            return translation ? replaceVariables(translation, replacements) : ''
        },
        isLoading: false,
        isError: false
    }
}
