import { PageError } from './page-error'
import { useTranslate }  from 'old/utils'

type Props = {
    message?: string
    style?: object
}

export const Forbidden = ({ message, style }: Props) => {

    //translate:
    const { __ } = useTranslate()

    return <PageError message={message ?? __`forbidden_you_dont_have_access_to_this_part`} style={style}/>

}
