import { usePaginationHandler } from 'old/utils'
import { Location } from 'types'
import {
    getShopId, updateAdminVersion
} from 'utils'

const locationQuery = `

    id
    name
    phone
    email
    description
    image {
        id
        path
    }

    timezone
    
    businessAddress {
        country
        address
        suite
        city
        state
        zipCode
        latitude
        longitude
    }
    
    isDefault
    status
    
`

//useLocations:
export const useLocations = (search: string | null, limit: number, status?: boolean) => {
    const shopId = getShopId()
    return usePaginationHandler<Location[]>({
        query: `
            query getLocations(
                $shop: String!,
                $status: Boolean,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String
            ){
                getLocations(
                    shop: $shop,
                    status: $status,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                ){
                    data{
                        ${locationQuery}
                    }
                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                }
            }
        `,
        variables: {
            shop: shopId,
            status,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['locations', shopId, search, status],
        limit,
        getResponse: json => json.data.getLocations,
        onSuccess: response => {

            if(response.data.length > 0){
                updateAdminVersion('v2')
            }
            
            return response.data
        }
    })

}
