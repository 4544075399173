import { objectAssign, useTranslate, useWindowDimensions } from 'utils'
import { Typography } from 'antd'
const { Paragraph } = Typography

type Props = {
    style?: object
}

export const Footer = ({ style }: Props) => {

    //window dimensions:
    const { SM } = useWindowDimensions()
    
    //translate:
    const { __ } = useTranslate()

    return (
        <Paragraph style={objectAssign({
            textAlign: 'center',
            background: SM ? '#f5f5f5' : '#fff'
        }, style)}>
            Sesami.co © 2024
            &nbsp;
            {__`all_rights_reserved`}
        </Paragraph>
    )
}
