import { BookingAction, BookingPageDispatch, BookingPageState } from 'pages/booking/booking-page/booking-page'
import { ResourceSelect } from 'components/resource/resource-select/resource-select'
import { VerticalContainer } from '../../styled'
import { theme } from 'antd'
import { Location, Service } from '../../../types'
import { useCurrentLocation } from 'api'

type Props = {
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const BookingResource = ({ pageState, pageStateDispatch }: Props) => {

    const { token: { marginLG } } = theme.useToken()
    const { data: location } = useCurrentLocation()

    const serviceLocationResources = location ? findResourceGroup(location, pageState.service) : []

    return serviceLocationResources.length ? (
        <VerticalContainer style={{ gap: marginLG }}>
            {serviceLocationResources.map((rr, i) => (
                <ResourceSelect
                    key={`${rr.type.id}.${i}`}
                    title={rr.type.name}
                    items={rr.resources.map(sr => ({ ...sr, availabilities: [] }))}
                    selectedId={pageState.resources[i]?.id ?? null}
                    onSelect={resource => {
                        const newResources = [...pageState.resources]
                        newResources[i] = resource
                        pageStateDispatch({
                            type: BookingAction.SET_RESOURCES,
                            payload: { resources: newResources }
                        })
                    }}
                />
            ))}
        </VerticalContainer>
    ) : <></>

}

export const findResourceGroup = (location: Location | null, service: Service | null) => (
    service?.locations.find(l => l.id === (location?.id))?.resources ?? []
)
