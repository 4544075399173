import { objectAssign, useTranslate, useWindowDimensions }  from 'old/utils'
import { Typography } from 'antd'
const { Paragraph } = Typography

type Props = {
    style?: object
}

export const Footer = ({ style }: Props) => {

    //window dimensions:
    const { SM } = useWindowDimensions()
    
    //translate:
    const { __ } = useTranslate()

    //render:
    return (
        <Paragraph style={objectAssign({
            textAlign: 'center',
            background: SM ? '#f5f5f5' : '#fff'
        }, style)}>
            {__`sesami_co_c`}
            &nbsp;
            {2024}
            &nbsp;
            {__`all_rights_reserved`}
        </Paragraph>
    )
}
