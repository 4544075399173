import { Typography, theme } from 'antd'
import { FormSection } from 'old/components/form-section/form-section'
import { VerticalContainer } from 'old/components/styled'
import { useField } from 'react-final-form'
import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { useTranslate }  from 'old/utils'
const { Text, Paragraph } = Typography

export const AppClientId = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: {
        colorBgTextHover,
        colorTextTertiary,
        colorTextDescription,
        paddingXXS,
        borderRadius,
        paddingXS,
        paddingXL,
        marginXS
    }} = theme.useToken()
    const { input: { value: clientId } } = useField('clientId')

    return (
        <FormSection title={__`client_id`}>
            
            <VerticalContainer style={{ gap: marginXS }}>

                <Paragraph
                    style={{
                        width: 'fit-content',
                        padding: `${paddingXXS}px 0`,
                        paddingRight: paddingXL,
                        margin: 0
                    }}
                    copyable={{
                        icon: [
                            <CopyOutlined  key={1} style={{ padding: paddingXXS, color: colorTextTertiary }}/>,
                            <CheckOutlined key={2} style={{ padding: paddingXXS, color: colorTextTertiary }}/>
                        ],
                        tooltips: [__`copy`, __`copied`],
                        text: clientId
                    }}
                >
                    
                    {__`your_apps_client_id_is`}
                    
                    <Text style={{
                        padding: paddingXS,
                        fontWeight: 'bold',
                        background: colorBgTextHover,
                        borderRadius: borderRadius,
                        marginLeft: marginXS
                    }}>
                        {clientId}
                    </Text>

                </Paragraph>

                <Paragraph style={{ margin: 0, color: colorTextDescription }}>
                    {__`use_this_to_identify_your_app`}
                </Paragraph>

            </VerticalContainer>

        </FormSection>
    )

}
