import { useEffect, useRef, useState } from 'react'
import { useConfig, useCreateResource, useCreateResourceType, useResource, useResources, useResourceTypes, useUpdateResource } from 'api'
import { Resource, ResourceFormSchema } from 'types'
import { HorizontalContainer, Loading, PageContentWrapper, PageError, RWContainer, RWElement, VerticalContainer } from 'components/styled'
import { CloudUploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { browserTimezone, getShopId, queryClient, useTranslate, useWindowDimensions, validateFormValues } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { FormSection } from 'components/form-section/form-section'
import { Button, Divider, Input, InputRef, Tabs, Typography, message, theme } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { Form, useField } from 'react-final-form'
import { ImageUpload } from 'components/image-upload/image-upload'
import { canCreateResource } from '../resource-list/resource-list'
import { ResourceInfo } from 'components/resource/resource-info/resource-info'
import { ResourceAvailabilities } from 'components/resource/resource-availabilities/resource-availabilities'
import { ResourceNotification } from 'components/resource/resource-notification/resource-notification'
import { ResourceGoogleSync } from 'components/resource/resource-google-sync/resource-google-sync'
import { ResourceRemove } from 'components/resource/resource-remove/resource-remove'
import { Status } from 'components/status/status'
import { ResourceAvailabilitiesRange } from 'components/resource/resource-availabilities-range/resource-availabilities-range'
import { ResourceTypeSelect } from 'components/resource/resource-type-select/resource-type-select'
const { Text } = Typography

export const ResourceForm = () => {
    const params = useParams()
    return params.id ? <EditResource id={params.id}/> : <CreateResource/>
}

const CreateResource = () => {

    //hooks:
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const navigate = useNavigate()

    //api:
    const { data: resources, total, isLoading: isResourcesLoading } = useResources(null, null, null, 1)

    //redirect if reached max limit:
    useEffect(() => {
        if(config && resources){
            if(!canCreateResource(config, true, total)){
                navigate('/resource')
            }
        }
    }, [config, resources])

    return (
        (isConfigLoading || isResourcesLoading) ? <Loading/> :
        (!config || !resources) ? <PageError/> :
        <ResourceFormController resource={null}/>
    )

}

type EditResourceParams = { id: string }
const EditResource = ({ id }: EditResourceParams) => {

    //hooks:
    const navigate = useNavigate()
    const { data: resource, isLoading } = useResource(id, () => navigate('/resource'))

    //redirect if not found:
    useEffect(() => {
        if(resource === null || resource === false){
            navigate('/resource')
        }
    }, [isLoading])

    return isLoading ? <Loading/> : !resource ? <PageError/> : <ResourceFormController resource={resource}/>

}

type ResourceControllerProps = { resource: Resource | null }
export const ResourceFormController = ({ resource }: ResourceControllerProps) => {

    const isCreate = !resource

    //hooks:
    const { token: { marginXS, margin, padding, paddingLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ initialValues, setInitialValues ] = useState(resource ?? getDefaultValues())
    const [ savingValues , setSavingValues  ] = useState(resource ?? initialValues)

    useEffect(() => {
        if(resource){
            setInitialValues({
                ...initialValues,
                calendarSync: resource.calendarSync //exception
            })
        }
    }, [resource])

    //api:
    const { data: resourceTypes } = useResourceTypes(null, 50)
    const { mutate: createResource, isLoading: isCreateLoading, data: createResponse } = useCreateResource(savingValues)
    const { mutate: updateResource, isLoading: isUpdateLoading, data: updateResponse } = useUpdateResource(savingValues)

    const create = (values: Resource) => {
        setSavingValues(values)
        createResource()
    }
    const update = (values: Resource) => {
        setSavingValues(values)
        updateResource()
    }

    //after loading types, select the first one (when create)
    useEffect(() => {
        if(resourceTypes && resourceTypes.length && !initialValues.type.id){
            setInitialValues({
                ...initialValues,
                type: resourceTypes[0]
            })
        }
    }, [resourceTypes])

    //after create:
    useEffect(() => {
        if(createResponse === true){
            queryClient.invalidateQueries(['resources', getShopId()])
            message.success(__`resource_created`)
            navigate(`/resource`)
        }else if(createResponse === 'name_is_used'){
            message.error(__`resource_names_have_to_be_unique`)
        }
    }, [isCreateLoading])

    //after update:
    useEffect(() => {
        if(updateResponse){
            if(updateResponse === 'name_is_used'){
                message.error(__`resource_names_have_to_be_unique`)
            }else{
                setInitialValues({
                    ...savingValues,
                    calendarSync: updateResponse
                })
                queryClient.invalidateQueries(['resource', savingValues.id])
                invalidateRelatedCache()
                message.success(__`resource_updated`)
            }
        }
    }, [isUpdateLoading])

    const invalidateRelatedCache = () => {
        queryClient.invalidateQueries(['resources', getShopId()])
        queryClient.invalidateQueries(['services' , getShopId()])
        queryClient.resetQueries(['service'])
    }

    return (
        <PageContentWrapper>
            <Form
                initialValues={initialValues}
                subscription={{ dirty: true }}
                validate={validateFormValues(ResourceFormSchema(__))}
                onSubmit={values => (
                    ResourceFormSchema(__).validate(values).then(
                        data => isCreate ? create(data as Resource) : update(data as Resource)
                    )
                )}
                render={({ form, dirty, handleSubmit }) => (
                    
                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={isCreate ? __`create_resource` : initialValues.name}
                            description={isCreate ? __`create_new_resource_and_determine_availability` : __`manage_your_resource`}
                            primaryButton={{
                                name: isCreate ? __`create` : __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: (isCreateLoading || isUpdateLoading) ? 'loading' : dirty ? 'normal': 'disabled',
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: (isCreateLoading || isUpdateLoading) ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: () => navigate('/resource')
                            }}
                            isFormDirty={dirty}
                        />

                        <Tabs
                            defaultActiveKey="basic"
                            style={{ padding: SM ? `0 ${paddingLG}px` : `0 ${padding}px` }}
                            items={[
                                {
                                    key: 'basic',
                                    label: __`basic`,
                                    children: (
                                        <div style={{ margin: SM ? `0 ${-marginXS}px` : `0 ${-margin}px` }}>
                                            <BasicContents isCreate={isCreate}/>
                                        </div>
                                    )
                                },
                                {
                                    key: 'advanced',
                                    label: __`advanced`,
                                    children: (
                                        <div style={{ margin: SM ? `0 ${-marginXS}px` : `0 ${-margin}px` }}>
                                            <AdvancedContents isCreate={isCreate} dirty={dirty}/>
                                        </div>
                                    )
                                }
                            ]}
                        />

                    </VerticalContainer>
                )}
            />
            
        </PageContentWrapper>
    )
    
}

//BasicContents:
const BasicContents = ({ isCreate }: { isCreate: boolean }) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { fontSizeSM, paddingXXS, paddingXS, padding, paddingLG, marginXS, marginLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const inputRef = useRef<InputRef>(null)

    //field:
    const { input: { value: typeId, onChange: updateTypeId } } = useField('type.id')

    //state:
    const [ newType, setNewType ] = useState('')

    //api:
    const { mutate: create, isLoading, data: createResponse } = useCreateResourceType(newType)

    const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewType(event.target.value)
    }

    const addType = () => {
        if(newType){
            create()
        }else{
            inputRef.current?.focus()
        }
    }

    //after create type:
    useEffect(() => {
        if(createResponse){
            queryClient.invalidateQueries(['resourceTypes', getShopId()])
            setNewType('')
            inputRef.current?.focus()
        }
    }, [createResponse])

    return (
        <VerticalContainer style={{ paddingTop: paddingXS, gap: marginLG }}>

            <FormSection style={{ padding: SM ? `${paddingLG}px 0 ` : 0 }}>

                <HorizontalContainer style={{
                    flexDirection: SM ? 'row' : 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: SM ? padding : paddingXXS
                }}>

                    <Text style={{
                        paddingTop: paddingXXS,
                        fontFamily: 'inter-medium'
                    }}>
                        {__`resource_type`}
                    </Text>

                    {/* resource type */}
                    <ResourceTypeSelect
                        value={typeId}
                        disabled={!isCreate}
                        onResourceTypeSelect={type => updateTypeId(type.id)}
                        style={{ minWidth: SM ? 230 : '100%' }}
                        dropdownRender={(menu: any) => (
                            <>
                                {menu}

                                <Divider style={{ marginTop: paddingXXS, marginBottom: paddingXS }} />
                                
                                <HorizontalContainer style={{ padding: paddingXS, paddingTop: paddingXXS }}>
                                    
                                    <Input
                                        placeholder={__`room_boat_etc`}
                                        ref={inputRef}
                                        value={newType}
                                        onChange={onTypeChange}
                                        disabled={isLoading}
                                        onKeyDown={e => {
                                            e.stopPropagation()
                                            if(e.key === 'Enter'){
                                                addType()
                                            }
                                        }}
                                    />
                                    
                                    <Button
                                        type="text"
                                        icon={isLoading ? <LoadingOutlined style={{ fontSize: fontSizeSM }}/> : <PlusOutlined style={{ fontSize: fontSizeSM }}/>}
                                        disabled={isLoading}
                                        onClick={e => {
                                            e.preventDefault()
                                            addType()
                                        }}
                                    >
                                        {__`add`}
                                    </Button>

                                </HorizontalContainer>
                            </>
                        )}
                    ></ResourceTypeSelect>

                </HorizontalContainer>

            </FormSection>
            
            <FormSection style={{ minHeight: 0, paddingBottom: SM ? marginLG : 0 }}>

                <RWContainer style={{ alignItems: 'normal', gap: marginLG }}>

                    {/* info */}
                    <RWElement breakPoint={300}>
                        <ResourceInfo/>
                    </RWElement>

                    {/* image */}
                    <RWElement breakPoint={300}>

                        <VerticalContainer style={{ height: '100%', gap: marginXS }}>

                            <Text style={{ fontFamily: 'inter-medium' }}>
                                {__`image`}
                            </Text>

                            <ImageUpload
                                type='resource'
                                nameField='name'
                                imageField='image'
                            />

                        </VerticalContainer>

                    </RWElement>

                </RWContainer>

            </FormSection>

            {/* availabilities */}
            <ResourceAvailabilities/>

            {/* range */}
            <ResourceAvailabilitiesRange/>
                
        </VerticalContainer>
    )
}

//AdvancedContents:
type AdvancedContentsProps = {
    isCreate: boolean,
    dirty: boolean
}
const AdvancedContents = ({ isCreate, dirty }: AdvancedContentsProps) => {

    const { __ } = useTranslate()
    const { token: { marginXL, paddingXS } } = theme.useToken()

    return (
        <VerticalContainer style={{ paddingTop: paddingXS, gap: marginXL }}>
            
            <Status
                name={__`resource`}
                enabledDescription={__`disable_if_you_want_to_be_temporarily_unavailable`}
                disabledDescription={__`enable_if_you_want_to_be_available`}
            />
            
            <ResourceNotification/>

            <ResourceGoogleSync dirty={dirty}/>

            {!isCreate && <ResourceRemove/>}

        </VerticalContainer>
    )

}

//defaultValues:
const getDefaultValues = (): Resource => ({
    id: '',
    type: {
        id: '',
        name: ''
    },
    name: '',
    description: null,
    image: {
        id: null,
        path: null
    },
    email: null,
    mobile: null,
    
    status: true,
    notificationEmailStatus: false,
    eventDescription: null,
    
    calendarSync: {
        canUseCalendarSync: true,
        calendarSyncStatus: null
    },

    timezone: browserTimezone(),

    availabilities: [],

    availabilitiesRange: {
        availableFrom: {
            type: 'NOW',
            value: 0
        },
        availableTo: {
            type: 'INDEFINITELY',
            value: 0
        }
    }

})
