import { useEffect } from 'react'
import { theme } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { HorizontalContainer, LogoLoading, PageError, StaticPageError, VerticalContainer } from 'components/styled'
import { updateAdminVersion, useAuthentication, useWindowDimensions } from 'utils'
import { useConfig, useCurrentLocation, useCurrentShop, useLocations } from 'api'
import { SideMenu } from 'components/layout/side-menu/side-menu'
import { BottomMenu } from 'components/layout/bottom-menu/bottom-menu'
import { LayoutHeader } from 'components/layout/layout-header/layout-header'

export const Layout = () => {

    const navigate = useNavigate()
    const { isLoggedIn } = useAuthentication()

    useEffect(() => { !isLoggedIn && navigate('/login') }, [isLoggedIn])

    return isLoggedIn ? <LayoutConfigWrapper/> : <></>

}

export const LayoutConfigWrapper = () => {
    const { data: config, isLoading: isConfigLoading } = useConfig()
    return (
        isConfigLoading ? <LogoLoading/> :
        !config ? <StaticPageError/> :
        <LayoutShopWrapper/>
    )
}

export const LayoutShopWrapper = () => {
    const { data: shop, isLoading: isShopLoading } = useCurrentShop()
    return (
        isShopLoading ? <LogoLoading/> :
        !shop ? <PageError/> :
        <LayoutMigrationController/>
    )
}

export const LayoutMigrationController = () => {

    const { data: locations, isLoading: isLocationsLoading } = useLocations(null, 1)

    useEffect(() => {
        if(locations && locations.length === 0){
            updateAdminVersion('v1')
        }
    }, [locations])

    return (
        isLocationsLoading ? <LogoLoading/> :
        !locations ? <PageError/> :
        locations.length === 0 ? <LogoLoading/> :
        <LayoutLocationWrapper/>
    )

}

export const LayoutLocationWrapper = () => {
    const { data: location, isLoading: isCurrentLocationLoading } = useCurrentLocation()
    return (
        isCurrentLocationLoading ? <LogoLoading/> :
        !location ? <PageError/> :
        <LayoutContent/>
    )
}

export const LayoutContent = () => {

    //hooks:
    const { token: { colorBgLayout, colorBgContainer, paddingXXS, margin }} = theme.useToken()
    const { SM, LG } = useWindowDimensions()

    return (
        <VerticalContainer style={{
            width: '100%',
            height: '100%',
            background: SM ? colorBgLayout : colorBgContainer,
            gap: 0
        }}>

            <VerticalContainer id='layoutScrollContainer' style={{ flex: 1, overflowY: 'auto', gap: 0 }}>

                {/* header */}
                {LG && <LayoutHeader/>}

                <VerticalContainer style={{
                    flex: 1,
                    justifyContent: 'space-between'
                }}>
                
                    <HorizontalContainer style={{
                        flex: 1,
                        padding: LG ? `0 ${paddingXXS}px` : 0,
                        alignItems: 'stretch',
                        gap: margin
                    }}>

                        {/* side menu */}
                        {LG && (<div><SideMenu/></div>)}

                        {/* outlet */}
                        <div style={{ maxWidth: LG ? 'calc(100% - 243px - 8px)' : '100%', flex: 1, display: 'flex' }}>
                            <Outlet/>
                        </div>

                    </HorizontalContainer>

                </VerticalContainer>

            </VerticalContainer>

            {/* bottom menu */}
            {!LG && <BottomMenu/>}

        </VerticalContainer>
    )

}
