import { DatePickerWrapper, MainWrapper, ResourcesWrapper, MenuWrapper, TextWrapper } from './calendar-page-navigation.style'
import { Button, DatePicker, Dropdown, InputRef, MenuProps, Typography, theme } from 'antd'
import { LoadingOutlined, ReloadOutlined, LeftOutlined, RightOutlined, DownOutlined, CheckOutlined } from '@ant-design/icons'
import { useTranslate, addDays, getDateRange, min, queryClient } from 'utils'
import { useConfig, useResources } from 'api'
import { HorizontalContainer, Loading, NotFound, PageError, VerticalContainer } from 'components/styled'
import { Shop, Resource } from 'types'
import { useEffect, useRef, useState } from 'react'
import { CALENDAR_TEAM_MEMBER_LCL_STR_KEY } from 'app.constants'
import { Avatar } from 'components/avatar/avatar'
import dayjs from 'dayjs'
import { Search } from 'components/search/search'
const { Text } = Typography

const items: MenuProps['items'] = [{ key: '1', label: <div></div> }]

type Props = {
    shop: Shop,
    startDate: Date
    numberOfDays: number
    selectedResources: Resource[]
    areSlotsLoading: boolean
    onStartDateChange: (date: Date) => void
    onResourcesChange: (resources: Resource[]) => void
}

export const CalendarPageNavigation = ({
    shop,
    startDate,
    numberOfDays,
    selectedResources,
    areSlotsLoading,
    onStartDateChange,
    onResourcesChange
}: Props) => {

    //hooks:
    const localStorageIds = getResourcesFromLocalStorage(shop.id)
    const { __ } = useTranslate()
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const container = useRef<HTMLDivElement>(null)
    const searchInputRef = useRef<InputRef>(null)
    const { token: {
        paddingXXS,
        paddingXS,
        paddingSM,
        margin,
        fontSizeSM,
        fontSizeLG,
        colorBgContainer,
        boxShadowTertiary,
        colorBorder,
        borderRadiusLG
    }} = theme.useToken()

    //state:
    const [ isRefreshSelected, setIsRefreshSelected ] = useState(false)
    const [ search, setSearch ] = useState<string | null>(null)
    const [ isTyping, setIsTyping ] = useState(false)

    //resources:
    const { data: resources, isLoading: isResourcesLoading, isFetching: isResourcesFetching } = useResources(null, null, search, 8)

    //select resources:
    useEffect(() => {
        if(resources){
            if(resources.length){
                if(localStorageIds.length > 0){
                    onResourcesChange(resources.filter(resource => localStorageIds.includes(resource.id)))
                }else if(container.current){
                    const containerWidth = container.current.offsetWidth
                    const columnsNumber = min(Math.floor(containerWidth / 150), resources.length)
                    const selectedTM = []
                    for(let i=0; i < columnsNumber; i++){
                        selectedTM.push(resources[i])
                    }
                    onResourcesChange(selectedTM)
                }
            }else{
                onResourcesChange([])
            }
        }
    }, [isResourcesLoading, shop])

    // disable refresh button loading for other fetches:
    useEffect(() => { !areSlotsLoading && setIsRefreshSelected(false) }, [areSlotsLoading])

    const handleSelect = (resource: Resource) => {
        let newResources
        if(selectedResources.find(sr => sr.id === resource.id)){
            newResources = [...selectedResources.filter(sr => sr.id !== resource.id)]
        }else{
            newResources = [...selectedResources]
            newResources.push(resource)
        }
        onResourcesChange(newResources)
        saveResourcesToLocalStorage(shop.id, newResources.map(r => r.id))
    }
    
    return (
        (isConfigLoading || !config) ? null :
        <MainWrapper ref={container}>
            
            <TextWrapper>

                {/* date */}
                <Text style={{ fontSize: fontSizeLG, fontFamily: 'inter-medium' }}>
                    {getDateRange(startDate, numberOfDays, config, true)}
                </Text>
            
            </TextWrapper>

            <MenuWrapper>

                <DatePickerWrapper>

                    {/* date picker */}
                    <DatePicker
                        inputReadOnly
                        defaultValue={dayjs(startDate)}
                        value={dayjs(startDate)}
                        allowClear={false}
                        style={{ width: '100%', height: 'fit-content' }}
                        onChange={val => onStartDateChange(dayjs(val).toDate())}
                    />

                    <HorizontalContainer>

                        {/* left arrow */}
                        <Button
                            icon={<LeftOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`previous_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => onStartDateChange(addDays(startDate, -1))}
                        />
                        
                        {/* right arrow */}
                        <Button
                            icon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`next_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => onStartDateChange(addDays(startDate, 1))}
                        />

                        {/* refresh */}
                        <Button
                            icon={(areSlotsLoading && isRefreshSelected) ? <LoadingOutlined style={{ fontSize: fontSizeSM }}/> : <ReloadOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`refresh`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => {
                                setIsRefreshSelected(true)
                                queryClient.invalidateQueries(['calendarSlots'])
                            }}
                        />
                        
                    </HorizontalContainer>

                </DatePickerWrapper>

                <ResourcesWrapper>

                    {/* resource select */}
                    <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        dropdownRender={() => (
                            <VerticalContainer style={{
                                width: 240,
                                paddingBottom: paddingXS,
                                backgroundColor: colorBgContainer,
                                border: `solid 1px ${colorBorder}`,
                                borderRadius: borderRadiusLG,
                                boxShadow: boxShadowTertiary,
                            }}>

                                <div style={{ padding: `${paddingXS}px ${paddingXS}px ${paddingXXS}px ${paddingXS}px` }}>
                                    <Search
                                        onSearch={setSearch}
                                        inputRef={searchInputRef}
                                        onType={setIsTyping}
                                    />
                                </div>

                                <VerticalContainer style={{ gap: 0 }}>
                                    {(isTyping || isResourcesFetching) ? <Loading/> : (
                                        !resources ? <PageError/> : (
                                            (!resources.length) ? <NotFound/> : (
                                                resources.map(resource => (
                                                    <Button
                                                        key={resource.id}
                                                        type='text'
                                                        style={{ borderRadius: 0 }}
                                                        onClick={() => handleSelect(resource)}
                                                    >
                                                        <HorizontalContainer style={{ width: '100%' }}>
                                                            <Text style={{
                                                                width: '90%',
                                                                textAlign: 'left',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden'
                                                            }}>
                                                                {resource.name}
                                                            </Text>
                                                            {selectedResources.find(sr => sr.id === resource.id) ? <CheckOutlined style={{ fontSize: fontSizeSM }}/> : <></>}
                                                        </HorizontalContainer>
                                                    </Button>
                                                ))
                                            )
                                        )
                                    )}
                                </VerticalContainer>

                            </VerticalContainer>
                        )}
                        onOpenChange={(isOpen: boolean) => {
                            if(isOpen){
                                setTimeout(() => searchInputRef.current?.focus(), 100)
                            }
                        }}
                    >

                        <Button type='default' style={{ width: 240, paddingLeft: paddingXXS, paddingRight: paddingSM }}>
                            <HorizontalContainer style={{ gap: margin }}>
                                <HorizontalContainer>
                                    {selectedResources.map((r, i) => i < 6 ? (
                                        <Avatar
                                            key={r.id}
                                            image={r.image.path}
                                            placeholder={r.name[0]}
                                            size='nano'
                                            style={{ padding: 0 }}
                                        />
                                    ) : null)}
                                    {selectedResources.length > 6 && (
                                        <Text>+{selectedResources.length - 6}</Text>
                                    )}
                                </HorizontalContainer>
                                <DownOutlined style={{ fontSize: fontSizeSM }}/>
                            </HorizontalContainer>
                        </Button>

                    </Dropdown>

                </ResourcesWrapper>

            </MenuWrapper>

        </MainWrapper>
    )
    
}

type Storage = {
    shopId: string,
    resourceIds: string[]
}

//getResourcesFromLocalStorage:
const getResourcesFromLocalStorage = (shopId: string) => {
    const storageList = getAllShopsResourcesFromLocalStorage()
    const storage = storageList.find(st => st.shopId === shopId)
    return storage?.resourceIds ?? []
}

//getResourcesFromLocalStorage:
const getAllShopsResourcesFromLocalStorage = () => {
    let storageList: Storage[] = []
    try{
        const storageListString = localStorage.getItem(CALENDAR_TEAM_MEMBER_LCL_STR_KEY)
        if(storageListString){
            storageList = JSON.parse(storageListString)
        }
    }catch(e){e}
    return storageList
}

//saveResourcesToLocalStorage:
const saveResourcesToLocalStorage = (shopId: string, resourceIds: string[]) => {
    const storageList = getAllShopsResourcesFromLocalStorage()
    let newStorageList = [...storageList]
    const isShopExists = storageList.find(st => st.shopId === shopId)
    if(isShopExists){
        newStorageList = storageList.map(st => {
            if(st.shopId === shopId){
                return { ...st, resourceIds }
            }else{
                return st
            }
        })
    }else{
        newStorageList.push({ shopId, resourceIds })
    }
    try{
        localStorage.setItem(CALENDAR_TEAM_MEMBER_LCL_STR_KEY, JSON.stringify(newStorageList))
    }catch(e){e}
}
