import { DivContainer, ImageContainer, MainWrapper, NameDescriptionContainer, NameTagContainer } from './avatar.style'
import { Avatar as AntAvatar, Button, Image, Typography, theme } from 'antd'
import { PropsWithChildren, ReactNode, useCallback, useMemo } from 'react'
import { isNil, nameShortener, objectAssign, useTranslate } from 'utils'
import { LineClampText } from 'components/styled'
const  { Text } = Typography

export type AvatarSize = 'nano' | 'micro' | 'tiny' | 'small' | 'medium' | 'large'

type Props = {
    name?: string | null
    title?: string | null
    image?: string | ReactNode | null
    description?: string | null
    tag?: ReactNode
    placeholder?: string | ReactNode
    size?: AvatarSize
    wrapperSize?: number
    isVertical?: boolean
    lineClamp?: number
    color?: string
    backgroundColor?: string
    shape?: 'circle' | 'square'
    expandImage?: boolean
    extraIcon?: ReactNode
    style?: object
    antdAvatarStyle?: object
    isDisabled?: boolean
    onClick?: (e: any) => void
}

export const Avatar = ({
    name,
    title,
    image,
    tag,
    description,
    placeholder,
    size = 'medium',
    wrapperSize,
    isVertical = false,
    lineClamp,
    color,
    backgroundColor,
    shape = 'circle',
    expandImage = false,
    extraIcon,
    style,
    antdAvatarStyle,
    isDisabled = false,
    onClick
}: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { colorPrimary, colorInfoBgHover, colorTextTertiary, fontSize, fontSizeSM, fontSizeLG } } = theme.useToken()

    //getAntAvatarSize:
    const avatarSize = useMemo(() =>
        wrapperSize ? wrapperSize :
        size === 'nano'   ? 24 :
        size === 'micro'  ? 28 :
        size === 'tiny'   ? 43 :
        size === 'small'  ? 46 :
        size === 'medium' ? 49 :
        size === 'large'  ? 52 : 0
    , [size])

    //nameFontSize:
    const nameFontSize = useMemo(() =>
        size === 'nano'   ? fontSize   :
        size === 'micro'  ? fontSize   :
        size === 'tiny'   ? fontSize   :
        size === 'small'  ? fontSize   :
        size === 'medium' ? fontSize   :
        size === 'large'  ? fontSizeLG : 0
    , [size])

    //placeholderFontSize:
    const placeholderFontSize = useMemo(() =>
        size === 'nano'   ? fontSizeSM :
        size === 'micro'  ? fontSizeSM :
        size === 'tiny'   ? fontSizeSM :
        size === 'small'  ? fontSize   :
        size === 'medium' ? fontSize   :
        size === 'large'  ? fontSizeLG : 0
    , [size])

    //descriptionFontSize:
    const descriptionFontSize = useMemo(() =>
        size === 'nano'   ? fontSizeSM :
        size === 'micro'  ? fontSizeSM :
        size === 'tiny'   ? fontSizeSM :
        size === 'small'  ? fontSizeSM :
        size === 'medium' ? fontSizeSM :
        size === 'large'  ? fontSize   : 0
    , [size])

    //image:
    const getImage = (avatarSize: number) => {

        //no space
        if(image === undefined)
            return null
        
        //if it is empty, just holds its space
        if(image === '')
            return <ImageContainer size={avatarSize}/>

        //if its null, shows the first letter of the name
        if(image === null)
            return (
                <ImageContainer size={avatarSize}>
                    <AntAvatar
                        shape={shape}
                        size={avatarSize}
                        alt={name ?? ''}
                        style={objectAssign({
                            fontSize: placeholderFontSize,
                            color: color || colorPrimary,
                            backgroundColor: backgroundColor || colorInfoBgHover,
                        }, antdAvatarStyle)}>
                        {placeholder || nameShortener(name) || __`?`}
                    </AntAvatar>
                </ImageContainer>
            )

        //icon:
        if(typeof image === 'object'){
            return (
                <ImageContainer size={avatarSize}>
                    <AntAvatar
                        shape={shape}
                        size={avatarSize}
                        alt={name ?? ''}
                        src={image}
                        style={antdAvatarStyle}
                    />
                </ImageContainer>
            )
        }else if(typeof image === 'string'){ //url:
            return (
                <ImageContainer size={avatarSize}>
                    <AntAvatar
                        shape={shape}
                        size={avatarSize}
                        alt={name ?? ''}
                        style={objectAssign({
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }, antdAvatarStyle)}
                        src={<Image src={image} preview={expandImage}/>}
                    />
                </ImageContainer>
            )
        }
    }
    
    return (
        <Container
            name={name}
            title={title}
            isVertical={isVertical}
            style={style}
            isDisabled={isDisabled}
            onClick={onClick}
        >

            <MainWrapper isVertical={isVertical} size={size}>
                
                {/* image */}
                {getImage(avatarSize)}

                {(!isNil(name) || !isNil(description)) &&
                    <NameDescriptionContainer>
                    
                        {/* name and tag */}
                        <NameTagContainer isVertical={isVertical}>
                            
                            {lineClamp ?

                                <LineClampText
                                    lineClamp={lineClamp}
                                    style={{ fontSize: nameFontSize }}
                                >
                                    {name}
                                </LineClampText>

                                :

                                <Text
                                    style={{
                                        maxWidth: '100%',
                                        fontSize: nameFontSize,
                                        fontWeight: isNil(description) ? 'normal' : 500,
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {name}
                                </Text>
                            
                            }

                            {tag}

                        </NameTagContainer>

                        {/* description */}
                        {!isNil(description) &&
                            <Text
                                style={{
                                    width: isVertical ? 'unset' : 'fit-content',
                                    maxWidth: '100%',
                                    fontSize: descriptionFontSize,
                                    color: colorTextTertiary,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                }}
                            >
                                {description}
                            </Text>
                        }

                    </NameDescriptionContainer>
                }

                {/* extra icon */}
                {extraIcon}

            </MainWrapper>

        </Container>
    )

}

type ContainerProps = {
    name?: string | null
    title?: string | null
    isVertical: boolean
    style?: object
    isDisabled?: boolean
    onClick?: (e: any) => void
}

const Container = ({ name, title, isVertical, style, isDisabled, onClick, children }: PropsWithChildren<ContainerProps>) => {

    const { token: { paddingXS, paddingXXS } } = theme.useToken()

    // handle click:
    const handleClick = useCallback((e: any) => {
        e.stopPropagation()
        onClick?.(e)
    }, [onClick])
    
    return !isNil(onClick) ?
        <Button
            type='text'
            title={title ?? name ?? ''}
            style={objectAssign({
                height: 'auto',
                border: 'none',
                // preventing the CSS conflict on re-rendering by not using the shortened names
                paddingTop: isVertical ? paddingXS : paddingXXS,
                paddingBottom: isVertical ? paddingXS : paddingXXS,
                paddingLeft: isVertical ? paddingXS : paddingXS,
                paddingRight: isVertical ? paddingXS : paddingXS,
                textAlign: isVertical ? 'center' : 'start',
                opacity: isDisabled ? .6 : 1
            }, style)}
            disabled={isDisabled}
            onClick={handleClick}
        >
            {children}
        </Button>
    :
        <DivContainer
            title={title ?? name ?? ''}
            style={objectAssign({
                // preventing the CSS conflict on re-rendering by not using the shortened names
                paddingTop: paddingXXS,
                paddingBottom: paddingXXS,
                paddingLeft: paddingXS,
                paddingRight: paddingXS,
                opacity: isDisabled ? .6 : 1
            }, style)}
        >
            {children}
        </DivContainer>

}
