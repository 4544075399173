import { TeamServiceItem } from '../team-service-item/team-service-item'
import { theme } from 'antd'
import { useTranslate, useWindowDimensions }  from 'old/utils'
import { useServices } from 'old/api'
import { FormSection } from 'old/components/form-section/form-section'
import { useField } from 'react-final-form'
import { Service }  from 'old/types'
import { useState } from 'react'
import { VerticalPaginatedList } from 'old/components/vertical-paginated-list/vertical-paginated-list'

export const TeamMemberServiceList = () => {

    //theme:
    const { token: { paddingMD } } = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //translate:
    const { __ } = useTranslate()

    //state:
    const [ search, setSearch ] = useState(null as string | null)
    
    //appointments:
    const {
        data: services,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useServices(search, 15)
    
    //field:
    const { input: { value: teamMemberId } } = useField('id')
    const { input: { value: sl , onChange: updateServices } } = useField('services')
    const selectedServices: Service[] = sl

    //add:
    const add = (service: Service) => updateServices([...selectedServices, service])

    //remove:
    const remove = (service: Service) => updateServices(selectedServices.filter(s => s.id !== service.id))
    
    //render:
    return (
        <FormSection
            title={__`services`}
            childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
            searchInput={{
                placeholder: __`search_services`,
                onSearch: setSearch
            }}
        >

            <VerticalPaginatedList
                isFirstLoading={isLoading}
                isForbidden={services === null}
                isError={services === false}
                notFound={(services && services.length === 0) ?? false}
                pageNum={pageNum}
                totalPage={totalPage}
                isPreviousData={isPreviousData}
                isNextDisabled={isNextDisabled}
                isPreviousDisabled={isPreviousDisabled}
                fetchNext={fetchNext}
                fetchPrevious={fetchPrevious}
            >

                {services && services.map(service => {
                    const isSelected = selectedServices.some(s => s.id === service.id)
                    const isDisabled = isSelected && service.teamMembers.length === 1 && service.teamMembers[0] === teamMemberId
                    return (
                        <TeamServiceItem
                            key={service.id}
                            name={service.title}
                            title={
                                isDisabled ? __`this_member_is_the_only_team_member_of_the_service` :
                                isSelected ? __`remove_from_members_services` :
                                __`add_to_members_services`
                            }
                            image={service.image.path}
                            isSelected={isSelected}
                            isDisabled={isDisabled}
                            onSelect={() => isSelected ? remove(service) : add(service)}
                        />
                    )
                })}

            </VerticalPaginatedList>
            
        </FormSection>
    )
    
}
