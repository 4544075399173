import { SelectedServiceIndicator, ServiceContainer, ServicesWrapper } from './booking-service.style'
import { useEffect, useState } from 'react'
import { InputNumber, Select, Typography, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { FormSection } from 'components/form-section/form-section'
import { useTranslate, isNil, getLocationId } from 'utils'
import { useCurrentLocation, useService, useServices } from 'api'
import { Loading, NotFound, PageError, RWContainer, RWElement, VerticalContainer, WhiteShadowLoading } from 'components/styled'
import { BookingAction, BookingPageDispatch, BookingPageState } from 'pages/booking/booking-page/booking-page'
import { Location, Service, Variant } from 'types'
import { getDurationVariants } from 'components/service/service-duration/service-duration'
import { BOOKING_SERVICE_SHADOW_Z_INDEX } from 'z-indexes'
import { useNavigate } from 'react-router-dom'
const { Text } = Typography

export type ServicePreset = string | null | ServicePresetInfo

export type ServicePresetInfo = {
    serviceId: string
    externalVariantId: string
    participants: number
}

type BookingServiceProps = {
    servicePreset: ServicePreset
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const BookingService = ({ servicePreset, pageState, pageStateDispatch }: BookingServiceProps) => {
    const isReschedule = servicePreset !== null && typeof servicePreset !== 'string'
    return (
        isReschedule ? (
            <LockedService servicePresetInfo={servicePreset} pageState={pageState} pageStateDispatch={pageStateDispatch}/>
        ) : (
            <SelectService servicePresetId={servicePreset} pageState={pageState} pageStateDispatch={pageStateDispatch}/>
        )
    )
}

type LockedServiceProps = {
    servicePresetInfo: ServicePresetInfo
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const LockedService = ({ servicePresetInfo, pageState, pageStateDispatch }: LockedServiceProps) => {

    //hooks:
    const { token: { padding, marginXS } } = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //api:
    const { data: service, isLoading: isServiceLoading } = useService(servicePresetInfo.serviceId)
    const { data: location } = useCurrentLocation()

    const findVariant = (service: Service, externalVariantId: string) => service.variants.find(variant => variant.externalId === externalVariantId) ?? null
    const findDuration = (variant: Variant | null) => variant?.options.find(option => option.name.toLowerCase() === 'duration')?.value

    useEffect(() => {
        if(location){
            if(service){
                handleServiceSelect(service, location, pageStateDispatch)
                pageStateDispatch({ type: BookingAction.SET_VARIANT, payload: { variant: findVariant(service, servicePresetInfo.externalVariantId) } })
                pageStateDispatch({ type: BookingAction.SET_PARTICIPANTS, payload: { participants: servicePresetInfo.participants } })
            }else if(service === null || service === false){
                navigate('/service')
            }
        }
    }, [service, location])

    return (
        <FormSection
            title={__`service`}
            style={{ gap: marginXS }}
            childrenWrapperStyle={{ paddingLeft: padding }}
        >

            {isServiceLoading ? <Loading/> : !service ? <PageError/> : (

                <Avatar
                    image={pageState.service?.image.path}
                    name={pageState.service?.title}
                    description={__('duration_minutes', { duration: findDuration(pageState.variant) })}
                />
                
            )}

        </FormSection>
    )

}

type SelectServiceProps = {
    servicePresetId: string | null
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const SelectService = ({ pageState, servicePresetId, pageStateDispatch }: SelectServiceProps) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginXXS, marginSM, margin, paddingXXS, padding } } = theme.useToken()

    //state:
    const [ search, setSearch ] = useState<string | null>(null)

    //api:
    const { data: service, isLoading: isServiceLoading } = useService(servicePresetId ?? '', !isNil(servicePresetId))
    const {
        data: services,
        pageNum,
        totalPages,
        isLoading: isServicesLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useServices(getLocationId(), search, 15)

    const { data: location } = useCurrentLocation()

    //variantsSelectOptions:
    const variantsSelectOptions: { value: string, label: string }[] = []
    if(pageState.service !== null){
        for(let i = 0; i < pageState.service.variants.length; i++ ){
            const variant = pageState.service.variants[i]
            variantsSelectOptions.push({
                value: variant.id,
                label: variant.title
            })
        }
    }

    //getVariantFromId:
    const getVariantFromId = (id: string) => pageState.service?.variants.find(variant => variant.id === id) ?? null

    //after loading:
    useEffect(() => {
        if(location){
            if(service){
                handleServiceSelect(service, location, pageStateDispatch)
            }else if(services && services.length > 0){
                handleServiceSelect(services[0], location, pageStateDispatch)
            }
        }
    }, [service, services, location])

    //LoadingMessageStyle:
    const LoadingMessageStyle = { padding: padding, marginTop: marginXXS, marginBottom: -margin }

    //getServiceJsx:
    const getServiceJsx = (service: Service, location: Location, selectedServiceId?: string) => {

        const hasDuration = getDurationVariants(service.variants).length > 0
        const hasLocation = service.locations.length > 0
        let hasResources = false
        service.locations.map(l => l.resources.map(lr => {
            if(lr.resources.length){
                hasResources = true
            }
        }))
        const isDisabled = !hasLocation || !hasResources || !hasDuration

        return (
            <SelectedServiceIndicator
                key={service.id}
                isSelected={selectedServiceId === service.id}
            >
                <Avatar
                    name={service.title}
                    title={!hasDuration ? __`no_durations` : !hasLocation ? __`no_locations` : !hasResources ? __`no_resources` : service.title}
                    image={service.image.path}
                    isVertical={true}
                    lineClamp={2}
                    shape='square'
                    style={{
                        height: '100%',
                        width: '100px',
                        paddingLeft: paddingXXS,
                        paddingRight: paddingXXS
                    }}
                    isDisabled={isDisabled}
                    onClick={() => handleServiceSelect(service, location, pageStateDispatch)}
                />
            </SelectedServiceIndicator>
        )
    }

    return location ? (
        <FormSection
            title={__`service`}
            searchInput={{
                placeholder:__`search_services`,
                onSearch: setSearch
            }}
            topNav={{
                pageNum,
                totalPages,
                isNextDisabled,
                isPreviousDisabled,
                onNext: fetchNext,
                onPrevious: fetchPrevious
            }}
        >

            <VerticalContainer style={{ minHeight: 55, gap: marginSM }}>

                <ServiceContainer isEmpty={(isServicesLoading || isServiceLoading) || (services ? services.length : 1) === 0}>

                    {
                        (isServicesLoading || (!isNil(servicePresetId) && isServiceLoading)) ? <Loading style={LoadingMessageStyle}/> :
                        !services ? <PageError style={LoadingMessageStyle}/> :
                        services.length === 0 ? <NotFound message={search ? undefined : __`no_services`} style={LoadingMessageStyle}/> : (

                            <>

                                {/* list */}
                                <ServicesWrapper>

                                    {/* preselected service */}
                                    {(!isNil(servicePresetId) && service) && getServiceJsx(service, location, pageState.service?.id)}

                                    {/* list */}
                                    {services.map(svc => (
                                        service && service.id === svc.id ? null : getServiceJsx(svc, location, pageState.service?.id)
                                    ))}

                                </ServicesWrapper>

                                {isPreviousData && <WhiteShadowLoading style={{ zIndex: BOOKING_SERVICE_SHADOW_Z_INDEX }}/>}
                            
                            </>

                        )
                    
                    }

                </ServiceContainer>

                {(services && services.length) ? (

                    <RWContainer>
                        
                        <RWElement breakPoint={250}>

                            <VerticalContainer>

                                <Text style={{ fontFamily: 'inter-medium' }}>{__`variant`}</Text>

                                <Select
                                    options={variantsSelectOptions}
                                    value={pageState.variant?.id}
                                    notFoundContent={<NotFound message={isNil(pageState.service) ? __`select_a_service` : __`no_variants`}/>}
                                    style={{ width: '100%' }}
                                    onChange={value => pageStateDispatch({ type: BookingAction.SET_VARIANT, payload: { variant: getVariantFromId(value) } })}
                                />

                            </VerticalContainer>

                        </RWElement>


                        {pageState.service?.groupAppointments &&
                        
                            <RWElement breakPoint={250}>

                                <VerticalContainer>

                                    <Text style={{ fontFamily: 'inter-medium' }}>{__`number_of_participants`}</Text>

                                    <InputNumber
                                        min={1}
                                        max={pageState.service?.groupAppointmentSlots ?? 1}
                                        step={1}
                                        value={pageState.participants}
                                        keyboard
                                        style={{ width: '100%' }}
                                        onChange={value => pageStateDispatch({ type: BookingAction.SET_PARTICIPANTS, payload: { participants: value ?? 1 } })}
                                    />

                                </VerticalContainer>

                            </RWElement>

                        }

                    </RWContainer>

                ) : <></>}

            </VerticalContainer>

        </FormSection>
    ) : <></>

}

//handleServiceSelect:
const handleServiceSelect = (service: Service, currentLocation: Location, pageStateDispatch: BookingPageDispatch) => {
    if(
        getDurationVariants(service.variants).length &&
        service.locations.length
    ){
        pageStateDispatch({ type: BookingAction.SET_SERVICE, payload: { service, currentLocation } })
    }
}
