import { ButtonWrapper, ImageWrapper, MainContainer, MainWrapper, NameDescriptionWrapper } from './app-card.style'
import { Button, Image, Typography, theme } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { accessToken, env, getShopId, useTranslate } from 'utils'
import { ShopApp } from 'types'
const { Title, Paragraph } = Typography

type Props = {
    app: ShopApp
}

export const AppCard = ({ app }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { fontSize, fontSizeSM, marginXS } } = theme.useToken()
    
    return (
        <MainContainer>
            <MainWrapper>

                {/* image */}
                <ImageWrapper>
                    <Image
                        height={110}
                        src={app.icon.path ?? ''}
                        preview={false}
                        style={{ borderRadius: '50%' }}
                    />
                </ImageWrapper>

                <NameDescriptionWrapper>
                
                    {/* name */}
                    <Title
                        level={4}
                        style={{
                            fontWeight: 'normal',
                            margin: 0
                        }}
                    >
                        {app.title || app.name}
                    </Title>

                    {/* description */}
                    <Paragraph
                        type='secondary'
                        style={{ marginBottom: marginXS }}
                    >
                        {app.description}
                    </Paragraph>

                </NameDescriptionWrapper>

                {/* button */}
                <ButtonWrapper>
                    <Button
                        type='primary'
                        size='large'
                        icon={<PlusOutlined style={{ fontSize: fontSizeSM }} />}
                        style={{ fontSize }}
                        onClick={() => window.location.assign(`${env().REST_API_BASE_URL}/apps/install?shop_id=${getShopId()}&app_id=${app.id}&token=${accessToken()}`)}
                    >
                        {__`install`}
                    </Button>
                </ButtonWrapper>
                
            </MainWrapper>
        </MainContainer>
    )
    
}
