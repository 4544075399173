import { AvailabilityCalendarNavigation } from '../availability-calendar-navigation/availability-calendar-navigation'
import { AvailabilityCalendarHeader } from '../availability-calendar-header/availability-calendar-header'
import { AvailabilityCalendarCell } from '../availability-calendar-cell/availability-calendar-cell'
import { EditAvailability } from '../edit-availability/edit-availability'
import { Button, Drawer, Modal, Typography, theme } from 'antd'
import { Calendar } from 'components/calendar/calendar/calendar'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { HorizontalContainer, PageError, VerticalContainer } from 'components/styled'
import { CalendarProvider } from 'context/calendar-provider'
import { TEAM_MEMBER_CALENDAR_HEADER_Z_INDEX } from 'z-indexes'
import { useEffect, useMemo, useRef, useState } from 'react'
import { addDays, getDateWeekday, getIsoStringYearMonthDay, useTranslate, useWindowDimensions } from 'utils'
import { useConfig } from 'api'
import { Availability, Config, DateAvailability, WeekdayAvailability } from 'types'
const { Paragraph } = Typography

type Props = {
    availabilities: Availability[]
    updateAvailabilities: (availabilities: Availability[]) => void
}

export const AvailabilityCalendar = ({
    availabilities,
    updateAvailabilities
}: Props) => {

    //calcCalendarStartDate:
    const calcCalendarStartDate = (startDate: Date) => {
        const dayOfWeek = startDate.getDay()
        return addDays(startDate, -(dayOfWeek - 1))
    }

    //hooks:
    const { token: {
        colorBgContainer,
        colorBorderSecondary,
        fontSizeSM,
        margin,
        marginXS,
        paddingXL,
        paddingXS,
    }} = theme.useToken()
    const { __ } = useTranslate()
    const { width, SM, LG, XXL } = useWindowDimensions()
    const calendarContainer = useRef<HTMLInputElement>(null)
    const { data: config } = useConfig()

    //state:
    const [ startDate, setStartDate ] = useState(calcCalendarStartDate(new Date()))
    const [ rowNumber, setRowNumber ] = useState(2)
    const [ columnNumber, setColumnNumber ] = useState(7)
    const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
    const [ selectedDate, setSelectedDate ] = useState(null as Date | null)
    const [ selectedAvailability, setSelectedAvailability ] = useState(null as Availability | null)

    //rows and columns:
    useEffect(() => {
        const cN = XXL ? 7 : 1
        const rN = XXL ? 4 : 7
        if(cN !== columnNumber){
            setColumnNumber(cN)
            setRowNumber(rN)
        }
    }, [XXL])

    //handleAddAvailability:
    const handleAddAvailability = (availability: Availability, config: Config) => {

        const copyAvailabilities = [...availabilities]
        let newAvailabilities: Availability[]

        // if it is a date specific rul:
        if(availability.type === 'date'){

            // remove:
            if(availability.intervals.length === 0){

                const date = new Date(`${availability.date} 00:00`)
                const weekDay = date.toLocaleString(['en-US'], { weekday: 'long' }).toLowerCase()

                // is there any week rules:
                const isThereAnyWeekdayRuleForThisDate = copyAvailabilities.find(ava => ava.type === 'wday' && ava.weekday === weekDay) !== undefined
                if(isThereAnyWeekdayRuleForThisDate){
                    // empty the intervals of the specific date, as an exception:
                    newAvailabilities = addOrUpdateDateAvailability(copyAvailabilities, availability)
                }else{
                    // remove the entire availability of the specific date:
                    newAvailabilities = copyAvailabilities.filter(ava => !(
                        ava.type === 'date' &&
                        ava.date === availability.date
                    ))
                }

            }else{ // update:
                newAvailabilities = addOrUpdateDateAvailability(copyAvailabilities, availability)
            }

        }else{ // if it is a weekday rule:

            // remove:
            if(availability.intervals.length === 0){

                // remove the weekday rule:
                newAvailabilities = copyAvailabilities.filter((ava: Availability) => !(
                    ava.type === 'wday' && ava.weekday === availability.weekday))

            }else{ // update:
                newAvailabilities = addOrUpdateWeekAvailability(copyAvailabilities, availability)
            }

            // find date specific rules for this weekday:
            const dateSpecificAvailabilities = copyAvailabilities.filter((ava: Availability) =>
                ava.type === 'date' && getDateWeekday(ava.date).toLowerCase() === availability.weekday)
            if(dateSpecificAvailabilities.length > 0){
                
                //confirm the override:
                Modal.confirm({
                    title: __`override_date_specific_rules`,
                    content: (
                        <VerticalContainer>
                            <Paragraph style={{ margin: 0 }}>
                                {__`you_have_previously_defined_date_specific_rules_for_these_dates`}
                            </Paragraph>
                            <ul style={{ margin: 0, paddingLeft: paddingXL }}>
                                {dateSpecificAvailabilities.map(ava => {
                                    const date = new Date(`${ava.date} 00:00`)
                                    const lclDateString = date.toLocaleString([config.locale, 'en-US'], { year: 'numeric', month: '2-digit', day: '2-digit' })
                                    return <li key={lclDateString}>{lclDateString}</li>
                                })}
                                
                            </ul>
                        </VerticalContainer>
                    ),
                    okText: __`do_not_override`,
                    cancelText: __`override`,
                    closable: false,
                    onCancel: () => {

                        // remove the entire date rule:
                        const overrideAvailabilities = newAvailabilities.filter((ava: Availability) => !(
                            ava.type === 'date' && getDateWeekday(ava.date).toLowerCase() === availability.weekday
                        ))
                        updateAvailabilities([...overrideAvailabilities])

                    }
                })

            }

        }

        updateAvailabilities([...newAvailabilities])
        handleClose()
    }

    //handleClose:
    const handleClose = () => {
        setSelectedDate(null)
        setSelectedAvailability(null)
        setIsDrawerOpen(false)
    }

    //addOrUpdateDateAvailability:
    const addOrUpdateDateAvailability = (availabilities: Availability[], availability: DateAvailability) => {
        let updated = false
        const updatedAvailabilities = availabilities.map((ava: Availability) => {
            if(ava.type === 'date' && ava.date === availability.date){
                updated = true
                return {...ava, intervals: availability.intervals}
            }else{
                return ava
            }
        })
        if(updated){
            return updatedAvailabilities
        }else{
            const addedAvailabilities = [...availabilities]
            addedAvailabilities.push(availability)
            return addedAvailabilities
        }
    }

    //addOrUpdateWeekAvailability:
    const addOrUpdateWeekAvailability = (availabilities: Availability[], availability: WeekdayAvailability) => {
        let updated = false
        const updatedAvailabilities = availabilities.map((ava: Availability) => {
            if(ava.type === 'wday' && ava.weekday === availability.weekday){
                updated = true
                return {...ava, intervals: availability.intervals}
            }else{
                return ava
            }
        })
        if(updated){
            return updatedAvailabilities
        }else{
            const addedAvailabilities = [...availabilities]
            addedAvailabilities.push(availability)
            return addedAvailabilities
        }
    }

    //data model:
    const dataModel = useMemo(() => {

        const copyAvailabilities = [...availabilities]
        const data = []
        let index = 0

        for(let i = 0; i < rowNumber; i++){

            const row = []

            for(let j = 0; j < columnNumber; j++){

                //date availability:
                const date = addDays(new Date(startDate.getTime()), index)
                const weekDay = date.toLocaleString(['en-US'], { weekday: 'long' }).toLowerCase()
                const newAvailabilities = copyAvailabilities.filter((av: Availability) =>
                    (av.type === 'date' && av.date === getIsoStringYearMonthDay(date)) ||
                    (av.type === 'wday' && av.weekday === weekDay)
                )

                //push to data model:
                row.push({
                    date: date,
                    info: {
                        availabilities: newAvailabilities
                    }
                })

                index++

            }

            data.push(row)

        }

        return data

    }, [availabilities, startDate, rowNumber, columnNumber])

    return !config ? <PageError/> : (
        <VerticalContainer style={{ gap: margin }}>

            {/* navigation */}
            <AvailabilityCalendarNavigation
                startDate={startDate}
                numberOfDays={rowNumber * columnNumber}
                onStartDateChange={date => setStartDate(calcCalendarStartDate(date))}
            />

            {/* calendar */}
            <div ref={calendarContainer}>
                <CalendarProvider dataModel={dataModel}>
                    <Calendar
                        headerRenderer={
                            columnNumber > 1 ? 
                            date => <AvailabilityCalendarHeader date={date}/> :
                            () => <div style={{ borderBottom: `solid 1px ${colorBorderSecondary}` }}/>
                        }
                        headerStyle={{
                            backgroundColor: colorBgContainer,
                            position: 'sticky',
                            top: LG ? 60 : 0,
                            zIndex: TEAM_MEMBER_CALENDAR_HEADER_Z_INDEX
                        }}
                        cellRenderer={(date, index) =>
                            <AvailabilityCalendarCell
                                date={date}
                                index={index}
                                onClick={availability => {
                                    setSelectedDate(date)
                                    setSelectedAvailability(availability ?? null)
                                    setIsDrawerOpen(true)
                                }}
                            />
                        }
                    />
                </CalendarProvider>
            </div>

            {/* more and less buttons */}
            {XXL &&
                <HorizontalContainer style={{ justifyContent: 'center', gap: 0 } }>

                    {/* less button */}
                    <Button
                        type='text'
                        icon={<UpOutlined style={{ fontSize: fontSizeSM }}/>}
                        style={{
                            padding: paddingXS,
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: fontSizeSM
                        }}
                        disabled={rowNumber === 1}
                        onClick={() => setRowNumber(rowNumber - 1)}
                    >
                        {__`show_less`}
                    </Button>

                    {/* more button */}
                    <Button
                        type='text'
                        icon={<DownOutlined style={{ fontSize: fontSizeSM }}/>}
                        style={{
                            padding: paddingXS,
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'center',
                            gap: marginXS,
                            fontSize: fontSizeSM
                        }}
                        disabled={rowNumber >= 13}
                        onClick={() => setRowNumber(rowNumber + 1)}
                    >
                        {__`show_more`}
                    </Button>

                </HorizontalContainer>
            }

            {/* drawer */}
            <Drawer
                closable={false}
                width={SM ? 450 : width } // full width in mobile
                open={isDrawerOpen}
                styles={{ body: { padding: 0 }}}
                onClose={handleClose}
            >
                {selectedAvailability !== null && selectedDate !== null &&
                    <EditAvailability
                        date={selectedDate}
                        availability={selectedAvailability}
                        onAdd={availability => handleAddAvailability(availability, config)}
                        onBack={handleClose}
                    />
                }
                
            </Drawer>

        </VerticalContainer>
    )

}
