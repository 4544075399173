import { Footer } from './footer'
import { theme } from 'antd'
import { PropsWithChildren } from 'react'
import { ScreenSize }  from 'old/types'
import { useWindowDimensions }  from 'old/utils'

type Props = {
    fullWidth?: boolean
    flex?: boolean
    noBottomPadding?: boolean
    noFooter?: boolean
}

export const PageContentWrapper = ({ fullWidth, flex, noBottomPadding, noFooter, children }: PropsWithChildren<Props>) => {

    //hooks:
    const { token: { paddingSM, paddingXL }} = theme.useToken()
    const { SM } = useWindowDimensions()
    
    return (
        <div style={{
            width: '100%',
            paddingBottom: SM ? 0 : noBottomPadding ? 0 : paddingSM,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>

            {/* children */}
            <div style={{
                width: '100%',
                maxWidth: fullWidth ? 'unset' : ScreenSize.XL,
                flex: 1,
                display: flex ? 'flex' : 'block'
            }}>
                {children}
            </div>

            {/* footer */}
            {SM && !noFooter && <Footer style={{ paddingTop: paddingXL }}/>}

        </div>
    )

}
