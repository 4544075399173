import { useTranslate }  from 'old/utils'
import { FormSection } from 'old/components/form-section/form-section'
import { useField } from 'react-final-form'
import { AvailabilityCalendar } from 'old/components/availability/availability-calendar/availability-calendar'

export const ServiceAvailabilities = () => {

    //hooks:
    const { __ } = useTranslate()
    const { input: { value: availabilities, onChange: updateAvailabilities } } = useField('availabilities')
    
    //render:
    return (
        <FormSection title={__`service_hours`}>
            
            <AvailabilityCalendar
                availabilities={availabilities}
                updateAvailabilities={updateAvailabilities}
            />

        </FormSection>
    )
    
}
