import { Typography, theme } from 'antd'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
import { PropsWithChildren } from 'react'
import { useWindowDimensions } from 'utils'
const  { Title, Paragraph } = Typography

type Props = {
    title: string
    description?: string
    divider?: boolean
    forceVertical?: boolean
}

export const FormZone = ({ title, description, divider = true, forceVertical = false, children }: PropsWithChildren<Props>) => {

    //theme:
    const { token: { paddingXS, padding, paddingXXS, paddingLG, marginSM, colorBorderSecondary } } = theme.useToken()

    //window dimensions:
    const { SM, LG } = useWindowDimensions()

    //isHorizontal:
    const isHorizontal = LG && !forceVertical
    
    return (
        <HorizontalContainer style={{
            paddingTop: (!SM && divider) ? paddingLG : 0,
            borderTop: (!SM && divider) ? `solid 1px ${colorBorderSecondary}` : 'none',
            flexDirection: isHorizontal ? 'row' : 'column',
            alignItems: 'flex-start',
            gap: marginSM,
        }}>
            
            <VerticalContainer style={{
                flex: 3,
                padding: isHorizontal ? `${paddingXS}px 0` : SM ? `0 ${paddingXXS}px` : `0 ${padding}px` }}>

                {/* title */}
                <Title
                    level={5}
                    style={{ margin: 0, fontWeight: SM ? 'normal' : 'bold' }}>
                    {title}
                </Title>

                {SM && (
                    <Paragraph style={{ margin: 0 }} type='secondary'>
                        {description}
                    </Paragraph>
                )}

            </VerticalContainer>

            {/* content */}
            <div style={{ width: '100%', flex: 5 }}>
                {children}
            </div>
            
        </HorizontalContainer>
    )
}
