import { AppointmentPage } from 'pages/appointment-page/appointment-page'
import { TokenList } from 'pages/token/token-list/token-list'
import { Layout } from 'components/layout/layout/layout'
import { Dashboard } from 'pages/dashboard/dashboard'
import { FlowList } from 'pages/flow/flow-list/flow-list'
import { Login } from 'pages/login/login'
import { MessagePage } from 'pages/message-page/message-page'
import { PasswordReset } from 'pages/password-reset/password-reset'
import { Plans } from 'pages/plans/plans'
import { Profile } from 'pages/profile/profile'
import { ServiceList } from 'pages/service/service-list/service-list'
import { SettingsPage } from 'pages/settings/settings'
import { RouteObject } from 'react-router-dom'
import { UserList } from 'pages/user/user-list/user-list'
import { CreateFlow } from 'pages/flow/create-flow/create-flow'
import { EditFlow } from 'pages/flow/edit-flow/edit-flow'
import { FlowPresets } from 'pages/flow/flow-presets/flow-presets'
import { UserForm } from 'pages/user/user-form/user-form'
import { ServiceForm } from 'pages/service/service-form/service-form'
import { BookingPage } from 'pages/booking/booking-page/booking-page'
import { BookingDraft } from 'pages/booking/booking-draft/booking-draft'
import { CalendarPage } from 'pages/calendar-page/calendar-page'
import { Config, Feature } from 'types'
import { AppForm } from 'pages/app/app-form/app-form'
import { RegisteredAppsList } from 'pages/app/registered-apps-list/registered-apps-list'
import { AppLoader } from 'pages/app/app-loader/app-loader'
import { AllAppsList } from 'pages/app/all-apps-list/all-apps-list'
import { AppPermissionPage } from 'pages/app/app-permissions/app-permissions'
import { ErrorPage400, ErrorPage404, ErrorPage500 } from 'pages/error-page/error-page'
import { InstalledAppsList } from 'pages/app/installed-apps-list/installed-apps-list'
import { TokenForm } from 'pages/token/token-form/token-form'
import { ResourcesList } from '../pages/resource/resource-list/resource-list'
import { ResourceForm } from '../pages/resource/resource-form/resource-form'
import { LocationList } from '../pages/location/location-list/location-list'
import { LocationForm } from '../pages/location/location-form/location-form'
import { BookingSuccess } from 'pages/booking/booking-success/booking-success'

//isMenuDisabled:
export const isMenuEnable = (navTo: string, config: Config) => {
    switch(navTo){
        case '/flow':
            return config.features.includes(Feature.FLOWS)
        default:
            return true
    }
}

//isMenuHidden:
export const isMenuVisible = (navTo: string, config: Config) => {
    switch(navTo){
        case '/app':
        case '/developers':
            return config.features.includes(Feature.APPS)
        case '/token':
            return config.features.includes(Feature.PERSONAL_ACCESS_TOKEN)
        default:
            return true
    }
}

//getBookingRoute:
const getBookingRoute = () => (
    {
        path: 'book',
        children: [
            {
                path: '',
                element: <BookingPage/>
            },
            {
                path: ':serviceId',
                element: <BookingPage/>
            },
            {
                path: 'success/:appointmentId',
                element: <BookingSuccess/>
            },
            {
                path: 'draft/:shopifyBookingId',
                element: <BookingDraft/>
            }
        ]
    }
)

//routes:
export const routes: RouteObject[] = [
    {
        path: '/login',
        element: <Login/>
    },
    {
        path: '/password-reset',
        element: <PasswordReset/>
    },
    {
        path: '/message',
        element: <MessagePage/>
    },
    {
        path: '400',
        element: <ErrorPage400/>
    },
    {
        path: '500',
        element: <ErrorPage500/>
    },
    {
        path: '*',
        element: <ErrorPage404/>
    },
    {
        path: '/',
        element: <Layout/>,
        children: [
            {
                path: '',
                element: <Dashboard/>
            },
            {
                path: 'profile',
                element: <Profile/>
            },
            {
                path: 'appointments',
                element: <AppointmentPage/>
            },
            {
                path: 'calendar',
                children: [
                    {
                        path: '',
                        element: <CalendarPage/>
                    },
                    {
                        path: 'reschedule/:appointmentId',
                        element: <BookingPage/>
                    },
                    getBookingRoute()
                ]
            },
            {
                path: 'location',
                children: [
                    {
                        path: '',
                        element: <LocationList/>
                    },
                    {
                        path: 'new',
                        element: <LocationForm/>
                    },
                    {
                        path: ':id',
                        element: <LocationForm/>
                    }
                ]
            },
            {
                path: 'service',
                children: [
                    {
                        path: '',
                        element: <ServiceList/>
                    },
                    {
                        path: ':id',
                        element: <ServiceForm/>
                    },
                    getBookingRoute()
                ]
            },
            {
                path: 'resource',
                children: [
                    {
                        path: '',
                        element: <ResourcesList/>
                    },
                    {
                        path: 'new',
                        element: <ResourceForm/>
                    },
                    {
                        path: ':id',
                        element: <ResourceForm/>
                    }
                ]
            },
            {
                path: 'flow',
                children: [
                    {
                        path: '',
                        element: <FlowList/>
                    },
                    {
                        path: 'presets',
                        element: <FlowPresets/>
                    },
                    {
                        path: 'new',
                        element: <CreateFlow/>
                    },
                    {
                        path: 'new/:presetId',
                        element: <CreateFlow/>
                    },
                    {
                        path: ':id',
                        element: <EditFlow/>
                    }
                ]
            },
            {
                path: 'user',
                children: [
                    {
                        path: '',
                        element: <UserList/>
                    },
                    {
                        path: 'new',
                        element: <UserForm/>
                    },
                    {
                        path: ':id',
                        element: <UserForm/>
                    }
                ]
            },
            {
                path: 'token',
                children: [
                    {
                        path: '',
                        element: <TokenList/>
                    },
                    {
                        path: 'new',
                        element: <TokenForm/>
                    }
                ]
            },
            {
                path: 'app',
                children: [
                    {
                        path: '',
                        element: <InstalledAppsList/>
                    },
                    {
                        path: 'all',
                        element: <AllAppsList/>
                    },
                    {
                        path: ':id',
                        element: <AppLoader/>
                    },
                    {
                        path: 'permission',
                        element: <AppPermissionPage/>
                    },
                ]
            },
            {
                path: 'developers',
                children: [
                    {
                        path: '',
                        element: <RegisteredAppsList/>
                    },
                    {
                        path: 'register',
                        element: <AppForm/>
                    },
                    {
                        path: ':id',
                        element: <AppForm/>
                    }
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        path: '',
                        element: <SettingsPage/>
                    },
                    {
                        path: 'plans',
                        element: <Plans/>
                    }
                ]
            }
        ]
    }
]
