import { TranslateFunction }  from 'old/utils'
import { array, object, string } from 'yup'

type Person = {
    id: string
    email: string
    firstName: string | null
    lastName: string | null
    image: {
        id: string | null
        path: string | null
    }
}

export type Me = Person & {
    locale: string
    loginStrategy: LoginStrategy | null
}

export type User = Person & {
    permissions: string[]
    permissionsAccepted: boolean
}

export enum LoginStrategy {
    MAGIC_LINK = 'MagicLink',
    PASSWORD = 'Password',
    GOOGLE = 'Google',
    APPLE = 'Apple',
    FACEBOOK = 'Facebook',
    TWEETER = 'Tweeter'
}

export const CreateUserFormSchema = (__: TranslateFunction) => object({
    email: string().required(__`this_field_is_required`).email(__`must_be_a_valid_email`),
    permissions: array().min(1)
})

export const UpdateUserFormSchema = () => object({
    permissions: array().min(1)
})
