import { Shop } from 'types'
import {
    getShopId,
    useQueryHandler,
    usePaginationHandler,
    updateShopId,
    isNil,
    getRefreshTokenUserId,
    refreshToken
} from 'utils'

const shopQuery = `
    id
    name
    image {
        id
        path
    }
    url
    isMigrated
`

//current shop:
export const useCurrentShop = () => {

    const shopId = getShopId()
    const hasShopId = shopId !== ''

    const removeShop = () => {
        updateShopId(null)
    }
    
    //handler:
    return useQueryHandler<Shop>({
        query: hasShopId ? `
            query getShop(
                $id: String!
            ){
                getShop(
                    id: $id
                ){
                    ${shopQuery}
                }
            }
        `: `
            query getShops(
                $limit: Int!
            ){
                getShops(
                    limit: $limit
                ){
                    data{
                        ${shopQuery}
                    }
                }
            }
        `,
        variables: hasShopId ? {
            id: shopId
        } : {
            limit: 1
        },
        cacheKey: ['currentShop', shopId, getRefreshTokenUserId(refreshToken())],
        enabled: !isNil(refreshToken()),
        onSuccess: json => {
            const shop: Shop = hasShopId ? json.data.getShop : json.data.getShops.data[0]
            if(!hasShopId){
                updateShopId(shop.id)
            }
            return shop
        },
        onNotFound: removeShop,
        onForbidden: removeShop,
        onUnknownError: removeShop
    })

}

//useShops:
export const useShops = (search: string | null, limit: number) => usePaginationHandler<Shop[]>({
    query: `
        query getShops(
            $searchTerm: String,
            $limit: Int!,
            $after: String,
            $before: String
        ){
            getShops(
                searchTerm: $searchTerm,
                limit: $limit,
                after: $after,
                before: $before
            ){
                data{
                    ${shopQuery}
                }
                totalItems
                remainingItems
                nextCursor
                previousCursor
            }
        }
    `,
    variables: {
        searchTerm: search,
        limit,
        after: null,
        before: null
    },
    cacheKey: ['shops', getRefreshTokenUserId(refreshToken())],
    limit,
    getResponse: json => json.data.getShops,
    onSuccess: response => response.data
})
