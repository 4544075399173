import { VerticalContainer } from 'components/styled'
import { Button, Typography, theme } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { PropsWithChildren } from 'react'
const { Text, Paragraph } = Typography

type Props = {
    title: string
    description?: string
    onBack: () => void
}

export const UserPermissionHeader = ({ title, description, children, onBack }: PropsWithChildren<Props>) => {

    //theme:
    const { token: { padding, paddingXS, fontSize, fontSizeSM, fontSizeHeading4 } } = theme.useToken()

    return (
        <VerticalContainer style={{ alignItems: 'flex-start', padding: `${paddingXS}px ${paddingXS}px 0 ${paddingXS}px`, gap: 0 }}>

            {/* back button */}
            <Button
                type='text'
                shape='circle'
                size='large'
                onClick={onBack}
            >
                <LeftOutlined style={{ fontSize }}/>
            </Button>

            <VerticalContainer style={{ paddingLeft: padding, paddingRight: padding, width: '100%' }}>
            
                {/* title */}
                <Text style={{ fontSize: fontSizeHeading4, fontFamily: 'inter-medium' }}>{title}</Text>

                {/* description */}
                {description && <Paragraph type='secondary' style={{ fontSize: fontSizeSM, margin: 0 }}>{description}</Paragraph>}

                {children}

            </VerticalContainer>

        </VerticalContainer>
    )
    
}
