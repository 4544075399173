import { Button, Dropdown, InputRef, MenuProps, Typography, theme } from 'antd'
import { PropsWithChildren, ReactNode, Ref } from 'react'
import { LeftOutlined, RightOutlined, MoreOutlined } from '@ant-design/icons'
import { objectAssign, useTranslate, useWindowDimensions }  from 'old/utils'
import { ButtonType } from 'antd/es/button'
import { HorizontalContainer, VerticalContainer } from 'old/components/styled'
import { Search } from 'old/components/search/search'
const  { Text, Title, Paragraph } = Typography

type TopButton = {
    label: string
    icon?: ReactNode
    type?: ButtonType
    style? : object
    onClick: () => void
}

type TopLink = {
    label: string
    icon?: ReactNode
    style? : object
    href: string
}

export type TopNav = {
    pageNum: number
    totalPage: number
    isNextDisabled?: boolean
    isPreviousDisabled?: boolean
    onNext: () => void
    onPrevious: () => void
}

type Props = {
    title?: string | ReactNode
    footer?: string
    topButton?: TopButton
    topLink?: TopLink
    topNav?: TopNav
    forceDropdown?: boolean
    searchInput?: {
        placeholder?: string
        inputRef?: Ref<InputRef>
        onType?: (isTyping: boolean) => void
        onSearch: (search: string | null) => void
    }
    style?: object
    childrenWrapperStyle?: object
}

export const FormSection = ({
    title,
    footer,
    topButton,
    topLink,
    topNav,
    forceDropdown = false,
    searchInput,
    style,
    childrenWrapperStyle,
    children
}: PropsWithChildren<Props>) => {
    
    //theme:
    const { token: {
        fontSizeSM,
        colorTextSecondary,
        marginXXS,
        marginXS,
        marginSM,
        margin,
        paddingXXS,
        padding,
        paddingMD,
        paddingLG,
        colorBgContainer,
        colorBorder,
        borderRadiusLG,
        boxShadowTertiary
    }} = theme.useToken()

    //translate:
    const { __ } = useTranslate()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //menu:
    const items: MenuProps['items'] = []
    if(topButton){
        items.push({ key: topButton.label, label: topButton.label })
    }
    if(topLink){
        items.push({ key: topLink.label, label: <a onClick={e => e.stopPropagation()} href={topLink.href}>{topLink.label}</a> })
    }

    //onClick:
    const onClick: MenuProps['onClick'] = ({ key }) => {
        switch(key){
            case topButton?.label:
                topButton?.onClick()
                break
        }
    }

    //border:
    const border = SM ? `solid 1px ${colorBorder}` : 'none'

    //render:
    return (
        <VerticalContainer style={objectAssign({
            // preventing the CSS conflict on re-rendering by not using the shortened names
            paddingTop: SM ? paddingMD : 0,
            paddingBottom: SM ? paddingMD : 0,
            backgroundColor: SM ? colorBgContainer : 'transparent',
            borderTop: border,
            borderRight: border,
            borderBottom: border,
            borderLeft: border,
            borderRadius: borderRadiusLG*2,
            boxShadow: SM ? boxShadowTertiary : 'none',
            gap: SM ? margin : marginSM
        }, style)}>
            
            {/* header */}
            {(title || searchInput || topNav || items.length > 0) && (

                <HorizontalContainer style={{
                    padding: SM ? `0 ${paddingLG}px` : `0 ${padding}px`,
                    alignItems: SM ? 'center' : 'flex-start'
                }}>

                    <HorizontalContainer style={{
                        flex: 1,
                        flexDirection: SM ? 'row' : 'column',
                        justifyContent: 'space-between',
                        alignItems: SM ? 'center' : 'flex-start',
                        gap: SM ? marginXXS : 0
                    }}>

                        {/* title */}
                        {title && (typeof title === 'object' ? title :
                            <Title
                                level={5}
                                style={{
                                    paddingTop: SM ? 0 : paddingXXS,
                                    margin: `${marginXXS}px 0`
                                }}
                            >
                                {title}
                            </Title>
                        )}

                        {(searchInput || topNav) && (
                            <HorizontalContainer style={{
                                width: SM ? 'auto' : '100%',
                                paddingTop: SM ? 0 : paddingXXS
                            }}>

                                {/* search */}
                                {searchInput &&
                                    <div style={{
                                        width: '100%',
                                        maxWidth: SM ? (topNav ? 200 : 230) : undefined,
                                    }}>
                                        <Search
                                            placeholder={searchInput.placeholder}
                                            inputRef={searchInput.inputRef}
                                            onType={searchInput.onType}
                                            onSearch={searchInput.onSearch}
                                        />
                                    </div>
                                }

                                {topNav && (
                                    <HorizontalContainer>

                                        {/* previous */}
                                        <Button
                                            title={__`previous`}
                                            size={'middle'}
                                            icon={<LeftOutlined style={{ fontSize: fontSizeSM }}/>}
                                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                                            disabled={topNav.isPreviousDisabled}
                                            onClick={topNav.onPrevious}
                                        />

                                        <Text style={{ whiteSpace: 'nowrap', fontSize: fontSizeSM, color: colorTextSecondary}}>
                                            {`${topNav.pageNum}/${topNav.totalPage}`}
                                        </Text>
                                        
                                        {/* next */}
                                        <Button
                                            title={__`next`}
                                            size={'middle'}
                                            icon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                                            disabled={topNav.isNextDisabled}
                                            onClick={topNav.onNext}
                                        />
                                        
                                    </HorizontalContainer>
                                )}

                            </HorizontalContainer>
                            
                        )}

                    </HorizontalContainer>

                    {items.length > 0 && (

                        <HorizontalContainer style={{ marginRight: SM ? -marginXS : 0 }}>

                                {(!SM || forceDropdown) ? (

                                    //dropdown
                                    <Dropdown menu={{ items, onClick }} trigger={['click']}>
                                        <Button
                                            type={'text'}
                                            shape={'circle'}
                                            size={'large'}
                                            icon={<MoreOutlined/>}
                                            style={{ marginRight: -marginXS }}
                                            onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                            }}
                                        />
                                    </Dropdown>
                
                                ) : (

                                    <HorizontalContainer>

                                        {/* top button */}
                                        { topButton &&
                                            <Button
                                                type={topButton.type ?? 'link'}
                                                icon={topButton?.icon}
                                                style={topButton.style}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    topButton?.onClick()
                                                }}
                                            >
                                                {topButton?.label}
                                            </Button>
                                        }

                                        {/* top link */}
                                        { topLink &&
                                            <Button
                                                type='link'
                                                icon={topLink?.icon}
                                                style={topLink.style}
                                                href={topLink?.href}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                {topLink?.label}
                                            </Button>
                                        }

                                    </HorizontalContainer>
                                )}

                        </HorizontalContainer>

                    )}

                </HorizontalContainer>
                
            )}

            {/* content */}
            <div style={objectAssign({
                padding: SM ? `0 ${paddingLG}px` : `0 ${padding}px`
            }, childrenWrapperStyle)}>
                {children}
            </div>
            
            {/* footer */}
            {footer &&
                <Paragraph
                    type='secondary'
                    style={{
                        margin: 0,
                        padding: SM ? `0 ${paddingLG}px` : `0 ${padding}px`
                    }}
                >
                    {footer}
                </Paragraph>
            }
            
        </VerticalContainer>
    )

}
