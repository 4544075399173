import { PermissionTree, User } from 'types'
import {
    useMutationHandler,
    usePaginationHandler,
    useQueryHandler,
    getShopId
} from 'utils'

//useUsers:
export const useUsers = (search: string | null, limit: number) => {

    //shop id:
    const shopId = getShopId()

    return usePaginationHandler<User[]>({
        query: `
            query getUsers (
                
                $shop: String!,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String

            ) {
                getUsers(

                    shop: $shop,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                    
                ) {
                    
                    data {

                        id
                        email
                        firstName
                        lastName
                        
                        image {
                            id
                            path
                        }

                        permissions
                        permissionsAccepted

                    }

                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                    
                }
            }
        `,
        variables: {
            shop: shopId,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['users', shopId],
        limit,
        getResponse: json => json.data.getUsers,
        onSuccess: response => response.data
    })

}

//user:
export const useUser = (id: string, onNotFound?: (json: any) => void) => {

    //shop id:
    const shopId = getShopId()
    
    return useQueryHandler<User>({
        query: `
            query getUser (

                $shop: String!,
                $id: String!

            ) {
                getUser (

                    shop: $shop,
                    id: $id

                ) {

                    id
                    email
                    firstName
                    lastName

                    image {
                        id
                        path
                    }

                    permissions
                    permissionsAccepted

                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        cacheKey: ['user', id, shopId],
        onSuccess: json => json.data.getUser,
        onNotFound
    })
}

//update:
export const useCreateUser = (user: User) => {

    //shop id:
    const shopId = getShopId()
    
    return useMutationHandler<boolean | 'currentUser' | 'existingUser'>({
        query: `
            mutation createUser (
                $request: CreateUserRequest!
            ) {
                createUser (
                    request: $request
                ) {
                    email
                }
            }
        `,
        variables: {
            request: {
                shop: shopId,
                email: user.email,
                permissions: user.permissions
            }
        },
        onSuccess: json => json.data.createUser.email === user.email,
        onError: json => {
            if(json.errors[0].extensions.errors[0].constraints[0].name === 'current_user'){
                return 'currentUser'
            }else if(json.errors[0].extensions.errors[0].constraints[0].name === 'existing_user'){
                return 'existingUser'
            }
        }
    })

}

//update user:
export const useUpdateUser = (user: User) => {

    //shop id:
    const shopId = getShopId()
    
    return useMutationHandler<boolean>({
        query: `
            mutation updateUser (

                $id: String!,
                $request: UpdateUserRequest!

            ) {
                updateUser (

                    id: $id,
                    request: $request

                ) {
                    id
                }
            }
        `,
        variables: {
            id: user.id,
            request: {
                shop: shopId,
                permissions: user.permissions
            }
        },
        onSuccess: json => json.data.updateUser.id === user.id
    })
}

//usePermissionTree:
export const usePermissionTree = () => useQueryHandler<PermissionTree>({
    query: `
        query getPermissionTree{
            getPermissionTree {
                tree
            }
        }
    `,
    cacheKey: ['permissionTree'],
    onSuccess: json => json.data.getPermissionTree.tree
})

//remove:
export const useUserRemove = (id: string) => {

    //shop id:
    const shopId = getShopId()
    
    return useMutationHandler<boolean>({
        query: `
            mutation deleteUser (

                $shop: String!,
                $id: String!

            ) {
                deleteUser (

                    shop: $shop,
                    id: $id

                ) {
                    id
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.deleteUser.id === id
    })
    
}
