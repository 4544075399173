import { CalendarContainer, CalendarLoadingContainer, MessageContainer } from './calendar-page-calendar.style'
import { CalendarPageCell } from '../calendar-page-cell/calendar-page-cell'
import { CalendarProvider } from 'context/calendar-provider'
import { useCalendarSlots, useConfig } from 'api'
import { useEffect, useMemo } from 'react'
import { Calendar } from 'components/calendar/calendar/calendar'
import { Loading, NotFound, PageError, WhiteShadowLoading } from 'components/styled'
import { calcTimezoneOffset, getIsoStringYearMonthDay, useTranslate, useWindowDimensions } from 'utils'
import { CalendarSlot, Resource, SimpleResourceWithSlots } from 'types'
import { CALENDAR_PAGE_CALENDAR_SHADOW_Z_INDEX } from 'z-indexes'

type CalendarPageCellProps = {
    date: Date
    selectedResources: Resource[] | undefined
    scale: number
    setAreSlotsLoading: (areSlotsLoading: boolean) => void
}

export const CalendarPageCalendar = ({ date, selectedResources, scale, setAreSlotsLoading }: CalendarPageCellProps) => {

    //hooks:
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config } = useConfig()

    //slots:
    const { data, isLoading, isFetching, isPreviousData } = useCalendarSlots(
        selectedResources?.map(m => m.id) || [],
        date,
        (selectedResources?.length ?? 0) > 0
    )

    //data model:
    const dataModel = useMemo(() => {
        
        if(data){

            const resources: SimpleResourceWithSlots[] | undefined = selectedResources?.map(selectedResource => {

                // find the slots:
                let slots: CalendarSlot[] = []
                data.resources.map(mem => {
                    if(mem.id === selectedResource.id){
                        slots = mem.slots
                    }
                })

                // make new object:
                return {
                    id: selectedResource.id,
                    type: selectedResource.type,
                    name: selectedResource.name,
                    image: selectedResource.image,
                    description: selectedResource.description,
                    slots
                }

            })

            return [[{
                date,
                info: {
                    selectedResources,
                    resources,
                    now: data.now,
                    timezoneOffset: calcTimezoneOffset(data.now),
                    scale
                }
            }]]

        }else{
            return getDefaultDataModel(date, scale)
        }

    }, [data, isLoading, scale])

    // update slots loading:
    useEffect(() => setAreSlotsLoading(isFetching), [isFetching])

    return (!config ? null : (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'relative'
        }}>

            <CalendarLoadingContainer style={{
                overflowX: SM ? 'auto' : 'visible',
                overflowY: SM ? 'hidden' : 'visible'
            }}>

                {/* calendar */}
                <CalendarContainer>
                    <CalendarProvider dataModel={dataModel}>
                        <Calendar cellRenderer={date => <CalendarPageCell date={date} config={config}/>}/>
                    </CalendarProvider>
                </CalendarContainer>

            </CalendarLoadingContainer>

            {/* shadow */}
            {(!data || isPreviousData) && (
                <>
                    <WhiteShadowLoading
                        hasLoading={false}
                        style={{ zIndex: CALENDAR_PAGE_CALENDAR_SHADOW_Z_INDEX }}
                    >

                        <MessageContainer>
                            {
                                selectedResources && selectedResources.length === 0 ? (
                                    <NotFound message={__`select_a_resource`}/>
                                ) : data === null ? (
                                    <PageError/>
                                ) : (
                                    <Loading style={{ width: 'auto', height: 'auto' }}/>
                                )
                            }
                        </MessageContainer>
                        
                    </WhiteShadowLoading>
                </>
            )}

        </div>
    ))
    
}

//getDefaultDataModel:
const getDefaultDataModel = (date: Date, scale: number) => [[{
    date,
    info: {
        selectedResources: [],
        resources: [],
        now: `${getIsoStringYearMonthDay(new Date())} 09:00`,
        timezoneOffset: 0,
        scale
    }
}]]
