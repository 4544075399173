import { AppContexts } from './app.contexts'
import reportWebVitals from './report-web-vitals'
import ReactDOM from 'react-dom/client'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import './index.scss'

//start week from Monday:
dayjs.extend(updateLocale)
dayjs.updateLocale('en', { weekStart: 1 })

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(<AppContexts/>)

reportWebVitals()
