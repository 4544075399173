import { ContentWrapper, MainWrapper } from './availability-calendar-cell.style'
import { useConfig } from 'old/api'
import { RetweetOutlined } from '@ant-design/icons'
import { Button, Typography, theme } from 'antd'
import { useCell }  from 'old/context/calendar-provider'
import { Availability, AvailabilityInterval, Config }  from 'old/types'
import { getIsoStringYearMonthDay, isSameDay, useTranslate, useWindowDimensions } from 'old/utils'
import { HorizontalContainer, Label, VerticalContainer } from 'old/components/styled'
const { Text, Paragraph } = Typography

const emptyAvailability = {
    type: 'date',
    date: '',
    weekday: null,
    intervals: []
} satisfies Availability

type Props = {
    date: Date
    index: number
    onClick: (availability?: Availability) => void
}

export const AvailabilityCalendarCell = ({ date, index, onClick }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { XL } = useWindowDimensions()
    const { token: {
        marginXS,
        marginXXS,
        marginSM,
        paddingXS,
        fontSizeHeading3,
        colorTextDescription,
        fontSizeSM,
        colorTextSecondary,
        colorInfoBorder
    }} = theme.useToken()
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //info:
    const info = useCell(date)
    const availabilities: Availability[] = info.availabilities

    //availability:
    let availability: Availability = emptyAvailability
    if(availabilities.length > 0){

        //generic rule:
        availabilities.map((ava: Availability) => {
            if(ava.type === 'wday'){
                availability = {...ava}
            }
        })

        //override the generic rule:
        availabilities.map((ava: Availability) => {
            if(ava.type === 'date'){
                availability = {...ava}
            }
        })

    }

    const getDay = (date: Date, config: Config) => date.toLocaleString([config.locale, 'en-US'], { day: '2-digit' })
    const getMonth = (date: Date, config: Config) => date.toLocaleString([config.locale, 'en-US'], { month: 'short' })
    const isToday = isSameDay(date, new Date())

    return (isConfigLoading || !config) ? null : (
        <MainWrapper
            index={index}
            isAvailable={availability.intervals.length > 0}
        >

            <Button
                type='text'
                style={{
                    width: '100%',
                    height: '100%',
                    padding: `${marginXXS}px ${XL ? marginXS : marginSM}px`,
                    borderRadius: 0,
                    fontFamily: 'inter-medium',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
                title={isToday ? __`today` : undefined}
                onClick={() => onClick(availability)}
            >

                <ContentWrapper>

                    <HorizontalContainer style={{ width: '100%', alignItems: 'flex-start' }}>

                        <Paragraph style={{ margin: 0 }}>

                            <Text style={{
                                fontSize: fontSizeHeading3,
                                borderBottom: isToday ? `solid 3px ${colorInfoBorder}` : 'none'
                            }}>
                                {getDay(date, config)}
                            </Text>

                            <Text style={{
                                marginLeft: marginXXS,
                                fontSize: fontSizeSM,
                                color: colorTextDescription
                            }}>
                                {getMonth(date, config)}
                            </Text>

                        </Paragraph>

                        {/* weekday availability indicator */}
                        {availability.type !== 'date' && <RetweetOutlined style={{ paddingTop: paddingXS }}/>}

                    </HorizontalContainer>

                    <VerticalContainer style={{ alignItems: 'flex-start' }}>

                        {availability.intervals.length > 0 ? availability?.intervals.map((interval: AvailabilityInterval) => {
                            const yearMonthDay = getIsoStringYearMonthDay(date)
                            const dateFrom = new Date(`${yearMonthDay} ${interval.from}`)
                            const dateTo = new Date(`${yearMonthDay} ${interval.to}`)
                            return (
                                <Text
                                    key={`${interval.from}.${interval.to}`}
                                    style={{
                                        fontSize: fontSizeSM,
                                        color: colorTextSecondary,
                                        fontFamily: 'inter-medium'
                                    }}
                                >
                                    {
                                        `• 
                                        ${dateFrom.toLocaleString([config.locale, 'en-US'], { hour: 'numeric', minute: '2-digit' })}
                                        ${__`to`}
                                        ${dateTo.toLocaleString([config.locale, 'en-US'], { hour: 'numeric', minute: '2-digit' })}
                                        `
                                    }
                                </Text>
                            )
                        }) : (
                            <Label
                                text={__`unavailable`}
                                type='disable'
                                size='tiny'
                            />
                        )}

                    </VerticalContainer>

                </ContentWrapper>

            </Button>
            
        </MainWrapper>
    )
    
}
