import { PageHeader } from 'old/components/page-header/page-header'
import { useTranslate, useWindowDimensions }  from 'old/utils'
import { Loading, PageContentWrapper, PageError, VerticalContainer } from 'old/components/styled'
import { Alert, Typography, theme } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { FormSection } from 'old/components/form-section/form-section'
import { AppointmentList } from 'old/components/appointment/appointment-list/appointment-list'
import { AppointmentFilter } from 'old/components/appointment/appointment-navigation/appointment-navigation'
import { useMe, useNotification } from 'old/api'
import { useNavigate } from 'react-router-dom'
const { Title } = Typography

export const Dashboard = () => {

    //theme:
    const { token: {
        marginXS,
        margin,
        marginLG,
        paddingXS,
        paddingXXS,
        padding,
        fontSizeSM
    }} = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //translate:
    const { __ } = useTranslate()

    //navigation:
    const navigate = useNavigate()
    
    //me:
    const { data: me, isLoading: isMeLoading } = useMe()
    
    //notifications:
    const { data: notification, isLoading: isNotificationLoading } = useNotification()

    //render:
    return (
        isMeLoading ? <Loading/> : !me ? <PageError/> : (
        
            <PageContentWrapper>

                <VerticalContainer style={{ gap: margin }}>

                    {/* header */}
                    <div> {/* stops the header to be sticky on tablet */}
                        <PageHeader
                            title={
                                <Title
                                    level={3}
                                    style={{
                                        margin: 0,
                                        fontWeight: 'normal'
                                    }}
                                >
                                    {`${__`welcome`}, ${me.firstName ?? me.lastName ?? me.email}!`}
                                </Title>
                            }
                            description={__`check_whats_happening_in_your_sesami_world_your_service_commerce`}
                        />
                    </div>

                    <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: marginLG }}>

                        {/* notification */}
                        {isNotificationLoading ? null :
                         notification === null ? null :
                        (notification === false || notification === undefined) ? (
                            <PageError
                                message={__`error_cannot_check_notifications`}
                                style={{ height: 'fit-content' }}
                            />
                        ) : notification.id && (
                            <div style={{ padding: SM ? 0 : `0 ${padding}px`}}>
                                <Alert
                                    type={notification.type}
                                    message={notification.message}
                                    description={<div dangerouslySetInnerHTML={{ __html: notification.description }}/>}
                                    showIcon
                                    closable
                                />
                            </div>
                        )}

                        {/* appointments list */}
                        <FormSection
                            title={__`upcoming_appointments`}
                            topButton={{
                                label: __`view_all`,
                                icon: <RightOutlined style={{ fontSize: fontSizeSM }}/>,
                                style: {
                                    padding: `${paddingXS}px ${paddingXXS}px `,
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    alignItems: 'center',
                                    gap: marginXS
                                },
                                onClick: () => navigate('/appointments')
                            }}
                            style={{ gap: margin }}
                            childrenWrapperStyle={{ padding: 0 }}
                        >

                            <AppointmentList
                                teamMemberId={null}
                                search={null}
                                filter={AppointmentFilter.UPCOMING}
                                start={null}
                                end={null}
                            />

                        </FormSection>

                    </VerticalContainer>

                </VerticalContainer>

            </PageContentWrapper>

        )
    )

}
