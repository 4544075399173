import { Availability }  from 'old/types'
import { TeamMemberSelect } from 'old/components/team-member/team-member-select/team-member-select'
import { theme } from 'antd'
import { useWindowDimensions }  from 'old/utils'

type Props = {
    onSelect: (availabilities: Availability[]) => void
}

export const TeamMemberCopy = ({ onSelect }: Props) => {

    //theme:
    const { token: { margin, marginLG, paddingXXS, paddingSM } } = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //render:
    return (
        <TeamMemberSelect
            selectedId={null}
            onSelect={member => onSelect(member?.availabilities ?? [])}
            wrapperStyle={{
                paddingTop: paddingXXS,
                paddingBottom: paddingSM,
                margin: `0 ${SM ? -marginLG : -margin}px`,
                borderTop: 'none',
                borderRight: 'none',
                borderBottom: 'none',
                borderLeft: 'none',
                boxShadow: 'none'
            }}
        />
    )

}
