import { UserPermissionLevelSelect } from 'components/user/user-permission-level-select/user-permission-level-select'
import { UserPermissionResourceSelect } from 'components/user/user-permission-resource-select/user-permission-resource-select'
import { UserPermissionTypeSelect } from 'components/user/user-permission-type-select/user-permission-type-select'
import { UserPermissionServiceSelect } from 'components/user/user-permission-service-select/user-permission-service-select'
import { UserPermissionFlowSelect } from 'components/user/user-permission-flow-select/user-permission-flow-select'
import { InheritedPermissions } from 'components/user/user-permission-list/user-permission-list'
import { PermissionLevel, PermissionType, Shop } from 'types'
import { useState } from 'react'
import { Loading, PageError } from 'components/styled'
import { useCurrentShop } from 'api'

enum Content {
    None,
    PermissionTypeSelect,
    PermissionLevelSelect,
    ResourceSelect,
    ServiceSelect,
    FlowSelect
}

type Props = {
    inheritedPermissions: InheritedPermissions
    onAdd: (permission: string) => void
    onBack: () => void
}

export const UserPermissionRouter = ({ inheritedPermissions, onAdd, onBack }: Props) => {

    //shop:
    const { data: shop, isLoading } = useCurrentShop()

    //state:
    const [ content, setContent ] = useState(Content.PermissionTypeSelect)
    const [ previousContent, setPreviousContent ] = useState(Content.None)
    const [ permission, setPermission ] = useState<string | null>(null)
    
    //handleTypeSelect:
    const handleTypeSelect = (type: PermissionType, shop: Shop) => {
        setPreviousContent(Content.PermissionTypeSelect)
        switch(type){
            case PermissionType.Shop:
                setContent(Content.PermissionLevelSelect)
                setPermission(`${PermissionType.Shop}:${shop.id}`)
                break
            case PermissionType.Resource:
                setContent(Content.ResourceSelect)
                break
            case PermissionType.Service:
                setContent(Content.ServiceSelect)
                break
            case PermissionType.Flow:
                setContent(Content.FlowSelect)
                break
        }
    }

    //handleObjectSelect:
    const handleObjectSelect = (permission: string, currentContent: Content) => {
        setPermission(permission)
        setContent(Content.PermissionLevelSelect)
        setPreviousContent(currentContent)
    }

    //handleLevelSelect:
    const handleLevelSelect = (permission: string, level: PermissionLevel) => {
        onAdd(`${permission}:${level}`)
        setPermission(null)
        setTimeout(() => {
            setContent(Content.PermissionTypeSelect)
            setPreviousContent(Content.None)
        }, 500)
        onBack()
    }

    //handleObjectBack:
    const handleObjectBack = () => {
        setPermission(null)
        setContent(Content.PermissionTypeSelect)
        setPreviousContent(Content.None)
    }

    //handleLevelBack:
    const handleLevelBack = (previousContent: Content) => {
        setPermission(null)
        setContent(previousContent)
        setPreviousContent(Content.PermissionTypeSelect)
    }

    return (
        (isLoading ? <Loading/> : !shop ? <PageError/> :
        <div style={{ height: '100%' }}>{
            
            //type:
            content === Content.PermissionTypeSelect ?

                <UserPermissionTypeSelect
                    inheritedPermissions={inheritedPermissions}
                    onSelect={type => handleTypeSelect(type, shop)}
                    onBack={onBack}
                />

            //resource:
            : content === Content.ResourceSelect ?

                <UserPermissionResourceSelect
                    onSelect={id => handleObjectSelect(`${PermissionType.Resource}:${id}`, Content.ResourceSelect)}
                    onBack={handleObjectBack}
                />

            //service:
            : content === Content.ServiceSelect ?

                <UserPermissionServiceSelect
                    onSelect={id => handleObjectSelect(`${PermissionType.Service}:${id}`, Content.ServiceSelect)}
                    onBack={handleObjectBack}
                />
            
            //flow:
            : content === Content.FlowSelect ?

                <UserPermissionFlowSelect
                    onSelect={id => handleObjectSelect(`${PermissionType.Flow}:${id}`, Content.FlowSelect)}
                    onBack={handleObjectBack}
                />

            //level:
            :

                <UserPermissionLevelSelect
                    onSelect={level => {permission && handleLevelSelect(permission, level)}}
                    onBack={() => handleLevelBack(previousContent)}
                />
            
        }</div>
    ))
    
}
