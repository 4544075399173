import { Availability, AvailabilityInterval } from 'types'
import { CalendarPageAvailabilitySlot } from 'components/calendar-page/calendar-page-availability-slot/calendar-page-availability-slot'
import { getIsoStringYearMonthDay } from 'utils'
import { theme } from 'antd'

type Props = {
    currentDay: Date
    availabilities: Availability[]
    dayStartHour: number
    scale: number
}

export const CalendarPageAvailabilityColumn = ({ currentDay, availabilities, dayStartHour, scale }: Props) => {

    const { token: { colorFillQuaternary } } = theme.useToken()
    
    return (
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: colorFillQuaternary,
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: 0
        }}>
            {availabilities.map(availability => (
                getAvailabilityCorrectInterval(currentDay, availability).map(interval =>
                    <CalendarPageAvailabilitySlot
                        key={`${availability.type}-${interval.from}-${interval.to}`}
                        dayStartHour={dayStartHour}
                        from={interval.from}
                        to={interval.to}
                        scale={scale}
                    />
                )
            ))}
        </div>
    )
    
}

//getAvailabilityCorrectInterval
const getAvailabilityCorrectInterval = (currentDay: Date, availability: Availability) => {

    let intervals: AvailabilityInterval[] = []
    const currentWeekDay = currentDay.toLocaleString(['en-US'], { weekday: 'long' }).toLowerCase()

    if(availability.type === 'wday'){
        if(availability.weekday === currentWeekDay){
            intervals = availability.intervals
        }
    }else if(availability.date === getIsoStringYearMonthDay(currentDay)){
        intervals = availability.intervals
    }

    return intervals

}
